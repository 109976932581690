import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { PlanningCycle } from 'src/models/PlanningCycleModel';
import { convertToLocalReadableFormat, getRemainingTimeDetails, isDateInThePast } from 'src/utils/date-time-utilities';

const useLockStatus = (planningCycle: PlanningCycle | null | undefined, isAdmin: boolean) => {
  const [isCycleLocked, setIsCycleLocked] = useState(false);
  const [lockDateInUserLocal, setLockDateInUserLocal] = useState('');
  const [remainingTime, setRemainingTime] = useState('');

  useEffect(() => {
    // Function to calculate and update lock status
    const calculateLockStatus = () => {
      if (!planningCycle) {
        setIsCycleLocked(true);
        setLockDateInUserLocal('');
        setRemainingTime('-');
        return;
      }

      const lockDateKey = isAdmin ? 'planning_cycle_lock_date' : 'budget_owner_lock_date';
      const lockDateUTC = planningCycle[lockDateKey] || planningCycle.planning_cycle_lock_date;
      const closingDateInLocalTime = moment.utc(lockDateUTC).local().format();

      const isLocked = isDateInThePast(closingDateInLocalTime);
      setIsCycleLocked(isLocked);
      setLockDateInUserLocal(convertToLocalReadableFormat(closingDateInLocalTime));
      setRemainingTime(isLocked ? 'Locked' : getRemainingTimeDetails(closingDateInLocalTime));
    };

    // Initial calculation
    calculateLockStatus();

    // Clear any existing interval before setting a new one
    let intervalId = setInterval(calculateLockStatus, 1000);

    // Cleanup interval on component unmount or effect cleanup
    return () => clearInterval(intervalId);
  }, [planningCycle, isAdmin]); // Re-run effect only if these dependencies change

  return { isCycleLocked, lockDateInUserLocal, remainingTime };
};

export default useLockStatus;
