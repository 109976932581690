import { Badge, Box, BoxProps, Button, Popover, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useAuth } from 'src/features/auth/AuthContextProvider';
import { currentBusinessGroupShortDesc } from '../businessGroupSelectors';
import useLockStatus from './hooks/useLockStatus';
import { planningCycleSelector } from './redux/forecastTemplateSelectors';
import { RootState } from 'src/store/store';
import { useForecastTemplateContext } from './ForecastTemplateContext';
import { convertsUTCtoUserLocalAndReturnsReadableFormat } from 'src/utils/date-time-utilities';

const ForecastTemplateStatusIndicator: React.FC = () => {
  const userAuth = useAuth();
  const isAdmin = userAuth.isDev || userAuth.isAdmin || userAuth.isBusinessLeader;
  const { selectedPlanningCycleSeqId } = useForecastTemplateContext();

  const businessGroupShortDesc = useSelector(currentBusinessGroupShortDesc) || 'default';
  const selectedPlanningCycle = useSelector((state: RootState) =>
    planningCycleSelector(state, businessGroupShortDesc, selectedPlanningCycleSeqId || '')
  );

  const { isCycleLocked, lockDateInUserLocal, remainingTime } = useLockStatus(selectedPlanningCycle, isAdmin);

  const buttonClassName = isCycleLocked ? 'lock-status-button' : 'unlock-status-button';
  const iconName = isCycleLocked ? 'lock-private' : 'unlocked';
  const statusType: BoxProps.Color = isCycleLocked ? 'text-status-error' : 'text-status-success';

  return (
    <SpaceBetween size="m" direction="horizontal">
      {/* TODO <Button>{`Last sync with Prophecy: ${
        selectedPlanningCycle?.last_synced_to_prophecy
          ? convertsUTCtoUserLocalAndReturnsReadableFormat(selectedPlanningCycle?.last_synced_to_prophecy)
          : '-'
      }`}</Button> */}
      <Button>{`Scenario: ${selectedPlanningCycle?.scenario.planning_cycle_name}`}</Button>
      <Popover
        dismissButton={false}
        position="top"
        size="large"
        triggerType="custom"
        content={
          <Box color={statusType}>
            {isCycleLocked ? `Forecast template locked on ${lockDateInUserLocal}` : `Forecast template locks on ${lockDateInUserLocal}`}
          </Box>
        }
      >
        <Button iconAlign="left" className={buttonClassName} iconName={iconName}>
          {remainingTime}
        </Button>
      </Popover>
    </SpaceBetween>
  );
};

export default ForecastTemplateStatusIndicator;
