import { BreadcrumbGroupProps } from '@amzn/awsui-components-react';

export const getPOTaggingTaggingBreadcrumbItems = (
  businessGroupBaseBreadcrumbs: BreadcrumbGroupProps.Item[],
  currentBusinessGroupName?: string
): BreadcrumbGroupProps.Item[] => {
  if (!currentBusinessGroupName) {
    return businessGroupBaseBreadcrumbs;
  }

  return [
    ...businessGroupBaseBreadcrumbs,
    {
      text: 'PO Tagging',
      href: `/${currentBusinessGroupName}/po-tagging`
    }
  ];
};
