import { Box, Container, FormField, Multiselect, Select, SelectProps, SpaceBetween } from '@amzn/awsui-components-react';
import { isEqual } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logger } from 'src/analytics/KatalLogger';
import { eCorpSegmentNames } from 'src/constants/corp-segment-constants';
import { selectAllPlanningCyclesForCurrentGroup, selectForecastPlanningCycles } from 'src/features/planning-cycle/planningCycleSelector';
import { fromPlanningCycleSelectOption, toPlanningCycleSelectOption } from 'src/features/planning-cycle/planningCycleUtils';
import { CorpSegmentFilterSelection, ForecastTemplateCorpSegmentDropdowns } from 'src/models/ForecastModels';
import { PlanningCycle } from 'src/models/PlanningCycleModel';
import { fetchMasterCorpSegmentDropdownValues } from 'src/store/slices/corpSegmentSlice';
import { fetchExpenseTypes } from 'src/store/slices/xPTMapperSlice';
import { AppDispatch, RootState } from 'src/store/store';
import { getMultiSelectPlaceHolderValue, isEmptyObject } from 'src/utils/generic-utilities';
import { currentBusinessGroupShortDesc } from '../businessGroupSelectors';
import { initializeAllCorpSegmentFilters, validateCorpSegmentSelections } from './forecast-utils/ForecastTemplateUtils';
import { useForecastTemplateContext } from './ForecastTemplateContext';
import { corpSegmentSelectionsSelector, planningCycleSelector } from './redux/forecastTemplateSelectors';
import { setCorpSegmentFilter, setPlanningCycleFilter } from './redux/forecastTemplateSlice';
import { clearForecastTemplateCompleteData, fetchForecastTemplateCompleteData } from './redux/forecastTemplateThunks';

const ForecastTemplateFilterSelection: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const businessGroupShortDesc = useSelector(currentBusinessGroupShortDesc) || 'default';
  const relevantPlanningCycles = useSelector(selectAllPlanningCyclesForCurrentGroup);

  const { selectedPlanningCycleSeqId, setSelectedPlanningCycleSeqId, clearAllMessages } = useForecastTemplateContext();

  const [planningCycleOptions, setPlanningCycleOptions] = useState<SelectProps.Options>([]);

  const selectedPlanningCycle = useSelector((state: RootState) =>
    selectedPlanningCycleSeqId ? planningCycleSelector(state, businessGroupShortDesc, selectedPlanningCycleSeqId) : null
  );
  const selectedCorpSegments = useSelector((state: RootState) =>
    selectedPlanningCycleSeqId ? corpSegmentSelectionsSelector(state, businessGroupShortDesc, selectedPlanningCycleSeqId) : {}
  );

  const forecastPlanningCycles = useSelector(selectForecastPlanningCycles);

  const corpSegmentDropdowns = useSelector((state: RootState) => state.forecastTemplateStore.forecastTemplateCorpSegmentDropdowns);
  const isForecastTemplateDataLoading = useSelector((state: RootState) => state.forecastTemplateStore.forecastTemplateDataLoading);
  const isForecastTemplateDataSubmitting = useSelector((state: RootState) => state.forecastTemplateStore.forecastDataSubmitting);

  useEffect(() => {
    const initializePlanningCycleOptions = async () => {
      try {
        setPlanningCycleOptions(forecastPlanningCycles.map(toPlanningCycleSelectOption));
      } catch (error: any) {
        logger.error(`Unable to load Planning Cycle options.`, error);
        setPlanningCycleOptions([]);
      }
    };
    initializePlanningCycleOptions();
  }, [businessGroupShortDesc]);

  useEffect(() => {
    if (selectedPlanningCycle) {
      logger.debug(
        `businessGroupShortDesc, relevantPlanningCycles => useEffect => loaded from selectedPlanningCycle => ${selectedPlanningCycle.scenario_seq_id}`
      );
      validateSelectedPlanningCycleFilter(selectedPlanningCycle);
    } else {
      logger.debug(`businessGroupShortDesc, relevantPlanningCycles => useEffect => loaded from selectFirstPlanningCycleFromRelevant`);
      selectFirstPlanningCycleFromRelevant();
    }
  }, [businessGroupShortDesc, relevantPlanningCycles, selectedPlanningCycle]);

  useEffect(() => {
    if (selectedCorpSegments && !isEmptyObject(selectedCorpSegments)) {
      logger.debug(`corpSegmentDropdowns => useEffect => loaded from selectedCorpSegments`);
      validateSelectedCorpSegmentFilter(selectedCorpSegments);
    } else {
      logger.debug(`corpSegmentDropdowns => useEffect => loaded from initializeCorpSegmentFilters`);
      initializeCorpSegmentFilters();
    }
  }, [corpSegmentDropdowns]);

  // planning cycle - Validates the selection
  const validateSelectedPlanningCycleFilter = (planningCycle: PlanningCycle) => {
    const validPlanningCycle = relevantPlanningCycles.find((cycle) => cycle.scenario_seq_id === planningCycle.scenario_seq_id);
    if (validPlanningCycle) {
      const planningCycleSeqId = validPlanningCycle.scenario_seq_id?.toString() || '';
      setSelectedPlanningCycleSeqId(planningCycleSeqId);
      dispatch(setPlanningCycleFilter({ businessGroupShortDesc, planningCycle: validPlanningCycle }));
      clearAllMessages();
      dispatch(fetchForecastTemplateCompleteData(planningCycleSeqId));
      dispatch(fetchMasterCorpSegmentDropdownValues());
      dispatch(fetchExpenseTypes());
    } else {
      selectFirstPlanningCycleFromRelevant();
    }
  };

  // planning cycle - Selects first available Planning Cycle
  const selectFirstPlanningCycleFromRelevant = () => {
    const firstPlanningCycle = relevantPlanningCycles[0] || null;
    if (firstPlanningCycle) {
      const planningCycleSeqId = firstPlanningCycle.scenario_seq_id?.toString() || '';
      setSelectedPlanningCycleSeqId(planningCycleSeqId);
      dispatch(setPlanningCycleFilter({ businessGroupShortDesc, planningCycle: firstPlanningCycle }));
      clearAllMessages();
      dispatch(fetchForecastTemplateCompleteData(planningCycleSeqId));
    } else {
      console.debug(`No planning cycles available.`);
      setSelectedPlanningCycleSeqId(null);
      dispatch(clearForecastTemplateCompleteData());
    }
  };

  // planning cycle - On change of selection
  const onPlanningCycleFilterChanged = (changeDetail: SelectProps.ChangeDetail) => {
    const selectedPlanningCycle = fromPlanningCycleSelectOption(changeDetail.selectedOption, relevantPlanningCycles);
    const planningCycleSeqId = selectedPlanningCycle?.scenario_seq_id?.toString() || null;
    if (planningCycleSeqId) {
      setSelectedPlanningCycleSeqId(planningCycleSeqId);
      dispatch(setPlanningCycleFilter({ businessGroupShortDesc, planningCycle: selectedPlanningCycle }));
      clearAllMessages();
      dispatch(fetchForecastTemplateCompleteData(planningCycleSeqId));
    }
  };

  // corp segment - Validates the selection
  const validateSelectedCorpSegmentFilter = (corpSegmentFilters: CorpSegmentFilterSelection) => {
    const validatedSelections = validateCorpSegmentSelections(corpSegmentFilters, corpSegmentDropdowns);
    const costCenterDropdown = corpSegmentDropdowns.find((dropdown) => dropdown.displayName === eCorpSegmentNames.COST_CENTER);
    if (costCenterDropdown) {
      if (!validatedSelections[costCenterDropdown.displayName] || validatedSelections[costCenterDropdown.displayName].length === 0) {
        validatedSelections[costCenterDropdown.displayName] =
          costCenterDropdown.fieldDropdownOptions?.length > 0 ? [costCenterDropdown.fieldDropdownOptions[0]] : [];
      }
    }
    if (selectedPlanningCycleSeqId) {
      dispatch(
        setCorpSegmentFilter({ businessGroupShortDesc, planningCycleSeqId: selectedPlanningCycleSeqId, corpSegmentSelections: validatedSelections })
      );
    }
  };

  // corp segment - Selects first Cost Center
  const initializeCorpSegmentFilters = () => {
    const defaultCorpSegmentSelections = initializeAllCorpSegmentFilters(corpSegmentDropdowns);
    if (selectedPlanningCycleSeqId) {
      dispatch(
        setCorpSegmentFilter({
          businessGroupShortDesc,
          planningCycleSeqId: selectedPlanningCycleSeqId,
          corpSegmentSelections: defaultCorpSegmentSelections
        })
      );
    }
  };

  // corp segment - On change of selection
  const handleCorpSegmentChange = (corpSegmentDisplayName: string, selectedOptions: SelectProps.Options, isMultiSelect: boolean) => {
    const newCorpSegmentSelections: CorpSegmentFilterSelection = {
      ...selectedCorpSegments,
      [corpSegmentDisplayName]: selectedOptions
    };

    if (selectedPlanningCycleSeqId && selectedCorpSegments && !isEqual(selectedCorpSegments[corpSegmentDisplayName], selectedOptions)) {
      dispatch(
        setCorpSegmentFilter({
          businessGroupShortDesc,
          planningCycleSeqId: selectedPlanningCycleSeqId,
          corpSegmentSelections: newCorpSegmentSelections
        })
      );
    }
  };

  const selectedCorpSegmentOption = (filter: ForecastTemplateCorpSegmentDropdowns): SelectProps.Options => {
    return selectedCorpSegments?.[filter.displayName] || [];
  };

  return (
    <Container disableHeaderPaddings disableContentPaddings className="transform">
      <Box padding={'m'}>
        <SpaceBetween size="m" direction="horizontal">
          <FormField label={'Planning Cycle'} className="width-20-rem">
            <Select
              options={planningCycleOptions}
              selectedOption={toPlanningCycleSelectOption(selectedPlanningCycle)}
              onChange={({ detail }) => onPlanningCycleFilterChanged(detail)}
              disabled={isForecastTemplateDataLoading || isForecastTemplateDataSubmitting}
              loadingText="Loading Planning Cycles"
              empty={'No active planning cycles'}
              expandToViewport
              filteringType="auto"
              placeholder="Planning Cycles"
            />
          </FormField>

          {corpSegmentDropdowns
            .filter((corpSegmentFilter) => corpSegmentFilter.isRequired)
            .map((filter) => {
              return !filter.isMultiSelect ? (
                <FormField label={filter.displayName} className="width-20-rem" key={filter.key}>
                  <Select
                    placeholder={getMultiSelectPlaceHolderValue(selectedCorpSegmentOption(filter), filter.displayName)}
                    options={filter.fieldDropdownOptions}
                    selectedOption={selectedCorpSegmentOption(filter)[0]}
                    onChange={({ detail }) => handleCorpSegmentChange(filter.displayName, [detail.selectedOption], false)}
                    disabled={isForecastTemplateDataLoading || isForecastTemplateDataSubmitting}
                    virtualScroll={filter.fieldDropdownOptions.length > 500}
                    expandToViewport
                    filteringType="auto"
                    empty="No data"
                    statusType={filter.loadingStatus}
                  />
                </FormField>
              ) : (
                <FormField label={filter.displayName} className="width-20-rem" key={filter.key}>
                  <Multiselect
                    placeholder={getMultiSelectPlaceHolderValue(selectedCorpSegmentOption(filter), filter.displayName)}
                    filteringType="auto"
                    options={[{ label: 'Select All', value: 'Select All', options: filter.fieldDropdownOptions }]}
                    selectedOptions={selectedCorpSegmentOption(filter)}
                    onChange={({ detail }) => handleCorpSegmentChange(filter.displayName, detail.selectedOptions, true)}
                    onBlur={() => handleCorpSegmentChange(filter.displayName, selectedCorpSegmentOption(filter), true)}
                    disabled={isForecastTemplateDataLoading || isForecastTemplateDataSubmitting}
                    virtualScroll={filter.fieldDropdownOptions.length > 500}
                    hideTokens
                    expandToViewport
                    empty="No data"
                    statusType={filter.loadingStatus}
                  />
                </FormField>
              );
            })}
        </SpaceBetween>
      </Box>
    </Container>
  );
};

export default ForecastTemplateFilterSelection;
