import { LicenseManager } from 'ag-grid-enterprise';
import { logger } from 'src/analytics/KatalLogger';
import { retrieveParameterFromSSM } from './ssm-parameter';

export const AgGridLicenseKeyParameterStoreKey = '/xpt-fe/ag-grid-license';
export const configureAgGridLicense = async (): Promise<string> => {
  const licenseKey = await retrieveParameterFromSSM(AgGridLicenseKeyParameterStoreKey);
  if (licenseKey) {
    LicenseManager.setLicenseKey(licenseKey);
    logger.info('Ag-Grid License configured successfully!', { logType: 'configStatus' });
    return new Promise((resolve) => resolve('setLicenseKey_Success'));
  } else {
    logger.error('Ag-Grid License setup failed!', { logType: 'configStatus' });
    return new Promise((reject) => reject('setLicenseKey_Failed'));
  }
};
