import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAllBusinessGroups, upsertBusinessGroupMutation } from 'src/api/app-sync-services';
import { BusinessGroupEntity } from 'src/models/AppContextModels';
import { LoadingStatus } from 'src/models/AuthContextModels';

interface BusinessGroupState {
  businessGroupLoadingStatus: LoadingStatus;
  businessGroups: BusinessGroupEntity[];
  currentBusinessGroup: BusinessGroupEntity | null;
  updateBusinessGroupsStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  updateBusinessGroupsError: string | null;
}

export const businessGroupInitialState: BusinessGroupState = {
  businessGroupLoadingStatus: LoadingStatus.NotInitiated,
  businessGroups: [],
  currentBusinessGroup: null,
  updateBusinessGroupsStatus: 'idle',
  updateBusinessGroupsError: null
};

// Async thunk for fetching business groups from the API. Handles loading states and errors.
export const fetchBusinessGroups = createAsyncThunk('businessGroup/fetchBusinessGroups', async (_, { rejectWithValue }) => {
  try {
    const businessGroups = await getAllBusinessGroups();
    const transformedBusinessGroups = businessGroups.map((businessGroup) => {
      return {
        ...businessGroup,
        restricted_groups: businessGroup.restricted_groups === null ? [] : businessGroup.restricted_groups,
        corp_segments: businessGroup.corp_segments,
        business_segments: businessGroup.business_segments
      };
    });
    return transformedBusinessGroups;
  } catch (error) {
    return rejectWithValue('Failed to fetch business groups');
  }
});

// Async thunk for business group form submission
export const submitBusinessGroups = createAsyncThunk(
  'businessGroup/submitBusinessGroups',
  async (onboardingSubmitModel: BusinessGroupEntity, { rejectWithValue }) => {
    try {
      const businessGroups = await upsertBusinessGroupMutation(onboardingSubmitModel);
      return businessGroups;
    } catch (error) {
      return rejectWithValue('Failed to update business groups');
    }
  }
);

const businessGroupSlice = createSlice({
  name: 'businessGroup',
  initialState: businessGroupInitialState,
  reducers: {
    setCurrentBusinessGroup(state, action: PayloadAction<BusinessGroupEntity | null>) {
      state.currentBusinessGroup = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBusinessGroups.pending, (state) => {
        state.businessGroupLoadingStatus = LoadingStatus.Loading;
      })
      .addCase(fetchBusinessGroups.fulfilled, (state, action) => {
        state.businessGroupLoadingStatus = LoadingStatus.Completed;
        state.businessGroups = action.payload;
      })
      .addCase(fetchBusinessGroups.rejected, (state) => {
        state.businessGroupLoadingStatus = LoadingStatus.Failed;
      })
      .addCase(submitBusinessGroups.pending, (state) => {
        state.updateBusinessGroupsStatus = 'loading';
      })
      .addCase(submitBusinessGroups.fulfilled, (state) => {
        state.updateBusinessGroupsStatus = 'succeeded';
      })
      .addCase(submitBusinessGroups.rejected, (state, action: PayloadAction<any>) => {
        state.updateBusinessGroupsStatus = 'failed';
        state.updateBusinessGroupsError = action.payload;
      });
  }
});

export const { setCurrentBusinessGroup } = businessGroupSlice.actions;
export default businessGroupSlice.reducer;
