import React from 'react';
import { USER_IMG_BASE_URL } from 'src/constants/generic-constants';

interface UserImageProps {
  alias: string;
  size?: number; // optional size prop to control the size of the image
}

const UserImageIcon: React.FC<UserImageProps> = React.memo(({ alias, size = 20 }) => {
  const imageUrl = `${USER_IMG_BASE_URL}${alias}`;

  const styles = {
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: '50%',
    marginRight: '8px'
  };

  return <img src={imageUrl} alt={`User: ${alias}`} style={styles} />;
});

// With UserImageIcon.displayName = 'UserImageIcon';, the component will correctly display as UserImageIcon in React DevTools
UserImageIcon.displayName = 'UserImageIcon';

export default UserImageIcon;
