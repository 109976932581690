import { Container } from '@amzn/awsui-components-react';
import React from 'react';

interface WidgetContainerProps {
  header: JSX.Element; // Explicitly defining the header as JSX.Element
  children: React.ReactNode; // Children can be any valid React node
  height?: string; // Optional height property. Height as a string to include units (e.g., '250px', '100%', etc.)
}

export const DEFAULT_WIDGET_HEIGHT = '260px'; // Default height for all widgets

export const WidgetContainer: React.FC<WidgetContainerProps> = ({ header, children, height }) => {
  return (
    <Container className="widget-container transform">
      {header}
      <div className="widget-content" style={{ height: height || DEFAULT_WIDGET_HEIGHT, overflowY: 'auto' }}>
        {children}
      </div>
    </Container>
  );
};
