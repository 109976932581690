import { HelpPanel } from '@amzn/awsui-components-react';
import React from 'react';

export const OnboardingHelpPanel = () => {
  return (
    <div>
      <HelpPanel header={<h3>Onboarding Help Panel</h3>}>
        <h4>Data Classification</h4>
        <p>Please provide a name and description for Data Classification.</p>
      </HelpPanel>
    </div>
  );
};
