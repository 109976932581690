import React, { createContext, useContext, useEffect, useState } from 'react';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Amplify, Auth } from 'aws-amplify';
import { LoadingStatus, UserAuthContext } from 'src/models/AuthContextModels';
import { LoadingSpinner } from '../../components/common/LoadingSpinner';
import { configureKatalLogger, entryLog } from 'src/analytics/KatalLogger';
import { configureCloudwatchRUM } from 'src/analytics/CloudWatchRumClient';
import { eLDAPGroup, eLocalStorageKeys } from 'src/constants/generic-constants';
import { getAmplifyAuthConfig, getApiConfig, getAppSyncConfig } from 'src/utils/amplify-config';
import { configureAgGridLicense } from 'src/utils/ag-grid-license-config';

const NEW_USER_THRESHOLD_DAYS = 1;

// Define the shape of your context including the new functions
interface AuthContextValue extends UserAuthContext {
  updateUserAuthDetails: (updates: Partial<UserAuthContext>, fromModifyAuthContext: boolean) => void;
  resetToDefaultAuthDetails: () => void;
}

export const authInitialState: UserAuthContext = {
  userAuthDataLoadingStatus: LoadingStatus.Loading
} as UserAuthContext;

const initialAuthContextValue: AuthContextValue = {
  ...authInitialState,
  updateUserAuthDetails: () => {},
  resetToDefaultAuthDetails: () => {}
};

const AuthContextDetails = createContext<AuthContextValue>(initialAuthContextValue);

export const useAuth = () => useContext(AuthContextDetails);

// Provider component that wraps your app and makes auth object
export const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { userCognitoAuthData, updateUserAuthDetails, resetToDefaultAuthDetails } = useAuthProvider();
  const [isInitializationComplete, setInitializationComplete] = useState(false);

  useEffect(() => {
    const configureLoggerAndServices = async () => {
      if (userCognitoAuthData?.userAuthDataLoadingStatus === LoadingStatus.Completed) {
        await configureKatalLogger(userCognitoAuthData);
        configureCloudwatchRUM(userCognitoAuthData.Alias);
        configureAgGridLicense();
        entryLog();
        setInitializationComplete(true);
      }
    };

    configureLoggerAndServices();
  }, [userCognitoAuthData]);

  if (userCognitoAuthData?.userAuthDataLoadingStatus !== LoadingStatus.Completed || !isInitializationComplete) {
    return <LoadingSpinner />;
  }

  return (
    <AuthContextDetails.Provider value={{ ...userCognitoAuthData, updateUserAuthDetails, resetToDefaultAuthDetails }}>
      {children}
    </AuthContextDetails.Provider>
  );
};

const useAuthProvider = () => {
  const [userCognitoAuthData, setUserCognitoAuthData] = useState<UserAuthContext>({
    ...authInitialState,
    userAuthDataLoadingStatus: LoadingStatus.Loading
  });

  useEffect(() => {
    authenticateAndSetUserDetails();
  }, []);

  const authenticateAndSetUserDetails = () => {
    Auth.configure(getAmplifyAuthConfig());

    const configureAppSync = async () => {
      try {
        const awsAppSync = await getAppSyncConfig();
        Amplify.configure(awsAppSync.Appsync);

        const apiConfig = getApiConfig();
        Amplify.configure(apiConfig);
      } catch (error: any) {
        console.error('Unable to fetch Config', error);
      }
    };

    const signInWithAmazonFederate = async () => {
      try {
        await Auth.federatedSignIn({ customProvider: 'AmazonFederate' });
        const session = await Auth.currentSession();
        setUserCognitoAuthData(getSessionDetails(session));
      } catch (error: any) {
        console.error('Unable to sign in with AmazonFederate', error);
        setUserCognitoAuthData({
          ...userCognitoAuthData,
          userAuthDataLoadingStatus: LoadingStatus.Loading
        });
      }
    };

    Auth.currentAuthenticatedUser()
      .then(async (userSessionDetails) => {
        await configureAppSync();
        const session = await Auth.currentSession();
        setUserCognitoAuthData(getSessionDetails(session));
      })
      .catch(() => {
        signInWithAmazonFederate();
      });
  };

  /**
   * Updates the user authentication details and saves them to local storage.
   * If called from ModifyAuthContext, it sets isUserRoleModified to true.
   */
  const updateUserAuthDetails = (updates: Partial<UserAuthContext>, fromModifyAuthContext: boolean) => {
    setUserCognitoAuthData((prevState) => {
      const updatedState = { ...prevState, ...updates };
      if (fromModifyAuthContext) {
        updatedState.isUserRoleModified = true;
      }
      localStorage.setItem(eLocalStorageKeys.UserAuthDetails, JSON.stringify(updatedState));
      return updatedState;
    });
  };

  // Reset to default auth details
  const resetToDefaultAuthDetails = () => {
    authenticateAndSetUserDetails(); // Re-authenticate or set to initial state
    localStorage.removeItem(eLocalStorageKeys.UserAuthDetails); // Clear saved details
  };

  /**
   * Retrieves and formats the LDAP groups from the credentials payload.
   * @param credentials The AWS credentials object containing the ID token payload.
   * @returns A properly formatted string array of LDAP groups.
   */
  const formatUserLDAPs = (credentials: CognitoUserSession): string[] => {
    // if user is not part of any ldap, we receive it as "[]"
    const ldapGroups = credentials.getIdToken().payload['custom:LDAP_GROUPS'];
    // Check if ldapGroups is a string and try to parse it as JSON
    if (typeof ldapGroups === 'string') {
      try {
        const parsedLdapGroups = JSON.parse(ldapGroups);
        return Array.isArray(parsedLdapGroups) ? parsedLdapGroups : [];
      } catch (error) {
        console.error('Failed to parse ldapGroups string: ', error);
        return [];
      }
    }

    // If ldapGroups is already an array, return it; otherwise, return an empty array
    return Array.isArray(ldapGroups) ? ldapGroups : [];
  };

  const getSessionDetails = (credentials: CognitoUserSession) => {
    const userLDAPs: string[] = formatUserLDAPs(credentials);
    console.debug('UserLDAPs ', userLDAPs);

    const isInitiallyDev = userLDAPs.includes(eLDAPGroup.DEV_LDAP);

    // Determine if the user is isReadOnlyUser
    // Check if userLDAPs only contains the 'expense_planning_tool_ro' group and no other.
    const isReadOnlyUser = userLDAPs.length === 1 && userLDAPs.includes(eLDAPGroup.expense_planning_tool_ro);

    // Extract the dateCreated timestamp
    const dateCreatedTimestamp = credentials.getIdToken().payload['identities'][0].dateCreated;
    const dateCreated = new Date(parseInt(dateCreatedTimestamp));
    // Determine if the user is new
    const isNewUser = isUserNew(dateCreated);

    // Always start with extracting the default session details from the credentials
    const defaultSessionDetails: UserAuthContext = {
      Alias: credentials.getIdToken().payload['identities'][0].userId,
      DisplayName: credentials.getIdToken().payload['custom:DISPLAY_NAME'],
      GivenName: credentials.getIdToken().payload['custom:GIVEN_NAME'],
      Email: credentials.getIdToken().payload['custom:EMAIL'],
      userLDAPGroups: userLDAPs,
      userAuthDataLoadingStatus: LoadingStatus.Completed,
      isUserRoleModified: false,
      modifiedRoleAs: 'Default',
      noAccess: userLDAPs.length === 0,
      initiallyDev: isInitiallyDev,
      isReadOnlyUser: isReadOnlyUser,
      isDev: userLDAPs.includes(eLDAPGroup.DEV_LDAP),
      isAdmin: userLDAPs.includes(eLDAPGroup.ADMIN_LDAP) || userLDAPs.includes(eLDAPGroup.expense_planning_tool_fpna_admin),
      isBusinessLeader: false, // Will define this in App Initializer once we have business group data
      isBudgetOwner: false, // Will define this once user moves to any of the business group
      isNewUser: isNewUser
    };

    // Check localStorage for any developer modifications
    const savedAuthDetails = localStorage.getItem(eLocalStorageKeys.UserAuthDetails);
    if (savedAuthDetails && JSON.parse(savedAuthDetails)?.isUserRoleModified) {
      console.debug('Identified as custom modified. so returning from local storage');
      const parsedDetails: UserAuthContext = JSON.parse(savedAuthDetails);
      return parsedDetails; // Return the modified session details
    }

    console.debug('Identified as non modified. so returning from cognito');
    return defaultSessionDetails; // Return the default session details if no modifications are found
  };

  // Helper function to determine if the user is new
  const isUserNew = (dateCreated: Date) => {
    const now = new Date();
    const timeDiff = now.getTime() - dateCreated.getTime();
    const daysDiff = timeDiff / (1000 * 3600 * 24); // Converting time difference to days
    return daysDiff <= NEW_USER_THRESHOLD_DAYS;
  };

  return { userCognitoAuthData, updateUserAuthDetails, resetToDefaultAuthDetails };
};
