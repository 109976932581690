import React, { useRef } from 'react';
import { XptAppLayout } from 'src/components/common/xpt-app-layout/XptAppLayout';
import { XPTBreadcrumbs } from 'src/components/common/XptBreadcrumb';
import { Box, Flashbar } from '@amzn/awsui-components-react';
import { getCapexBreadcrumbItems } from './CapexConfig';
import { CapexFileUpload } from './CapexFileUpload';
import { AuditLogTable } from 'src/features/admin-console/audit-log/AuditLogTable';
import { AuditType } from 'src/constants/generic-constants';
import AdminConsoleSideNavigation from '../AdminConsoleSideNavigation';

export const Capex: React.FC = () => {
  const appLayout = useRef<any>();
  return (
    <>
      <XptAppLayout
        ref={appLayout}
        headerSelector="#h"
        navigation={<AdminConsoleSideNavigation />}
        breadcrumbs={<XPTBreadcrumbs items={getCapexBreadcrumbItems()} />}
        notifications={<Flashbar items={[]} stackItems />}
        stickyNotifications={true}
        maxContentWidth={Number.MAX_VALUE}
        contentType="default"
        content={
          <div className="xpt-app-layout-content">
            <CapexFileUpload />
            <Box margin={{ top: 'm' }}>
              <AuditLogTable showAuditFor={AuditType.Capex} itemsPerPage={5} headerName={'Capex Upload History'} />
            </Box>
          </div>
        }
      />
    </>
  );
};
