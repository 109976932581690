import { BreadcrumbGroupProps, SelectProps } from '@amzn/awsui-components-react';
import { logger } from 'src/analytics/KatalLogger';
import { BusinessGroupEntity } from 'src/models/AppContextModels';
import { ROLL_UP_PERIOD, VarianceReportData, VarianceReportFlattenedData, XptVarianceReportInput } from 'src/models/XptReportingModels';
import { customCorpSegmentSort } from '../admin-console/onboarding-business-groups/OnboardingFormUtils';
import { getSelectedPlanningCycle } from './XptReportingUtils';
import { PlanningCycle, PlanningCycleSnapshot } from 'src/models/PlanningCycleModel';

// Breadcrumb items generator with error handling
export const getXptVarianceReportsBreadcrumbItems = (
  businessGroupBaseBreadcrumbs: BreadcrumbGroupProps.Item[],
  currentBusinessGroupName?: string
): BreadcrumbGroupProps.Item[] => {
  try {
    if (!currentBusinessGroupName) {
      return businessGroupBaseBreadcrumbs;
    }

    return [
      ...businessGroupBaseBreadcrumbs,
      {
        text: 'Variance Report',
        href: `/${currentBusinessGroupName}/variance-report`
      }
    ];
  } catch (error: any) {
    logger.error('Error generating breadcrumb items:', error);
    throw new Error('Failed to generate breadcrumb items');
  }
};

// Flattens the variance report data and handles any processing errors
export const flattenReportData = (
  varianceReportData: VarianceReportData[],
  currentBusinessGroup: BusinessGroupEntity
): VarianceReportFlattenedData[] => {
  try {
    const requiredCorpSegmentsHeader = getSortedRequiredCorpSegments(currentBusinessGroup);

    return varianceReportData.map((item) => {
      const corpSegmentsFlattened = flattenSegments(item.corp_segments, requiredCorpSegmentsHeader);
      const busSegmentsFlattened = flattenSegments(item.bus_segments);
      const processedRest = processRestFields(item);

      return {
        xpt_line_item_seq_id: item.xpt_line_item_seq_id,
        ...corpSegmentsFlattened,
        ...busSegmentsFlattened,
        ...processedRest
      };
    });
  } catch (error: any) {
    logger.error('Error flattening forecast row data structured:', error);
    throw new Error('Error flattening forecast row data structured');
  }
};

// Helper function to get and sort required corp segments
export const getSortedRequiredCorpSegments = (businessGroup: BusinessGroupEntity): string[] => {
  return businessGroup.corp_segments
    .filter((corp) => corp.corp_segment_required)
    .sort(customCorpSegmentSort)
    .map((corp) => corp.corp_segment_name);
};

// Helper function to flatten segment data
export const flattenSegments = (segments: any[], headers: string[] = []): { [key: string]: any } => {
  return Object.assign(
    {},
    ...segments.map((segment) => {
      const filteredSegment: { [key: string]: any } = {};
      headers.forEach((header) => {
        if (segment[header] !== undefined) {
          filteredSegment[header] = segment[header];
        }
      });
      return filteredSegment;
    })
  );
};

// Processes the rest of the fields excluding arrays
export const processRestFields = (item: VarianceReportData): { [key: string]: any } => {
  const { actual_months, bus_segments, corp_segments, forecast_months, xpt_line_item_seq_id, ...rest } = item;

  return Object.keys(rest).reduce((acc, key) => {
    const value = rest[key];
    acc[key] = processFieldValue(value);
    return acc;
  }, {} as { [key: string]: any });
};

// Processes individual field values
export const processFieldValue = (value: any): any => {
  if (typeof value === 'string') {
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? null : parseFloat(parsedValue.toFixed(6));
  }
  return value;
};

export const EMPTY_OPTION: SelectProps.Option | null = null;

export const RollUpPeriodOptions: SelectProps.Options = [
  { label: 'Yearly', value: ROLL_UP_PERIOD.YEARLY },
  { label: 'Quarterly', value: ROLL_UP_PERIOD.QUARTERLY }
];

// Constructs input for variance report API with improved error handling
export const getVarianceInputForAPI = (
  selectedCurrentPlanningCycle: SelectProps.Option,
  selectedComparisonPlanningCycle: SelectProps.Option,
  selectedRollupPeriod: string,
  data_classification_id: number,
  dataClassificationShortDesc: string,
  scenarioSnapshots: any,
  planningCycles: any,
  alias: string,
  bucketName: string,
  region: string
): XptVarianceReportInput => {
  try {
    const currentPlanningCycleData = getPlanningCycleDetails(
      selectedCurrentPlanningCycle,
      data_classification_id,
      dataClassificationShortDesc,
      scenarioSnapshots,
      planningCycles
    );

    const comparisonPlanningCycleData = getPlanningCycleDetails(
      selectedComparisonPlanningCycle,
      data_classification_id,
      dataClassificationShortDesc,
      scenarioSnapshots,
      planningCycles
    );

    const s3Key = buildS3Key(selectedRollupPeriod, currentPlanningCycleData, comparisonPlanningCycleData);

    const varianceReportInput: XptVarianceReportInput = {
      current_planning_cycle_seq_id: currentPlanningCycleData.isSnapshot
        ? currentPlanningCycleData.scenarioSnapshot?.scenario_snapshot_id!
        : currentPlanningCycleData.selected?.scenario_seq_id!,
      current_planning_cycle_is_snapshot: currentPlanningCycleData.isSnapshot,
      current_planning_cycle_name: currentPlanningCycleData.isSnapshot ? currentPlanningCycleData.scenarioSnapshot?.scenario_snapshot_name! : currentPlanningCycleData.selected?.scenario.planning_cycle_name!,

      comparison_planning_cycle_seq_id: comparisonPlanningCycleData.isSnapshot
        ? comparisonPlanningCycleData.scenarioSnapshot?.scenario_snapshot_id!
        : comparisonPlanningCycleData.selected?.scenario_seq_id!,
      comparison_planning_cycle_is_snapshot: comparisonPlanningCycleData.isSnapshot,
      comparison_planning_cycle_name: comparisonPlanningCycleData.isSnapshot ? comparisonPlanningCycleData.scenarioSnapshot?.scenario_snapshot_name! : comparisonPlanningCycleData.selected?.scenario.planning_cycle_name!,

      rollup_period: selectedRollupPeriod,

      s3_bucket: bucketName,
      s3_region: region,

      export_s3_key: s3Key,
      created_by: alias
    };

    logger.info(`Generating variance report for this varianceReportInput.`, { info: varianceReportInput });
    return varianceReportInput;
  } catch (error: any) {
    logger.error('Error generating variance input for API:', error);
    throw new Error('Failed to generate variance input for API');
  }
};

// Helper function to get detailed planning cycle information
export const getPlanningCycleDetails = (
  selectedCycle: SelectProps.Option,
  data_classification_id: number,
  dataClassificationShortDesc: string,
  scenarioSnapshots: any,
  planningCycles: any
) => {
  const { planningCycleSelected, isSnapshot, scenarioSnapshot } = getSelectedPlanningCycle(
    selectedCycle,
    data_classification_id,
    dataClassificationShortDesc,
    scenarioSnapshots,
    planningCycles
  );

  return {
    selected: planningCycleSelected,
    isSnapshot,
    scenarioSnapshot
  };
};

// Helper function to build S3 key for variance report
export const buildS3Key = (
  selectedRollupPeriod: string,
  currentPlanningCycle: { selected: PlanningCycle | undefined; isSnapshot: boolean; scenarioSnapshot: PlanningCycleSnapshot | undefined },
  comparisonPlanningCycle: { selected: PlanningCycle | undefined; isSnapshot: boolean; scenarioSnapshot: PlanningCycleSnapshot | undefined }
): string => {
  // Helper function to build individual cycle key
  const buildCycleKey = (cycle: {
    selected: PlanningCycle | undefined;
    isSnapshot: boolean;
    scenarioSnapshot: PlanningCycleSnapshot | undefined;
  }): string => {
    if (cycle.isSnapshot) {
      const snapshotId = cycle.scenarioSnapshot?.scenario_snapshot_id;
      if (!snapshotId) {
        throw new Error('Missing scenario snapshot ID for a snapshot-based cycle');
      }
      return `snapshot_${snapshotId}`;
    } else {
      const seqId = cycle.selected?.scenario_seq_id;
      if (!seqId) {
        throw new Error('Missing scenario sequence ID for a planning cycle');
      }
      return `planning_cycle_${seqId}`;
    }
  };

  try {
    const currentCycleKey = buildCycleKey(currentPlanningCycle);
    const comparisonCycleKey = buildCycleKey(comparisonPlanningCycle);

    if (!selectedRollupPeriod) {
      throw new Error('Rollup period is required to build S3 key');
    }

    return `reports/${selectedRollupPeriod}/${currentCycleKey}_${comparisonCycleKey}/list_report.json`;
  } catch (error: any) {
    // Log the error (assuming you have a logger utility)
    logger.error('Error building S3 key:', { error });
    throw new Error('Failed to build S3 key');
  }
};
