import { HelpPanel } from '@amzn/awsui-components-react';
import React from 'react';

export const AdminPlanningCycleHelpPanel: React.FC = () => {
  return (
    <div>
      <HelpPanel header={<h3>Planning Cycle Help Panel</h3>}>
        <h4>Planning Cycle</h4>
        <p>TBD...</p>
      </HelpPanel>
    </div>
  );
};
