import { Alert, Button, Container, ContentLayout, Flashbar, Header, Popover, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import { CellValueChangedEvent, ColDef, GetRowIdParams, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { logger } from 'src/analytics/KatalLogger';
import { updateExpenseType } from 'src/api/app-sync-services';
import { ConfirmDiscardModal } from 'src/components/common/ConfirmDiscardModal';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import { XptAppLayout } from 'src/components/common/xpt-app-layout/XptAppLayout';
import { XPTBreadcrumbs } from 'src/components/common/XptBreadcrumb';
import { useAuth } from 'src/features/auth/AuthContextProvider';
import { useFlashbar } from 'src/hooks/useFlashbar';
import { useGridState } from 'src/hooks/useGridState';
import { LoadingStatus } from 'src/models/AuthContextModels';
import { ValidationStatusEntity } from 'src/models/XptGenericModels';
import { ExpenseTypeGridEntity } from 'src/models/xPTMappingModels';
import { fetchExpenseTypes } from 'src/store/slices/xPTMapperSlice';
import { RootState } from 'src/store/store';
import { useAppDispatch } from 'src/store/useAppDispatch';
import {
  addNewRowToGrid,
  ensureRowVisibleAndFocusFirstCell,
  processCellForClipboard,
  processCellFromClipboard,
  StatusBarConfig
} from 'src/utils/ag-grid-utils';
import { getCurrentUTCTimeInISO } from 'src/utils/date-time-utilities';
import { generateUniqueId } from 'src/utils/generic-utilities';
import AdminConsoleSideNavigation from '../AdminConsoleSideNavigation';
import { validateExpenseTypeData } from './ExpenseTypeDataValidations';
import {
  convertToExpenseTypeEntities,
  expenseTypeColumnGenerator,
  flattenedExpenseType,
  getExpenseTypeBreadcrumbItems,
  isExpenseTypeRowModified
} from './ExpenseTypeMappingConfig';

export const ExpenseTypeMapping: React.FC = () => {
  const appLayout = useRef<any>();
  const { Alias } = useAuth();
  const dispatch = useAppDispatch();

  const businessGroups = useSelector((state: RootState) => state.businessGroupStore.businessGroups);

  const expenseTypeMappings = useSelector((state: RootState) => state.xPTMappingStore.expenseTypeMappings);
  const expenseTypeStatus = useSelector((state: RootState) => state.xPTMappingStore.expenseTypeStatus);
  const themeClassName = useSelector((state: RootState) => state.xptAppMetadataStore.themeClassName);

  const gridRef = useRef<AgGridReact>(null);
  const gridStateKey = 'UniqueGridStateKey-Expense-Type-Mapping';
  const { restoreGridState } = useGridState(gridRef, gridStateKey);

  const [columnDefinition, setColumnDefinition] = useState<ColDef[]>([]);
  const [expenseTypeGridData, setExpenseTypeGridData] = useState<ExpenseTypeGridEntity[]>([]);
  const [originalData, setOriginalData] = useState<Map<string, ExpenseTypeGridEntity>>(new Map());
  const [selectedRows, setSelectedRows] = useState<ExpenseTypeGridEntity[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [removedRecords, setRemovedRecords] = useState<ExpenseTypeGridEntity[]>([]);
  const [validationResults, setValidationResults] = useState<ValidationStatusEntity[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitClickCount, setSubmitClickCount] = useState(0);
  const { flashbarItems, displayFlashMessage, clearSpecificFlashMessage } = useFlashbar();

  const hasChanges = useMemo(() => {
    return expenseTypeGridData.some((item) => item.is_modified) || removedRecords.length > 0;
  }, [expenseTypeGridData, removedRecords]);

  const allValidationsPassed = useMemo(() => {
    return validationResults.every((result) => result.validationStatus === 'success');
  }, [validationResults]);

  const fetchExpenseTypeData = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(fetchExpenseTypes()).unwrap();
    } catch (error: any) {
      logger.error('Unable to load expense type data', error);
      displayFlashMessage(`Unable to load expense type data`, 'error', true);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchExpenseTypeData();
  }, [fetchExpenseTypeData]);

  useEffect(() => {
    if (expenseTypeStatus === LoadingStatus.Completed) {
      const flattenedExpenseTypes = flattenedExpenseType(expenseTypeMappings);
      setExpenseTypeGridData(flattenedExpenseTypes);

      const dynamicColDefs = expenseTypeColumnGenerator(flattenedExpenseTypes);
      setColumnDefinition(dynamicColDefs);

      setOriginalData(new Map(flattenedExpenseTypes.map((row) => [row.row_id, { ...row }])));
    }
  }, [expenseTypeMappings, expenseTypeStatus]);

  const getRowId = (params: GetRowIdParams) => params.data.row_id;

  const reloadDataAndClearAllStates = useCallback(() => {
    fetchExpenseTypeData();
    setRemovedRecords([]);
    setValidationResults([]);
    setSubmitClickCount(0);
  }, [fetchExpenseTypeData]);

  const handleRefreshClick = () => {
    if (hasChanges) {
      setIsModalVisible(true);
    } else {
      reloadDataAndClearAllStates();
    }
  };

  const handleConfirmDiscard = () => {
    setIsModalVisible(false);
    reloadDataAndClearAllStates();
  };

  const handleCancelDiscard = () => {
    setIsModalVisible(false);
  };

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      filter: true,
      editable: true,
      filterParams: {
        applyMiniFilterWhileTyping: true
      },
      headerClass: 'header-center'
    }),
    []
  );

  const restoreGrid = useCallback(() => {
    setTimeout(() => {
      restoreGridState();
      gridRef.current?.api.refreshCells();
    }, 0);
  }, [restoreGridState]);

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      restoreGrid();
    },
    [restoreGrid]
  );

  // Function to synchronize state with grid data
  const synchronizeGridData = useCallback(() => {
    const api = gridRef.current?.api;
    const updatedRowData: ExpenseTypeGridEntity[] = [];
    api?.forEachNode((node) => {
      updatedRowData.push(node.data);
    });
    setExpenseTypeGridData(updatedRowData);
  }, []);

  const debouncedSynchronizedData = debounce(synchronizeGridData, 500);

  const handleAddNewRow = useCallback(() => {
    const currentBusinessGroups = businessGroups.map((businessGroup) => {
      return {
        [businessGroup.data_classification.data_classification_name]: false,
        [businessGroup.data_classification.data_classification_name + '_classification_id']: businessGroup.data_classification.data_classification_id
      };
    });
    const flattenedBusinessGroups = currentBusinessGroups.reduce((acc: any, group: any) => ({ ...acc, ...group }), {});
    const newRecord: ExpenseTypeGridEntity = {
      row_id: generateUniqueId(),
      expense_type_id: null,
      expense_type: '',
      budget_type: '',
      description: '',
      is_active: true,
      created_by: Alias,
      created_at: getCurrentUTCTimeInISO(),
      updated_at: getCurrentUTCTimeInISO(),
      updated_by: Alias,
      is_checked: false,
      is_modified: true,
      ...flattenedBusinessGroups
    };

    // Add the new row at the top of the grid and get the response from the transaction
    const transactionResult = addNewRowToGrid(gridRef, newRecord, 0);

    // Ensure the newly added row is visible and set focus to the first cell
    if (transactionResult?.add) {
      const addedRowNode = transactionResult.add[0];
      if (addedRowNode && addedRowNode.rowIndex != null) {
        ensureRowVisibleAndFocusFirstCell(gridRef, addedRowNode.rowIndex, 1);
      }
    }

    debouncedSynchronizedData();
  }, [Alias, expenseTypeGridData]);

  const handleDeleteSelectedRows = useCallback(() => {
    const selectedRows = gridRef.current?.api.getSelectedRows() as ExpenseTypeGridEntity[];
    if (selectedRows && selectedRows.length > 0) {
      const removedRows = selectedRows.map((row) => ({
        ...row,
        is_active: false,
        is_modified: true
      }));
      setRemovedRecords((prev) => [...prev, ...removedRows.filter((row) => row.expense_type_id)]);

      gridRef.current?.api.applyTransaction({ remove: selectedRows });

      debouncedSynchronizedData();
    }
  }, [expenseTypeGridData]);

  const onSelectionChanged = useCallback((event: SelectionChangedEvent) => {
    setSelectedRows(event.api.getSelectedRows() as ExpenseTypeGridEntity[]);
  }, []);

  const onCellValueChanged = useCallback(
    (event: CellValueChangedEvent) => {
      const { data: newData } = event;
      const originalRow = originalData.get(newData.row_id);

      if (originalRow) {
        const isModified = isExpenseTypeRowModified(originalRow, newData);

        const updatedData: ExpenseTypeGridEntity[] = expenseTypeGridData.map((item) =>
          item.row_id === newData.row_id ? { ...item, is_modified: isModified } : item
        );

        gridRef.current?.api?.applyTransaction({ update: updatedData });
      }

      debouncedSynchronizedData();
    },
    [expenseTypeGridData, originalData]
  );

  const handlePasteEnd = useCallback(() => {
    setExpenseTypeGridData((prevData) => {
      return prevData.map((item) => {
        if (item.expense_type_id !== null) {
          const originalRow = originalData.get(item.row_id);
          if (originalRow) {
            const isModified = isExpenseTypeRowModified(originalRow, item);
            return { ...item, is_modified: isModified };
          }
        }
        return item;
      });
    });
  }, [originalData]);

  const validateData = useCallback(async () => {
    const results = await validateExpenseTypeData(expenseTypeGridData);
    setValidationResults(results);
  }, [expenseTypeGridData]);

  useEffect(() => {
    validateData();
  }, [expenseTypeGridData, validateData]);

  const handleSubmit = useCallback(async () => {
    setSubmitClickCount((prevCount) => prevCount + 1);
    await validateData();
    if (!allValidationsPassed) {
      return;
    }

    setIsSubmitting(true);
    const inProgressMessageId = generateUniqueId();
    displayFlashMessage('Submission in progress...', 'info', false, inProgressMessageId);

    const modifiedAndNewRecords = expenseTypeGridData.filter((row) => row.is_modified);
    const combinedRecords = [...modifiedAndNewRecords, ...removedRecords];
    if (combinedRecords.length === 0) {
      displayFlashMessage('No changes to submit.', 'info', true);
      clearSpecificFlashMessage(inProgressMessageId);
      setIsSubmitting(false);
      reloadDataAndClearAllStates();
      return;
    }

    const finalDataToSubmit = convertToExpenseTypeEntities(combinedRecords, Alias);

    try {
      const response = await updateExpenseType(finalDataToSubmit);
      const noOfRecordsUpdated = response?.createOrUpdateExpenseTypeDetails?.numberOfRecordsUpdated;
      displayFlashMessage(`Submission successful. ${noOfRecordsUpdated} record${noOfRecordsUpdated > 1 ? 's' : ''} updated.`, 'success', true);
      reloadDataAndClearAllStates();
    } catch (error: any) {
      logger.error('Unable to submit expense type data', error);
      displayFlashMessage('Submission failed.', 'error', true);
    } finally {
      setIsSubmitting(false);
      clearSpecificFlashMessage(inProgressMessageId);
    }
  }, [
    Alias,
    displayFlashMessage,
    validateData,
    expenseTypeGridData,
    removedRecords,
    clearSpecificFlashMessage,
    allValidationsPassed,
    reloadDataAndClearAllStates
  ]);

  const validationErrors = validationResults.filter((result) => result.validationStatus === 'error');

  return (
    <>
      <XptAppLayout
        ref={appLayout}
        headerSelector="#h"
        navigation={<AdminConsoleSideNavigation />}
        breadcrumbs={<XPTBreadcrumbs items={getExpenseTypeBreadcrumbItems()} />}
        notifications={<Flashbar items={flashbarItems} stackItems />}
        stickyNotifications={true}
        maxContentWidth={Number.MAX_VALUE}
        contentType="default"
        content={
          <div >
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <ErrorBoundary
                FallbackComponent={() => (
                  <Alert
                    type="error"
                    dismissible={false}
                    visible={true}
                    header="Error"
                    action={<Button onClick={() => window.location.reload()}>Reload</Button>}
                  >
                    {'Unable to load data, try reloading once'}
                  </Alert>
                )}
              >
                <ContentLayout header={<Header>{`Expense Type Mappings`}</Header>}>
                  <Container
                    className="xpt-mapping-ag-grid-container"
                    disableContentPaddings
                    header={
                      <Header
                        actions={
                          <SpaceBetween size="m" direction="horizontal">
                            <Button iconName="refresh" onClick={handleRefreshClick} disabled={isSubmitting}></Button>
                            <Button iconName="insert-row" onClick={handleAddNewRow} disabled={isSubmitting}>
                              {`Add expense type`}
                            </Button>
                            <Button iconName="delete-marker" onClick={handleDeleteSelectedRows} disabled={isSubmitting || selectedRows.length === 0}>
                              {`Delete`}
                            </Button>
                            <Button variant="primary" onClick={handleSubmit} disabled={isSubmitting || !hasChanges}>
                              {`Submit`}
                            </Button>
                          </SpaceBetween>
                        }
                      >
                        <SpaceBetween size="s" direction="horizontal">
                          {validationErrors.length > 0 && submitClickCount > 0
                            ? validationErrors.map((error, index) => (
                                <Popover
                                  key={index}
                                  dismissButton={false}
                                  triggerType="text"
                                  size="large"
                                  content={
                                    <div className="popover-content">
                                      {error.validationDefaultMessage && (
                                        <div>
                                          <strong>{error.validationDefaultMessage}</strong>
                                        </div>
                                      )}
                                    </div>
                                  }
                                >
                                  <StatusIndicator type="error" colorOverride="red">
                                    {error.validationMessage}
                                  </StatusIndicator>
                                </Popover>
                              ))
                            : hasChanges && (
                                <StatusIndicator type="info" colorOverride="blue">
                                  {'Changes detected. Submit when done.'}
                                </StatusIndicator>
                              )}
                        </SpaceBetween>
                      </Header>
                    }
                  >
                    <div className={themeClassName} style={{ height: '100%', width: '100%' }}>
                      <AgGridReact
                        ref={gridRef}
                        rowData={expenseTypeGridData}
                        columnDefs={columnDefinition}
                        getRowId={getRowId}
                        defaultColDef={defaultColDef}
                        onGridReady={onGridReady}
                        rowSelection="multiple"
                        onCellValueChanged={onCellValueChanged}
                        suppressRowClickSelection={true}
                        enableAdvancedFilter={false}
                        alwaysMultiSort={true}
                        statusBar={StatusBarConfig}
                        rowBuffer={10}
                        rowModelType="clientSide"
                        rowHeight={30}
                        suppressContextMenu
                        enterNavigatesVerticallyAfterEdit
                        enterNavigatesVertically
                        onSelectionChanged={onSelectionChanged}
                        onPasteEnd={handlePasteEnd}
                        suppressLastEmptyLineOnPaste
                        processCellForClipboard={processCellForClipboard}
                        processCellFromClipboard={processCellFromClipboard}
                      />
                    </div>
                  </Container>
                </ContentLayout>
                <ConfirmDiscardModal visible={isModalVisible} onConfirm={handleConfirmDiscard} onCancel={handleCancelDiscard} />
              </ErrorBoundary>
            )}
          </div>
        }
      />
    </>
  );
};
