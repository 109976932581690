export interface XptReportRowDataStructured {
  scenario_seq_id: number;
  xpt_line_item_seq_id: number;
  xpt_line_item_id: string;

  budget_owner: string;
  budget_type: string | null;

  bus_segments: any[];
  corp_segments: any[];

  actual_months: any;
  forecast_months: any[];

  updated_at: string;
  updated_by: string;
}

export interface XptForecastReportGridRowData {
  scenario_seq_id: number;
  xpt_line_item_seq_id: number;
  xpt_line_item_id: string;

  budget_owner: string;
  budget_type: string | null;

  scenario_year: string | null;

  updated_at: string;
  updated_by: string;
  [key: string]: any;
}

export interface XptReportExportFileDetails {
  fileName: string;
  sheetName: string;
}

export enum ROLL_UP_PERIOD {
  YEARLY = 'YEARLY',
  QUARTERLY = 'QUARTERLY',
}

export interface XptVarianceReportInput {
  current_planning_cycle_seq_id: number;
  current_planning_cycle_is_snapshot: boolean;
  current_planning_cycle_name: string;

  comparison_planning_cycle_seq_id: number;
  comparison_planning_cycle_is_snapshot: boolean;
  comparison_planning_cycle_name: string;
  
  rollup_period: string;

  s3_bucket: string;
  export_s3_key: string;
  s3_region: string;
  created_by: string;
}

export interface VarianceReportEntity {
  variance_periods: string[];
  variance_report: VarianceReportData[];
}

export interface VarianceReportData {
  xpt_line_item_seq_id: number;
  bus_segments: any[];
  corp_segments: any[];
  [key: string]: any;
}

export interface VarianceReportFlattenedData {
  xpt_line_item_seq_id: number;
  [key: string]: any;
}