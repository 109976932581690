import { DateRangePickerProps } from '@amzn/awsui-components-react';

export const dateRangeI18n: (dateTimeConstraintText?: string) => DateRangePickerProps.I18nStrings = (dateTimeConstraintText) => ({
  todayAriaLabel: 'Today',
  nextMonthAriaLabel: 'Next month',
  previousMonthAriaLabel: 'Previous month',
  customRelativeRangeDurationLabel: 'Duration',
  customRelativeRangeDurationPlaceholder: 'Enter duration',
  customRelativeRangeOptionLabel: 'Custom range',
  customRelativeRangeOptionDescription: 'Set a custom range in the past',
  customRelativeRangeUnitLabel: 'Unit of time',
  formatRelativeRange: (e) => {
    const n = 1 === e.amount ? e.unit : `${e.unit}s`;
    return `Last ${e.amount} ${n}`;
  },
  formatUnit: (e, n) => (1 === n ? e : `${e}s`),
  dateTimeConstraintText: dateTimeConstraintText ? dateTimeConstraintText : 'For date, use YYYY/MM/DD. For time, use 24 hr format.',
  relativeModeTitle: 'Relative range',
  absoluteModeTitle: 'Absolute range',
  relativeRangeSelectionHeading: 'Choose a range',
  startDateLabel: 'Start date',
  endDateLabel: 'End date',
  startTimeLabel: 'Start time',
  endTimeLabel: 'End time',
  clearButtonLabel: 'Clear',
  cancelButtonLabel: 'Cancel',
  applyButtonLabel: 'Apply'
});

export const dateOnlyRangeI18n: () => DateRangePickerProps.I18nStrings = () => ({
  todayAriaLabel: 'Today',
  nextMonthAriaLabel: 'Next month',
  previousMonthAriaLabel: 'Previous month',
  customRelativeRangeDurationLabel: 'Duration',
  customRelativeRangeDurationPlaceholder: 'Enter duration',
  customRelativeRangeOptionLabel: 'Custom range',
  customRelativeRangeOptionDescription: 'Set a custom range in the past',
  customRelativeRangeUnitLabel: 'Unit of time',
  dateTimeConstraintText: 'For date, use YYYY/MM/DD.',
  formatRelativeRange: (e) => {
    const t = 1 === e.amount ? e.unit : `${e.unit}s`;
    return `Last ${e.amount} ${t}`;
  },
  formatUnit: (e, t) => (1 === t ? e : `${e}s`),
  relativeModeTitle: 'Relative range',
  absoluteModeTitle: 'Absolute range',
  relativeRangeSelectionHeading: 'Choose a range',
  startDateLabel: 'Start date',
  endDateLabel: 'End date',
  startTimeLabel: 'Start time',
  endTimeLabel: 'End time',
  clearButtonLabel: 'Clear',
  cancelButtonLabel: 'Cancel',
  applyButtonLabel: 'Apply'
});
