import { FlashbarProps } from '@amzn/awsui-components-react';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface POContextType {
  flashbarItems: FlashbarProps.MessageDefinition[];
  displayFlashMessage: (content: string, flashBarType: FlashbarProps.Type, isDismissible: boolean, messageId?: string) => void;
  clearSpecificFlashMessage: (messageId: string) => void;
  clearAllMessages: () => void;
}

const POContext = createContext<POContextType | undefined>(undefined);

export const POTaggingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  const displayFlashMessage = useCallback((content: string, flashBarType: FlashbarProps.Type, isDismissible: boolean, messageId?: string) => {
    const flashbarId = messageId ? messageId : uuidv4();

    const newFlashbarItem = {
      id: flashbarId,
      type: flashBarType,
      content: content,
      dismissible: isDismissible,
      dismissLabel: 'Dismiss message',
      onDismiss: () => setFlashbarItems((currentItems) => currentItems.filter((item) => item.id !== newFlashbarItem.id))
    };

    // Add newFlashbarItem at first
    setFlashbarItems((currentItems) => [newFlashbarItem, ...currentItems]);

    if (flashBarType === 'success') {
      setTimeout(() => {
        setFlashbarItems((currentItems) => currentItems.filter((item) => item.id !== newFlashbarItem.id));
      }, 5000);
    }
  }, []);

  const clearSpecificFlashMessage = (messageId: string) => {
    setFlashbarItems((currentItems) => currentItems.filter((item) => item.id !== messageId));
  };

  const clearAllMessages = () => {
    setFlashbarItems([]);
  };

  return (
    <POContext.Provider value={{ flashbarItems, displayFlashMessage, clearSpecificFlashMessage, clearAllMessages }}>
      {children}
    </POContext.Provider>
  );
};

export const usePOTaggingContext = (): POContextType => {
  const context = useContext(POContext);
  if (!context) {
    throw new Error('usePOTaggingContext must be used within an POTaggingProvider');
  }
  return context;
};
