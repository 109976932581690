import {
  AttributeEditor,
  Autosuggest,
  AutosuggestProps,
  Box,
  Button,
  FormField,
  Input,
  Modal,
  SpaceBetween,
  StatusIndicator,
  StatusIndicatorProps
} from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useAuth } from 'src/features/auth/AuthContextProvider';
import { ContactInformation } from 'src/models/AppContextModels';
import { fetchNewItemMetadata } from 'src/utils/generic-utilities';
import {
  extractDataClassificationAsAutoSuggestions,
  getContactAliasAsCSV,
  ContactInformationSchema,
  ContactMessages,
  convertFormValuesToContactInformation
} from './ContactUtils';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { getActiveDataClassifications } from 'src/features/business-group/businessGroupSelectors';
import { LoadingStatus } from 'src/models/AuthContextModels';
import { Formik, Form, FieldArray, FormikTouched, FormikErrors } from 'formik';
import { updateContacts } from 'src/api/app-sync-services';

export interface Item {
  id: number | null;
  key: string;
  value: string;
}

interface ContactsFormProps {
  showModal: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  contacts: ContactInformation[];
}

export interface ContactFormValues {
  items: Item[];
}

export const getFieldError = (
  touched: FormikTouched<{ items: Item[] }>,
  errors: FormikErrors<{ items: Item[] }>,
  index: number,
  field: 'key' | 'value'
): string | undefined => {
  // @ts-ignore
  return touched.items?.[index]?.[field] && typeof errors.items?.[index] === 'object' && errors.items?.[index]?.[field]
    ? // @ts-ignore
      errors.items[index][field]
    : undefined;
};

const ContactsForm: React.FC<ContactsFormProps> = ({ showModal, onCancel, onConfirm, contacts }) => {
  const userAuthDetails = useAuth();

  const businessGroupLoadingStatus = useSelector((state: RootState) => state.businessGroupStore.businessGroupLoadingStatus);
  const dataClassifications = useSelector(getActiveDataClassifications);

  const [submissionStatus, setSubmissionStatus] = useState<string | null>(null);
  const [statusType, setStatusType] = useState<StatusIndicatorProps.Type | undefined>(undefined);

  const [autoSuggestionsForGroup, setAutoSuggestionsForGroup] = useState<AutosuggestProps.OptionGroup[]>([]);

  useEffect(() => {
    if (businessGroupLoadingStatus === LoadingStatus.Completed) {
      const autoSuggests = extractDataClassificationAsAutoSuggestions(dataClassifications);
      setAutoSuggestionsForGroup(autoSuggests);
    }
  }, [businessGroupLoadingStatus, dataClassifications]);

  const initialFormValues: ContactFormValues = {
    items: contacts.map((contact) => ({
      id: contact.contact_id,
      key: contact.group_name,
      value: getContactAliasAsCSV(contact)
    }))
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleSubmit = async (values: ContactFormValues) => {
    setSubmissionStatus(ContactMessages.SUBMITTING);
    setStatusType('in-progress');
    const alias = userAuthDetails.Alias;
    const { newContacts, updatedContacts, deletedContacts } = convertFormValuesToContactInformation(values, contacts, alias);
    const contactsToUpdate = [...newContacts, ...updatedContacts, ...deletedContacts];

    try {
      await updateContacts(contactsToUpdate);
      setSubmissionStatus(ContactMessages.SUBMITTED_SUCCESSFULLY);
      setStatusType('success');
      onConfirm();
    } catch (error) {
      setSubmissionStatus(ContactMessages.SUBMIT_ERROR);
      setStatusType('error');
    }
  };

  return (
    <Formik initialValues={initialFormValues} validationSchema={ContactInformationSchema} onSubmit={handleSubmit} enableReinitialize>
      {({ values, errors, touched, setFieldValue, setFieldTouched, dirty, isSubmitting, resetForm }) => {
        useEffect(() => {
          if (showModal) {
            resetForm();
          }
        }, [showModal, resetForm]);

        return (
          <>
            <Form id="contactsForm">
              <Modal
                onDismiss={handleCancel}
                visible={showModal}
                size="large"
                footer={
                  <Box>
                    {submissionStatus && <StatusIndicator type={statusType}>{submissionStatus}</StatusIndicator>}
                    <Box float="right">
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={handleCancel} disabled={isSubmitting}>
                          Cancel
                        </Button>
                        <Button variant="primary" formAction="submit" form="contactsForm" disabled={isSubmitting || !dirty}>
                          {isSubmitting ? 'Submitting...' : 'Submit'}
                        </Button>
                      </SpaceBetween>
                    </Box>
                  </Box>
                }
                header="Contacts"
              >
                <Box className="announcement-box">
                  <SpaceBetween size="m" direction="vertical" className="announcement-content">
                    {errors.items && typeof errors.items === 'string' && (
                      <Box color="text-status-error" fontWeight="bold">
                        {errors.items}
                      </Box>
                    )}
                    <FieldArray
                      name="items"
                      render={(arrayHelpers) => (
                        <AttributeEditor
                          onAddButtonClick={() => arrayHelpers.push({ id: null, key: '', value: '' })}
                          onRemoveButtonClick={({ detail: { itemIndex } }) => arrayHelpers.remove(itemIndex)}
                          addButtonText="Add new contact"
                          removeButtonText="Remove"
                          items={values.items}
                          definition={[
                            {
                              label: 'Group',
                              control: (item, index) => (
                                <FormField errorText={getFieldError(touched, errors, index, 'key')}>
                                  <Autosuggest
                                    enteredTextLabel={(value) => `Use "${value}"`}
                                    onChange={({ detail }) => setFieldValue(`items.${index}.key`, detail.value)}
                                    onBlur={() => setFieldTouched(`items.${index}.key`, true, true)}
                                    value={item.key}
                                    options={autoSuggestionsForGroup}
                                    ariaLabel="General Suggestions"
                                    placeholder="Enter value"
                                    empty="No matches found"
                                  />
                                </FormField>
                              )
                            },
                            {
                              label: 'Contacts',
                              control: (item, index) => (
                                <FormField errorText={getFieldError(touched, errors, index, 'value')}>
                                  <Input
                                    value={item.value}
                                    placeholder="Enter comma separated aliases"
                                    onChange={(e) => setFieldValue(`items.${index}.value`, e.detail.value)}
                                    onBlur={() => setFieldTouched(`items.${index}.value`, true, true)}
                                  />
                                </FormField>
                              )
                            }
                          ]}
                          empty="No contacts available"
                        />
                      )}
                    />
                  </SpaceBetween>
                </Box>
              </Modal>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default ContactsForm;
