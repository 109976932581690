import { Alert, Box, Button, ButtonDropdown, Container, Header, SpaceBetween, Toggle } from '@amzn/awsui-components-react';
import { CellValueChangedEvent, ColDef, GetRowIdParams, GridReadyEvent, ProcessCellForExportParams, SideBarDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { logger } from 'src/analytics/KatalLogger';
import { selectDistinctUsersCombinedFromCurrentBusinessGroup } from 'src/app/AppMetadataSelector';
import { ErrorFallback } from 'src/components/common/ErrorFallback';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import XptGridOverlay from 'src/components/common/XptGridDataStatusOverlay';
import { OperationType } from 'src/constants/generic-constants';
import XptMessages from 'src/constants/xpt-messages';
import { useAuth } from 'src/features/auth/AuthContextProvider';
import { useGridState } from 'src/hooks/useGridState';
import { MasterBusinessSegments } from 'src/models/AppContextModels';
import { LoadingStatus } from 'src/models/AuthContextModels';
import {
  CorpSegmentFilterSelection,
  ForecastGridRowData,
  ForecastTemplateColumns,
  ForecastTemplateMasterCorpSegmentDropdownValues
} from 'src/models/ForecastModels';
import { PlanningCycle } from 'src/models/PlanningCycleModel';
import { selectAllExpenseTypes, selectExpenseTypesForCurrentGroup } from 'src/store/selectors/xPTMapperSelector';
import { fetchAccountBudgetTypeMappings } from 'src/store/slices/xPTMapperSlice';
import { RootState } from 'src/store/store';
import { useAppDispatch } from 'src/store/useAppDispatch';
import {
  addNewRowToGrid,
  CellDataType,
  deepClone,
  ensureRowVisibleAndFocusFirstCell,
  handleCellEditingStopped,
  processCellForClipboard,
  processCellFromClipboard,
  StatusBarConfig
} from 'src/utils/ag-grid-utils';
import { getCurrentUTCTimeInISO } from 'src/utils/date-time-utilities';
import { compareNullableNumbers, generateUniqueId } from 'src/utils/generic-utilities';
import { currentBusinessGroup, currentBusinessGroupShortDesc } from '../businessGroupSelectors';
import { generateColumnDefinitions, isUserAuthorizedToEditThisRow } from './forecast-utils/ForecastColumnGenerator';
import * as ForecastGridConstants from './forecast-utils/ForecastGridConstants';
import { submitForecastData } from './forecast-utils/ForecastTemplateDataSubmission';
import {
  validateDuplicateRecords,
  validateMandatoryFields,
  validateSegments,
  validateUnAuthorizedRows
} from './forecast-utils/ForecastTemplateDataValidations';
import {
  convertToLabelArrays,
  filterRowDataWithAllMatches,
  forecastGridFileActions,
  getForecastExportFileName,
  getForecastTemplateHeaderInfo,
  getNewlyAddedSelections,
  getRowWithUpdatedMetadata,
  isForecastGridRowModified,
  prepareForecastTemplateUploadData
} from './forecast-utils/ForecastTemplateUtils';
import ForecastValidationMessages from './forecast-utils/ValidationMessages';
import { useForecastTemplateContext } from './ForecastTemplateContext';
import ForecastTemplateErrorDisplay from './ForecastTemplateErrorDisplay';
import { ForecastTemplateFileUpload } from './ForecastTemplateFileUpload';
import useLockStatus from './hooks/useLockStatus';
import { corpSegmentSelectionsSelector, planningCycleSelector } from './redux/forecastTemplateSelectors';
import {
  clearForecastDataSubmitError,
  setCorpSegmentFilter,
  setForecastDataSubmitClickCount,
  setForecastDataSubmitting,
  setForecastTemplateDataStatus,
  setShowOnlyCurrentUser,
  setValidationStatus
} from './redux/forecastTemplateSlice';
import { fetchForecastTemplateCompleteData } from './redux/forecastTemplateThunks';

const ForecastTemplateGrid: React.FC = () => {
  const gridRef = useRef<AgGridReact>(null);
  const dispatch = useAppDispatch();
  const userAuth = useAuth();
  const isReadOnlyUser = userAuth.isReadOnlyUser;
  const isAdminUser = userAuth.isDev || userAuth.isAdmin || userAuth.isBusinessLeader;
  const isUserBudgetOwner = userAuth.isBudgetOwner;

  const { clearSpecificFlashMessage, notificationMessage, selectedPlanningCycleSeqId } = useForecastTemplateContext();

  const businessGroup = useSelector(currentBusinessGroup);
  const businessGroupShortDesc = useSelector(currentBusinessGroupShortDesc) || 'default';

  const gridStateKey = `UniqueGridStateKey-ForecastInput-${businessGroupShortDesc}`; // -${currentFilterSelection?.planningCycle?.scenario_seq_id || 0}
  const { saveGridState, restoreGridState, clearGridState } = useGridState(gridRef, gridStateKey);

  const selectedPlanningCycle: PlanningCycle | null | undefined = useSelector((state: RootState) =>
    planningCycleSelector(state, businessGroupShortDesc, selectedPlanningCycleSeqId || '')
  );
  const selectedCorpSegmentFilters: CorpSegmentFilterSelection | undefined = useSelector((state: RootState) =>
    corpSegmentSelectionsSelector(state, businessGroupShortDesc, selectedPlanningCycleSeqId || '')
  );

  const { isCycleLocked } = useLockStatus(selectedPlanningCycle, isAdminUser);

  const themeClassName = useSelector((state: RootState) => state.xptAppMetadataStore.themeClassName);
  const masterBusinessSegments = useSelector((state: RootState) => state.corpSegmentsStore.masterBusinessSegments);
  const masterCorpSegmentDropdowns = useSelector((state: RootState) => state.corpSegmentsStore.masterCorpSegmentDropdownValues);
  const expenseTypesForCurrentGroup = useSelector(selectExpenseTypesForCurrentGroup);
  const expenseTypesCompleteList = useSelector(selectAllExpenseTypes);

  const forecastTemplateCompleteData = useSelector((state: RootState) => state.forecastTemplateStore.forecastTemplateCompleteData);
  const isForecastTemplateDataLoading = useSelector((state: RootState) => state.forecastTemplateStore.forecastTemplateDataLoading);
  const isForecastTemplateDataSubmitting = useSelector((state: RootState) => state.forecastTemplateStore.forecastDataSubmitting);
  const showAllUsersData = useSelector((state: RootState) => state.forecastTemplateStore.showAllData);
  const distinctUsersCombined = useSelector(selectDistinctUsersCombinedFromCurrentBusinessGroup);

  const { accountBudgetTypeMapping, accountBudgetTypeMappingStatus } = useSelector((state: RootState) => state.xPTMappingStore);

  const showLoadingSpinner = isForecastTemplateDataLoading || isForecastTemplateDataSubmitting;

  const [columnDefinitions, setColumnDefinitions] = useState<ColDef[]>([]);
  const [forecastTemplateFilteredData, setForecastTemplateFilteredData] = useState<ForecastGridRowData[]>([]);
  const [originalData, setOriginalData] = useState<Map<number, ForecastGridRowData>>(new Map());

  const [gridDataLoaded, setGridDataLoaded] = useState(false);
  const [forecastRowsRemoved, setForecastRowsRemoved] = useState<any[]>([]);
  const [selectedRowCount, setSelectedRowCount] = useState<number>(0);
  const [showFileImportModal, setShowFileImportModal] = useState<boolean>(false);

  const [forecastTemplateColumnInfo, setForecastTemplateColumnInfo] = useState<ForecastTemplateColumns>({} as ForecastTemplateColumns);
  const dataSubmitError = useSelector((state: RootState) => state.forecastTemplateStore.forecastDataSubmitError);
  const showCurrentUser = useSelector((state: RootState) => state.forecastTemplateStore.showAllData);

  let submitClickCount = 0;
  const sideBarConfigRef = useRef<SideBarDef | null>(null);

  if (!sideBarConfigRef.current) {
    sideBarConfigRef.current = {
      ...ForecastGridConstants.SideBar,
      toolPanels: [...(ForecastGridConstants.SideBar.toolPanels || [])]
    };
  }

  const getRowId = (params: GetRowIdParams) => params.data[ForecastGridConstants.ForecastGridFixedFields.RowId.value];

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      restoreGrid();
    },
    [gridStateKey]
  );

  const restoreGrid = () => {
    setTimeout(() => {
      restoreGridState();
      gridRef?.current?.api.refreshCells();
    }, 0);
  };

  useEffect(() => {
    const initializeMappings = async () => {
      try {
        await dispatch(fetchAccountBudgetTypeMappings()).unwrap();
      } catch (error: any) {
        logger.error(`Error while fetching Account Budget Type Mappings`);
      }
    };
    initializeMappings();
  }, []);

  useEffect(() => {
    if (
      businessGroup &&
      selectedPlanningCycle &&
      selectedCorpSegmentFilters &&
      accountBudgetTypeMappingStatus === LoadingStatus.Completed
    ) {
      applySelectedFiltersOnCompleteForecastData();
    } else {
      clearAllStateVariableForGrid();
    }
  }, [
    selectedCorpSegmentFilters,
    forecastTemplateCompleteData,
    showCurrentUser,
    businessGroup,
    selectedPlanningCycle,
    isCycleLocked,
    masterCorpSegmentDropdowns,
    masterBusinessSegments,
    expenseTypesForCurrentGroup,
    accountBudgetTypeMappingStatus
  ]);

  useEffect(() => {
    if (dataSubmitError) {
      notificationMessage(dataSubmitError, 'error', true);
      dispatch(clearForecastDataSubmitError()); // Clear the error after handling it
    }
  }, [dataSubmitError]);

  // Generate column definitions
  const loadColumnDefinitions = async (
    masterCorpSegmentDropdowns: ForecastTemplateMasterCorpSegmentDropdownValues[],
    masterBusinessSegments: MasterBusinessSegments[],
    expenseTypesForCurrentGroup: string[]
  ) => {
    if (businessGroup && selectedPlanningCycle && selectedCorpSegmentFilters && forecastTemplateCompleteData) {
      const templateColumns = getForecastTemplateHeaderInfo(businessGroup, selectedPlanningCycle);
      setForecastTemplateColumnInfo(templateColumns);
      const colDefs = await generateColumnDefinitions(
        userAuth.Alias,
        selectedPlanningCycle,
        businessGroup,
        isCycleLocked,
        isReadOnlyUser,
        isAdminUser,
        isUserBudgetOwner,
        masterCorpSegmentDropdowns,
        masterBusinessSegments,
        expenseTypesForCurrentGroup,
        accountBudgetTypeMapping,
        distinctUsersCombined
      );
      setColumnDefinitions(colDefs);
    } else {
      setColumnDefinitions([]);
    }
    restoreGrid();
  };

  const sortByLineItemSeqId = (a: { xpt_line_item_seq_id: number | null }, b: { xpt_line_item_seq_id: number | null }) =>
    compareNullableNumbers(a.xpt_line_item_seq_id, b.xpt_line_item_seq_id, 'desc');

  // After fetching complete data, this function applies the selected filters, taking into account the "Show all owners" toggle selection. The filtered data is then rendered into the Ag-Grid component.
  const applySelectedFiltersOnCompleteForecastData = async () => {
    setGridDataLoaded(false);

    const filtersToApply = convertToLabelArrays(selectedCorpSegmentFilters || {});
    const filteredData: ForecastGridRowData[] = filterRowDataWithAllMatches(forecastTemplateCompleteData, filtersToApply);

    const currentUser = userAuth.Alias;

    const filteredDataBasedOnUser = showCurrentUser
      ? filteredData.filter((row) => row[ForecastGridConstants.ForecastGridFixedFields.BudgetOwner.value] === currentUser).sort(sortByLineItemSeqId)
      : filteredData.sort(sortByLineItemSeqId);

    // Make a deep copy to ensure data is mutable
    const mutableForecastRowData = deepClone(filteredDataBasedOnUser);
    setForecastTemplateFilteredData(mutableForecastRowData);
    setOriginalData(
      new Map(mutableForecastRowData.map((row) => [row[ForecastGridConstants.ForecastGridFixedFields.XptLineItemSeqId.value]!, { ...row }]))
    );
    await loadColumnDefinitions(masterCorpSegmentDropdowns, masterBusinessSegments, expenseTypesForCurrentGroup);
    setGridDataLoaded(true);

    setForecastRowsRemoved([]);

    dispatch(setForecastTemplateDataStatus({ dataStatus: ForecastGridConstants.ForecastTemplateDataStatuses.Latest }));
    dispatch(setForecastDataSubmitClickCount(0));
    submitClickCount = 0;
  };

  const clearAllStateVariableForGrid = () => {
    logger.debug('clearing all forecast template state variables...');
    setColumnDefinitions([]);
    setForecastTemplateFilteredData([]);
    setForecastRowsRemoved([]);
    setForecastTemplateColumnInfo({} as ForecastTemplateColumns);
    dispatch(setForecastTemplateDataStatus({ dataStatus: ForecastGridConstants.ForecastTemplateDataStatuses.NotLoaded }));
    dispatch(setForecastDataSubmitClickCount(0));
    setGridDataLoaded(true);
    submitClickCount = 0;
  };

  const expandAllColumnGroups = () => {
    if (gridRef.current) {
      const allColumnGroups = gridRef.current.api.getAllDisplayedColumnGroups() || [];

      allColumnGroups.forEach((group: any) => {
        if (group.getChildren().length > 0) {
          gridRef?.current?.api.setColumnGroupOpened(group.getGroupId(), true);
        }
      });

      // Refresh the grid after expanding column groups
      gridRef.current.api.refreshHeader();
    }
  };

  /**
   * Handles adding a new row to the grid.
   * It initializes the new row with specific properties and adds it to the top of the grid.
   */
  const handleAddNewRow = () => {
    if (!gridRef.current) {
      return;
    }

    if (businessGroup && selectedPlanningCycle) {
      // Initialize the newRow object with specific properties
      const newRow: ForecastGridRowData = {
        [ForecastGridConstants.ForecastGridFixedFields.RowId.value]: generateUniqueId(), // Generate a unique ID for the row
        [ForecastGridConstants.ForecastGridFixedFields.IsNewRow.value]: true,
        [ForecastGridConstants.ForecastGridFixedFields.IsTouched.value]: true,
        [ForecastGridConstants.ForecastGridFixedFields.IsEdited.value]: true,
        [ForecastGridConstants.ForecastGridFixedFields.IsActive.value]: true,
        [ForecastGridConstants.ForecastGridFixedFields.UpdatedAt.value]: getCurrentUTCTimeInISO(),
        [ForecastGridConstants.ForecastGridFixedFields.UpdatedBy.value]: userAuth.Alias,
        [ForecastGridConstants.ForecastGridFixedFields.XptLineItemId.value]: null,
        [ForecastGridConstants.ForecastGridFixedFields.XptLineItemSeqId.value]: null,
        [ForecastGridConstants.ForecastGridFixedFields.ScenarioSeqId.value]: selectedPlanningCycle?.scenario_seq_id,
        [ForecastGridConstants.ForecastGridFixedFields.BudgetOwner.value]: userAuth.Alias,
        [ForecastGridConstants.ForecastGridFixedFields.BudgetType.value]: null
      };

      // Populate the newRow object based on the columns, ensuring not to overwrite the initialized properties
      forecastTemplateColumnInfo?.addNewRowColumns?.forEach((colName) => {
        if (newRow[colName] === undefined) {
          newRow[colName] = null;
        }
      });

      // Add the new row at the top of the grid and get the response from the transaction
      const transactionResult = addNewRowToGrid(gridRef, newRow, 0);

      // Ensure the newly added row is visible and set focus to the first cell
      if (transactionResult?.add) {
        const addedRowNode = transactionResult.add[0];
        if (addedRowNode && addedRowNode.rowIndex != null) {
          ensureRowVisibleAndFocusFirstCell(gridRef, addedRowNode.rowIndex);
        }
      }

      expandAllColumnGroups();
      debouncedSynchronizedData();
      debouncedValidateData();
    }
  };

  /**
   * Handles deletion of selected rows in the grid.
   * It updates the state and grid to reflect the removed rows.
   */
  const handleDeleteSelectedRows = () => {
    // Retrieve the selected rows from the grid
    const selectedRows = gridRef.current?.api?.getSelectedRows();

    // Proceed if there are selected rows
    if (selectedRows && selectedRows.length > 0) {
      // Identifying rows for deletion
      const removedRows = selectedRows
        .filter((row: ForecastGridRowData) => !row[ForecastGridConstants.ForecastGridFixedFields.IsNewRow.value])
        .map((row: ForecastGridRowData) => {
          return {
            ...row,
            [ForecastGridConstants.ForecastGridFixedFields.IsTouched.value]: true,
            [ForecastGridConstants.ForecastGridFixedFields.IsEdited.value]: true,
            [ForecastGridConstants.ForecastGridFixedFields.IsActive.value]: false,
            [ForecastGridConstants.ForecastGridFixedFields.UpdatedAt.value]: getCurrentUTCTimeInISO(),
            [ForecastGridConstants.ForecastGridFixedFields.UpdatedBy.value]: userAuth.Alias
          };
        });

      // Update state to reflect the removed rows
      setForecastRowsRemoved((prevRemovedRows) => [...prevRemovedRows, ...removedRows]);

      // Remove the selected rows from the grid
      gridRef.current?.api?.applyTransaction({ remove: selectedRows });

      debouncedSynchronizedData();
      debouncedValidateData();
    }
  };

  /**
   * Handles cell value changes in the grid.
   * It updates the metadata of the row if it is modified.
   */
  const onCellValueChanged = useCallback(
    (cellValueChangedEvent: CellValueChangedEvent) => {
      const { data: newData } = cellValueChangedEvent;
      const xptLineItemSeqId = newData[ForecastGridConstants.ForecastGridFixedFields.XptLineItemSeqId.value];
      const originalRow = originalData.get(xptLineItemSeqId!);

      // If the row exists, check if a cell value is actually modified or reverted to the original value.
      if (originalRow) {
        const isModified = isForecastGridRowModified(originalRow, newData);
        if (isModified) {
          // Identified as modified row, update the metadata of that row.
          const updatedRow = getRowWithUpdatedMetadata(newData, userAuth.Alias);
          gridRef.current?.api?.applyTransaction({ update: [updatedRow] });
        }
      } else {
        const updatedRow = getRowWithUpdatedMetadata(newData, userAuth.Alias);
        gridRef.current?.api?.applyTransaction({ update: [updatedRow] });
      }

      // Trigger debounced synchronization and validation of the data.
      debouncedSynchronizedData();
      debouncedValidateData();
    },
    [originalData]
  );

  /**
   * Handles the end of a paste operation.
   * It checks if each pasted row has been modified and updates relevant fields.
   */
  const handlePasteEnd = useCallback(() => {
    setForecastTemplateFilteredData((prevData) => {
      return prevData.map((item) => {
        const xptLineItemSeqId = item[ForecastGridConstants.ForecastGridFixedFields.XptLineItemSeqId.value];
        const originalRow = originalData.get(xptLineItemSeqId!);

        // Check if the original row exists and if the row has been modified.
        if (originalRow && isForecastGridRowModified(originalRow, item)) {
          return getRowWithUpdatedMetadata(item, userAuth.Alias);
        } else {
          return item;
        }
      });
    });

    // Trigger debounced synchronization and validation of the data.
    debouncedSynchronizedData();
    debouncedValidateData();
  }, [originalData]);

  // Function to synchronize state with grid data
  const synchronizeGridData = useCallback(() => {
    const api = gridRef.current?.api;
    const updatedRowData: ForecastGridRowData[] = [];
    api?.forEachNode((node) => {
      updatedRowData.push(node.data);
    });
    setForecastTemplateFilteredData(updatedRowData);
  }, []);
  const debouncedSynchronizedData = debounce(synchronizeGridData, 500);

  const hasChanges = useMemo(() => {
    const changesDetected =
      forecastTemplateFilteredData.some((item) => item[ForecastGridConstants.ForecastGridFixedFields.IsTouched.value]) ||
      forecastRowsRemoved.length > 0;

    if (changesDetected) {
      dispatch(setForecastTemplateDataStatus({ dataStatus: ForecastGridConstants.ForecastTemplateDataStatuses.Modified }));
    } else {
      dispatch(setForecastTemplateDataStatus({ dataStatus: ForecastGridConstants.ForecastTemplateDataStatuses.Latest }));
    }
    return changesDetected;
  }, [forecastTemplateFilteredData, forecastRowsRemoved]);

  const validateData = async () => {
    if (businessGroup && selectedPlanningCycle) {
      console.time('ForecastTemplateDataValidation');
      const dirtyRows = forecastTemplateFilteredData.filter((row) => {
        return row[ForecastGridConstants.ForecastGridFixedFields.IsEdited.value];
      });

      const mandatoryFieldValidation = await validateMandatoryFields(dirtyRows, forecastTemplateColumnInfo);
      const unauthorizedRowsValidation = await validateUnAuthorizedRows(dirtyRows, userAuth.Alias, isAdminUser);
      const segmentValidation = await validateSegments(
        dirtyRows,
        forecastTemplateColumnInfo,
        masterCorpSegmentDropdowns,
        masterBusinessSegments,
        expenseTypesForCurrentGroup,
        expenseTypesCompleteList
      );
      const duplicateRecordValidationResult = await validateDuplicateRecords(forecastTemplateFilteredData, forecastTemplateColumnInfo);

      dispatch(
        setValidationStatus({
          HeadersMatching: {
            validationStatus: 'success',
            colorOverride: 'green',
            validationMessage: ForecastValidationMessages.HEADER_VALIDATION_SUCCESS,
            validationDefaultMessage: '',
            validationErrorDetails: []
          },
          ForecastMonthValidation: {
            validationStatus: 'success',
            colorOverride: 'green',
            validationMessage: ForecastValidationMessages.FORECAST_MONTH_DATA_VALIDATION_SUCCESS,
            validationDefaultMessage: '',
            validationErrorDetails: []
          },
          MandatoryFieldValidation: mandatoryFieldValidation,
          NonEditableFieldValidations: {
            validationStatus: 'success',
            colorOverride: 'green',
            validationMessage: ForecastValidationMessages.NON_EDITABLE_FIELD_VALIDATION_SUCCESS,
            validationDefaultMessage: '',
            validationErrorDetails: []
          },
          UnAuthorizedRows: unauthorizedRowsValidation,
          SegmentsValidation: segmentValidation,
          DuplicateRecordValidation: duplicateRecordValidationResult,
          RepeatedRecordValidation: {
            validationStatus: 'success',
            colorOverride: 'green',
            validationMessage: ForecastValidationMessages.NO_EXISTING_RECORDS,
            validationDefaultMessage: '',
            validationErrorDetails: []
          }
        })
      );

      console.timeEnd('ForecastTemplateDataValidation');
      return (
        mandatoryFieldValidation.validationStatus === 'success' &&
        segmentValidation.validationStatus === 'success' &&
        unauthorizedRowsValidation.validationStatus === 'success' &&
        duplicateRecordValidationResult.validationStatus === 'success'
      );
    }
  };

  // Create a debounced version of validateData
  const debouncedValidateData = debounce(validateData, 500);

  useEffect(() => {
    return () => {
      // Cleanup debounced function on component unmount
      debouncedValidateData.cancel();
    };
  }, []);

  const incrementSubmitClickCount = () => {
    submitClickCount++;
    dispatch(setForecastDataSubmitClickCount(submitClickCount));
  };

  /**
   * Marks all touched rows as edited.
   * @param forecastTemplateFilteredData - The current state data.
   * @returns A promise that resolves to the updated data with touched rows marked as edited.
   */
  const markTouchedRowsAsEdited = async (forecastTemplateFilteredData: ForecastGridRowData[]): Promise<ForecastGridRowData[]> => {
    return Promise.resolve(
      forecastTemplateFilteredData.map((item) => {
        if (item[ForecastGridConstants.ForecastGridFixedFields.IsTouched.value]) {
          return {
            ...item,
            [ForecastGridConstants.ForecastGridFixedFields.IsEdited.value]: true
          };
        }
        return item;
      })
    );
  };

  const handleSubmit = async () => {
    if (!businessGroup || !selectedPlanningCycle) {
      logger.error('Business group or planning cycle data is missing.');
      return;
    }

    incrementSubmitClickCount();
    const updatedData = await markTouchedRowsAsEdited(forecastTemplateFilteredData);
    setForecastTemplateFilteredData(updatedData); // Update state with marked data

    setTimeout(async () => {
      const editedRows = forecastTemplateFilteredData.filter((row) => {
        return row[ForecastGridConstants.ForecastGridFixedFields.IsEdited.value];
      });
      const dirtyRows = editedRows.concat(...forecastRowsRemoved);

      if (dirtyRows.length > 0) {
        const validationPassed = await validateData();
        if (!validationPassed) return;

        await submitForecast(dirtyRows);
      } else {
        handleNoChanges();
      }
    }, 500); // Delay to ensure debounced sync is complete
  };

  const submitForecast = async (dirtyRows: any[]) => {
    if (!businessGroup || !selectedPlanningCycle || !selectedPlanningCycleSeqId || !selectedCorpSegmentFilters) {
      logger.error('Business group or planning cycle data is missing.');
      return;
    }

    const inProgressMessageId = generateUniqueId();
    notificationMessage(XptMessages.FORECAST_UPDATE_IN_PROGRESS(selectedPlanningCycle.scenario_year), 'info', false, inProgressMessageId);
    dispatch(setForecastDataSubmitting(true));

    const newRowsAdded = dirtyRows.filter((row) => row[ForecastGridConstants.ForecastGridFixedFields.IsNewRow.value]);
    const newlyAddedSelections = getNewlyAddedSelections(newRowsAdded, selectedCorpSegmentFilters, masterCorpSegmentDropdowns);
    dispatch(
      setCorpSegmentFilter({
        businessGroupShortDesc,
        planningCycleSeqId: selectedPlanningCycleSeqId,
        corpSegmentSelections: newlyAddedSelections
      })
    );

    const forecastTemplateDataForUpload = prepareForecastTemplateUploadData(dirtyRows, selectedPlanningCycle, businessGroup);

    try {
      const message = await submitForecastData({
        userAlias: userAuth.Alias,
        businessGroup,
        selectedPlanningCycle,
        forecastTemplateDataForUpload,
        submitOperationType: OperationType.CRUD
      });
      clearSpecificFlashMessage(inProgressMessageId);
      notificationMessage(message, 'success', true);
      dispatch(setForecastDataSubmitting(false));
      dispatch(fetchForecastTemplateCompleteData(selectedPlanningCycleSeqId));
    } catch (error: any) {
      handleForecastSubmissionError(error, inProgressMessageId);
    }
  };

  const handleForecastSubmissionError = (error: any, inProgressMessageId: string) => {
    logger.error(error);
    dispatch(setForecastDataSubmitting(false));
    clearSpecificFlashMessage(inProgressMessageId);
    notificationMessage(XptMessages.FORECAST_UPDATE_FAILED, 'error', true);
  };

  const handleNoChanges = () => {
    notificationMessage(XptMessages.FORECAST_UPDATE_NO_CHANGES, 'info', true);
    dispatch(setForecastTemplateDataStatus({ dataStatus: ForecastGridConstants.ForecastTemplateDataStatuses.Latest }));
    dispatch(setForecastDataSubmitClickCount(0));
    submitClickCount = 0;
  };

  // Memoize the isRowSelectable function to prevent unnecessary re-renders
  // Defines if a row is selectable based on user authorization.
  const isRowSelectable = useCallback(
    (node: any): boolean => {
      const rowData: any = node.data;
      return isUserAuthorizedToEditThisRow(rowData, userAuth.Alias, isAdminUser);
    },
    [userAuth.Alias, isAdminUser]
  );

  const selectionChanged = () => {
    setSelectedRowCount(gridRef.current!.api.getSelectedNodes().length);
  };

  const forecastGridFileActionsClicked = (id: string) => {
    const { fileName, sheetName } = getForecastExportFileName(businessGroupShortDesc, selectedPlanningCycle?.scenario_year || '');
    switch (id) {
      case 'discard_all_changes':
        selectedPlanningCycleSeqId && dispatch(fetchForecastTemplateCompleteData(selectedPlanningCycleSeqId));
        break;
      case 'ag_grid_export_to_excel':
        agGridExportToExcel(fileName, sheetName);
        break;
      case 'import_from_excel':
        setShowFileImportModal(true);
        break;
      case 'restore-view':
        restoreGridState();
        break;
      case 'save-view':
        saveGridState();
        break;
      case 'reset-view':
        clearGridState();
        break;
      default:
        logger.warn(`No handler defined for action ${id}`);
    }
  };

  const agGridExportToExcel = (fileName: string, sheetName: string) => {
    if (businessGroup && selectedPlanningCycle) {
      gridRef?.current?.api.exportDataAsExcel({
        fileName,
        sheetName,
        author: userAuth.Alias,
        allColumns: true,
        skipColumnGroupHeaders: true,
        skipRowGroups: true,
        columnKeys: getForecastTemplateHeaderInfo(businessGroup, selectedPlanningCycle).forecastTemplateExportFileHeader,
        rowHeight: 20,
        processCellCallback: (params: ProcessCellForExportParams) => {
          if (params.value === '-' || params.value === null || params.value === '') {
            return ''; // Exporting empty string instead of '-'
          }

          // Check if the cell class indicates it's a text field and should be treated as text in Excel
          const isTextField = params.column.getColDef().cellDataType === CellDataType.TEXT;
          if (isTextField) {
            return `'${params.value}`; // Prepend a single quote to force Excel to treat as text
          }

          return params.value;
        },
        shouldRowBeSkipped: (params) => {
          const xptLineItemId = params.node.data?.[ForecastGridConstants.ForecastGridFixedFields.XptLineItemId.value];
          return params.node.footer || xptLineItemId == null;
        }
      });
    }
  };

  const onCancelFileImport = () => {
    setShowFileImportModal(false);
  };

  const onConfirmingFileImport = () => {
    setShowFileImportModal(false);
    selectedPlanningCycleSeqId && dispatch(fetchForecastTemplateCompleteData(selectedPlanningCycleSeqId));
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {showLoadingSpinner && <LoadingSpinner />}
      {!showLoadingSpinner && (
        <>
          <ForecastTemplateFileUpload
            gridRef={gridRef}
            showModal={showFileImportModal}
            onCancel={onCancelFileImport}
            onSuccessConfirm={() => onConfirmingFileImport()}
          />
          <Container
            className="ag-grid-container"
            disableContentPaddings
            header={
              <Header
                actions={
                  selectedPlanningCycle && (
                    <SpaceBetween size="s" direction="horizontal">
                      <Box display="inline-block" variant="awsui-key-label" padding={{ top: 'xxs' }}>
                        {'Show all owners'}
                      </Box>
                      <Box padding={{ top: 'xxs' }}>
                        <Toggle onChange={({ detail }) => dispatch(setShowOnlyCurrentUser(!detail.checked))} checked={!showAllUsersData} />
                      </Box>
                      <Button iconName="refresh" onClick={() => forecastGridFileActionsClicked('discard_all_changes')} />
                      <ButtonDropdown
                        variant="normal"
                        onItemClick={({ detail }) => forecastGridFileActionsClicked(detail.id)}
                        items={forecastGridFileActions(userAuth, isCycleLocked, hasChanges)}
                      >
                        {'Actions'}
                      </ButtonDropdown>
                      {!isReadOnlyUser && (
                        <>
                          <Button iconName="insert-row" disabled={isCycleLocked} onClick={handleAddNewRow}>
                            {'Add'}
                          </Button>
                          <Button iconName="delete-marker" disabled={isCycleLocked || selectedRowCount === 0} onClick={handleDeleteSelectedRows}>
                            {'Delete'}
                          </Button>
                          <Button variant="primary" disabled={isCycleLocked || !hasChanges} onClick={handleSubmit}>
                            {`Submit`}
                          </Button>
                        </>
                      )}
                    </SpaceBetween>
                  )
                }
              >
                <SpaceBetween size="s" direction="horizontal">
                  {<ForecastTemplateErrorDisplay />}
                </SpaceBetween>
              </Header>
            }
          >
            <div className={themeClassName} style={{ height: '100%', width: '100%' }}>
              <ErrorBoundary
                FallbackComponent={() => (
                  <Alert
                    type="error"
                    dismissible={false}
                    visible={true}
                    header="Error"
                    action={<Button onClick={() => window.location.reload()}>Reload</Button>}
                  >
                    {'Unable to load grid data, try reloading once'}
                  </Alert>
                )}
              >
                {!gridDataLoaded && <LoadingSpinner />}
                {gridDataLoaded && (
                  <AgGridReact
                    ref={gridRef}
                    onGridReady={onGridReady}
                    onSelectionChanged={selectionChanged}
                    getRowId={getRowId}
                    onCellValueChanged={onCellValueChanged}
                    onCellEditingStopped={handleCellEditingStopped}
                    defaultColDef={ForecastGridConstants.DefaultColDef}
                    columnDefs={columnDefinitions}
                    rowData={forecastTemplateFilteredData}
                    rowSelection={'multiple'}
                    isRowSelectable={isRowSelectable}
                    sideBar={sideBarConfigRef.current}
                    icons={ForecastGridConstants.forecastDataGridIcons}
                    reactiveCustomComponents={true}
                    suppressRowClickSelection={true}
                    enableRangeHandle={true}
                    enableRangeSelection={true}
                    suppressMultiRangeSelection={true}
                    rowGroupPanelShow={ForecastGridConstants.RowGroupPanelShow}
                    noRowsOverlayComponent={XptGridOverlay}
                    enableAdvancedFilter={false}
                    alwaysMultiSort={true}
                    excelStyles={ForecastGridConstants.forecastExcelStyles}
                    statusBar={StatusBarConfig}
                    rowBuffer={10}
                    rowModelType="clientSide"
                    rowHeight={30}
                    suppressContextMenu
                    undoRedoCellEditing
                    undoRedoCellEditingLimit={20}
                    enterNavigatesVerticallyAfterEdit
                    enterNavigatesVertically
                    onPasteEnd={handlePasteEnd}
                    processCellForClipboard={processCellForClipboard}
                    processCellFromClipboard={processCellFromClipboard}
                    suppressLastEmptyLineOnPaste
                    grandTotalRow="bottom"
                    suppressAggFuncInHeader={true}
                    suppressRowGroupHidesColumns
                  />
                )}
              </ErrorBoundary>
            </div>
          </Container>
        </>
      )}
    </ErrorBoundary>
  );
};

export default ForecastTemplateGrid;
