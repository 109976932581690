import ENVIRONMENT_VARIABLES from 'src/constants/environment-variables';

/**
 * Returns the appropriate stage suffix for S3 bucket names.
 * If the environment stage is 'dev', it returns 'alpha'; otherwise, it returns the actual stage.
 * @returns {string} The stage suffix for S3 bucket names.
 */
const getStageSuffix = (): string => {
  return ENVIRONMENT_VARIABLES.env.Stage === 'dev' ? 'alpha' : ENVIRONMENT_VARIABLES.env.Stage;
};

/**
 * Generates the S3 bucket name for Forecast Template data based on the current environment stage.
 * @returns {object} The complete S3 bucket name and region for Forecast Template data.
 */
export const getForecastS3BucketName = (): { bucketName: string; region: string } => {
  return { bucketName: `xpt-forecast-template-${getStageSuffix()}`, region: `us-west-2` };
};

/**
 * Generates the S3 bucket name for Onboarding Entity Segment load based on the current environment stage.
 * @returns {string} The complete S3 bucket name for Onboarding Entity Segment.
 */
export const getOnboardSegmentBucketName = (): string => {
  return `xpt-glue-corp-segment-${getStageSuffix()}`;
};

/**
 * Generates the S3 bucket name for Actuals data based on the current environment stage.
 * @returns {Object} An object containing the S3 bucket name and region for Actuals data.
 */
export const getActualsS3BucketName = (): { bucketName: string; region: string } => {
  // The bucket name and region are configured by the backend. The bucket name is dynamically generated
  // based on the current environment stage, and the region is hardcoded to 'us-west-2'.
  return { bucketName: `xpt-actuals-${getStageSuffix()}`, region: `us-west-2` };
};

/**
 * Generates the S3 bucket name for Capex data based on the current environment stage.
 * @returns {Object} An object containing the S3 bucket name and region for Actuals data.
 */
export const getCapexS3BucketName = (): { bucketName: string; region: string } => {
  // The bucket name and region are configured by the backend. The bucket name is dynamically generated
  // based on the current environment stage, and the region is hardcoded to 'us-west-2'.
  return { bucketName: `xpt-capex-actuals-${getStageSuffix()}`, region: `us-west-2` };
};
