import { Alert, Box, Button, Input, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';

export type EventContentType = 'default_simple_confirmation' | 'closing_planning_cycle' | 'lock_it_before_closing_planning_cycle';
export interface ShowModalInfo {
  showModal: boolean;
  eventHeader: string;
  eventContentType: EventContentType;
  messageInfo?: string;
  entityInfo?: Record<string, any>;
}

export interface ConfirmationModalProps {
  showModalInfo: ShowModalInfo;
  onCancel: () => void;
  onConfirm: (eventDetails: any) => void;
}
export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ showModalInfo, onCancel, onConfirm }) => {
  const [disableConfirmButton, setDisableConfirmButton] = useState(true);

  const renderContent = () => {
    switch (showModalInfo.eventContentType) {
      case 'closing_planning_cycle':
        return <ClosePlanningCycle setDisableConfirmButton={setDisableConfirmButton} />;
      case 'lock_it_before_closing_planning_cycle':
        return <LockItBeforeClosingPlanningCycle setDisableConfirmButton={setDisableConfirmButton} />;
      default:
        return <SimpleConfirmation setDisableConfirmButton={setDisableConfirmButton} />;
    }
  };

  return (
    <>
      <Modal
        onDismiss={onCancel}
        visible={showModalInfo.showModal}
        closeAriaLabel="Close modal"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={onCancel} formAction="none">
                Cancel
              </Button>
              <Button variant="primary" formAction="none" onClick={() => onConfirm(showModalInfo)} disabled={disableConfirmButton}>
                {showModalInfo.eventContentType !== 'lock_it_before_closing_planning_cycle' ? `Confirm` : 'Confirm Lock & Close'}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={showModalInfo.eventHeader}
      >
        <div>{renderContent()}</div>
      </Modal>
    </>
  );
};

const SimpleConfirmation: React.FC<{ setDisableConfirmButton: (disable: boolean) => void }> = ({ setDisableConfirmButton }) => {
  useEffect(() => {
    setDisableConfirmButton(false);
  }, []);
  return (
    <SpaceBetween size="xs" direction="vertical">
      <p>{`You can’t undo this action.`}</p>
      <Alert statusIconAriaLabel="Info">{`Proceeding with this action will delete all the references.`}</Alert>
    </SpaceBetween>
  );
};
const ClosePlanningCycle: React.FC<{ setDisableConfirmButton: (disable: boolean) => void }> = ({ setDisableConfirmButton }) => {
  useEffect(() => {
    setDisableConfirmButton(false);
  }, []);
  return (
    <SpaceBetween size="xs" direction="vertical">
      <p>{`You can’t undo this action.`}</p>
      <Alert statusIconAriaLabel="Info">{`Proceeding with this action will close the Planning Cycle.`}</Alert>
    </SpaceBetween>
  );
};

const LockItBeforeClosingPlanningCycle: React.FC<{ setDisableConfirmButton: (disable: boolean) => void }> = ({ setDisableConfirmButton }) => {
  useEffect(() => {
    setDisableConfirmButton(false);
  }, []);
  return (
    <SpaceBetween size="xs" direction="vertical">
      <p>{`You can’t undo this action.`}</p>
      <Alert statusIconAriaLabel="Info">{`The Planning Cycle remains unlocked. Would you like to lock it before closure?`}</Alert>
    </SpaceBetween>
  );
};
