import { AppLayout, ContentLayout, Flashbar, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { ErrorFallback } from 'src/components/common/ErrorFallback';
import { XPTBreadcrumbs } from 'src/components/common/XptBreadcrumb';
import BusinessGroupSideNavigation from 'src/features/xpt-layout/XptBusinessGroupSideNavigation';
import { businessGroupBaseBreadcrumbs, currentBusinessGroupName } from '../business-group/businessGroupSelectors';
import { getPOTaggingTaggingBreadcrumbItems } from './POTaggingConfig';
import { POTaggingProvider, usePOTaggingContext } from './POTaggingContext';
import { POTaggingFilterSelection } from './POTaggingFilterSelection';
import { POTaggingGrid } from './POTaggingGrid';

export const POTaggingContent: React.FC = () => {
  const businessGroupName = useSelector(currentBusinessGroupName);
  const businessGroupBaseBreadcrumb = useSelector(businessGroupBaseBreadcrumbs);
  const { flashbarItems } = usePOTaggingContext();

  return (
    <AppLayout
      headerSelector="#h"
      navigation={<BusinessGroupSideNavigation />}
      toolsHide={true}
      breadcrumbs={<XPTBreadcrumbs items={getPOTaggingTaggingBreadcrumbItems(businessGroupBaseBreadcrumb, businessGroupName)} />}
      notifications={<Flashbar stackItems items={flashbarItems} />}
      maxContentWidth={Number.MAX_VALUE}
      contentType="default"
      content={
        <>
          <ContentLayout>
            <SpaceBetween size="xs" direction="vertical">
              <POTaggingFilterSelection />
              <POTaggingGrid />
            </SpaceBetween>
          </ContentLayout>
        </>
      }
    />
  );
};

export const POTagging: React.FC = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <POTaggingProvider>
        <POTaggingContent />
      </POTaggingProvider>
    </ErrorBoundary>
  );
};
