import { AppLayout, Button, Flashbar, FlashbarProps, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { logger } from 'src/analytics/KatalLogger';
import { getPlanningCycleSnapshots } from 'src/api/app-sync-services';
import { ErrorFallback } from 'src/components/common/ErrorFallback';
import { XPTBreadcrumbs } from 'src/components/common/XptBreadcrumb';
import XptTable from 'src/components/common/xpt-table/XptTable';
import { PlanningCycleSnapshot } from 'src/models/PlanningCycleModel';
import { RootState } from 'src/store/store';
import BusinessGroupSideNavigation from '../../xpt-layout/XptBusinessGroupSideNavigation';
import CreateSnapshotModal from './SnapshotModal';
import { Scenario_Snapshot_TABLE_CONFIG, Scenario_Snapshot_TABLE_DEFINITIONS, getScenarioSnapshotTableBreadcrumbItems } from './SnapshotConfig';
import { businessGroupBaseBreadcrumbs } from '../businessGroupSelectors';
import { getHeaderCounter } from 'src/utils/generic-utilities';

const SnapshotsHomePage: React.FC = () => {
  const appLayout = useRef<any>();
  const currentBusinessGroup = useSelector((state: RootState) => state.businessGroupStore.currentBusinessGroup);
  const currentBusinessGroupName = currentBusinessGroup?.data_classification.data_classification_name;
  const businessGroupBaseBreadcrumb = useSelector(businessGroupBaseBreadcrumbs);

  // State for managing Flashbar notifications
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);
  // State to control visibility of the Create Snapshot Modal
  const [showModal, setShowModal] = useState(false);

  // Using React Query to fetch and cache scenario snapshots data
  const {
    data: planningCycleSnapshots,
    isFetching,
    error,
    refetch
  } = useQuery<PlanningCycleSnapshot[], Error>(
    ['planningCycleSnapshots', currentBusinessGroup?.data_classification?.data_classification_id],
    async () => {
      const id = currentBusinessGroup?.data_classification?.data_classification_id;
      if (typeof id === 'number') {
        return getPlanningCycleSnapshots(id);
      } else {
        const errorMessage = 'Data classification ID is missing.';
        logger.error(errorMessage);
        setFlashbarItems([{ type: 'error', content: errorMessage }]);
        throw new Error(errorMessage);
      }
    },
    {
      // Ensures the query only runs when a valid ID is available
      enabled: !!currentBusinessGroup?.data_classification?.data_classification_id,
      staleTime: Infinity
    }
  );

  // Converts any fetch error into a user-friendly message
  const fetchError = error ? 'Failed to fetch scenario snapshots. Please try again.' : null;

  // Component for rendering the table header, with refresh and create snapshot buttons
  const scenarioSnapshotHeader = () => (
    <Header
      actions={
        <SpaceBetween size="m" direction="horizontal">
          <Button iconName="refresh" onClick={() => refetch()}></Button>
          <Button iconName="add-plus" variant="primary" onClick={handleCreateSnapshot}>
            {`Create snapshot`}
          </Button>
        </SpaceBetween>
      }
      counter={getHeaderCounter([], planningCycleSnapshots || [])}
    >
      {`Snapshots`}
    </Header>
  );

  // Handler for opening the Create Snapshot Modal
  const handleCreateSnapshot = () => setShowModal(true);

  // Handler for cancel action in Create Snapshot Modal
  const onCancelCreateSnapshot = () => setShowModal(false);

  // Handler for confirm action in Create Snapshot Modal, triggers data refetch
  const onConfirmingCreateSnapshot = () => {
    setShowModal(false);
    refetch();
  };

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <CreateSnapshotModal
          showModal={showModal}
          onCancel={onCancelCreateSnapshot}
          onConfirm={() => onConfirmingCreateSnapshot()}
          dataClassificationId={currentBusinessGroup?.data_classification?.data_classification_id}
          planningCycleSnapshots={planningCycleSnapshots || []}
        />
        <AppLayout
          ref={appLayout}
          headerSelector="#h"
          navigation={<BusinessGroupSideNavigation />}
          toolsHide={true}
          breadcrumbs={<XPTBreadcrumbs items={getScenarioSnapshotTableBreadcrumbItems(businessGroupBaseBreadcrumb, currentBusinessGroupName)} />}
          notifications={
            <Flashbar
              stackItems
              items={[...flashbarItems, ...(fetchError ? [{ type: 'error', content: fetchError } as FlashbarProps.MessageDefinition] : [])]}
            />
          }
          stickyNotifications={true}
          maxContentWidth={Number.MAX_VALUE}
          contentType="table"
          content={
            <div className="xpt-app-layout-content transform">
              <XptTable
                xptTableVariant={'full-page'}
                loadingStatus={isFetching}
                entityName={Scenario_Snapshot_TABLE_CONFIG.columnInfo.entityName}
                xptTableHeader={scenarioSnapshotHeader()}
                allItems={planningCycleSnapshots || []}
                allColumns={Scenario_Snapshot_TABLE_CONFIG}
                columnDefinitions={Scenario_Snapshot_TABLE_DEFINITIONS}
                itemsPerPage={20}
                selectedItems={[]}
                setSelectedItems={async () => {}}
              />
            </div>
          }
        />
      </ErrorBoundary>
    </>
  );
};

export default SnapshotsHomePage;
