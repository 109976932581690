import { ButtonDropdownProps } from '@amzn/awsui-components-react';
import { logger } from 'src/analytics/KatalLogger';
import { updatePOTaggingData } from 'src/api/app-sync-services';
import { OperationType } from 'src/constants/generic-constants';
import { TABLE_VIEW_ACTIONS } from 'src/hooks/useGridState';
import {
  AccountFilter,
  POActualsDetailsMutation,
  POCorpSegmentFilterDropdowns,
  POFilterDropdowns,
  POTaggingEntity,
  ProductFilter,
  ProjectFilter
} from 'src/models/POTaggingModel';
import { uploadToS3 } from 'src/utils/aws-s3-services';
import { getCurrentUTCTimeInISO } from 'src/utils/date-time-utilities';
import { extractUniqueSortedValues, generateUniqueId } from 'src/utils/generic-utilities';
import { getActualsS3BucketName, getForecastS3BucketName } from 'src/utils/xpt-s3-bucket-details';

export const LOCAL_STORAGE_KEY = 'actualsTaggingSelections';

export const saveSelectionsToLocalStorage = (businessGroupShortDesc: string, actualMonth: string, costCenter: string) => {
  const storedSelections = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
  storedSelections[businessGroupShortDesc] = { actualMonth, costCenter };
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(storedSelections));
};

export const getSelectionsFromLocalStorage = (businessGroupShortDesc: string) => {
  const storedSelections = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
  return storedSelections[businessGroupShortDesc] || { actualMonth: null, costCenter: null };
};

export const validateSelections = (selections: { actualMonth: string | null; costCenter: string | null }, dropdownValues: POFilterDropdowns) => {
  const validActualMonth = selections.actualMonth && dropdownValues.actual_months.includes(selections.actualMonth) ? selections.actualMonth : null;
  const validCostCenter =
    selections.costCenter && dropdownValues.cost_center.map((cc) => cc.cost_center_code).includes(selections.costCenter)
      ? selections.costCenter
      : null;
  return { actualMonth: validActualMonth, costCenter: validCostCenter };
};

/**
 * Filters the POTaggingEntity data based on the provided POCorpSegmentFilterDropdowns.
 * @param {POTaggingEntity[]} data - Array of POTaggingEntity to be filtered.
 * @param {POCorpSegmentFilterDropdowns} filters - Object containing filter values for product, project, account, and je_category.
 * @returns {POTaggingEntity[]} - Array of POTaggingEntity that match the filter criteria.
 */
export const filterPOTaggingEntities = (data: POTaggingEntity[], filters: POCorpSegmentFilterDropdowns): POTaggingEntity[] => {
  if (
    filters.product_filter.length === 0 ||
    filters.project_filter.length === 0 ||
    filters.account_filter.length === 0 ||
    filters.je_category.length === 0
  ) {
    return [];
  }

  return data.filter((entity) => {
    const productMatch = filters.product_filter.some((filter) => filter.product_code === entity.product_code);
    const projectMatch = filters.project_filter.some((filter) => filter.project_code === entity.project_code);
    const accountMatch = filters.account_filter.some((filter) => filter.account_code === entity.account_code);
    const jeCategoryMatch = filters.je_category.includes(entity.je_category);

    return productMatch && projectMatch && accountMatch && jeCategoryMatch;
  });
};

/**
 * Extracts unique sorted values for product, project, account, and JE category filters from the given PO tagging data.
 *
 * @param {POTaggingEntity[]} poTaggingData - Array of POTaggingEntity objects containing the tagging data.
 * @returns {POCorpSegmentFilterDropdowns} - Object containing the unique sorted filter values for product, project, account, and JE category.
 */
export const extractFilterData = (poTaggingData: POTaggingEntity[]): POCorpSegmentFilterDropdowns => {
  const productFilter: ProductFilter[] = extractUniqueSortedValues(poTaggingData, 'product_code').map((code) => ({
    product_code: code,
    product_description: poTaggingData.find((item) => item.product_code === code)?.product_description || ''
  }));

  const projectFilter: ProjectFilter[] = extractUniqueSortedValues(poTaggingData, 'project_code').map((code) => ({
    project_code: code,
    project_description: poTaggingData.find((item) => item.project_code === code)?.project_description || ''
  }));

  const accountFilter: AccountFilter[] = extractUniqueSortedValues(poTaggingData, 'account_code').map((code) => ({
    account_code: code,
    account_description: poTaggingData.find((item) => item.account_code === code)?.account_description || ''
  }));

  const jeCategory = extractUniqueSortedValues(poTaggingData, 'je_category');

  return {
    product_filter: productFilter,
    project_filter: projectFilter,
    account_filter: accountFilter,
    je_category: jeCategory
  };
};

/**
 * Preserves the current filter selections and updates with new filter data if they match existing selections.
 * @param {any[]} currentSelections - Array of current selected filter values.
 * @param {any[]} newSelections - Array of new filter values.
 * @param {string} key - Key to identify the filter value.
 * @returns {any[]} - Array of preserved filter values.
 */
export const preserveFilterSelections = (currentSelections: any[], newSelections: any[], key: string): any[] => {
  const preserved = currentSelections.filter((selection) => newSelections.some((newSelection) => newSelection[key] === selection[key]));
  return preserved.length > 0 ? preserved : newSelections;
};

export const submitPOTaggingData = async (
  mutationData: POTaggingEntity[],
  businessGroupShortDesc: string,
  dataClassificationId: number,
  selectedActualMonth: string,
  selectedCostCenter: string,
  userAlias: string,
  submitOperationType: OperationType
) => {
  try {
    const forecastS3Bucket = getForecastS3BucketName().bucketName;
    const s3BucketName = getActualsS3BucketName().bucketName;
    const s3Region = getActualsS3BucketName().region;
    const uniqueId = generateUniqueId();
    const s3Key = `${businessGroupShortDesc}_${dataClassificationId}/${selectedActualMonth}/${selectedCostCenter}/mutation/${uniqueId}.json`;
    const s3ObjectMetadata: Record<string, string> = {
      po_tagging_data_month: selectedActualMonth,
      po_tagging_cost_center: selectedCostCenter,
      po_tagging_data_length: `${mutationData.length}`,
      updated_by: userAlias,
      updated_at: getCurrentUTCTimeInISO()
    };

    const mutationRequest: POActualsDetailsMutation = {
      period_name: selectedActualMonth,
      cost_center_code: selectedCostCenter,
      data_classification_id: dataClassificationId,
      forecast_s3_bucket: forecastS3Bucket,
      operation_type: submitOperationType,
      s3_bucket: s3BucketName,
      s3_region: s3Region,
      s3_key: s3Key,
      updated_by: userAlias,
      updated_at: getCurrentUTCTimeInISO()
    };

    try {
      await uploadToS3(s3BucketName, s3Key, mutationData, true, s3ObjectMetadata);
      logger.info(
        `Uploaded actuals tagging data to S3 for month ${selectedActualMonth} & cost center ${selectedCostCenter} with S3 URI: ${s3BucketName}/${s3Key}`
      );

      await updatePOTaggingData(mutationRequest);

      return `Successfully updated PO Tagging data`;
    } catch (error: any) {
      logger.error('Error during the po tagging data submission process:', error);
      throw error;
    }
  } catch (error: any) {
    logger.error('Error during the po tagging data submission process:', error);
    throw error;
  }
};

export const getPOTaggingExportFileName = (businessGroupShortDesc: string, selectedActualMonth: string, selectedCostCenter: string) => {
  const fileName = `${businessGroupShortDesc}_${selectedActualMonth}_${selectedCostCenter}`;
  const sheetName = `${selectedActualMonth}_${selectedCostCenter}`;
  return { fileName, sheetName };
};

export const poTaggingGridFileActions = (isReadOnlyUser: boolean): ButtonDropdownProps.ItemOrGroup[] => {
  const defaultActions: ButtonDropdownProps.ItemOrGroup[] = [
    {
      id: 'ag_grid_export_to_excel',
      text: 'Export to Excel'
    }
  ];

  if (isReadOnlyUser) {
    return defaultActions;
  }

  const actions: ButtonDropdownProps.ItemOrGroup[] = [
    ...defaultActions,
    {
      id: 'import_from_excel',
      text: 'Upload from Excel'
    },
    ...TABLE_VIEW_ACTIONS
  ] as ButtonDropdownProps.ItemOrGroup[];
  return actions;
};

export const getLineItemIdsBasedOnCC = (lineItemIds: string[], selectedCostCenter: string) => {
  const lineItemIdsBasedOnCostCenter = lineItemIds.filter((lineItemId) => lineItemId.split('-')[1] === selectedCostCenter);
  return lineItemIdsBasedOnCostCenter;
};
