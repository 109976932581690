import { createAsyncThunk } from '@reduxjs/toolkit';
import { logger } from 'src/analytics/KatalLogger';
import { eCorpSegmentNames, isSegmentMultiSelectable } from 'src/constants/corp-segment-constants';
import { eErrorMessages } from 'src/constants/generic-constants';
import XptMessages from 'src/constants/xpt-messages';
import { ForecastRowDataStructured, ForecastTemplateCorpSegmentDropdowns } from 'src/models/ForecastModels';
import { RootState } from 'src/store/store';
import { getFileFromS3URI } from 'src/utils/aws-s3-services';
import { INITIAL_VALIDATION_STATUS } from '../forecast-utils/ForecastTemplateDataValidations';
import {
  flattenForecastRowDataStructured,
  forecastDataReadS3URI,
  generateAndProcessCorpSegmentFilterValues,
  getForecastTemplateHeaderInfo
} from '../forecast-utils/ForecastTemplateUtils';
import {
  setForecastDataSubmitError,
  setForecastTemplateCompleteData,
  setForecastTemplateCorpSegmentDropdowns,
  setForecastTemplateDataLoading,
  setValidationStatus
} from './forecastTemplateSlice';

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const fetchForecastTemplateCompleteData = createAsyncThunk(
  'forecastTemplate/fetchForecastTemplateCompleteData',
  async (selectedPlanningCycleSeqId: string, { getState, dispatch, rejectWithValue }) => {
    dispatch(setForecastTemplateDataLoading(true));

    const state = getState() as RootState;

    let businessGroup = state.businessGroupStore.currentBusinessGroup;
    let attempts = 0;

    // Retry logic to wait for businessGroup to be defined
    while (!businessGroup && attempts < 5) {
      await delay(100); // Wait for 100ms before retrying
      businessGroup = state.businessGroupStore.currentBusinessGroup;
      attempts += 1;
    }

    if (!businessGroup) {
      const errorMsg = 'No business group selected';
      logger.error(errorMsg);
      dispatch(setForecastTemplateDataLoading(false));
      return rejectWithValue(errorMsg);
    }

    const { filterSelection } = state.forecastTemplateStore;
    const dataClassificationId = businessGroup.data_classification.data_classification_id;
    const businessGroupShortDesc = businessGroup.data_classification.data_classification_short_description;

    if (!dataClassificationId || !selectedPlanningCycleSeqId) {
      const errorMsg = 'No planning cycle selected to fetchForecastTemplateCompleteData';
      logger.error(errorMsg);
      dispatch(setForecastTemplateDataLoading(false));
      return rejectWithValue(errorMsg);
    }

    const planningCycle = filterSelection[businessGroupShortDesc]?.[selectedPlanningCycleSeqId]?.planningCycle;

    if (!planningCycle || !planningCycle.scenario_seq_id) {
      const errorMsg = 'No valid planning cycle selected';
      logger.error(errorMsg);
      dispatch(setForecastTemplateDataLoading(false));
      return rejectWithValue(errorMsg);
    }

    try {
      const forecastHeaderInfo = getForecastTemplateHeaderInfo(businessGroup, planningCycle);
      const optionalCorpSegmentsHeader = forecastHeaderInfo.corpSegmentOptionalFields;
      const s3URI = forecastDataReadS3URI(dataClassificationId, businessGroupShortDesc, planningCycle.scenario_seq_id);
      let forecastRowDataResponseFromS3: ForecastRowDataStructured[] = [];
      try {
        forecastRowDataResponseFromS3 = (await getFileFromS3URI(s3URI)) as unknown as ForecastRowDataStructured[];
      } catch (error: any) {
        if (error.message === eErrorMessages.NO_DATA_FOUND) {
          dispatch(setForecastDataSubmitError(XptMessages.FORECAST_FETCH_FAILED_S3_NO_DATA));
        } else {
          dispatch(setForecastDataSubmitError(XptMessages.FORECAST_FETCH_FAILED_S3));
        }
      }

      const flattenedData = flattenForecastRowDataStructured(forecastRowDataResponseFromS3, optionalCorpSegmentsHeader);
      logger.info(
        `Fetched data for Business Group: ${businessGroupShortDesc}, Planning Cycle: ${planningCycle.scenario_year} (Seq ID: ${
          planningCycle.scenario_seq_id
        }) - ${flattenedData?.length || 0} records`
      );

      // Update the complete data in the store
      dispatch(setForecastTemplateCompleteData(flattenedData));
      dispatch(setValidationStatus(INITIAL_VALIDATION_STATUS));

      const corpSegmentFilters: ForecastTemplateCorpSegmentDropdowns[] = [];
      businessGroup.corp_segments
        .filter((corpSegment) => corpSegment.corp_segment_required)
        .forEach((corpSegment) => {
          const uniqueSortedValues = generateAndProcessCorpSegmentFilterValues(flattenedData, corpSegment);
          const corpSegmentFilter: ForecastTemplateCorpSegmentDropdowns = {
            key: corpSegment.corp_segment_id,
            segmentKey: corpSegment.corp_segment_name_key,
            displayName: corpSegment.corp_segment_name,
            isRequired: corpSegment.corp_segment_required,
            isMultiSelect: isSegmentMultiSelectable(corpSegment.corp_segment_name as eCorpSegmentNames),
            loadingStatus: 'finished',
            fieldDropdownOptions: uniqueSortedValues
          };
          corpSegmentFilters.push(corpSegmentFilter);
        });
      // Update the corp segment dropdowns in the store
      dispatch(setForecastTemplateCorpSegmentDropdowns(corpSegmentFilters));
      dispatch(setForecastTemplateDataLoading(false));
    } catch (error: any) {
      logger.error(XptMessages.FORECAST_FETCH_FAILED, error);
      dispatch(setForecastTemplateCompleteData([]));
      dispatch(setForecastTemplateCorpSegmentDropdowns([]));
      dispatch(setForecastTemplateDataLoading(false));
      dispatch(setValidationStatus(INITIAL_VALIDATION_STATUS));
      dispatch(setForecastDataSubmitError(XptMessages.FORECAST_FETCH_FAILED));
      return rejectWithValue(XptMessages.FORECAST_FETCH_FAILED);
    }
  }
);

export const clearForecastTemplateCompleteData = createAsyncThunk('forecastTemplate/clearForecastTemplateCompleteData', async (_, { dispatch }) => {
  logger.debug('clearing forecast template data');
  dispatch(setForecastTemplateCompleteData([]));
  dispatch(setForecastTemplateDataLoading(false));
  dispatch(setForecastTemplateCorpSegmentDropdowns([]));
  dispatch(setValidationStatus(INITIAL_VALIDATION_STATUS));
});
