const ForecastValidationMessages = {
  HEADER_VALIDATION_DEFAULT_MESSAGE: "The file format is not recognized. The header fields do not match.",
  HEADER_VALIDATION_FAILED: "The file format is not recognized. The header fields do not match.",
  HEADER_VALIDATION_SUCCESS: 'The header fields are valid',
  HEADER_VALIDATION_ERROR: 'Header validation error',

  SKIPPED_DUE_TO_HEADER_FAILURE: 'Skipped due to header validation failure',

  NO_MODIFIED_ROWS: 'No modified rows to validate',

  VALIDATION_ERROR: 'Validation error',

  MANDATORY_FIELDS_VALIDATION_DEFAULT_MESSAGE: (mandatoryFields: string[]) => `The following fields are required: ${mandatoryFields.join(', ')}.`,
  MANDATORY_FIELDS_VALIDATION_IN_PROGRESS: 'Validating required fields',
  MANDATORY_FIELDS_VALIDATION_SUCCESS: 'All required fields are present',
  MANDATORY_FIELDS_VALIDATION_FAILED: 'Required fields are missing',
  MANDATORY_FIELDS_VALIDATION_ERROR: 'Required field validation error',

  NON_EDITABLE_FIELD_VALIDATION_DEFAULT: 'Modification Attempt on a Non-Editable Field',
  NON_EDITABLE_FIELD_VALIDATION_IN_PROGRESS: 'Validating non-editable fields',
  NON_EDITABLE_FIELD_VALIDATION_SUCCESS: 'Non-editable fields validation passed',
  NON_EDITABLE_FIELD_VALIDATION_FAILED: 'Non-editable fields validation failed',
  NON_EDITABLE_FIELD_VALIDATION_ERROR: 'Non-editable field validation error',

  SEGMENTS_VALIDATION_DEFAULT: 'Ensure that Corp Segments have valid data.',
  SEGMENTS_VALIDATION_IN_PROGRESS: 'Validating segments',
  SEGMENTS_VALIDATION_IN_SUCCESS: 'Valid segments',
  SEGMENTS_VALIDATION_IN_FAILED: 'Invalid segments',
  SEGMENTS_VALIDATION_IN_ERROR: 'Segment validation error',

  UNAUTHORIZED_ROWS_VALIDATION_DEFAULT: 'Update only the data you own.',
  UNAUTHORIZED_ROWS_VALIDATION_IN_PROGRESS: 'Validating unauthorized modifications',
  UNAUTHORIZED_ROWS_VALIDATION_SUCCESS: 'Authorization validated',
  UNAUTHORIZED_ROWS_VALIDATION_FAILED: 'Unauthorized modifications',
  UNAUTHORIZED_ROWS_VALIDATION_ERROR: 'Authorization validation error',

  DUPLICATE_RECORDS_VALIDATION_DEFAULT:`Duplicate records found in the data.`,
  DUPLICATE_RECORDS_VALIDATION_IN_PROGRESS: 'Checking for duplicate records',
  DUPLICATE_RECORDS_FOUND: 'Duplicate records found',
  NO_DUPLICATE_RECORDS: 'No duplicate records found',
  DUPLICATE_RECORDS_VALIDATION_ERROR: 'Error checking for duplicate records',

  EXISTING_RECORDS_VALIDATION_DEFAULT: `Checking if records already exist`,
  EXISTING_RECORDS_VALIDATION_IN_PROGRESS: 'Checking if records already exist',
  EXISTING_RECORDS_FOUND: 'Record already exists',
  NO_EXISTING_RECORDS: 'No existing records found',
  EXISTING_RECORDS_VALIDATION_ERROR: 'Error checking for existing records',

  FORECAST_MONTH_DATA_VALIDATION_DEFAULT: 'Forecast months should have valid values',
  FORECAST_MONTH_DATA_VALIDATION_IN_PROGRESS: 'Validating forecast month data',
  FORECAST_MONTH_DATA_VALIDATION_SUCCESS: 'Forecast month data is valid',
  FORECAST_MONTH_DATA_VALIDATION_FAILED: 'Forecast month data is invalid',
  FORECAST_MONTH_DATA_VALIDATION_ERROR: 'Error validating forecast month data'
};

export default ForecastValidationMessages;