import { getCurrentUTCTimeInISO } from 'src/utils/date-time-utilities';
import { EntityStatus, PlanningCycleSnapshot } from 'src/models/PlanningCycleModel';
import * as Yup from 'yup';
import { getForecastS3BucketName } from 'src/utils/xpt-s3-bucket-details';

export const SCENARIO_SNAPSHOT_NOTES_MAX_LENGTH = 200;
export const scenarioSnapshotSchema = Yup.object().shape({
  scenario_name: Yup.string().required('required'),
  scenario_snapshot_name: Yup.string()
    .required('required.')
    .max(SCENARIO_SNAPSHOT_NOTES_MAX_LENGTH, 'Snapshot name must be at most 200 characters long.'),
  scenario_snapshot_time: Yup.string().required('required.'),
  scenario_snapshot_note: Yup.string().nullable().max(200, 'Note must be at most 200 characters long.')
});

/**
 * Generates the S3 URI for reading snapshot data.
 * @param dataClassificationId The data classification ID.
 * @param dataClassificationShortDesc The short description of the data classification.
 * @param snapshotId The scenario sequence ID.
 * @returns The S3 URI string.
 */
export const snapshotDataReadS3URI = (
  dataClassificationId: number,
  dataClassificationShortDesc: string,
  scenarioSeqId: number,
  snapshotId: number
): string => {
  const bucketName = getForecastS3BucketName().bucketName;
  const s3Key = `${dataClassificationShortDesc}_${dataClassificationId}/${scenarioSeqId}/snapshots/${snapshotId}/list_snapshot_details.json`;
  return `s3://${bucketName}/${s3Key}`;
};

export const prepareForSubmitSnapshotMutation = (
  values: PlanningCycleSnapshot,
  userAlias: string,
  pendingEntityStatus: EntityStatus | undefined,
  dataClassificationShortDesc: string,
  dataClassificationId: number,
  scenarioSeqId: number
): PlanningCycleSnapshot => {
  const snapshotFolderKey = `${dataClassificationShortDesc}_${dataClassificationId}/${scenarioSeqId}/snapshots`;
  return {
    ...values,
    scenario_snapshot_id: null,
    scenario_snapshot_status: pendingEntityStatus,
    s3_bucket_name: getForecastS3BucketName().bucketName,
    s3_region: getForecastS3BucketName().region,
    s3_key: snapshotFolderKey,
    created_by: userAlias,
    created_at: getCurrentUTCTimeInISO(),
    updated_by: userAlias,
    updated_at: getCurrentUTCTimeInISO()
  };
};
