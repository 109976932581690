import { Button, FlashbarProps, Header, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import React, { useCallback, useEffect, useState } from 'react';
import { logger } from 'src/analytics/KatalLogger';
import { getAuditLogs } from 'src/api/app-sync-services';
import CodeViewModal from 'src/components/common/CodeViewModal';
import XptTable from 'src/components/common/xpt-table/XptTable';
import { AuditType } from 'src/constants/generic-constants';
import { AuditLogEntity } from 'src/models/AppContextModels';
import { dateTimeComparator } from 'src/utils/date-time-utilities';
import { getHeaderCounter, parseAWSJSONToJsonObject } from 'src/utils/generic-utilities';
import { AUDIT_TABLE_CONFIG, getAuditTableDefinitions } from './AuditLogConfig';

interface AuditLogTableProps {
  showAuditFor: AuditType;
  itemsPerPage: number;
  headerName: string;
  displayFlashMessage?: () => {};
}

export const AuditLogTable: React.FC<AuditLogTableProps> = ({ showAuditFor, itemsPerPage, headerName, displayFlashMessage }) => {
  const [auditLogs, setAuditLogs] = useState<AuditLogEntity[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedAdditionalInfo, setSelectedAdditionalInfo] = useState<any>(null);
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  useEffect(() => {
    loadAuditData();
  }, []);

  // Use useCallback to memoize the function and prevent unnecessary re-renders
  const loadAuditData = useCallback(async () => {
    try {
      setIsLoading(true);
      const auditLogResponse = await getAuditLogs();
      const filteredAudit = auditLogResponse.filter((log) => {
        if (showAuditFor === AuditType.Capex) {
          return log.page_name === AuditType.Capex;
        }
        return true;
      });
      const auditLogsSorted = filteredAudit.sort((a, b) => dateTimeComparator(a.created_at, b.created_at, false));
      setAuditLogs(auditLogsSorted);
      setFlashbarItems([]);
    } catch (error: any) {
      logger.error('Unable to load audit log data', error);
      setAuditLogs([]);
      setFlashbarItems([
        {
          type: error,
          content: 'Unable to load audit log data',
          dismissible: true
        }
      ]);
    } finally {
      setIsLoading(false);
    }
  }, [showAuditFor, getAuditLogs, dateTimeComparator, logger, displayFlashMessage]);

  // Handler for the "Show Details" button click
  const handleAdditionalInfoClick = (auditLogId: number): void => {
    try {
      const selectedLog = auditLogs.find((log) => log.audit_log_id === auditLogId);
      if (selectedLog) {
        const parsedInfo = parseAWSJSONToJsonObject(selectedLog.additional_info);
        setSelectedAdditionalInfo(parsedInfo);
        setIsModalVisible(true);
        setFlashbarItems([]);
      }
    } catch (error: any) {
      setFlashbarItems([
        {
          type: error,
          content: 'Unable to load additional info',
          dismissible: true
        }
      ]);
    }
  };

  // Handler for modal close
  const handleModalClose = (): void => {
    setSelectedAdditionalInfo(null);
    setIsModalVisible(false);
  };

  const AuditTableHeader = () => {
    return (
      <Header
        actions={
          <SpaceBetween size="m" direction="horizontal">
            {flashbarItems.length > 0 && (
              <Button className="lock-status-button">
                <StatusIndicator type="error">{flashbarItems[0].content}</StatusIndicator>
              </Button>
            )}
            <Button iconName="refresh" onClick={loadAuditData}></Button>
          </SpaceBetween>
        }
        counter={getHeaderCounter([], auditLogs)}
      >
        {headerName}
      </Header>
    );
  };

  return (
    <>
      <XptTable
        xptTableVariant={'container'}
        loadingStatus={isLoading}
        entityName={headerName}
        xptTableHeader={<AuditTableHeader />}
        allItems={auditLogs}
        allColumns={AUDIT_TABLE_CONFIG}
        columnDefinitions={getAuditTableDefinitions({ handleAdditionalInfoClick })}
        itemsPerPage={itemsPerPage}
        selectedItems={[]}
        setSelectedItems={async (items) => {}}
      />
      <CodeViewModal
        visible={isModalVisible}
        onClose={handleModalClose}
        header="Additional Information"
        content={selectedAdditionalInfo}
        contentType="json"
      />
    </>
  );
};
