import { DropdownModel, ItemMetadata } from 'src/models/AppContextModels';
import { getCurrentUTCTimeInISO } from './date-time-utilities';
import { eEntityStatus } from 'src/constants/generic-constants';
import { BadgeProps, SelectProps, StatusIndicatorProps } from '@amzn/awsui-components-react';
import { v4 as uuidv4 } from 'uuid';
import { logger } from 'src/analytics/KatalLogger';

export const generateUniqueId = () => uuidv4();

export const isEmptyObject = (obj: any) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const uniqueSortedValues = (items: string[]): string[] => {
  return Array.from(new Set(items)).sort();
};

// Helper function to extract unique and sorted values from an array of objects
export const extractUniqueSortedValues = <T, K extends keyof T>(items: T[], key: K) => {
  const uniqueItems = Array.from(new Set(items.map((item) => item[key])));
  return uniqueItems.sort((a, b) => (a > b ? 1 : -1));
};

export const localeCompareNullableSelectProps = (a: SelectProps.Option, b: SelectProps.Option): number => {
  const labelA = a.label || ''; // If label is undefined, use an empty string
  const labelB = b.label || ''; // If label is undefined, use an empty string
  return labelA.localeCompare(labelB);
};

/**
 * Compares two numbers, handling null values by treating them as the smallest value.
 *
 * @param {number | null} a - The first number to compare.
 * @param {number | null} b - The second number to compare.
 * @param {'asc' | 'desc'} [order='asc'] - The order of comparison, 'asc' for ascending or 'desc' for descending.
 * @returns {number} - 0 if the numbers are equal, -1 if a is less than b, and 1 if a is greater than b.
 */
export const compareNullableNumbers = (a: number | null, b: number | null, order: 'asc' | 'desc' = 'asc'): number => {
  if (a === b) return 0; // If both are equal, return 0
  if (a === null) return order === 'asc' ? -1 : 1; // null is considered the smallest value
  if (b === null) return order === 'asc' ? 1 : -1; // null is considered the smallest value

  if (order === 'asc') {
    return a > b ? 1 : -1;
  } else {
    return a > b ? -1 : 1;
  }
};

export const getMultiSelectPlaceHolderValue = (entity: any, entityName: string): string => {
  let placeHolderValue = 'Select ' + entityName;
  if (entity?.length == 0) {
    return placeHolderValue;
  }

  if (entity?.length == 1) {
    placeHolderValue = entity[0].label;
  }

  if (entity?.length > 1) {
    placeHolderValue = entity[0].label + ' + ' + (entity?.length - 1) + (entity?.length - 1 === 1 ? ' Other' : ' Others');
  }
  return `${placeHolderValue} `;
};

export const arrayToSelectDropdownOptions = (array: any[], fieldToConsider: string, valuesToConsider?: string): DropdownModel[] => {
  return array.map((item) => {
    return {
      label: item[fieldToConsider],
      value: item[valuesToConsider ? valuesToConsider : fieldToConsider]
    };
  });
};

export const stringToSingleSelectDropdown = (value: string): DropdownModel => {
  return {
    label: value,
    value: value
  };
};

export const objectToSingleSelectDropdown = (value: string, id: string): DropdownModel | null => {
  if (!id) return null;

  return { label: value, value: id };
};

export const stringToMultiSelectDropdown = (value: string): DropdownModel[] => {
  return [{ label: value, value: value }];
};

export const objectToMultiSelectDropdown = (value: string, id: string): DropdownModel[] => {
  if (!id) return [];

  return [{ label: value, value: id }];
};

export const formatBytes = (bytes: number | undefined) => {
  if (!bytes) {
    return '';
  }
  if (bytes >= 1024 * 1024) {
    // Convert to MB with two decimal places
    return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
  } else if (bytes >= 1024) {
    // Convert to KB with two decimal places
    return (bytes / 1024).toFixed(2) + ' KB';
  } else {
    // Display in bytes
    return bytes + ' bytes';
  }
};

export const fetchNewItemMetadata = (userAlias: string): ItemMetadata => {
  return {
    is_active: true,
    created_by: userAlias,
    created_at: getCurrentUTCTimeInISO(),
    updated_by: userAlias,
    updated_at: getCurrentUTCTimeInISO()
  } as ItemMetadata;
};

export const fetchUpdatedItemMetadata = (userAlias: string, itemMetadata: ItemMetadata): ItemMetadata => {
  return {
    is_active: itemMetadata.is_active,
    created_by: itemMetadata.created_by,
    created_at: itemMetadata.created_at,
    updated_by: userAlias,
    updated_at: getCurrentUTCTimeInISO()
  } as ItemMetadata;
};

export const isDefinedAndNotEmptyObject = (obj: any): boolean => {
  if (!obj) return false;
  return typeof obj !== 'undefined' && Object.keys(obj).length !== 0;
};

export const getStatusIndicatorColorOverride = (status: eEntityStatus): StatusIndicatorProps.Color => {
  switch (status) {
    case eEntityStatus.Open:
    case eEntityStatus.Success:
    case eEntityStatus.Active:
      return 'green';
    case eEntityStatus.Error:
    case eEntityStatus.Warning:
    case eEntityStatus.Stopped:
    case eEntityStatus.Locked:
    case eEntityStatus.Inactive:
      return 'red';
    case eEntityStatus.Info:
    case eEntityStatus.Scheduled:
      return 'blue';
    case eEntityStatus.Loading:
    case eEntityStatus.InProgress:
    case eEntityStatus.Pending:
    case eEntityStatus.Closed:
      return 'grey';
    default:
      return 'grey'; // default color if no match
  }
};

export const getStatusIndicatorType = (status: eEntityStatus): StatusIndicatorProps.Type => {
  switch (status) {
    case eEntityStatus.Open:
    case eEntityStatus.Success:
    case eEntityStatus.Active:
      return 'success';
    case eEntityStatus.Error:
    case eEntityStatus.Closed:
    case eEntityStatus.Inactive:
      return 'error';
    case eEntityStatus.Warning:
      return 'warning';
    case eEntityStatus.Stopped:
    case eEntityStatus.Locked:
      return 'stopped';
    case eEntityStatus.Info:
      return 'info';
    case eEntityStatus.Loading:
      return 'loading';
    case eEntityStatus.InProgress:
      return 'in-progress';
    case eEntityStatus.Pending:
    case eEntityStatus.Scheduled:
      return 'pending';
    default:
      return 'info'; // default color if no match
  }
};

export const getStatusIndicatorColorOverrideForBoolean = (data: boolean): StatusIndicatorProps.Color => {
  return data ? 'green' : 'red';
};

export const getStatusIndicatorTypeForBoolean = (data: boolean): StatusIndicatorProps.Type => {
  return data ? 'success' : 'stopped';
};

export const getActiveStatusBadge = (status: boolean): BadgeProps['color'] => {
  let badgeColor: BadgeProps['color'] = 'green';
  return status ? badgeColor : 'red';
};

// Utility function to get header counter text
export const getHeaderCounter = (selectedRowData: any[], rowData: any[]): string => {
  return rowData && (selectedRowData.length ? `(${selectedRowData.length}/${rowData.length})` : `(${rowData.length})`);
};

export const flattenArrayOfObjects = (array: Record<string, any>[]): Record<string, any>[] => {
  return array.map((obj) => {
    const flatData: Record<string, any> = {};

    const flatten = (source: Record<string, any>, prefix = '') => {
      for (const key in source) {
        if (Array.isArray(source[key])) {
          // If the property is an array, leave it as is
          flatData[key] = source[key];
        } else if (typeof source[key] === 'object') {
          // If the property is an object, recursively flatten it
          flatten(source[key], key + '_');
        } else {
          // If the property is a primitive value, add it to the flatData
          flatData[key] = source[key];
        }
      }
    };
    flatten(obj);
    return flatData;
  });
};

export const characterCountConstraintMessage = (max: number, fieldLength: number) => {
  return `Maximum ${max} characters (${Math.max(max - fieldLength, 0)} remaining)`;
};

/**
 * Attempts to parse a JSON string into a specified type. If parsing fails, returns a default value.
 * This function provides a safe way to handle potentially malformed JSON data.
 *
 * @param {string} jsonString The JSON string to parse.
 * @param {T} defaultValue The default value to return in case parsing fails. This helps ensure type safety.
 * @return {T} The parsed object if successful, or the default value if parsing fails.
 */
export const safeParseJSON = <T>(jsonString: string, defaultValue: T): T => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    console.error('Error parsing JSON string:', error);
    return defaultValue;
  }
};

/**
 * Parses the additional_info string twice to convert AWSJSON format to standard JSON.
 *
 * AWSJSON format can sometimes result in strings that are double-encoded JSON.
 * For instance, the string received might look like:
 * "\"[{\\\"s3_key\\\": \\\"test/abc.txt\\\"}]\""
 * The first parse converts it to:
 * "[{\"s3_key\": \"test/abc.txt\"}]"
 * The second parse converts it to a JavaScript object:
 * [{ s3_key: "test/abc.txt" }]
 *
 * @param {string} awsJSONObjectAsString - The additional_info string to be parsed.
 * @returns {any} The parsed JSON object.
 * @throws Will throw an error if parsing fails.
 */
export const parseAWSJSONToJsonObject = (awsJSONObjectAsString: string): any => {
  try {
    // parse to handle the double-encoded JSON string
    return JSON.parse(JSON.parse(awsJSONObjectAsString));
  } catch (error: any) {
    // Log the error details for debugging purposes
    logger.error('Failed to parse additional_info:', { error: error, errorData: awsJSONObjectAsString });
    // Throw a new error to indicate parsing failure
    throw new Error('Failed to load Additional Info data');
  }
};