import { LocalizationContextBuilder } from '@amzn/arb-tools';
import { MbmProvider } from '@amzn/react-arb-tools';
import React, { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { AppInitializationFailed } from '../components/common/ErrorFallback';
import { DEFAULT_LOCALE_EN_US, TRANSLATION_ROUTE, eErrorMessages } from '../constants/generic-constants';
import { AuthContextProvider } from '../features/auth/AuthContextProvider';
import arbManifest from '../i18n/translations/arbManifest';
import { store } from '../store/store';
import AppInitializer from './AppInitializer';
import { AppRouter } from './AppRouter';
import { FlashMessageProvider } from './XptAppBannerContext';

const localizationContext = new LocalizationContextBuilder().withLocale(DEFAULT_LOCALE_EN_US).withDefaultLocale(DEFAULT_LOCALE_EN_US).build();

const mbmOptions = {
  arbManifest,
  defaultLocalizationContext: localizationContext,
  urlPrefix: TRANSLATION_ROUTE
};

interface AppProps {
  children?: ReactNode;
}

export const App: React.FC<AppProps> = ({ children }) => {
  const handleErrorReset = () => {
    window.location.reload();
  };

  return (
    <ErrorBoundary
      FallbackComponent={({ error, resetErrorBoundary }) =>
        AppInitializationFailed(error, resetErrorBoundary, eErrorMessages.APP_INITIALIZATION_FAILED)
      }
      onReset={handleErrorReset}
    >
      <MbmProvider {...mbmOptions}>
        <FlashMessageProvider>
          <AuthContextProvider>
            <Provider store={store}>
              <AppInitializer>
                <AppRouter />
              </AppInitializer>
            </Provider>
          </AuthContextProvider>
        </FlashMessageProvider>
      </MbmProvider>
    </ErrorBoundary>
  );
};
