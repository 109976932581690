import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../auth/AuthContextProvider';

export const XPTDevOnlyWrapper: React.FC = () => {
  const { isDev } = useAuth();

  return isDev ? <Outlet /> : <Navigate to="/" replace />;
};

export const XPTAdminOrDevWrapper: React.FC = () => {
  const { isAdmin, isDev } = useAuth();

  return isAdmin || isDev ? <Outlet /> : <Navigate to="/" replace />;
};

export const XPTBusinessLeaderOrAdminOrDevOnlyWrapper: React.FC = () => {
  const { isDev, isAdmin, isBusinessLeader } = useAuth();

  return isDev || isAdmin || isBusinessLeader ? <Outlet /> : <Navigate to="/" replace />;
};
