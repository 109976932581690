import { Button, SelectProps, TableProps } from '@amzn/awsui-components-react';
import { Announcement, DataClassification } from 'src/models/AppContextModels';
import { formatDateString, getCurrentDate, getCurrentUTCTimeInISO } from 'src/utils/date-time-utilities';
import * as Yup from 'yup';
import { AnnouncementFormValues } from './AnnouncementForm';

export const ANNOUNCEMENT_DATE_INITIAL_FORMAT = 'YYYY-MM-DD';
export const ANNOUNCEMENT_DATE_DISPLAY_FORMAT = 'MMM D';

export const AnnouncementMessages = {
  SUBMITTING: 'Submitting...',
  SUBMITTED_SUCCESSFULLY: 'Submitted successfully',
  SUBMIT_ERROR: 'Unable to Submit'
};

export const announcementSchema = Yup.object().shape({
  data_classification: Yup.array().min(1, 'At least one classification is required').required('Data classification is required'),
  announcement_title: Yup.string().max(100, 'Title must be at most 100 characters').required('Title is required'),
  announcement_message: Yup.string().max(500, 'Message must be at most 500 characters').required('Message is required')
});

export const announcementsHeaderConfig = (
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedAnnouncementId: React.Dispatch<React.SetStateAction<number | null>>,
  setModalContent: React.Dispatch<React.SetStateAction<Announcement | null>>,
  enableEditButton: boolean
): TableProps.ColumnDefinition<Announcement>[] => {
  const columns: TableProps.ColumnDefinition<Announcement>[] = [
    {
      id: 'announcement_date',
      header: 'Date',
      cell: (item: Announcement) => (
        <strong>{formatDateString(item.announcement_date, ANNOUNCEMENT_DATE_INITIAL_FORMAT, ANNOUNCEMENT_DATE_DISPLAY_FORMAT)}</strong>
      ),
      minWidth: 75,
      width: 75,
      maxWidth: 75
    },
    {
      id: 'announcement_title',
      header: 'Title',
      cell: (item: Announcement) => (
        <Button variant="link" onClick={() => setModalContent(item)}>
          {item.announcement_title}
        </Button>
      )
    }
  ];

  if (enableEditButton) {
    columns.push({
      id: 'edit',
      header: 'Edit',
      minWidth: 40,
      cell: (item: Announcement) => (
        <Button
          variant="icon"
          iconName="edit"
          ariaLabel={`Edit`}
          onClick={() => {
            setSelectedAnnouncementId(item.announcement_id);
            setShowModal(true);
          }}
        />
      )
    });
  }

  return columns;
};

export const ALL_BUSINESS_GROUPS = 'All Business Groups';

export const extractDataClassificationOptions = (
  dataClassifications: DataClassification[],
  setCurrentDataClassificationsList: React.Dispatch<React.SetStateAction<SelectProps.Options>>
) => {
  const dataClassificationsFormatted = dataClassifications.map((dataClassification: DataClassification) => {
    return {
      label: dataClassification.data_classification_name,
      value: `${dataClassification.data_classification_id}`
    } as SelectProps.Option;
  });
  setCurrentDataClassificationsList([
    {
      label: ALL_BUSINESS_GROUPS,
      value: 'all',
      options: dataClassificationsFormatted
    }
  ]);
};

export const getPublishToPlaceHolder = (data_classification: SelectProps.Option[], currentDataClassificationsLength: number) => {
  if (data_classification.length === 0) return 'Select Business Groups to publish';

  return data_classification.length === currentDataClassificationsLength
    ? `Selected ${ALL_BUSINESS_GROUPS}`
    : `${data_classification.map((dt) => dt.label).join(', ')}`;
};

export const formatAnnouncementData = (values: AnnouncementFormValues, selectedAnnouncement: Announcement | null, alias: string): Announcement => {
  return {
    announcement_id: selectedAnnouncement?.announcement_id || null,
    data_classification: values.data_classification.map((dc) => ({
      data_classification_id: dc.value ? +dc.value : 0,
      data_classification_name: dc.label || ''
    })),
    announcement_title: values.announcement_title,
    announcement_message: values.announcement_message,
    announcement_date: selectedAnnouncement ? selectedAnnouncement.announcement_date : getCurrentDate(),
    item_metadata: {
      created_at: selectedAnnouncement ? selectedAnnouncement.item_metadata.created_at : getCurrentUTCTimeInISO(),
      created_by: selectedAnnouncement ? selectedAnnouncement.item_metadata.created_by : alias,
      updated_at: getCurrentUTCTimeInISO(),
      updated_by: alias,
      is_active: true
    }
  };
};
