import { BreadcrumbGroupProps } from '@amzn/awsui-components-react';
import { ColDef } from 'ag-grid-community';
import { ExpenseTypeEntity, ExpenseTypeGridEntity, ExpenseTypeGroupInfo } from 'src/models/xPTMappingModels';
import { compareObjects, filterObjectFields } from 'src/utils/comparison-utils';
import { getCurrentUTCTimeInISO } from 'src/utils/date-time-utilities';
import { generateUniqueId } from 'src/utils/generic-utilities';
import { AdminBaseBreadcrumbs } from '../AdminConsole';

export const getExpenseTypeBreadcrumbItems = (): BreadcrumbGroupProps.Item[] => {
  return [
    ...AdminBaseBreadcrumbs,
    {
      text: 'Expense Type',
      href: '/admin-console/expense-type'
    }
  ];
};

/**
 * Flattens an array of ExpenseTypeEntity objects into ExpenseTypeGridEntity objects
 * and sorts them by budget_type in asc order and then by expense_type in asc order.
 *
 * @param {ExpenseTypeEntity[]} expenseTypes - The array of ExpenseTypeEntity objects.
 * @returns {ExpenseTypeGridEntity[]} The array of flattened and sorted ExpenseTypeGridEntity objects.
 */
export const flattenedExpenseType = (expenseTypes: ExpenseTypeEntity[]): ExpenseTypeGridEntity[] => {
  return expenseTypes
    .map((expenseType) => {
      const flattenedExpenseType: ExpenseTypeGridEntity = {
        row_id: generateUniqueId(),
        expense_type_id: expenseType.expense_type_id,
        expense_type: expenseType.expense_type,
        budget_type: expenseType.budget_type,
        description: expenseType.description,
        is_active: expenseType.is_active,
        created_by: expenseType.created_by,
        created_at: expenseType.created_at,
        updated_at: expenseType.updated_at,
        updated_by: expenseType.updated_by,
        is_checked: false,
        is_modified: false
      };

      // Add dynamic group fields
      expenseType.group_info.forEach((group) => {
        flattenedExpenseType[group.group_name] = group.group_value || false;
        flattenedExpenseType[`${group.group_name}_classification_id`] = group.data_classification_id;
      });

      return flattenedExpenseType;
    })
    .sort((a, b) => {
      // First, sort by budget_type in asc order
      const budgetTypeComparison = a.budget_type.localeCompare(b.budget_type);

      // If budget_type is the same, then sort by expense_type in asc order
      if (budgetTypeComparison === 0) {
        return a.expense_type.localeCompare(b.expense_type);
      }

      return budgetTypeComparison;
    });
};

/**
 * Converts an array of ExpenseTypeGridEntity objects into ExpenseTypeEntity objects.
 * @param {ExpenseTypeGridEntity[]} gridEntities - The array of ExpenseTypeGridEntity objects.
 * @param {string} userAlias - The user alias to set for created_by and updated_by fields.
 * @returns {ExpenseTypeEntity[]} The array of ExpenseTypeEntity objects.
 */
export const convertToExpenseTypeEntities = (gridEntities: ExpenseTypeGridEntity[], userAlias: string): ExpenseTypeEntity[] => {
  return gridEntities.map((gridEntity) => {
    const { row_id, is_checked, is_modified, ...rest } = gridEntity;

    // Extract dynamic group fields
    const group_info: ExpenseTypeGroupInfo[] = Object.keys(rest)
      .filter(
        (key) =>
          ![
            'row_id',
            'expense_type_id',
            'expense_type',
            'budget_type',
            'description',
            'is_active',
            'created_by',
            'created_at',
            'updated_at',
            'updated_by'
          ].includes(key) && !key.endsWith('_classification_id') // Exclude classification_id suffix
      )
      .map((groupName) => ({
        group_name: groupName,
        group_value: rest[groupName] as boolean | null,
        data_classification_id: rest[`${groupName}_classification_id`] as number
      }));

    // Remove dynamic group fields from the rest object
    group_info.forEach((group) => {
      delete rest[group.group_name];
      delete rest[`${group.group_name}_classification_id`];
    });

    return {
      ...rest,
      created_by: rest.expense_type_id ? rest.created_by : userAlias,
      created_at: rest.expense_type_id ? rest.created_at : getCurrentUTCTimeInISO(),
      updated_by: userAlias,
      updated_at: getCurrentUTCTimeInISO(),
      group_info
    } as ExpenseTypeEntity;
  });
};

/**
 * Generates column definitions for an ag-Grid table based on the flattened data.
 * @param {ExpenseTypeGridEntity[]} flattenedData - The array of flattened data.
 * @returns {ColDef[]} The array of column definitions.
 */
export const expenseTypeColumnGenerator = (flattenedData: ExpenseTypeGridEntity[]): ColDef[] => {
  const fixedColumns: ColDef[] = [
    {
      field: 'is_checked',
      headerName: '',
      hide: false,
      minWidth: 50,
      width: 50,
      maxWidth: 50,
      sortable: false,
      suppressHeaderMenuButton: true,
      lockPinned: true,
      lockPosition: 'left',
      lockVisible: true,
      checkboxSelection: true,
      showDisabledCheckboxes: false
    },
    {
      field: 'expense_type_id',
      headerName: 'Expense Type Id',
      suppressColumnsToolPanel: true,
      hide: true,
      width: 150,
      minWidth: 150
    },
    {
      field: 'row_id',
      headerName: 'Row Id',
      hide: true,
      suppressColumnsToolPanel: true,
      width: 150,
      minWidth: 150
    },
    {
      field: 'budget_type',
      headerName: 'Budget Type',
      width: 120,
      minWidth: 120,
      editable: true
    },
    {
      field: 'expense_type',
      headerName: 'Expense Type',
      width: 280,
      minWidth: 280,
      editable: true
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 150,
      minWidth: 150,
      editable: true
    },
    {
      field: 'is_active',
      headerName: 'Active',
      hide: true,
      editable: false
    },
    {
      field: 'created_by',
      headerName: 'Created By',
      hide: true,
      editable: false
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      hide: true,
      editable: false
    },
    {
      field: 'updated_by',
      headerName: 'Updated By',
      hide: true,
      editable: false
    },
    {
      field: 'updated_at',
      headerName: 'Updated At',
      hide: true,
      editable: false
    }
  ];

  // Collect unique group names from all rows
  const uniqueGroupNames: Set<string> = new Set();
  flattenedData.forEach((row) => {
    Object.keys(row).forEach((key) => {
      if (
        !fixedColumns.some((col) => col.field === key) &&
        key !== 'is_checked' &&
        key !== 'row_id' &&
        key !== 'is_modified' &&
        !key.endsWith('_classification_id')
      ) {
        uniqueGroupNames.add(key);
      }
    });
  });

  // Generate dynamic columns based on unique group names
  const dynamicColumns: ColDef[] = Array.from(uniqueGroupNames).map((groupName) => {
    return {
      field: groupName,
      headerName: groupName,
      width: 120,
      minWidth: 120,
      editable: true,
      cellRenderer: 'agCheckboxCellRenderer',
      cellClass: 'cell-center',
      headerClass: 'header-center',
      cellStyle: { textAlign: 'center' }
    } as ColDef;
  });

  return fixedColumns.concat(dynamicColumns);
};

/**
 * Filters out the non-relevant fields for comparison from the row data.
 * @param {ExpenseTypeGridEntity} row - The row data to filter.
 * @returns {Partial<ExpenseTypeGridEntity>} The filtered row data.
 */
const filterExpenseTypeFields = (row: ExpenseTypeGridEntity): Partial<ExpenseTypeGridEntity> => {
  return filterObjectFields(row, ['is_modified', 'is_checked', 'row_id']);
};

/**
 * Compares the original row data with the new row data to determine if they are different.
 * @param {ExpenseTypeGridEntity} originalRow - The original row data.
 * @param {ExpenseTypeGridEntity} newRow - The new row data.
 * @returns {boolean} True if the rows are different, false otherwise.
 */
export const isExpenseTypeRowModified = (originalRow: ExpenseTypeGridEntity, newRow: ExpenseTypeGridEntity): boolean => {
  const filteredOriginalRow = filterExpenseTypeFields(originalRow);
  const filteredNewData = filterExpenseTypeFields(newRow);

  return !compareObjects(filteredOriginalRow, filteredNewData);
};
