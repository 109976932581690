import { Auth } from 'aws-amplify';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import ENVIRONMENT_VARIABLES from 'src/constants/environment-variables';

const getToken = async () => {
  const currentSession = await Auth.currentSession();
  return currentSession.getIdToken().getJwtToken();
};

const getCognitoDomain = () => {
  return 'cognito-idp.' + ENVIRONMENT_VARIABLES.env.Region + '.amazonaws.com/' + ENVIRONMENT_VARIABLES.env.CognitoUserPoolId;
};

export async function getAWSServiceCredentials() {
  let COGNITO_LOGIN: any = {};
  COGNITO_LOGIN[getCognitoDomain()] = await getToken();
  return fromCognitoIdentityPool({
    clientConfig: { region: ENVIRONMENT_VARIABLES.env.Region },
    identityPoolId: ENVIRONMENT_VARIABLES.env.CognitoIdentityPoolId,
    logins: COGNITO_LOGIN
  });
}
