import { useState, useCallback } from 'react';
import { FlashbarProps } from '@amzn/awsui-components-react';
import { v4 as uuidv4 } from 'uuid';

export const useFlashbar = () => {
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  const displayFlashMessage = useCallback((content: string, flashBarType: FlashbarProps.Type, isDismissible: boolean, messageId?: string) => {
    const flashbarId = messageId ? messageId : uuidv4();

    const newFlashbarItem = {
      id: flashbarId,
      type: flashBarType,
      content: content,
      dismissible: isDismissible,
      dismissLabel: 'Dismiss message',
      onDismiss: () => setFlashbarItems((currentItems) => currentItems.filter((item) => item.id !== newFlashbarItem.id))
    };

    setFlashbarItems((currentItems) => [...currentItems, newFlashbarItem]);

    if (flashBarType === 'success') {
      setTimeout(() => {
        setFlashbarItems((currentItems) => currentItems.filter((item) => item.id !== newFlashbarItem.id));
      }, 5000);
    }
  }, []);

  const clearSpecificFlashMessage = (messageId: string) => {
    setFlashbarItems((currentItems) => currentItems.filter((item) => item.id !== messageId));
  };

  const clearAllMessages = () => {
    setFlashbarItems([]);
  };

  return { flashbarItems, displayFlashMessage, clearSpecificFlashMessage, clearAllMessages };
};
