import { SideNavigationProps } from '@amzn/awsui-components-react';

/**
 * Recursively finds the active href in the side navigation items.
 * This function navigates through the nested structure of the navigation items,
 * checking if the current pathname starts with any of the item's href.
 *
 * The type assertion `as SideNavigationProps.Item[]` is used for `item.items` because:
 * - The `items` property in `SideNavigationProps.Item` (specifically in `Section`, `LinkGroup`, and `ExpandableLinkGroup` types) is defined as a readonly array.
 * - However, TypeScript treats `ReadonlyArray<T>` and `Array<T>` as different types.
 * - Since the function only reads from the `items` array and does not perform any operations that would mutate it, it's safe to treat a `ReadonlyArray` as an `Array`.
 * - This assertion allows the function to work uniformly with both mutable and immutable arrays without compromising the integrity of the data or functionality.
 */
export const findActiveHref = (pathname: string, items: ReadonlyArray<SideNavigationProps.Item> | SideNavigationProps.Item[]): string => {
  for (const item of items) {
    if ('href' in item && pathname.startsWith(item.href)) {
      return item.href;
    }
    if ('items' in item) {
      const activeHref = findActiveHref(pathname, item.items as SideNavigationProps.Item[]);
      if (activeHref) return activeHref;
    }
  }
  return '';
};
