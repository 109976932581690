import { CAPEXFileEntity, CAPEXValidationStatusWithData } from 'src/models/CapexModels';
import { ValidationErrorDetail, ValidationStatusEntity } from 'src/models/XptGenericModels';
import { CAPEXValidationMessages, getCAPEXFileHeaderInfo } from './CapexConfig';
import { logger } from 'src/analytics/KatalLogger';

/**
 * Validates that the headers match the expected headers in both presence and order.
 * @param {string[]} headerRow - The list of headers from the uploaded file.
 * @param {string[]} expectedHeaders - The expected list of headers.
 * @returns {Promise<ValidationStatusEntity>} - The validation status of the headers.
 */
export const validateCapexHeaders = async (headerRow: string[], expectedHeaders: string[]): Promise<ValidationStatusEntity> => {
  const validationErrorDetails: ValidationErrorDetail[] = [];
  if (headerRow.length !== expectedHeaders.length) {
    validationErrorDetails.push({
      message: `Header count mismatch. Expected ${expectedHeaders.length}, found ${headerRow.length}.`
    });
  } else {
    headerRow.forEach((header, rowIndex) => {
      if (header !== expectedHeaders[rowIndex]) {
        validationErrorDetails.push({
          rowIndex: rowIndex + 1,
          message: `Header mismatch at index ${rowIndex}. Expected '${expectedHeaders[rowIndex]}', found '${header}'.`
        });
      }
    });
  }

  if (validationErrorDetails.length > 0) {
    return {
      colorOverride: 'red',
      validationMessage: CAPEXValidationMessages.HEADER_VALIDATION_FAILED,
      validationStatus: 'error',
      validationDefaultMessage: CAPEXValidationMessages.HEADER_VALIDATION_DEFAULT_MESSAGE,
      validationErrorDetails
    };
  }

  return {
    colorOverride: 'green',
    validationMessage: CAPEXValidationMessages.HEADER_VALIDATION_SUCCESS,
    validationStatus: 'success',
    validationDefaultMessage: CAPEXValidationMessages.HEADER_VALIDATION_DEFAULT_MESSAGE,
    validationErrorDetails: []
  };
};

/**
 * Transforms the CAPEX uploaded file data by converting header names to field names and validating data types.
 * @param {any[]} capexUploadedFileData - The uploaded file data.
 * @returns {Promise<CAPEXValidationStatusWithData>} - The validation status of the transformation.
 */
export const transformCapexFileHeader = async (capexUploadedFileData: any[]): Promise<CAPEXValidationStatusWithData> => {
  const { fileHeaders } = getCAPEXFileHeaderInfo();
  const headerMap: { [key: string]: string } = {};
  const validationErrorDetails: ValidationErrorDetail[] = [];

  // Map to convert headerName to field
  fileHeaders.forEach((header) => {
    headerMap[header.headerName] = header.field;
  });

  // Extract headers from the first row of the uploaded file data
  const headerRow = Object.keys(capexUploadedFileData[0]);

  // Validate headers
  const expectedHeaders = fileHeaders.map((header) => header.headerName);
  if (headerRow.length !== expectedHeaders.length) {
    validationErrorDetails.push({
      message: `Header count mismatch. Expected ${expectedHeaders.length}, found ${headerRow.length}.`
    });
  } else {
    headerRow.forEach((header, rowIndex) => {
      if (header !== expectedHeaders[rowIndex]) {
        validationErrorDetails.push({
          rowIndex: rowIndex + 1,
          message: `Header mismatch at index ${rowIndex}. Expected '${expectedHeaders[rowIndex]}', found '${header}'.`
        });
      }
    });
  }

  if (validationErrorDetails.length > 0) {
    logger.debug(`Header validation failed.` + JSON.stringify(validationErrorDetails, null, 2));
    return {
      colorOverride: 'red',
      validationMessage: CAPEXValidationMessages.DATA_VALIDITY_FAILED,
      validationStatus: 'error',
      validationDefaultMessage: CAPEXValidationMessages.DATA_VALIDITY_DEFAULT,
      validationErrorDetails
    };
  }

  try {
    // Transform the uploaded file data
    const transformedData: CAPEXFileEntity[] = capexUploadedFileData.map((row, rowIndex) => {
      const transformedRow: any = {};
      Object.keys(row).forEach((key) => {
        if (headerMap[key]) {
          const field = headerMap[key];
          const header = fileHeaders.find((header) => header.field === field);

          // Validate field data
          if (header?.dataType === 'number' && isNaN(row[key])) {
            validationErrorDetails.push({
              rowIndex: rowIndex + 1,
              message: `Invalid number for field at ${rowIndex + 1}, ${field}: ${row[key]}`
            });
          } else if (header?.dataType === 'currency_string' && (row[key] == null || (row[key] as string).length > 3)) {
            validationErrorDetails.push({
              rowIndex: rowIndex + 1,
              message: `Invalid currency, ${field}: ${row[key]}. Must be non-null and max 3 characters.`
            });
          } else if (header?.dataType === 'pl_month_string' && !/^\d{6}$/.test(row[key])) {
            validationErrorDetails.push({
              rowIndex: rowIndex + 1,
              message: `Invalid planning month, ${field}: ${row[key]}. Format should be YYYYMM.`
            });
          } else if (header?.isNullable === false && (row[key] === '' || row[key] == null)) {
            validationErrorDetails.push({
              rowIndex: rowIndex + 1,
              message: `Field ${field} cannot be null or empty.`
            });
          }

          transformedRow[field] = row[key];
        } else {
          transformedRow[key] = row[key]; // Keep other fields unchanged
        }
      });
      return transformedRow as CAPEXFileEntity;
    });

    if (validationErrorDetails.length > 0) {
      logger.debug(`Data uniqueness validation failed.` + JSON.stringify(validationErrorDetails, null, 2));
      return {
        colorOverride: 'red',
        validationMessage: CAPEXValidationMessages.DATA_VALIDITY_FAILED,
        validationStatus: 'error',
        validationDefaultMessage: CAPEXValidationMessages.DATA_VALIDITY_DEFAULT,
        validationErrorDetails
      };
    }

    // If transformation is successful
    return {
      colorOverride: 'green',
      validationMessage: CAPEXValidationMessages.DATA_VALIDITY_SUCCESS,
      validationStatus: 'success',
      validationDefaultMessage: CAPEXValidationMessages.DATA_VALIDITY_DEFAULT,
      validationErrorDetails,
      transformedData
    };
  } catch (error: any) {
    return {
      colorOverride: 'red',
      validationMessage: CAPEXValidationMessages.DATA_VALIDITY_ERROR,
      validationStatus: 'error',
      validationDefaultMessage: CAPEXValidationMessages.DATA_VALIDITY_DEFAULT,
      validationErrorDetails: [{ message: error.message }]
    };
  }
};

/**
 * Validates that the CAPEX uploaded file data contains unique entries based on unique key fields.
 * @param {CAPEXFileEntity[]} capexUploadedFileData - The uploaded file data.
 * @returns {Promise<CAPEXValidationStatusWithData>} - The validation status of the uniqueness check.
 */
export const validateCapexDataIsUnique = async (capexUploadedFileData: CAPEXFileEntity[]): Promise<CAPEXValidationStatusWithData> => {
  const { uniqueKeyFields } = getCAPEXFileHeaderInfo();
  const validationErrorDetails: ValidationErrorDetail[] = [];
  const uniqueKeysSet = new Set<string>();

  capexUploadedFileData.forEach((row, rowIndex) => {
    const uniqueKey = uniqueKeyFields.map((field) => row[field as keyof CAPEXFileEntity]).join('|');
    if (uniqueKeysSet.has(uniqueKey)) {
      validationErrorDetails.push({
        rowIndex: rowIndex + 1,
        message: `Duplicate row found at index ${rowIndex + 1} with unique key: ${uniqueKey}`
      });
    } else {
      uniqueKeysSet.add(uniqueKey);
    }
  });

  if (validationErrorDetails.length > 0) {
    logger.debug(`Data uniqueness validation failed.` + JSON.stringify(validationErrorDetails, null, 2));
    return {
      colorOverride: 'red',
      validationMessage: CAPEXValidationMessages.UNIQUE_KEY_VALIDATION_FAILED,
      validationStatus: 'error',
      validationDefaultMessage: CAPEXValidationMessages.UNIQUE_KEY_VALIDATION_DEFAULT,
      validationErrorDetails
    };
  }

  return {
    colorOverride: 'green',
    validationMessage: CAPEXValidationMessages.UNIQUE_KEY_VALIDATION_SUCCESS,
    validationStatus: 'success',
    validationDefaultMessage: CAPEXValidationMessages.UNIQUE_KEY_VALIDATION_DEFAULT,
    validationErrorDetails: []
  };
};
