import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import {
  CorpSegmentFilterSelection,
  ForecastTemplateContext,
  ForecastTemplateCorpSegmentDropdowns,
  ForecastTemplateDataState,
  ForecastTemplateDataValidationStatus
} from 'src/models/ForecastModels';
import { PlanningCycle } from 'src/models/PlanningCycleModel';
import { ForecastTemplateDataStatuses } from '../forecast-utils/ForecastGridConstants';
import { INITIAL_VALIDATION_STATUS } from '../forecast-utils/ForecastTemplateDataValidations';

export interface ForecastTemplateState {
  filterSelection: ForecastTemplateContext;
  forecastTemplateDataLoading: boolean;
  forecastTemplateCompleteData: any[];
  showAllData: boolean;
  forecastTemplateCorpSegmentDropdowns: ForecastTemplateCorpSegmentDropdowns[];
  forecastDataStatus: ForecastTemplateDataState;
  forecastDataSubmitClickCount: number;
  forecastDataSubmitting: boolean;
  forecastDataSubmitError: string;
  forecastDataValidationStatus: ForecastTemplateDataValidationStatus;
}

export const ForecastTemplateInitialState: ForecastTemplateState = {
  filterSelection: {},
  forecastTemplateDataLoading: false,
  forecastTemplateCompleteData: [],
  showAllData: false,
  forecastTemplateCorpSegmentDropdowns: [],
  forecastDataStatus: {
    dataStatus: ForecastTemplateDataStatuses.NotLoaded // Data is 'NotLoaded' at this point of time.
  },
  forecastDataSubmitClickCount: 0,
  forecastDataSubmitting: false,
  forecastDataSubmitError: '',
  forecastDataValidationStatus: INITIAL_VALIDATION_STATUS
};

const forecastTemplateSlice = createSlice({
  name: 'forecastTemplate',
  initialState: ForecastTemplateInitialState,
  reducers: {
    setPlanningCycleFilter: (state, action: PayloadAction<{ businessGroupShortDesc: string; planningCycle: PlanningCycle }>) => {
      const { businessGroupShortDesc, planningCycle } = action.payload;
      const planningCycleSeqId = planningCycle.scenario_seq_id?.toString() || '';

      if (!state.filterSelection[businessGroupShortDesc]) {
        state.filterSelection[businessGroupShortDesc] = {};
      }

      if (!state.filterSelection[businessGroupShortDesc]![planningCycleSeqId]) {
        state.filterSelection[businessGroupShortDesc]![planningCycleSeqId] = {
          planningCycle: null,
          corpSegmentSelections: {}
        };
      }

      state.filterSelection[businessGroupShortDesc]![planningCycleSeqId]!.planningCycle = planningCycle;
    },
    setCorpSegmentFilter: (
      state,
      action: PayloadAction<{ businessGroupShortDesc: string; planningCycleSeqId: string; corpSegmentSelections: CorpSegmentFilterSelection }>
    ) => {
      const { businessGroupShortDesc, planningCycleSeqId, corpSegmentSelections } = action.payload;

      if (!state.filterSelection[businessGroupShortDesc]) {
        state.filterSelection[businessGroupShortDesc] = {};
      }

      if (!state.filterSelection[businessGroupShortDesc]![planningCycleSeqId]) {
        state.filterSelection[businessGroupShortDesc]![planningCycleSeqId] = {
          planningCycle: null,
          corpSegmentSelections: {}
        };
      }

      state.filterSelection[businessGroupShortDesc]![planningCycleSeqId]!.corpSegmentSelections =
        corpSegmentSelections as Draft<CorpSegmentFilterSelection>;
    },
    setForecastTemplateCompleteData: (state, action: PayloadAction<any[]>) => {
      state.forecastTemplateCompleteData = action.payload;
    },
    setForecastTemplateCorpSegmentDropdowns: (state, action: PayloadAction<ForecastTemplateCorpSegmentDropdowns[]>) => {
      state.forecastTemplateCorpSegmentDropdowns = action.payload as Draft<ForecastTemplateCorpSegmentDropdowns[]>;
    },
    setForecastTemplateDataStatus: (state, action: PayloadAction<ForecastTemplateDataState>) => {
      state.forecastDataStatus = action.payload;
    },
    setForecastTemplateDataLoading: (state, action: PayloadAction<boolean>) => {
      state.forecastTemplateDataLoading = action.payload;
    },
    setForecastDataSubmitClickCount: (state, action: PayloadAction<number>) => {
      state.forecastDataSubmitClickCount = action.payload;
    },
    setForecastDataSubmitting: (state, action: PayloadAction<boolean>) => {
      state.forecastDataSubmitting = action.payload;
    },
    setForecastDataSubmitError: (state, action: PayloadAction<string>) => {
      state.forecastDataSubmitError = action.payload;
    },
    clearForecastDataSubmitError: (state) => {
      state.forecastDataSubmitError = '';
    },
    setValidationStatus: (state, action: PayloadAction<ForecastTemplateDataValidationStatus>) => {
      state.forecastDataValidationStatus = action.payload;
    },
    resetValidationStatus: (state) => {
      state.forecastDataValidationStatus = INITIAL_VALIDATION_STATUS;
    },
    toggleShowOnlyCurrentUser: (state) => {
      state.showAllData = !state.showAllData;
    },
    setShowOnlyCurrentUser: (state, action: PayloadAction<boolean>) => {
      state.showAllData = action.payload;
    }
  }
});

export const {
  setPlanningCycleFilter,
  setCorpSegmentFilter,
  setForecastTemplateCompleteData,
  setForecastTemplateCorpSegmentDropdowns,
  setForecastTemplateDataStatus,
  setForecastTemplateDataLoading,
  setForecastDataSubmitClickCount,
  setForecastDataSubmitting,
  setForecastDataSubmitError,
  clearForecastDataSubmitError,
  setValidationStatus,
  resetValidationStatus,
  toggleShowOnlyCurrentUser,
  setShowOnlyCurrentUser
} = forecastTemplateSlice.actions;

export default forecastTemplateSlice.reducer;
