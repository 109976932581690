import { BreadcrumbGroupProps } from '@amzn/awsui-components-react';
import React from 'react';
import { CapexActualsDetailsMutation, CAPEXDataValidationStatus, CAPEXEntity, CAPEXFileHeaderInfo } from 'src/models/CapexModels';
import { generateExcelFile } from 'src/utils/file-utils';
import { logger } from 'src/analytics/KatalLogger';
import { ValidationStatusEntity } from 'src/models/XptGenericModels';
import { getCapexS3BucketName } from 'src/utils/xpt-s3-bucket-details';
import { generateUniqueId } from 'src/utils/generic-utilities';
import { getCurrentUTCTimeInISO, getCurrentYearMonthDate } from 'src/utils/date-time-utilities';
import { uploadToS3 } from 'src/utils/aws-s3-services';
import { updateCapex } from 'src/api/app-sync-services';
import { AdminBaseBreadcrumbs } from '../AdminConsole';

export const getCapexBreadcrumbItems = (): BreadcrumbGroupProps.Item[] => {
  return [
    ...AdminBaseBreadcrumbs,
    {
      text: 'Capex',
      href: '/admin-console/capex'
    }
  ];
};

// Exported constant for CAPEX file headers
export const getCAPEXFileHeaderInfo = (): CAPEXFileHeaderInfo => {
  return {
    fileHeaders: [
      { field: 'cost_center_code', headerName: 'Cost Center Code', dataType: 'string', isNullable: false },
      { field: 'product_code', headerName: 'Product Code', dataType: 'string', isNullable: false },
      { field: 'project_code', headerName: 'Project Code', dataType: 'string', isNullable: false },
      { field: 'account_code', headerName: 'Account Code', dataType: 'string', isNullable: false },
      { field: 'channel_code', headerName: 'Channel Code', dataType: 'string', isNullable: false },
      { field: 'company_code', headerName: 'Company Code', dataType: 'string', isNullable: false },
      { field: 'location_code', headerName: 'Location Code', dataType: 'string', isNullable: false },
      { field: 'currency', headerName: 'Currency', dataType: 'currency_string', isNullable: false }, // Max 3 characters
      { field: 'pl_month', headerName: 'Planning Month', dataType: 'pl_month_string', isNullable: false }, // Format: YYYYMM
      { field: 'expense_amount', headerName: 'Expense Amount', dataType: 'number', isNullable: false }
    ],
    uniqueKeyFields: [
      'cost_center_code',
      'product_code',
      'project_code',
      'account_code',
      'channel_code',
      'company_code',
      'location_code',
      'currency',
      'pl_month'
    ]
  };
};

export const downloadSampleTemplate = () => {
  const sheetName = 'Capex_Sample';
  const fileNameWithoutExtension = 'Capex';

  try {
    const { fileHeaders } = getCAPEXFileHeaderInfo();
    const data = [
      fileHeaders.reduce((acc, header) => {
        acc[header.headerName] = '';
        return acc;
      }, {} as Record<string, string>)
    ];

    // Use the generateExcelFile utility
    generateExcelFile({ data, sheetName, fileNameWithoutExtension });

    logger.info(`Excel file generated and download initiated: ${fileNameWithoutExtension}.xlsx with sheet: ${sheetName}`);
  } catch (error: any) {
    logger.error(`Failed to generate Excel file: ${fileNameWithoutExtension}.xlsx. Error:`, error);
  }
};

export const CAPEXValidationMessages = {
  HEADER_VALIDATION_DEFAULT_MESSAGE: "The file is not valid. Headers don't match.",
  HEADER_VALIDATION_FAILED: "The file is not valid. Headers don't match.",
  HEADER_VALIDATION_SUCCESS: 'Headers are valid.',
  HEADER_VALIDATION_ERROR: 'There was an error validating the headers.',

  SKIPPED_DUE_TO_HEADER_FAILURE: 'Skipped due to header validation failure.',
  UPLOAD_SUCCESS: `Successfully updated Capex data`,
  UPLOAD_FAILED: 'Error submitting Capex data',

  VALIDATION_ERROR: 'There was a validation error.',

  DATA_VALIDITY_DEFAULT: 'Data should be valid fields with corresponding data types.',
  DATA_VALIDITY_IN_PROGRESS: 'Validating data fields and types.',
  DATA_VALIDITY_SUCCESS: 'Validation succeeded: All data fields and types are valid.',
  DATA_VALIDITY_FAILED: 'Validation failed: Some data fields or types are invalid.',
  DATA_VALIDITY_ERROR: 'There was an error validating the data fields and types.',

  UNIQUE_KEY_VALIDATION_DEFAULT: 'Each combination of fields must be unique.',
  UNIQUE_KEY_VALIDATION_IN_PROGRESS: 'Validating unique key combinations.',
  UNIQUE_KEY_VALIDATION_SUCCESS: 'Validation succeeded: All combinations are unique.',
  UNIQUE_KEY_VALIDATION_FAILED: 'Validation failed: Duplicate combinations detected.',
  UNIQUE_KEY_VALIDATION_ERROR: 'There was an error validating unique key combinations.'
};

export const VALIDATION_NOT_INITIATED: ValidationStatusEntity = {
  colorOverride: 'grey',
  validationStatus: 'pending',
  validationMessage: 'Not initiated',
  validationDefaultMessage: '',
  validationErrorDetails: []
};

export const VALIDATION_STARTED: ValidationStatusEntity = {
  colorOverride: 'grey',
  validationStatus: 'loading',
  validationMessage: 'Validating',
  validationDefaultMessage: '',
  validationErrorDetails: []
};

export const INITIAL_CAPEX_VALIDATION_STATUS: CAPEXDataValidationStatus = {
  HeadersMatching: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Header validation' },
  DataValidity: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Data validation' },
  UniqueKeyValidation: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Unique key field validation' }
};

export const INITIAL_CAPEX_SUBMIT_STATUS: ValidationStatusEntity = {
  colorOverride: 'grey',
  validationStatus: 'pending',
  validationMessage: 'Not Initiated',
  validationDefaultMessage: '',
  validationErrorDetails: []
};

export const submitCapexData = async (capexDataToUpload: CAPEXEntity[], userAlias: string) => {
  try {
    const { bucketName, region } = getCapexS3BucketName();
    const uniqueId = generateUniqueId();

    const { year, month, date } = getCurrentYearMonthDate();
    const s3Key = `${year}/${month}/${date}/${uniqueId}.json`;

    const s3ObjectMetadata: Record<string, string> = {
      capex_data_length: `${capexDataToUpload.length}`,
      created_by: userAlias,
      created_at: getCurrentUTCTimeInISO()
    };

    const mutationRequest: CapexActualsDetailsMutation = {
      s3_bucket: bucketName,
      s3_region: region,
      s3_key: s3Key,
      created_by: userAlias,
      created_at: getCurrentUTCTimeInISO()
    };

    try {

      // Uploads file into s3 location
      await uploadToS3(bucketName, s3Key, capexDataToUpload, true, s3ObjectMetadata);
      logger.info(`Uploaded capex data to S3 with S3 URI: ${bucketName}/${s3Key}`);

      // Once file uploads to s3, calls Mutation to inform
      await updateCapex(mutationRequest);

      return CAPEXValidationMessages.UPLOAD_SUCCESS;
    } catch (error: any) {
      logger.error('Error during the capex data submission process:', error);
      throw error;
    }
  } catch (error: any) {
    logger.error('Error submitting Capex data:', error);
    throw error;
  }
};
