import { HelpPanel, Icon } from '@amzn/awsui-components-react';
import React from 'react';

export interface ToolPanelContent {
  title: string;
  content: JSX.Element;
  footer?: JSX.Element; // Optional footer component
}

export interface XptHomePageToolPanelProps {
  toolPanelContent: ToolPanelContent;
}

const XptHomePageToolPanel: React.FC<XptHomePageToolPanelProps> = ({ toolPanelContent }) => {
  return (
    <HelpPanel header={<h2>{toolPanelContent.title}</h2>} footer={toolPanelContent.footer}>
      {toolPanelContent.content}
    </HelpPanel>
  );
};

export default XptHomePageToolPanel;
