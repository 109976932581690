const XptMessages = {
  MASTER_CORP_SEGMENTS_LOADING_FAILED: 'Unable to load master corporate segments.',
  ONBOARDING_INITIALIZATION_ERROR: 'Initialization failed. Unable to load corporate segments data.',
  ONBOARDING_NO_MASTER_SEGMENTS: 'No master corporate segments found.',
  ONBOARDING_INVALID_DATA_CLASSIFICATION_ID: 'The provided Data Classification ID is invalid. Please verify and try again.',
  ONBOARDING_ACTIVATE_BUSINESS_GROUPS: 'Activate the business group to enable updates.',
  ONBOARDING_UNABLE_TO_FETCH: 'Failed to load Business Group form data. Contact support if the issue persists.',

  FORECAST_FETCH_FAILED_S3_NO_DATA: 'Forecast data is not available for this Planning Cycle.',
  FORECAST_FETCH_FAILED_S3: 'Unable to load Forecast details from S3.',
  FORECAST_FETCH_FAILED: 'Unable to load Forecast details.',
  FORECAST_UPDATE_IN_PROGRESS: (year: string) => `Updating the forecast data for ${year}.`,
  FORECAST_UPDATE_SUCCESS: (year: string) => `Forecast data for ${year} has been successfully updated.`,
  FORECAST_UPDATE_FAILED: 'Failed to update forecast data.',
  FORECAST_DELETE_IN_PROGRESS: (year: string) => `Deleting the selected records.`,
  FORECAST_DELETE_SUCCESS: (year: string) => `The records have been deleted successfully.`,
  FORECAST_DELETE_FAILED: 'Failed to delete forecast data.',
  FORECAST_UPDATE_NO_CHANGES: 'No changes to submit.'
};

export default XptMessages;
