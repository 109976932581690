import { Container, FormField, Multiselect, Select, SelectProps, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logger } from 'src/analytics/KatalLogger';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import { eErrorMessages, SELECT_ALL_OPTION } from 'src/constants/generic-constants';
import {
  AccountFilter,
  CostCenterFilter,
  POCorpSegmentFilterDropdowns,
  POFilterDropdowns,
  ProductFilter,
  ProjectFilter
} from 'src/models/POTaggingModel';
import { AppDispatch, RootState } from 'src/store/store';
import { getMultiSelectPlaceHolderValue } from 'src/utils/generic-utilities';
import { ConfirmDiscardModal } from '../../components/common/ConfirmDiscardModal';
import { currentBusinessGroup } from '../business-group/businessGroupSelectors';
import { usePOTaggingContext } from './POTaggingContext';
import {
  applyFilters,
  fetchActualsFilterDropdowns,
  fetchActualTaggingData,
  setFiltersSelected,
  setIsGridDirty,
  setSelectedActualMonth,
  setSelectedCostCenter
} from './POTaggingSlice';
import { getSelectionsFromLocalStorage, saveSelectionsToLocalStorage, validateSelections } from './POTaggingUtils';

export const POTaggingFilterSelection: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const businessGroup = useSelector(currentBusinessGroup);
  const dataClassificationId = businessGroup?.data_classification?.data_classification_id;
  const businessGroupShortDesc = businessGroup?.data_classification?.data_classification_short_description;
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [pendingFilterChange, setPendingFilterChange] = useState<(() => void) | null>(null);

  const { displayFlashMessage } = usePOTaggingContext();
  const { actualsFilterDropdownValues, selectedActualMonth, selectedCostCenter, actualTaggingData, filtersSelected, isGridDirty } = useSelector(
    (state: RootState) => state.poTaggingStore
  );

  // Fetch PO Tagging dropdown values (Months & CC's) from the API
  useEffect(() => {
    if (dataClassificationId && businessGroupShortDesc) {
      dispatch(fetchActualsFilterDropdowns(dataClassificationId))
        .then((response) => {
          const apiResponse: POFilterDropdowns = response.payload as unknown as POFilterDropdowns;
          const savedSelections = getSelectionsFromLocalStorage(businessGroupShortDesc);
          const validSelections = validateSelections(savedSelections, apiResponse);

          if (validSelections.actualMonth) {
            dispatch(setSelectedActualMonth(validSelections.actualMonth));
          } else if (apiResponse.actual_months.length > 0) {
            dispatch(setSelectedActualMonth(apiResponse.actual_months[0]));
          }

          if (validSelections.costCenter) {
            dispatch(setSelectedCostCenter(validSelections.costCenter));
          } else if (apiResponse.cost_center.length > 0) {
            dispatch(setSelectedCostCenter(apiResponse.cost_center[0].cost_center_code));
          }
        })
        .catch((error: string) => {
          logger.error(`Error: ${error}`);
          displayFlashMessage(`Unable to load PO Tagging dropdown data`, 'error', true);
        })
        .finally(() => {
          setFiltersLoaded(true);
        });
    }
  }, [dataClassificationId, businessGroupShortDesc]);

  // Based on Month & Cost Center selection, fetches PO Tagging data
  useEffect(() => {
    if (dataClassificationId && businessGroupShortDesc && selectedActualMonth && selectedCostCenter && filtersLoaded) {
      dispatch(
        fetchActualTaggingData({
          dataClassificationId,
          businessGroupShortDesc,
          selectedActualMonth,
          selectedCostCenter
        })
      )
        .unwrap()
        .catch((error: string) => {
          logger.error(`Error: ${error}`);
          const errorMessage =
            error === eErrorMessages.NO_DATA_FOUND
              ? `No data found for the selected month (${selectedActualMonth}) and cost center (${selectedCostCenter})`
              : `Unable to load PO tagging data`;
          displayFlashMessage(errorMessage, 'error', true);
        });
    }
  }, [selectedActualMonth, selectedCostCenter, filtersLoaded]);

  // Month selection change event
  const handleActualMonthChange = (selectedOption: SelectProps.Option) => {
    const change = () => {
      if (businessGroupShortDesc) {
        dispatch(setSelectedActualMonth(selectedOption.label || ''));
        saveSelectionsToLocalStorage(businessGroupShortDesc, selectedOption.label || '', selectedCostCenter || '');
      }
    };
    if (isGridDirty) {
      setPendingFilterChange(() => change);
      setShowDiscardModal(true);
    } else {
      change();
    }
  };

  // CC selection change event
  const handleCostCenterChange = (selectedOption: SelectProps.Option) => {
    const change = () => {
      if (businessGroupShortDesc) {
        dispatch(setSelectedCostCenter(selectedOption.label || ''));
        saveSelectionsToLocalStorage(businessGroupShortDesc, selectedActualMonth || '', selectedOption.label || '');
      }
    };
    if (isGridDirty) {
      setPendingFilterChange(() => change);
      setShowDiscardModal(true);
    } else {
      change();
    }
  };

  // Other filters Product, Project, Account & JE Category change event
  const handleFilterChange = (field: keyof POCorpSegmentFilterDropdowns) => (selectedOptions: SelectProps.Options) => {
    const change = () => {
      let selectedValues: any[];
      switch (field) {
        case 'product_filter':
          selectedValues = selectedOptions.map((option: SelectProps.Option) => ({
            product_code: option.value,
            product_description: option.description || ''
          }));
          break;
        case 'project_filter':
          selectedValues = selectedOptions.map((option: SelectProps.Option) => ({
            project_code: option.value,
            project_description: option.description || ''
          }));
          break;
        case 'account_filter':
          selectedValues = selectedOptions.map((option: SelectProps.Option) => ({
            account_code: option.value,
            account_description: option.description || ''
          }));
          break;
        case 'je_category':
          selectedValues = selectedOptions.map((option: SelectProps.Option) => option.value);
          break;
        default:
          selectedValues = [];
      }
      dispatch(setFiltersSelected({ [field]: selectedValues } as Partial<POCorpSegmentFilterDropdowns>));
      dispatch(applyFilters());
    };

    if (isGridDirty) {
      setPendingFilterChange(() => change);
      setShowDiscardModal(true);
    } else {
      change();
    }
  };

  const handleConfirmDiscard = () => {
    setShowDiscardModal(false);
    if (pendingFilterChange) {
      pendingFilterChange();
      setPendingFilterChange(null);
      dispatch(setIsGridDirty(false));
    }
  };

  const handleCancelDiscard = () => {
    setShowDiscardModal(false);
    setPendingFilterChange(null);
  };

  return (
    <>
      {!filtersLoaded && <LoadingSpinner />}
      {filtersLoaded && (
        <Container disableHeaderPaddings className="transform">
          <SpaceBetween size="m" direction="horizontal">
            <FormField label="Month" className="width-20-rem">
              <Select
                placeholder={getMultiSelectPlaceHolderValue(selectedActualMonth, 'Actuals Months')}
                options={actualsFilterDropdownValues.actual_months?.map((month: string) => {
                  return { label: month, value: month };
                })}
                selectedOption={{ label: selectedActualMonth || '', value: selectedActualMonth || '' }}
                onChange={({ detail }) => handleActualMonthChange(detail.selectedOption)}
                expandToViewport
                filteringType="auto"
                empty="No data"
              />
            </FormField>

            <FormField label="Cost Center" className="width-20-rem">
              <Select
                placeholder={getMultiSelectPlaceHolderValue(selectedCostCenter, 'Cost Center')}
                options={actualsFilterDropdownValues.cost_center?.map((costCenter: CostCenterFilter) => {
                  return { label: costCenter.cost_center_code, value: costCenter.cost_center_code, description: costCenter.cost_center_description };
                })}
                selectedOption={{ label: selectedCostCenter || '', value: selectedCostCenter || '' }}
                onChange={({ detail }) => handleCostCenterChange(detail.selectedOption)}
                expandToViewport
                filteringType="auto"
                empty="No data"
              />
            </FormField>

            {/* Distinct Product options from the PO Tagging data will be shown here. */}
            <FormField label="Product" className="width-20-rem">
              <Multiselect
                placeholder={getMultiSelectPlaceHolderValue(
                  filtersSelected.product_filter.map((product: ProductFilter) => ({
                    label: product.product_code,
                    value: product.product_code,
                    description: product.product_description
                  })),
                  'Product'
                )}
                options={[
                  {
                    ...SELECT_ALL_OPTION,
                    options: actualTaggingData.filterData.product_filter?.map((product: ProductFilter) => {
                      return { label: product.product_code, value: product.product_code, description: product.product_description };
                    })
                  }
                ]}
                selectedOptions={filtersSelected.product_filter.map((product: ProductFilter) => ({
                  label: product.product_code,
                  value: product.product_code,
                  description: product.product_description
                }))}
                onChange={({ detail }) => handleFilterChange('product_filter')(detail.selectedOptions)}
                expandToViewport
                filteringType="auto"
                empty="No data"
                hideTokens
              />
            </FormField>

            {/* Distinct Project options from the PO Tagging data will be shown here. */}
            <FormField label="Project" className="width-20-rem">
              <Multiselect
                placeholder={getMultiSelectPlaceHolderValue(
                  filtersSelected.project_filter.map((project: ProjectFilter) => ({
                    label: project.project_code,
                    value: project.project_code,
                    description: project.project_description
                  })),
                  'Project'
                )}
                options={[
                  {
                    ...SELECT_ALL_OPTION,
                    options: actualTaggingData.filterData.project_filter?.map((project: ProjectFilter) => {
                      return { label: project.project_code, value: project.project_code, description: project.project_description };
                    })
                  }
                ]}
                selectedOptions={filtersSelected.project_filter.map((project: ProjectFilter) => ({
                  label: project.project_code,
                  value: project.project_code,
                  description: project.project_description
                }))}
                onChange={({ detail }) => handleFilterChange('project_filter')(detail.selectedOptions)}
                expandToViewport
                filteringType="auto"
                empty="No data"
                hideTokens
              />
            </FormField>

            {/* Distinct Account options from the PO Tagging data will be shown here. */}
            <FormField label="Account" className="width-20-rem">
              <Multiselect
                placeholder={getMultiSelectPlaceHolderValue(
                  filtersSelected.account_filter.map((account: AccountFilter) => ({
                    label: account.account_code,
                    value: account.account_code,
                    description: account.account_description
                  })),
                  'Account'
                )}
                options={[
                  {
                    ...SELECT_ALL_OPTION,
                    options: actualTaggingData.filterData.account_filter?.map((account: AccountFilter) => {
                      return { label: account.account_code, value: account.account_code, description: account.account_description };
                    })
                  }
                ]}
                selectedOptions={filtersSelected.account_filter.map((account: AccountFilter) => ({
                  label: account.account_code,
                  value: account.account_code,
                  description: account.account_description
                }))}
                onChange={({ detail }) => handleFilterChange('account_filter')(detail.selectedOptions)}
                expandToViewport
                filteringType="auto"
                empty="No data"
                hideTokens
              />
            </FormField>

            {/* Distinct JE Category options from the PO Tagging data will be shown here. */}
            <FormField label="JE Category" className="width-20-rem">
              <Multiselect
                placeholder={getMultiSelectPlaceHolderValue(
                  filtersSelected.je_category.map((category: string) => ({ label: category, value: category })),
                  'JE Category'
                )}
                options={[
                  {
                    ...SELECT_ALL_OPTION,
                    options: actualTaggingData.filterData.je_category?.map((category: string) => {
                      return { label: category, value: category };
                    })
                  }
                ]}
                selectedOptions={filtersSelected.je_category.map((category: string) => ({ label: category, value: category }))}
                onChange={({ detail }) => handleFilterChange('je_category')(detail.selectedOptions)}
                expandToViewport
                filteringType="auto"
                empty="No data"
                hideTokens
              />
            </FormField>
          </SpaceBetween>
        </Container>
      )}

      <ConfirmDiscardModal visible={showDiscardModal} onConfirm={handleConfirmDiscard} onCancel={handleCancelDiscard} />
    </>
  );
};
