import { Box, Button, Container, FileUpload, Form, FormField, Header, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { fileUploadI18nStrings } from 'src/i18n-strings/file-upload';
import {
  CAPEXValidationMessages,
  downloadSampleTemplate,
  getCAPEXFileHeaderInfo,
  INITIAL_CAPEX_SUBMIT_STATUS,
  INITIAL_CAPEX_VALIDATION_STATUS,
  submitCapexData
} from './CapexConfig';
import { useAuth } from 'src/features/auth/AuthContextProvider';
import { CAPEXDataValidationStatus, CAPEXEntity, CAPEXFileEntity } from 'src/models/CapexModels';
import { ValidationStatusEntity } from 'src/models/XptGenericModels';
import { logger } from 'src/analytics/KatalLogger';
import { readExcelFileWithHeader } from 'src/utils/file-utils';
import { transformCapexFileHeader, validateCapexDataIsUnique, validateCapexHeaders } from './CapexDataValidation';
import { getCurrentUTCTimeInISO } from 'src/utils/date-time-utilities';

const AcceptedFileFormForExcel = {
  ConstraintMessage: 'Upload Excel (.xlsx) file only.',
  FileFormats: '.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};

export const CapexFileUpload: React.FC = () => {
  const { Alias: userAlias } = useAuth();
  const [uploadedFile, setUploadedFile] = useState<File[]>([]);
  const [uploadedFileModifiedData, setUploadedFileModifiedData] = useState<CAPEXFileEntity[]>([]);
  const [isUploadedFileValid, setIsUploadedFileValid] = useState<boolean>(false);
  const [fileValidationStatus, setFileValidationStatus] = useState<CAPEXDataValidationStatus>(INITIAL_CAPEX_VALIDATION_STATUS);
  const [fileSubmitStatus, setFileSubmitStatus] = useState<ValidationStatusEntity>(INITIAL_CAPEX_SUBMIT_STATUS);

  useEffect(() => {
    resetFileAndValidationStatus();
  }, []);

  useEffect(() => {
    if (uploadedFile.length > 0) {
      handleOnFileUpload();
    }
  }, [uploadedFile]);

  const resetFileAndValidationStatus = () => {
    setFileValidationStatus(INITIAL_CAPEX_VALIDATION_STATUS);
    setUploadedFileModifiedData([]);
    setIsUploadedFileValid(false);
    setFileSubmitStatus(INITIAL_CAPEX_SUBMIT_STATUS);
  };

  const handleOnFileUpload = async () => {
    if (uploadedFile.length > 0) {
      logger.debug(`File upload initiated for Capex by ${userAlias}`);
      try {
        const { headerRow, fileData } = await readExcelFileWithHeader(uploadedFile[0]);

        logger.info(`User ${userAlias} uploaded a file for Capex with total number of records: ${fileData.length}`);
        logger.debug('Capex headerRow:', headerRow);
        logger.debug('Capex fileData:', { data: JSON.stringify(fileData) });

        validateFileData(headerRow, fileData);
      } catch (error: any) {
        logger.error('Error while reading the file:', error);
        setUploadedFile([]);
        setFileValidationStatus({
          ...INITIAL_CAPEX_VALIDATION_STATUS,
          HeadersMatching: {
            colorOverride: 'red',
            validationStatus: 'error',
            validationMessage: 'Error reading the file',
            validationDefaultMessage: 'Error reading the file',
            validationErrorDetails: [error.message]
          }
        });
      }
    }
  };

  const validateFileData = async (headerRow: string[], fileData: any[]) => {
    resetFileAndValidationStatus();
    try {
      logger.debug(`Validating file data for Capex`);

      const { fileHeaders } = getCAPEXFileHeaderInfo();
      const expectedHeaders = fileHeaders.map((column) => column.headerName);
      const headerValidation = await validateHeadersStep(headerRow, expectedHeaders);

      if (headerValidation.validationStatus === 'error') {
        logger.debug(`Headers validation failed for Capex upload. No further validations.`);
        return;
      }

      logger.debug(`Headers validation passed for Capex. Proceeding with further validations.`);
      const transformHeaderValidation = await transformCapexFileHeader(fileData);

      if (transformHeaderValidation.validationStatus === 'error') {
        logger.debug(`Headers transformation failed for Capex upload. No further validations.`);
        setFileValidationStatus((prevState) => ({
          ...prevState,
          HeadersMatching: {
            colorOverride: 'green',
            validationStatus: 'success',
            validationMessage: CAPEXValidationMessages.HEADER_VALIDATION_SUCCESS,
            validationDefaultMessage: '',
            validationErrorDetails: []
          },
          DataValidity: {
            ...transformHeaderValidation,
            validationStatus: 'error'
          }
        }));
        return;
      } else {
        setFileValidationStatus((prevState) => ({
          ...prevState,
          HeadersMatching: {
            colorOverride: 'green',
            validationStatus: 'success',
            validationMessage: CAPEXValidationMessages.HEADER_VALIDATION_SUCCESS,
            validationDefaultMessage: '',
            validationErrorDetails: []
          },
          DataValidity: transformHeaderValidation
        }));
      }

      const transformedData: CAPEXFileEntity[] | undefined = transformHeaderValidation.transformedData;

      if (transformedData) {
        setUploadedFileModifiedData(transformedData);
        const finalValidation = await validateCapexDataIsUnique(transformedData);
        setFileValidationStatus((prevState) => ({
          ...prevState,
          UniqueKeyValidation: finalValidation
        }));
      }
    } catch (error: any) {
      logger.error('Error while validating the file data:', error);
      setFileValidationStatus({
        ...INITIAL_CAPEX_VALIDATION_STATUS,
        HeadersMatching: {
          colorOverride: 'red',
          validationStatus: 'error',
          validationMessage: 'Error validating the file data',
          validationDefaultMessage: 'Error validating the file data',
          validationErrorDetails: [error.message]
        }
      });
    }
  };

  const validateHeadersStep = async (headerRow: string[], expectedHeaders: string[]): Promise<ValidationStatusEntity> => {
    const headerValidation = await validateCapexHeaders(headerRow, expectedHeaders);

    if (headerValidation.validationStatus === 'error') {
      setIsUploadedFileValid(false);
      setFileValidationStatus((prevState) => ({
        ...prevState,
        HeadersMatching: {
          ...headerValidation,
          validationStatus: 'error'
        },
        DataValidity: {
          ...prevState.DataValidity,
          validationStatus: 'error',
          validationMessage: CAPEXValidationMessages.SKIPPED_DUE_TO_HEADER_FAILURE
        },
        UniqueKeyValidation: {
          ...prevState.UniqueKeyValidation,
          validationStatus: 'error',
          validationMessage: CAPEXValidationMessages.SKIPPED_DUE_TO_HEADER_FAILURE
        }
      }));
    } else {
      setIsUploadedFileValid(true);
      setFileValidationStatus((prevState) => ({
        ...prevState,
        HeadersMatching: headerValidation
      }));
    }

    return headerValidation;
  };

  const isAllValidationsPassed = () => {
    return (
      fileValidationStatus.HeadersMatching.validationStatus === 'success' &&
      fileValidationStatus.DataValidity.validationStatus === 'success' &&
      fileValidationStatus.UniqueKeyValidation.validationStatus === 'success'
    );
  };

  const handleSubmit = async () => {
    setFileSubmitStatus({
      validationMessage: 'Request in progress',
      validationStatus: 'loading',
      colorOverride: 'blue',
      validationDefaultMessage: '',
      validationErrorDetails: []
    });

    try {
      const capexDataToUpload = uploadedFileModifiedData.map((row) => {
        return {
          ...row,
          created_at: getCurrentUTCTimeInISO(),
          created_by: userAlias
        } as CAPEXEntity;
      });

      console.debug('Capex file data to upload:', JSON.stringify(capexDataToUpload));

      await submitCapexData(capexDataToUpload, userAlias);

      setFileSubmitStatus({
        validationMessage: CAPEXValidationMessages.UPLOAD_SUCCESS,
        validationStatus: 'success',
        colorOverride: 'green',
        validationDefaultMessage: '',
        validationErrorDetails: []
      });
      setTimeout(() => {
        resetFileAndValidationStatus();
        setUploadedFile([]);
      }, 3000);
    } catch (error: any) {
      setFileSubmitStatus({
        validationMessage: CAPEXValidationMessages.UPLOAD_FAILED,
        validationStatus: 'error',
        colorOverride: 'red',
        validationDefaultMessage: CAPEXValidationMessages.UPLOAD_FAILED,
        validationErrorDetails: []
      });
    }
  };

  return (
    <form onSubmit={(event) => event.preventDefault()}>
      <Form
        header={
          <Header
            variant="h2"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                {fileSubmitStatus.validationMessage !== 'Not Initiated' && (
                  <Box>
                    <StatusIndicator type={fileSubmitStatus.validationStatus} colorOverride={fileSubmitStatus.colorOverride}>
                      {fileSubmitStatus.validationMessage}
                    </StatusIndicator>
                  </Box>
                )}
                <Box>
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button onClick={downloadSampleTemplate}>Download Template</Button>
                  </SpaceBetween>
                </Box>
              </SpaceBetween>
            }
          >
            {`CapEx File Upload`}
          </Header>
        }
      >
        <SpaceBetween size="m" direction="vertical">
          <Container
            header={
              <Header
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    {fileSubmitStatus.validationStatus !== 'success' && (
                      <Button
                        variant="primary"
                        onClick={handleSubmit}
                        disabled={
                          !isAllValidationsPassed() ||
                          fileSubmitStatus.validationStatus === 'loading' ||
                          fileSubmitStatus.validationStatus === 'in-progress'
                        }
                      >
                        Upload
                      </Button>
                    )}
                  </SpaceBetween>
                }
              ></Header>
            }
          >
            <FormField label="Upload CapEx Data" description="">
              <FileUpload
                onChange={({ detail }) => setUploadedFile(detail.value)}
                value={uploadedFile}
                i18nStrings={fileUploadI18nStrings()}
                accept={AcceptedFileFormForExcel.FileFormats}
                constraintText={AcceptedFileFormForExcel.ConstraintMessage}
                ariaRequired
                multiple={false}
                showFileLastModified={true}
                showFileSize={true}
                showFileThumbnail={true}
                tokenLimit={1}
                invalid={!isUploadedFileValid}
              />
            </FormField>

            <Box padding={{ top: 's' }}>
              <SpaceBetween size="m" direction="vertical">
                {Object.keys(fileValidationStatus).map((key, index) => {
                  const validationStatus = fileValidationStatus[key as keyof CAPEXDataValidationStatus];
                  return (
                    <StatusIndicator key={index} type={validationStatus.validationStatus} colorOverride={validationStatus.colorOverride}>
                      {validationStatus.validationMessage}
                    </StatusIndicator>
                  );
                })}
              </SpaceBetween>
            </Box>
          </Container>
        </SpaceBetween>
      </Form>
    </form>
  );
};
