import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAccountBudgetTypeMappings, getCommodityData, getExpenseTypeMappings, getUsefulLifeDetails } from 'src/api/app-sync-services';
import { LoadingStatus } from 'src/models/AuthContextModels';
import { AccountBudgetTypeMapping, CommodityEntity, ExpenseTypeEntity, UsefulLifeMappingEntity } from 'src/models/xPTMappingModels';
import { RootState } from '../store';

interface xPTMapperState {
  commodityStatus: LoadingStatus;
  commodityMappings: CommodityEntity[];
  commoditiesList: string[];
  expenseTypeStatus: LoadingStatus;
  expenseTypeMappings: ExpenseTypeEntity[];
  usefulLifeStatus: LoadingStatus;
  usefulLifeMappings: UsefulLifeMappingEntity[];
  accountBudgetTypeMappingStatus: LoadingStatus;
  accountBudgetTypeMapping: AccountBudgetTypeMapping[];
}

const initialState: xPTMapperState = {
  commodityStatus: LoadingStatus.NotInitiated,
  commodityMappings: [],
  commoditiesList: [],
  expenseTypeStatus: LoadingStatus.NotInitiated,
  expenseTypeMappings: [],
  usefulLifeStatus: LoadingStatus.NotInitiated,
  usefulLifeMappings: [],
  accountBudgetTypeMappingStatus: LoadingStatus.NotInitiated,
  accountBudgetTypeMapping: []
};

// Define async thunks for fetching data
export const fetchCommodities = createAsyncThunk('xPTMapper/fetchCommodities', async () => {
  const response = await getCommodityData();
  return response;
});

export const fetchExpenseTypes = createAsyncThunk('xPTMapper/fetchExpenseTypes', async () => {
  const response = await getExpenseTypeMappings();
  return response;
});

export const fetchUsefulLifeDetails = createAsyncThunk('xPTMapper/fetchUsefulLifeDetails', async () => {
  const response = await getUsefulLifeDetails();
  return response;
});

export const fetchAccountBudgetTypeMappings = createAsyncThunk('xPTMapper/fetchAccountBudgetTypeMappings', async () => {
  const response = await getAccountBudgetTypeMappings();
  return response;
});

// Utility function to get sorted and unique values
const getSortedUniqueValues = (array: string[]): string[] => {
  return Array.from(new Set(array)).sort((a, b) => a.localeCompare(b));
};

// Create the slice
const xPTMapperSlice = createSlice({
  name: 'xPTMapper',
  initialState,
  reducers: {
    // Define any synchronous actions here if needed
  },
  extraReducers: (builder) => {
    // Handle fetchCommodities lifecycle actions
    builder.addCase(fetchCommodities.pending, (state) => {
      state.commodityStatus = LoadingStatus.Loading;
    });
    builder.addCase(fetchCommodities.fulfilled, (state, action: PayloadAction<CommodityEntity[]>) => {
      state.commodityStatus = LoadingStatus.Completed;
      state.commodityMappings = action.payload.sort((a, b) => a.commodity.localeCompare(b.commodity));
      state.commoditiesList = getSortedUniqueValues(action.payload.map((item) => item.commodity));
    });
    builder.addCase(fetchCommodities.rejected, (state) => {
      state.commodityStatus = LoadingStatus.Failed;
    });

    // Handle fetchExpenseTypes lifecycle actions
    builder.addCase(fetchExpenseTypes.pending, (state) => {
      state.expenseTypeStatus = LoadingStatus.Loading;
    });
    builder.addCase(fetchExpenseTypes.fulfilled, (state, action: PayloadAction<ExpenseTypeEntity[]>) => {
      state.expenseTypeStatus = LoadingStatus.Completed;
      state.expenseTypeMappings = action.payload.sort((a, b) => a.budget_type.localeCompare(b.budget_type));
    });
    builder.addCase(fetchExpenseTypes.rejected, (state) => {
      state.expenseTypeStatus = LoadingStatus.Failed;
    });

    // Handle fetchUsefulLifeDetails lifecycle actions
    builder.addCase(fetchUsefulLifeDetails.pending, (state) => {
      state.usefulLifeStatus = LoadingStatus.Loading;
    });
    builder.addCase(fetchUsefulLifeDetails.fulfilled, (state, action: PayloadAction<UsefulLifeMappingEntity[]>) => {
      state.usefulLifeStatus = LoadingStatus.Completed;
      state.usefulLifeMappings = action.payload.sort((a, b) => a.cost_center_code.localeCompare(b.cost_center_code));
    });
    builder.addCase(fetchUsefulLifeDetails.rejected, (state) => {
      state.usefulLifeStatus = LoadingStatus.Failed;
    });

    // Handle fetchAccountBudgetTypeMappings lifecycle actions
    builder.addCase(fetchAccountBudgetTypeMappings.pending, (state) => {
      state.accountBudgetTypeMappingStatus = LoadingStatus.Loading;
    });
    builder.addCase(fetchAccountBudgetTypeMappings.fulfilled, (state, action: PayloadAction<AccountBudgetTypeMapping[]>) => {
      state.accountBudgetTypeMappingStatus = LoadingStatus.Completed;
      state.accountBudgetTypeMapping = action.payload;
    });
    builder.addCase(fetchAccountBudgetTypeMappings.rejected, (state) => {
      state.accountBudgetTypeMappingStatus = LoadingStatus.Failed;
    });
  }
});

// Selectors to access the lists from the state
export const selectExpenseTypesMapping = (state: RootState) => state.xPTMappingStore.expenseTypeMappings;
export const selectCommoditiesList = (state: RootState) => state.xPTMappingStore.commoditiesList;

// Export the reducer
export default xPTMapperSlice.reducer;
