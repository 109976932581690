import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getEntityStatuses, getXPTLDAPGroups } from 'src/api/app-sync-services';
import { eAgGridThemes } from 'src/constants/generic-constants';
import { LDAPGroups } from 'src/models/AppContextModels';
import { LoadingStatus } from 'src/models/AuthContextModels';
import { EntityStatus } from 'src/models/PlanningCycleModel';

// State interface definition for the Application level metadata.
interface AppMetadataState {
  loadingStatus: LoadingStatus;
  ldapGroups: LDAPGroups;
  entityStatus: EntityStatus[];
  themeClassName: string;
}

export const appMetadataInitialState: AppMetadataState = {
  loadingStatus: LoadingStatus.NotInitiated,
  ldapGroups: {} as LDAPGroups,
  entityStatus: [],
  themeClassName: eAgGridThemes.Quartz
};

/**
 * Updates the loading status in the state.
 * @param state - The current state of the application metadata.
 * @param status - The new loading status to be set.
 */
const updateLoadingStatus = (state: AppMetadataState, status: LoadingStatus) => {
  state.loadingStatus = status;
};

/**
 * Thunk to fetch XPT LDAP groups.
 */
export const fetchXptLDAPGroups = createAsyncThunk('appMetadata/fetchXptLDAPGroups', async (_, { rejectWithValue }) => {
  try {
    const userLDAPGroups = await getXPTLDAPGroups();
    return userLDAPGroups;
  } catch (error) {
    return rejectWithValue('Failed to fetch ldap groups');
  }
});

/**
 * Thunk to fetch entity statuses.
 */
export const fetchEntityStatuses = createAsyncThunk('appMetadata/fetchEntityStatuses', async (_, { rejectWithValue }) => {
  try {
    const entityStatuses = await getEntityStatuses();
    return entityStatuses;
  } catch (error) {
    return rejectWithValue('Failed to fetch entity statuses');
  }
});

/**
 * Slice for application metadata.
 */
const appMetadataSlice = createSlice({
  name: 'appMetadata',
  initialState: appMetadataInitialState,
  reducers: {
    toggleTheme: (state, action: PayloadAction<boolean>) => {
      state.themeClassName = action.payload ? eAgGridThemes.Quartz_Dark : eAgGridThemes.Quartz;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchXptLDAPGroups.pending, (state) => updateLoadingStatus(state, LoadingStatus.Loading))
      .addCase(fetchXptLDAPGroups.fulfilled, (state, action) => {
        state.ldapGroups = action.payload;
        updateLoadingStatus(state, LoadingStatus.Completed);
      })
      .addCase(fetchXptLDAPGroups.rejected, (state) => updateLoadingStatus(state, LoadingStatus.Failed))
      .addCase(fetchEntityStatuses.fulfilled, (state, action) => {
        state.entityStatus = action.payload;
        updateLoadingStatus(state, LoadingStatus.Completed);
      });
  }
});

export const { toggleTheme } = appMetadataSlice.actions;
export default appMetadataSlice.reducer;
