import React from 'react';
import { Box, Spinner } from '@amzn/awsui-components-react';

export const LoadingSpinner: React.FC = () => {
  return (
    <Box margin="xxl" padding="xxl" textAlign="center" data-testid="loading-spinner">
      <Spinner size="large" />
    </Box>
  );
};
