import React from 'react';
import { Box, Button, Popover, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import { IHeaderParams } from 'ag-grid-community';
import { createRoot } from 'react-dom/client';
import { ForecastGridFixedFields } from './ForecastGridConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

export class ForecastCustomTooltip {
  private headerElement: HTMLElement | undefined;

  init(params: IHeaderParams) {
    this.headerElement = document.createElement('div');
    this.headerElement.classList.add('custom-header');

    const headerContent = (
      <div>
        <span>{params.displayName}</span>
        <Popover dismissButton={false} position="top" size="medium" triggerType="custom" content={this.renderTooltipContent(params.displayName)}>
          <FontAwesomeIcon icon={faCircleInfo} className="custom-ag-grid-header-info-icon" />
        </Popover>
      </div>
    );

    const root = createRoot(this.headerElement);
    root.render(headerContent);
  }

  renderTooltipContent(headerDisplayName: string) {
    switch (headerDisplayName) {
      case ForecastGridFixedFields.TotalLifetimeSpend.displayName:
        return (
          <Box>
            <SpaceBetween size="xs" direction="vertical">
              <Box variant="h5">{ForecastGridFixedFields.TotalLifetimeSpend.displayName}</Box>
              <Box variant="div">
                <strong>Total:</strong> $[Actuals + Forecast Total Amount]
              </Box>
              <Box variant="div">
                <strong>Note:</strong> Actuals are shown for POs that have been tagged at the line item ID level.
              </Box>
            </SpaceBetween>
          </Box>
        );

      case ForecastGridFixedFields.BudgetType.displayName:
        return <Box>Auto-fills based on account selection</Box>;

      default:
        return (
          <Box>
            <SpaceBetween size="xs" direction="vertical">
              <Box variant="div">{`${headerDisplayName}`}</Box>
            </SpaceBetween>
          </Box>
        );
    }
  }

  getGui() {
    return this.headerElement!;
  }

  refresh(params: IHeaderParams): boolean {
    return false;
  }
}
