import { SideNavigation, SideNavigationProps } from '@amzn/awsui-components-react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_NAME_SHORT } from 'src/constants/generic-constants';
import { useAuth } from '../auth/AuthContextProvider';
import { findActiveHref } from 'src/utils/navigation-utilities';

const SECTION_DIVIDER: SideNavigationProps.Divider = { type: 'divider' };

// can be accessed by isDev, isAdmin, isBusinessLeader
const businessLeaderNavItems: SideNavigationProps.Item[] = [
  {
    type: 'section-group',
    title: 'Budget Leader Tools',
    items: [
      { type: 'link', text: 'Budget Owner Transfer', href: '/admin-console/budget-owner-transfer' },
      // { type: 'link', text: 'Commodity Mapping', href: '/admin-console/commodity' },
      { type: 'link', text: 'Expense Type Mapping', href: '/admin-console/expense-type' },
      { type: 'link', text: 'Useful Life Mapping', href: '/admin-console/useful-life' },
      { type: 'link', text: 'Project Mapping', href: '/admin-console/project' }
    ]
  }
];

// can be accessed by isDev, isAdmin
const adminNavItems: SideNavigationProps.Item[] = [
  {
    type: 'section-group',
    title: 'Admin Tools',
    items: [
      { type: 'link', text: 'Planning Cycles', href: '/admin-console/planning-cycle' },
      { type: 'link', text: 'CapEx File Upload', href: '/admin-console/capex' }
    ]
  }
];

// can be accessed by isDev only
const developerNavItems: SideNavigationProps.Item[] = [
  {
    type: 'section-group',
    title: 'Developer Tools',
    items: [
      { type: 'link', text: 'Business Groups', href: '/admin-console/onboarding' },
      { type: 'link', text: 'Audit Log', href: '/admin-console/audit-log' }
    ]
  }
];

const AdminConsoleSideNavigation: React.FC = () => {
  const { isDev, isAdmin, isBusinessLeader } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // Create the side navigation items array based on roles
  let sideNavigationItems: SideNavigationProps.Item[] = [];

  if (isDev) {
    // If the user is a developer, show all sections
    sideNavigationItems = [...businessLeaderNavItems, SECTION_DIVIDER, ...adminNavItems, SECTION_DIVIDER, ...developerNavItems];
  } else {
    // Add business leader items if the user is an admin or business leader
    if (isAdmin || isBusinessLeader) {
      sideNavigationItems = [...sideNavigationItems, ...businessLeaderNavItems];
    }

    // Add admin items if the user is an admin
    if (isAdmin) {
      if (sideNavigationItems.length) {
        sideNavigationItems.push(SECTION_DIVIDER);
      }
      sideNavigationItems = [...sideNavigationItems, ...adminNavItems];
    }

    // Add developer items if the user is a developer
    if (isDev) {
      if (sideNavigationItems.length) {
        sideNavigationItems.push(SECTION_DIVIDER);
      }
      sideNavigationItems = [...sideNavigationItems, ...developerNavItems];
    }
  }

  // Determine the active link based on the current path
  const activeHref = findActiveHref(pathname, sideNavigationItems);

  return (
    <SideNavigation
      data-testid="admin-console-side-navigation"
      activeHref={activeHref}
      header={{
        href: '/admin-console/budget-owner-transfer',
        text: `${APP_NAME_SHORT} Admin Console`
      }}
      onFollow={(event) => {
        if (!event.detail.external) {
          event.preventDefault();
          navigate(event.detail.href);
        }
      }}
      items={sideNavigationItems}
    />
  );
};

export default AdminConsoleSideNavigation;
