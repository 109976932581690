import { FileUploadProps } from '@amzn/awsui-components-react';
import { convertsUTCtoUserLocalAndReturnsReadableFormat } from 'src/utils/date-time-utilities';

export const fileUploadI18nStrings: () => FileUploadProps.I18nStrings = () => ({
  uploadButtonText: (e) => (e ? 'Choose files' : 'Choose file'),
  dropzoneText: (e) => (e ? 'Drop files to upload' : 'Drop file to upload'),
  removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
  limitShowFewer: 'Show fewer files',
  limitShowMore: 'Show more files',
  errorIconAriaLabel: 'Error',
  formatFileLastModified: (e: Date) => `Last modified: ${convertsUTCtoUserLocalAndReturnsReadableFormat(e.toISOString())}`
});
