import { Alert, Box, Button, ColumnLayout, Container, Header, Icon, SpaceBetween, Tabs, TabsProps, TextContent } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { logger } from 'src/analytics/KatalLogger';
import { LoadingStatus } from 'src/models/AuthContextModels';
import { PlanningCycleHomePageWidgetModel } from 'src/models/PlanningCycleModel';
import { RootState } from 'src/store/store';
import { convertsUTCtoUserLocalAndReturnsReadableFormat, getReadableFormatOfMonthDateYear, isDateInThePast } from 'src/utils/date-time-utilities';
import { getActualsDateRange, getForecastDateRange } from 'src/features/planning-cycle/planningCycleUtils';
import { useAuth } from 'src/features/auth/AuthContextProvider';
import { compareNullableNumbers } from 'src/utils/generic-utilities';

/**
 * Component for displaying Planning Cycle Home Page Widget
 */
export const PlanningCycleHomePageWidget: React.FC = () => {
  const [tabs, setTabs] = useState<TabsProps.Tab[]>([]);
  const { planningCyclesLoadingStatus: loadingStatus, scenarios } = useSelector((state: RootState) => state.planningCycleStore);

  useEffect(() => {
    if (loadingStatus === LoadingStatus.Completed) {
      createTabsFromScenarios();
    } else if (loadingStatus === LoadingStatus.Failed) {
      logger.error('Unable to load scenario tabs in Planning Cycle tabs');
      setTabs([]);
    }
  }, [loadingStatus]);

  const createTabsFromScenarios = () => {
    const scenarioTabs: TabsProps.Tab[] = scenarios.map((scenario) => ({
      label: scenario.scenario_name,
      id: `${scenario.scenario_id}`,
      content: <PlanningCycleTabContent scenarioId={scenario.scenario_id} />
    }));
    setTabs(scenarioTabs);
  };

  return (
    <Container header={<Header>Planning Cycle Deadlines</Header>}>
      <Tabs tabs={tabs} />
    </Container>
  );
};

interface PlanningCycleTabContentProps {
  scenarioId: number | null;
}

/**
 * Component for displaying the content of each Planning Cycle Tab
 */
export const PlanningCycleTabContent: React.FC<PlanningCycleTabContentProps> = ({ scenarioId }) => {
  if (!scenarioId) return <Alert type="error">Planning Cycle not available</Alert>;

  const { isBudgetOwner } = useAuth();

  const { planningCyclesLoadingStatus: loadingStatus, planningCycles } = useSelector((state: RootState) => state.planningCycleStore);
  const [planningCycleContent, setPlanningCycleContent] = useState<PlanningCycleHomePageWidgetModel[]>([]);

  useEffect(() => {
    if (loadingStatus === LoadingStatus.Completed) {
      updatePlanningCycleContent();
    }
  }, [loadingStatus, planningCycles, scenarioId]);

  const updatePlanningCycleContent = () => {
    try {
      const filteredCycles = planningCycles
        .filter((cycle) => cycle.scenario.scenario_id === scenarioId)
        .sort((a, b) => compareNullableNumbers(a.scenario_seq_id, b.scenario_seq_id, 'desc'))
        .slice(0,3)
        .map((cycle) => {
          let lockDate = null;
          if (isBudgetOwner) {
            lockDate = cycle.budget_owner_lock_date ? cycle.budget_owner_lock_date : cycle.planning_cycle_lock_date;
          } else {
            lockDate = cycle.planning_cycle_lock_date;
          }

          const planningCycleWindow = `${getReadableFormatOfMonthDateYear(
            cycle.planning_cycle_open_date
          )} - ${convertsUTCtoUserLocalAndReturnsReadableFormat(lockDate)}`;

          return {
            businessGroupName: cycle.data_classification.data_classification_name,
            scenarioName: cycle.scenario.planning_cycle_name,
            planningCycleLockStatus: isDateInThePast(cycle.planning_cycle_lock_date) ? 'Locked' : 'Unlocked',
            forecastDateRange: getForecastDateRange(cycle),
            actualsDateRange: getActualsDateRange(cycle),
            planningCycleWindow: planningCycleWindow
          } as PlanningCycleHomePageWidgetModel;
        });
      setPlanningCycleContent(filteredCycles);
    } catch (error: any) {
      logger.error('Error updating planning cycle content:', error);
    }
  };

  return (
    <>
      {planningCycleContent.length > 0 ? (
        planningCycleContent
          .sort((a, b) => a.businessGroupName.localeCompare(b.businessGroupName))
          .map((cycle, index) => (
            <React.Fragment key={index}>
              <Box margin={{ left: 's' }}>
                <Box fontWeight="bold" variant="h3" margin={{ top: 's' }}>
                  {cycle.businessGroupName}
                </Box>
                <div className="horizontal-bar"></div>

                <Box padding={{ top: 'xxs', bottom: 'xxs' }}>
                  <ColumnLayout columns={2}>
                    <Box>Scenario</Box>
                    <Box>{cycle.scenarioName}</Box>
                  </ColumnLayout>
                </Box>
                <div className="horizontal-bar"></div>

                <Box padding={{ top: 'xxs', bottom: 'xxs' }}>
                  <ColumnLayout columns={2}>
                    <Box>Forecast Date Range</Box>
                    <Box>{cycle.forecastDateRange}</Box>
                  </ColumnLayout>
                </Box>
                <div className="horizontal-bar"></div>

                <Box padding={{ top: 'xxs', bottom: 'xxs' }}>
                  <ColumnLayout columns={2}>
                    <Box>Actuals Date Range</Box>
                    <Box>{cycle.actualsDateRange}</Box>
                  </ColumnLayout>
                </Box>
                <div className="horizontal-bar"></div>

                <Box padding={{ top: 'xxs', bottom: 'xxs' }}>
                  <ColumnLayout columns={2}>
                    <Box>Status</Box>
                    <SpaceBetween size="s" direction="horizontal" alignItems="center">
                      <Icon
                        name={cycle.planningCycleLockStatus === 'Locked' ? 'lock-private' : 'unlocked'}
                        variant={cycle.planningCycleLockStatus === 'Locked' ? 'error' : 'success'}
                      />
                      <Box color={cycle.planningCycleLockStatus === 'Locked' ? 'text-status-error' : 'text-status-success'}>
                        {cycle.planningCycleLockStatus}
                      </Box>
                    </SpaceBetween>
                  </ColumnLayout>
                </Box>
                <div className="horizontal-bar"></div>

                <Box padding={{ top: 'xxs', bottom: 'xxs' }}>
                  <ColumnLayout columns={2}>
                    <Box>Planning Cycle Window</Box>
                    <Box>{cycle.planningCycleWindow}</Box>
                  </ColumnLayout>
                </Box>
              </Box>
            </React.Fragment>
          ))
      ) : (
        <Alert type="warning">No planning cycles available for this scenario</Alert>
      )}
    </>
  );
};
