import { Header, StatusIndicator } from '@amzn/awsui-components-react';
import React, { useCallback, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { logger } from 'src/analytics/KatalLogger';
import { getUserMetrics } from 'src/api/app-sync-services';
import { ErrorFallback } from 'src/components/common/ErrorFallback';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import XptWidgetTable from 'src/components/common/xpt-table/XptWidgetTable';
import { MonthlyUniqueUsersPayloadEntity, UserMetricsRaw } from 'src/models/AppContextModels';
import { dateTimeComparator } from 'src/utils/date-time-utilities';
import { WidgetContainer } from '../WidgetContainer';
import { MetricsName, parseUserMetrics, uniqueUsersPerMonthTableColumnDefinition } from './UniqueUsersConfig';

const UserMetricsWidget: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [monthlyUniqueUsersData, setMonthlyUniqueUsersData] = useState<MonthlyUniqueUsersPayloadEntity[]>([]);

  useEffect(() => {
    loadMetrics();
  }, []);

  const loadMetrics = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      // Fetches unparsed User Metrics from API
      const userMetricsResponse: UserMetricsRaw[] = await getUserMetrics();
      const parsedMetrics = parseUserMetrics(userMetricsResponse);
      const monthlyUniqueUsers = parsedMetrics.find((parsedMetric) => parsedMetric.metrics_name === MetricsName.MonthlyUniqueUsers);
      if (monthlyUniqueUsers) {
        const monthlyUniqueUsersData: MonthlyUniqueUsersPayloadEntity[] = monthlyUniqueUsers.metrics_payload;
        setMonthlyUniqueUsersData(monthlyUniqueUsersData.sort((a, b) => dateTimeComparator(a.log_date, b.log_date, false)).slice(0, 6));
      } else {
        setMonthlyUniqueUsersData([]);
        setError('Failed to load user metrics');
        logger.error('Failed to load user metrics widget data');
      }
    } catch (err: any) {
      setMonthlyUniqueUsersData([]);
      setError('Failed to load user metrics');
      logger.error('Failed to load user metrics widget data', err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const widgetHeader = (
    <Header variant="h3" >
      User Metrics
    </Header>
  );

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <WidgetContainer header={widgetHeader}>
        {isLoading ? (
          <LoadingSpinner />
        ) : error ? (
          <StatusIndicator type="error">{error}</StatusIndicator> // Error message display
        ) : (
          <>
            <XptWidgetTable
              variant="embedded"
              loadingStatus={false}
              allItems={monthlyUniqueUsersData}
              columnDefinitions={uniqueUsersPerMonthTableColumnDefinition()}
              hasPagination={false}
              itemsPerPage={6}
            />
          </>
        )}
      </WidgetContainer>
    </ErrorBoundary>
  );
};

export default UserMetricsWidget;
