import { AppLayout, ContentLayout, Flashbar, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { ErrorFallback } from 'src/components/common/ErrorFallback';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import { XPTBreadcrumbs } from 'src/components/common/XptBreadcrumb';
import BusinessGroupSideNavigation from 'src/features/xpt-layout/XptBusinessGroupSideNavigation';
import { useFlashbar } from 'src/hooks/useFlashbar';
import { businessGroupBaseBreadcrumbs, currentBusinessGroup, currentBusinessGroupName } from '../businessGroupSelectors';
import { getForecastTemplateBreadcrumbItems } from './forecast-utils/ForecastTemplateConfig';
import { ForecastTemplateContext } from './ForecastTemplateContext';
import ForecastTemplateFilterSelection from './ForecastTemplateFilterSelection';
import ForecastTemplateGrid from './ForecastTemplateGrid';
import ForecastTemplateStatusIndicator from './ForecastTemplateStatusIndicator';

export const ForecastTemplateMainPage: React.FC = () => {
  const businessGroup = useSelector(currentBusinessGroup);
  const businessGroupName = useSelector(currentBusinessGroupName);
  const businessGroupBaseBreadcrumb = useSelector(businessGroupBaseBreadcrumbs);

  const [selectedPlanningCycleSeqId, setSelectedPlanningCycleSeqId] = useState<string | null>(null);
  const { flashbarItems, displayFlashMessage, clearSpecificFlashMessage, clearAllMessages } = useFlashbar();

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {!businessGroup && <LoadingSpinner />}
        {businessGroup && (
          <ForecastTemplateContext.Provider
            value={{
              selectedPlanningCycleSeqId,
              setSelectedPlanningCycleSeqId,
              notificationMessage: displayFlashMessage,
              clearSpecificFlashMessage,
              clearAllMessages
            }}
          >
            <AppLayout
              headerSelector="#h"
              navigation={<BusinessGroupSideNavigation />}
              toolsHide={true}
              breadcrumbs={<XPTBreadcrumbs items={getForecastTemplateBreadcrumbItems(businessGroupBaseBreadcrumb, businessGroupName)} />}
              notifications={<Flashbar stackItems items={flashbarItems} />}
              maxContentWidth={Number.MAX_VALUE}
              contentType="default"
              content={
                <>
                  <ContentLayout
                    header={
                      <Header
                        actions={
                          <SpaceBetween size="xxxs" direction="horizontal">
                            {selectedPlanningCycleSeqId && <ForecastTemplateStatusIndicator />}
                          </SpaceBetween>
                        }
                      >
                        {`Forecast Input`}
                      </Header>
                    }
                  >
                    <SpaceBetween size="xs" direction="vertical">
                      <ForecastTemplateFilterSelection />
                      <ForecastTemplateGrid />
                    </SpaceBetween>
                  </ContentLayout>
                </>
              }
            />
          </ForecastTemplateContext.Provider>
        )}
      </ErrorBoundary>
    </>
  );
};
