export enum eCorpSegmentNames {
  COST_CENTER = 'Cost Center',
  PRODUCT_LINE = 'Product Line',
  PROJECT = 'Project',
  ACCOUNT = 'Account',
  CHANNEL = 'Channel',
  LOCATION = 'Location',
  COMPANY = 'Company'
}

export enum eBusinessSegmentNames {
  EXPENSE_TYPE = 'Expense Type',
  COMMODITY = 'Commodity',
}

export enum eSegmentCategoryType {
  CORP = 'CORP',
  BUSINESS = 'BUSINESS'
}

export enum eBusinessSegmentDataType {
  TEXT = 'text',
  NUMBER = 'number',
  DROPDOWN = 'dropdown'
}

// segments that support multiple selections.
export const multiSelectableSegments: eCorpSegmentNames[] = [eCorpSegmentNames.COST_CENTER, eCorpSegmentNames.PRODUCT_LINE, eCorpSegmentNames.PROJECT, eCorpSegmentNames.ACCOUNT];

// segments that do not support multiple selections.
export const nonMultiSelectableSegments: eCorpSegmentNames[] = [];

// To check if a segment is multiple selections.
export const isSegmentMultiSelectable = (corpSegmentName: eCorpSegmentNames): boolean => {
  return multiSelectableSegments.includes(corpSegmentName);
};

// To check if a segment is mandatory.
export const isMandatoryCorpSegment = (corpSegmentName: eCorpSegmentNames): boolean => {
  return corpSegmentName === eCorpSegmentNames.COST_CENTER;
};

// Adding the definition of the sequence of corp segments to display on the Onboarding page, ensuring consistent ordering regardless of the backend response.
export const CORP_SEGMENTS_SEQUENCE: eCorpSegmentNames[] = [
  eCorpSegmentNames.COST_CENTER,
  eCorpSegmentNames.PRODUCT_LINE,
  eCorpSegmentNames.ACCOUNT,
  eCorpSegmentNames.PROJECT,
  eCorpSegmentNames.CHANNEL,
  eCorpSegmentNames.LOCATION,
  eCorpSegmentNames.COMPANY
];
