import React from 'react';
import { useAuth } from '../auth/AuthContextProvider';
import { XptSecondaryNavBar } from './XptSecondaryNavBar';
import { XptTopNavigation } from './XptTopNavigation';

export const XPTGlobalTopNavigation: React.FC = () => {
  const { noAccess } = useAuth();
  return (
    <>
      {!noAccess && (
        <div id="h">
          <XptTopNavigation />
          <XptSecondaryNavBar />
        </div>
      )}
      {noAccess && (
        <div id="h2">
          <XptTopNavigation />
        </div>
      )}
    </>
  );
};
