import { BreadcrumbGroupProps } from '@amzn/awsui-components-react';

export const getForecastTemplateBreadcrumbItems = (
  businessGroupBaseBreadcrumbs: BreadcrumbGroupProps.Item[],
  currentBusinessGroupName?: string
): BreadcrumbGroupProps.Item[] => {
  if (!currentBusinessGroupName) {
    return businessGroupBaseBreadcrumbs;
  }

  return [
    ...businessGroupBaseBreadcrumbs,
    {
      text: 'Forecast Input',
      href: `/${currentBusinessGroupName}/forecast-input`
    }
  ];
};
