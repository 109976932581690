import { Box } from '@amzn/awsui-components-react/polaris';
import React from 'react';

interface ValueWithLabelProps {
  label: string;
  children: React.ReactNode;
}

const ValueWithLabel: React.FC<ValueWithLabelProps> = ({ label, children }) => (
  <div>
    <Box variant="awsui-key-label">{label}</Box>
    <div>{children}</div>
  </div>
);

export default ValueWithLabel;
