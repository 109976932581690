import { Button, Header, SpaceBetween, StatusIndicator, TableProps } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { logger } from 'src/analytics/KatalLogger';
import { getResources } from 'src/api/app-sync-services';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import XptWidgetTable from 'src/components/common/xpt-table/XptWidgetTable';
import { WidgetContainer } from '../WidgetContainer';
import { Resources } from 'src/models/AppContextModels';
import { ResourcesForm } from './ResourcesForm';
import { useAuth } from 'src/features/auth/AuthContextProvider';

interface ResourceWidgetProps {
  dataClassificationId?: number | null | undefined;
}

const ResourceWidget: React.FC<ResourceWidgetProps> = () => {
  const headerText = 'Resources';
  const userAuthDetails = useAuth();
  const enableEditButton = userAuthDetails.isAdmin || userAuthDetails.isDev;
  const [resources, setResources] = useState<Resources[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    const loadResources = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const fetchedResources = await getResources();
        setResources(fetchedResources);
      } catch (err: any) {
        setResources([]);
        setError('Failed to load resources');
        logger.error('Failed to load resources', err);
      } finally {
        setIsLoading(false);
      }
    };

    loadResources();
  }, []);

  const onCancelCreateContact = () => {
    setShowModal(false);
  };

  const onConfirmingCreateContact = (updatedResources: Resources[]) => {
    setResources(updatedResources);
    setShowModal(false);
  };

  const widgetHeader = () => {
    return (
      <Header
        variant="h2"
        actions={
          <SpaceBetween size="s" direction="horizontal">
            {enableEditButton && <Button onClick={() => setShowModal(true)}>{'Edit'}</Button>}
          </SpaceBetween>
        }
      >
        {headerText}
      </Header>
    );
  };

  return (
    <>
      <ResourcesForm
        showModal={showModal}
        onCancel={onCancelCreateContact}
        onConfirm={(updatedResources: Resources[]) => onConfirmingCreateContact(updatedResources)}
        currentResources={resources}
      />
      <WidgetContainer header={widgetHeader()}>
        {isLoading ? (
          <LoadingSpinner />
        ) : error ? (
          <StatusIndicator type="error"> {error}</StatusIndicator>
        ) : (
          <>
            <XptWidgetTable
              variant={'embedded'}
              loadingStatus={isLoading}
              allItems={resources}
              columnDefinitions={Resources_Header_Config()}
              hasPagination={false}
              itemsPerPage={5}
            />
          </>
        )}
      </WidgetContainer>
    </>
  );
};

export default ResourceWidget;

export const Resources_Header_Config = (): TableProps.ColumnDefinition<Resources>[] => {
  return [
    {
      id: 'resourceName',
      header: '',
      cell: (item: Resources) =>
        item.resource_link ? (
          <Button href={item.resource_link} iconAlign="right" iconName="external" target="_blank" variant="inline-link">
            {item.resource_name}
          </Button>
        ) : (
          <span>{item.resource_name}</span>
        )
    }
  ] as TableProps.ColumnDefinition<Resources>[];
};
