// AuthWrapper.tsx
import React from 'react';
import { KatSpinner } from '@amzn/katal-react';
import { useAuth } from '../auth/AuthContextProvider';
import AccessRestricted from './AccessRestricted';

const AuthWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { noAccess, userAuthDataLoadingStatus } = useAuth();

  if (userAuthDataLoadingStatus === 'loading') {
    return <KatSpinner size="large" />;
  }

  if (noAccess) {
    return <AccessRestricted />;
  }

  return <>{children}</>;
};

export default AuthWrapper;
