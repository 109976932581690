import React, { Suspense, lazy } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuditLog } from 'src/features/admin-console/audit-log/AuditLog';
import BudgetOwnerTransfer from 'src/features/admin-console/budget-owner-transfer/BudgetOwnerTransfer';
import { Capex } from 'src/features/admin-console/capex/Capex';
import { ExpenseTypeMapping } from 'src/features/admin-console/expense-type-mapping/ExpenseTypeMapping';
import { ProjectMapping } from 'src/features/admin-console/project-mapping/ProjectMapping';
import { UsefulLifeMapping } from 'src/features/admin-console/useful-life mapping/UsefulLifeMapping';
import { ForecastTemplateMainPage } from 'src/features/business-group/forecast-template-v2/ForecastTemplate';
import PlanningCycleHomePageNonAdmin from 'src/features/planning-cycle/PlanningCycleHomePageNonAdmin';
import { POTagging } from 'src/features/po-tagging/POTagging';
import AuthWrapper from 'src/features/xpt-layout/AuthWrapper';
import { XPTFooter } from 'src/features/xpt-layout/XPTFooter';
import XptHomePage from 'src/features/xpt-layout/XptHomePage';
import { XptForecastReport } from 'src/features/xpt-reports/XptForecastReport';
import { XptVarianceReport } from 'src/features/xpt-reports/XptVarianceReport';
import { ErrorFallback } from '../components/common/ErrorFallback';
import { LoadingSpinner } from '../components/common/LoadingSpinner';
import { PageNotFound } from '../components/common/PageNotFound';
import OnboardingForm from '../features/admin-console/onboarding-business-groups/OnboardingForm';
import OnboardingHomePage from '../features/admin-console/onboarding-business-groups/OnboardingHomePage';
import { XPTAdminOrDevWrapper, XPTBusinessLeaderOrAdminOrDevOnlyWrapper, XPTDevOnlyWrapper } from '../features/admin-console/XPTAdminWrapper';
import SnapshotsHomePage from '../features/business-group/planning-cycle-snapshot/SnapshotHomePage';
import PlanningCycleHomePageAdmin from '../features/planning-cycle/PlanningCycleHomePageAdmin';
import PlanningCycleManageFormAdmin from '../features/planning-cycle/PlanningCycleManageFormAdmin';
import { XPTGlobalTopNavigation } from '../features/top-navigation/XptGlobalTopNavigation';
import { XptApplicationLevelBanner } from './XptAppBanner';
import XPTBusinessGroupWrapper from './XPTBusinessGroupWrapper';

// lazy imports
const AdminConsole = lazy(() => import('../features/admin-console/AdminConsole'));

export const AppRouter: React.FC = () => {
  return (
    <div data-testid="app-router">
      <BrowserRouter>
        <XPTGlobalTopNavigation />
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Suspense fallback={<LoadingSpinner />}>
            <AuthWrapper>
              <XptApplicationLevelBanner />
              <Routes>
                {/* LandingPage is accessible to all types of users */}
                <Route path="/" element={<XptHomePage />} />

                {/* Only Admins, Developers, and Business Leaders can access Admin Console */}
                <Route path="/admin-console" element={<XPTBusinessLeaderOrAdminOrDevOnlyWrapper />}>
                  <Route index element={<AdminConsole />} />

                  {/* Only Admins or Dev can access these routes */}
                  <Route element={<XPTAdminOrDevWrapper />}>
                    <Route path="planning-cycle" element={<PlanningCycleHomePageAdmin />}>
                      <Route path="manage" element={<PlanningCycleManageFormAdmin />} />
                    </Route>
                  </Route>

                  {/* Admins or Developers can access these routes */}
                  <Route element={<XPTAdminOrDevWrapper />}>
                    <Route path="capex" element={<Capex />} />
                  </Route>

                  {/* Only Developers can access these routes */}
                  <Route path="audit-log" element={<XPTDevOnlyWrapper />}>
                    <Route index element={<AuditLog />} />
                  </Route>
                  <Route element={<XPTDevOnlyWrapper />}>
                    <Route path="onboarding" element={<OnboardingHomePage />}>
                      <Route path=":id" element={<OnboardingForm />} />
                    </Route>
                  </Route>

                  {/* common for Admins, Developers, and Business Leaders */}
                  <Route path="budget-owner-transfer" element={<BudgetOwnerTransfer />} />
                  <Route path="expense-type" element={<ExpenseTypeMapping />} />
                  <Route path="useful-life" element={<UsefulLifeMapping />} />
                  <Route path="project" element={<ProjectMapping />} />
                </Route>

                {/* Business Group routes with :businessGroupName parameter */}
                <Route path="/:businessGroupName" element={<XPTBusinessGroupWrapper />}>
                  <Route index element={<ForecastTemplateMainPage />} />

                  <Route element={<XPTBusinessLeaderOrAdminOrDevOnlyWrapper />}>
                    <Route path="planning-cycle" element={<PlanningCycleHomePageNonAdmin />}></Route>
                  </Route>

                  <Route element={<XPTBusinessLeaderOrAdminOrDevOnlyWrapper />}>
                    <Route path="snapshots" element={<SnapshotsHomePage />} />
                  </Route>

                  <Route path="forecast-input" element={<ForecastTemplateMainPage />} />
                  <Route path="po-tagging" element={<POTagging />} />
                  <Route path="forecast-report" element={<XptForecastReport />} />
                  <Route path="variance-report" element={<XptVarianceReport />} />
                </Route>

                {/* Redirect all 404's to PageNotFound */}
                <Route path="*" element={<PageNotFound />} />
              </Routes>
              <XPTFooter />
            </AuthWrapper>
          </Suspense>
        </ErrorBoundary>
      </BrowserRouter>
    </div>
  );
};
