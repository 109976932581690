import { AppLayout, AppLayoutProps, ContentLayout, ContentLayoutProps } from '@amzn/awsui-components-react';
import React, { forwardRef } from 'react';

export const XptAppLayout = forwardRef<AppLayoutProps.Ref, AppLayoutProps>((props, ref) => {
  return (
    <>
      <AppLayout ref={ref} {...props} />
    </>
  );
});
