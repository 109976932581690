import { RestAPI } from '@aws-amplify/api-rest';
import { UserValidationMessages } from 'src/constants/generic-constants';
import { PAPIEmployeeJobStatus, PAPIUserInfo, ValidateUserAliasResponse } from 'src/models/PeopleApiModel';

/**
 * Generates parameters for PAPI request.
 *
 * @param userAlias - The user alias to be validated.
 * @returns The parameters object for the PAPI request.
 */
const getPapiParams = (userAlias: string) => ({
  response: true,
  queryStringParameters: {
    login: userAlias,
    expandOptions: ''
  }
});

/**
 * Validates the user alias with PAPI.
 *
 * @param userAlias - The user alias to be validated.
 * @returns A promise that resolves to the validation response.
 */
export const validateUserAliasWithPAPI = async (userAlias: string): Promise<ValidateUserAliasResponse> => {
  try {
    const response = await RestAPI.get('PAPI', '/employee', getPapiParams(userAlias));
    const employeeBasicInfo: PAPIUserInfo = response.data.basicInfo;

    // Employee's job status response as "A" for active, "I" for inactive
    return {
      isValid: employeeBasicInfo.jobStatus === PAPIEmployeeJobStatus.Active,
      validationMessage:
        employeeBasicInfo.jobStatus === PAPIEmployeeJobStatus.Active ? UserValidationMessages.UserIsValid : UserValidationMessages.UserIsInactive,
      userInfo: {
        firstName: employeeBasicInfo.firstName,
        lastName: employeeBasicInfo.lastName
      }
    };
  } catch (error: any) {
    return { isValid: false, validationMessage: UserValidationMessages.UserAliasIsInvalid };
  }
};
