import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { logger } from 'src/analytics/KatalLogger';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import { useAuth } from 'src/features/auth/AuthContextProvider';
import { useRoleModifier } from 'src/features/auth/RoleModifier';
import { fetchBusinessGroups } from 'src/features/business-group/businessGroupSlice';
import { fetchForecastPlanningCycles, fetchPlanningCycles, fetchScenarios } from 'src/features/planning-cycle/planningCycleSlice';
import { BusinessGroupEntity } from 'src/models/AppContextModels';
import { fetchBusinessSegments, fetchCorpSegments } from 'src/store/slices/corpSegmentSlice';
import { AppDispatch } from '../store/store';
import { fetchEntityStatuses, fetchXptLDAPGroups } from './AppMetadataSlice';
import { useFlashMessage } from './XptAppBannerContext';

interface AppInitializerProps {
  children: React.ReactNode;
}

const AppInitializer: React.FC<AppInitializerProps> = ({ children }) => {
  const { userLDAPGroups } = useAuth();
  const { setBusinessLeader } = useRoleModifier();

  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const { addMessage } = useFlashMessage();

  useEffect(() => {
    const initializeApp = async () => {
      try {
        const response = await Promise.all([
          dispatch(fetchCorpSegments()), // Corp Segment Slice
          dispatch(fetchBusinessSegments()),
          dispatch(fetchEntityStatuses()), // App metadata store
          dispatch(fetchXptLDAPGroups()), // App metadata store
          dispatch(fetchBusinessGroups()), // Business Group Store
          dispatch(fetchPlanningCycles()), // Planning Cycle store
          dispatch(fetchForecastPlanningCycles()), // Planning Cycle store
          dispatch(fetchScenarios()) // Planning Cycle store
        ]);

        const businessGroups: BusinessGroupEntity[] = response[4].payload as unknown as BusinessGroupEntity[];
        // Extract the budget leader group names from all business groups
        const businessLeadersGroupNames = businessGroups.map((group) => group.business_leaders_group.group_name);
        
        // Determine if the user is part of at least 1 business leader groups
        const isBusinessLeader = businessLeadersGroupNames.some((groupName) => userLDAPGroups.includes(groupName));
        setBusinessLeader(isBusinessLeader);
        
      } catch (error: any) {
        logger.error('Failed to initialize the application:', error);
        addMessage({
          type: 'error',
          content: 'Failed to initialize the application. Some of the features might not work as expected.'
        });
      } finally {
        setIsInitialized(true);
      }
    };

    initializeApp();
  }, [dispatch]);

  if (!isInitialized) {
    return <LoadingSpinner />;
  }

  return <>{children}</>;
};

export default AppInitializer;
