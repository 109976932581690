import { Box, Flashbar, FlashbarProps } from '@amzn/awsui-components-react';
import React from 'react';

const WorkInProgress: React.FC = () => {
  const [items, setItems] = React.useState<FlashbarProps.MessageDefinition[]>([
    {
      type: 'info',
      content: 'Work in progress',
      dismissible: false,
      dismissLabel: 'Dismiss message',
      onDismiss: () => setItems([]),
      id: 'message_1'
    }
  ]);

  return (
    <Box margin={'m'} data-testid="work-in-progress-box">
      <Flashbar items={items} data-testid="work-in-progress-flashbar" />
    </Box>
  );
};

export default WorkInProgress;
