import { Box, Popover, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { ForecastTemplateDataValidationStatus } from 'src/models/ForecastModels';
import { RootState } from 'src/store/store';
import { ForecastTemplateDataStatuses } from './forecast-utils/ForecastGridConstants';
import { ValidationErrorDetail } from 'src/models/XptGenericModels';

const formatValidationErrorDetail = (errorDetail: ValidationErrorDetail): string => {
  // Currently, this function only returns the message. 
  // Keeping it here in case more complex formatting is needed in the future.
  return errorDetail.message;
};

const getValidationErrorMessages = (
  validationStatus: ForecastTemplateDataValidationStatus
): { message: string; defaultMessage: string; details: string[] }[] => {
  const errorMessages: { message: string; defaultMessage: string; details: string[] }[] = [];

  Object.keys(validationStatus).forEach((key) => {
    const status = validationStatus[key as keyof ForecastTemplateDataValidationStatus];
    if (status.validationStatus === 'error' && status.validationErrorDetails.length > 0) {
      const formattedErrors: string[] = status.validationErrorDetails.map(formatValidationErrorDetail);
      errorMessages.push({
        message: status.validationMessage,
        defaultMessage: status.validationDefaultMessage || '',
        details: formattedErrors
      });
    }
  });

  return errorMessages;
};

const ForecastTemplateErrorDisplay: React.FC = () => {
  const forecastDataValidation = useSelector((state: RootState) => state.forecastTemplateStore.forecastDataValidationStatus);
  const forecastTemplateState = useSelector((state: RootState) => state.forecastTemplateStore.forecastDataStatus);
  const forecastDataSubmitClickCount = useSelector((state: RootState) => state.forecastTemplateStore.forecastDataSubmitClickCount);
  const validationErrors = getValidationErrorMessages(forecastDataValidation);

  return (
    <SpaceBetween size="s" direction="horizontal">
      {forecastTemplateState.dataStatus.status === ForecastTemplateDataStatuses.Modified.status &&
      validationErrors.length > 0 &&
      forecastDataSubmitClickCount > 0
        ? validationErrors.map((error, index) => (
            <Popover
              key={index}
              dismissButton={false}
              triggerType="text"
              size="large"
              // header={error.message}
              content={
                <div className="popover-content">
                  <SpaceBetween size="xs" direction="vertical">
                    {error.defaultMessage && (
                      <div>
                        <strong>{error.defaultMessage}</strong>
                      </div>
                    )}

                    <Box padding={{ top: 's' }}>
                      {error.details.map((detail, index) => (
                        <Box key={index}>{detail}</Box>
                      ))}
                    </Box>
                  </SpaceBetween>
                </div>
              }
            >
              <StatusIndicator type="error" colorOverride="red">
                {error.message}
              </StatusIndicator>
            </Popover>
          ))
        : forecastTemplateState.dataStatus.status === ForecastTemplateDataStatuses.Modified.status && (
            <StatusIndicator type={forecastTemplateState.dataStatus.statusType} colorOverride={forecastTemplateState.dataStatus.statusColor}>
              {forecastTemplateState.dataStatus.statusDisplayName}
            </StatusIndicator>
          )}
    </SpaceBetween>
  );
};

export default ForecastTemplateErrorDisplay;
