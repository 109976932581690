import { Box } from '@amzn/awsui-components-react';
import { faPalette } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomToolPanelProps } from 'ag-grid-react';
import React from 'react';

// style for the legend items
const legendStyle = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px'
};

const colorDot = (color: string) => ({
  height: '15px',
  width: '15px',
  backgroundColor: color,
  borderRadius: '50%',
  marginRight: '10px'
});

export interface ForecastTemplateLegendProps extends CustomToolPanelProps {
  title: string;
}

// TODO - Work in progress
export const ForecastTemplateLegend: React.FC<ForecastTemplateLegendProps> = ({ title }) => {
  return (
    <Box padding="s">
      <div style={{ padding: '10px' }}>
        {/* Legend for Informative fields */}
        <div style={legendStyle}>
          <span style={colorDot('#BDD7EE')} /> <span>Informative fields</span>
        </div>

        {/* Legend for Read-only fields */}
        <div style={legendStyle}>
          <span style={colorDot('#F4CCCC')} /> <span>Read-only fields</span>
        </div>

        {/* Legend for Error cells */}
        <div style={legendStyle}>
          <span style={colorDot('#E06666')} /> <span>Error cells</span>
        </div>

        {/* Legend for Editable fields */}
        <div style={legendStyle}>
          <span style={colorDot('#D9EAD3')} /> <span>Editable fields</span>
        </div>

        {/* Legend for Aggregation fields */}
        <div style={legendStyle}>
          <span style={colorDot('#FFE599')} /> <span>Aggregation fields</span>
        </div>
      </div>
    </Box>
  );
};

export const ColorLegendIcon: React.FC = () => {
  return <FontAwesomeIcon icon={faPalette} />;
};
