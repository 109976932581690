import { Flashbar } from '@amzn/awsui-components-react';
import React from 'react';
import { useFlashMessage } from './XptAppBannerContext';

export const XptApplicationLevelBanner: React.FC = () => {
  const { messages, removeMessage } = useFlashMessage();

  const flashbarItems = messages.map((message) => ({
    ...message,
    onDismiss: () => removeMessage(message.id)
  }));

  return <Flashbar items={flashbarItems} stackItems={true} />;
};
