import { Modal, Button, SpaceBetween, Box } from '@amzn/awsui-components-react';
import React from 'react';

interface ConfirmDiscardModalProps {
  visible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmDiscardModal: React.FC<ConfirmDiscardModalProps> = ({ visible, onConfirm, onCancel }) => {
  return (
    <Modal
      visible={visible}
      onDismiss={onCancel}
      header="Unsaved Changes"
      footer={
        <Box float="right">
          <SpaceBetween size="s" direction="horizontal">
            <Button variant="normal" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onConfirm}>
              Proceed
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      {`You have unsaved changes. Do you want to discard them and proceed?`}
    </Modal>
  );
};
