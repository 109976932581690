import { GetSecretValueCommand, GetSecretValueResponse, SecretsManagerClient, SecretsManagerClientConfig } from '@aws-sdk/client-secrets-manager';
import { getAWSServiceCredentials } from './auth-token-services';
import { eSecretManagerKeys } from 'src/constants/generic-constants';
import ENVIRONMENT_VARIABLES from 'src/constants/environment-variables';
import { retrieveParameterFromSSM } from './ssm-parameter';

/**
 * Returns the Amplify authentication configuration.
 * @returns The Amplify authentication configuration.
 */
export const getAmplifyAuthConfig = () => {
  return {
    Auth: {
      region: ENVIRONMENT_VARIABLES.env.Region,
      userPoolId: ENVIRONMENT_VARIABLES.env.CognitoUserPoolId,
      userPoolWebClientId: ENVIRONMENT_VARIABLES.env.CognitoUserPoolWebClientId,
      identityPoolId: ENVIRONMENT_VARIABLES.env.CognitoIdentityPoolId,
      awsAccountId: ENVIRONMENT_VARIABLES.env.AwsAccountId,
      mandatorySignIn: true,
      authenticationFlowType: 'USER_SRP_AUTH',
      oauth: {
        domain: ENVIRONMENT_VARIABLES.env.CognitoDomain,
        scope: ['openid'],
        redirectSignIn: ENVIRONMENT_VARIABLES.env.ApplicationDomain,
        redirectSignOut: ENVIRONMENT_VARIABLES.env.ApplicationDomain,
        responseType: 'code'
      }
    }
  };
};

// Applicable to only for Dev environment (dev).
const APP_SYNC_ENDPOINT_SECRET_FOR_DEV = 'arn:aws:secretsmanager:us-west-2:631849255022:secret:xpt-app-api-secret-dev-qo2qX7';

// Applicable to all other environments (alpha, beta & prod)
const APP_SYNC_ENDPOINT_PARAMETER = '/xpt-fe/xpt-appsync-endpoint';

/**
 * Retrieves the AppSync endpoint information for the development environment.
 * @returns A Promise that resolves to the AppSync endpoint information or undefined if not found.
 */
export const retrieveDevAppSyncEndpointInfo = async (): Promise<GetSecretValueResponse> => {
  const secretsManageClientConfig: SecretsManagerClientConfig = {
    region: ENVIRONMENT_VARIABLES.env.Region,
    credentials: await getAWSServiceCredentials()
  };

  const secretsManagerClient = new SecretsManagerClient(secretsManageClientConfig);
  const command = new GetSecretValueCommand({ SecretId: APP_SYNC_ENDPOINT_SECRET_FOR_DEV });

  return await secretsManagerClient.send(command);
};

/**
 * Retrieves the AppSync configuration based on the environment stage.
 * @returns A Promise that resolves to the AppSync configuration.
 */
export const getAppSyncConfig = async () => {
  const { AuthenticationType, Stage, Region } = ENVIRONMENT_VARIABLES.env;

  try {
    let graphQlAPI: string = '';
    let graphQlAPIKey: string | undefined = undefined;

    if (Stage === 'dev') {
      // Retrieve API secrets for the development environment
      const apiSecrets = (await retrieveDevAppSyncEndpointInfo())?.SecretString;
      if (apiSecrets) {
        const parsedSecrets = JSON.parse(apiSecrets);
        graphQlAPI = parsedSecrets[eSecretManagerKeys.XPT_APPSYNC_API] || '';
        graphQlAPIKey = parsedSecrets[eSecretManagerKeys.XPT_DEV_API_KEY] || '';
      }
    } else {
      // Retrieve API endpoint from SSM (Parameter Store) for non-development environments
      graphQlAPI = await retrieveParameterFromSSM(APP_SYNC_ENDPOINT_PARAMETER);
    }

    // Return the AppSync configuration
    return {
      Appsync: {
        aws_appsync_region: Region,
        aws_appsync_authenticationType: AuthenticationType,
        aws_appsync_graphqlEndpoint: graphQlAPI,
        aws_appsync_apiKey: graphQlAPIKey
      }
    };
  } catch (error) {
    console.error('Unable to retrieve AppSync configuration:', error);
    throw error;
  }
};

/**
 * Determines the PAPI endpoint based on the environment stage.
 * @param stage The current environment stage.
 * @returns The API endpoint as a string.
 */
const getApiEndpoint = (stage: string): string => {
  if (stage === 'prod') {
    return 'https://ad3papi.device.finance.amazon.dev';
  }
  return 'https://beta.ad3papi.device.finance.amazon.dev';
};

/**
 * Retrieves the PAPI configuration based on the environment stage.
 * @returns An object containing the PAPI configuration.
 */
export const getApiConfig = (): { endpoints: { name: string; endpoint: string }[] } => {
  const { Stage } = ENVIRONMENT_VARIABLES.env;
  const apiEndpoint = getApiEndpoint(Stage);

  return {
    endpoints: [
      {
        name: 'PAPI',
        endpoint: apiEndpoint
      }
    ]
  };
};
