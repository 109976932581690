import { CellClassParams, ColDef, ValueFormatterParams, ValueParserParams } from 'ag-grid-community';
import { logger } from 'src/analytics/KatalLogger';
import { BusinessGroupEntity } from 'src/models/AppContextModels';
import { ROLL_UP_PERIOD } from 'src/models/XptReportingModels';
import { CellDataType, cellValueParser, forecastCurrencyFormatter } from 'src/utils/ag-grid-utils';
import { generateBusinessGroupColumns, generateCorpSegmentsRequiredColumns } from './XptReportColumnGenerator';
import { ColumnWidths, XptReportGridFixedFields } from './XptReportGridConstants';

export const generateVarianceReportColumnDefinitions = async (
  businessGroup: BusinessGroupEntity,
  variancePeriods: string[],
  rollupPeriod: ROLL_UP_PERIOD
): Promise<ColDef[]> => {
  try {
    const requiredCorpSegments = businessGroup.corp_segments.filter((corpSegment) => corpSegment.corp_segment_required);
    const businessSegments = businessGroup.business_segments;

    return [
      ...generateInitialColumns(),
      ...generateCorpSegmentsRequiredColumns(requiredCorpSegments),
      ...generateBusinessGroupColumns(businessSegments),
      ...generatePeriodColumnDefinitions(variancePeriods, rollupPeriod)
    ];
  } catch (error: any) {
    logger.error('Unable to generate variance column definition', error);
    throw new Error('Unable to generate column definition');
  }
};

export const generateInitialColumns = (): ColDef[] => {
  return [
    {
      field: XptReportGridFixedFields.XptLineItemSeqId.value,
      headerName: XptReportGridFixedFields.XptLineItemSeqId.displayName,
      wrapHeaderText: true,
      hide: true,
      suppressColumnsToolPanel: true,
      cellClass: ['text-field'],
      cellDataType: CellDataType.TEXT,
      enableRowGroup: false,
      enablePivot: false
    }
  ];
};

export const generatePeriodColumnDefinitions = (variancePeriods: string[], rollupPeriod: ROLL_UP_PERIOD): ColDef[] => {
  return variancePeriods.map((period) => createPeriodColumn(period, rollupPeriod));
};

export const createPeriodColumn = (period: string, rollupPeriod: ROLL_UP_PERIOD): ColDef => {
  return {
    field: period,
    headerName: generateReadableHeader(period),
    wrapHeaderText: true,
    width: ColumnWidths.VARIANCE_PERIOD_COLUMN,
    minWidth: ColumnWidths.VARIANCE_PERIOD_COLUMN,
    suppressMovable: true,
    cellDataType: CellDataType.NUMBER,
    cellClass: ['forecast-month-cell'],
    cellClassRules: {
      'bold-text': (params: CellClassParams) => params.node.footer || false
    },
    cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellEditor: 'agNumberCellEditor',
    valueParser: (params: ValueParserParams) => cellValueParser(params.newValue),
    valueFormatter: (params: ValueFormatterParams) => forecastCurrencyFormatter(params.value),
    aggFunc: 'sum',
    enableValue: true
  };
};

// Replace multiple _ in the period
export const generateReadableHeader = (period: string) => {
  return period.replace(/_/g, ' ');
}
