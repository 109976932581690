import { Box, Button, Checkbox, CollectionPreferences, PropertyFilterProps, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';

interface TableEmptyStateProps {
  resourceName?: string;
}

export const TableEmptyState: React.FC<TableEmptyStateProps> = ({ resourceName }) => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <Box variant="p" color="inherit">
        <b>{resourceName ? `No ${resourceName.toLowerCase()}` : `No data`}</b>
      </Box>
    </SpaceBetween>
  </Box>
);

interface TableNoMatchStateProps {
  onClearFilter: () => void;
}

export const TableNoMatchState: React.FC<TableNoMatchStateProps> = ({ onClearFilter }) => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No matches</b>
        <Box variant="p" color="inherit">
          We can't find a match.
        </Box>
      </div>
      <Button onClick={onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

export const BLANK_SEARCH_OR = { tokens: [], operation: 'or' } as PropertyFilterProps.Query;
export const BLANK_SEARCH_AND = { tokens: [], operation: 'and' } as PropertyFilterProps.Query;

export function getMatchesCountText(count: number) {
  return count === 1 ? `1 match` : `${count} matches`;
}

/* table page default size */
export const DEFAULT_PAGE_SIZE = 100;

/* table page default large size */
export const DEFAULT_LARGE_PAGE_SIZE = 200;

/* table page size options */
export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 items' },
  { value: 20, label: '20 items' },
  { value: 30, label: '30 items' },
  { value: 50, label: '50 items' },
  { value: 100, label: '100 items' },
  { value: 200, label: '200 items' }
];

interface XptTablePreferencesProps {
  preferences: any;
  setPreferences: (preferences: any) => void;
  visibleContentOptions: any;
  disabled?: boolean;
  pageSizeOptions?: any;
  hasCustomPreference?: boolean;
}

export const XptTablePreferences: React.FC<XptTablePreferencesProps> = ({
  preferences,
  setPreferences,
  visibleContentOptions,
  disabled = false,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  hasCustomPreference = true
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: 'Page size',
      options: pageSizeOptions
    }}
    wrapLinesPreference={{
      label: 'Wrap lines',
      description: 'Check to see all the text and wrap the lines'
    }}
    stripedRowsPreference={{
      label: 'Striped rows',
      description: 'Check to add alternating shaded rows'
    }}
    visibleContentPreference={{
      title: 'Select visible columns',
      options: visibleContentOptions
    }}
    {...(hasCustomPreference
      ? {
          customPreference: (checked, setChecked) => (
            <Checkbox
              onChange={({ detail }) => {
                setChecked(detail.checked);
              }}
              checked={checked}
            >
              Resizable Columns
            </Checkbox>
          )
        }
      : {})}
  />
);

export const XptTablePreferencesWithoutPagination = ({ preferences, setPreferences, disabled, visibleContentOptions }: any) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    wrapLinesPreference={{
      label: 'Wrap lines',
      description: 'Check to see all the text and wrap the lines'
    }}
    stripedRowsPreference={{
      label: 'Striped rows',
      description: 'Check to add alternating shaded rows'
    }}
    visibleContentPreference={{
      title: 'Select visible columns',
      options: visibleContentOptions
    }}
    customPreference={(checked, setChecked) => (
      <Checkbox
        onChange={({ detail }) => {
          setChecked(detail.checked);
        }}
        checked={checked}
      >
        Resizable Columns
      </Checkbox>
    )}
  />
);
