import { ButtonDropdownProps } from '@amzn/awsui-components-react';
import { AdvancedFilterModel, ColumnState, FilterModel, GridApi } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { RefObject, useCallback } from 'react';

export const TABLE_VIEW_ACTIONS = [
  {
    text: 'Table View',
    items: [
      {
        id: 'save-view',
        text: 'Save current view'
      },
      {
        id: 'reset-view',
        text: 'Reset to default view'
      }
    ]
  }
];

interface GridState {
  columnState: ColumnState[];
  columnGroupState: {
    groupId: string;
    open: boolean;
  }[];
  filterState: FilterModel;
  advancedFilterState: AdvancedFilterModel | null;
}

export const useGridState = (gridApiRef: RefObject<AgGridReact>, key: string) => {
  const atLeastOneStateAvailable = useCallback(() => {
    const stateJSON = localStorage.getItem(key);
    return !!stateJSON; // Returns true if there is any saved state, false otherwise
  }, [gridApiRef, key]);

  const saveGridState = useCallback(() => {
    const gridApi: GridApi | undefined = gridApiRef?.current?.api;
    if (!gridApi) return;

    try {
      const gridState: GridState = {
        columnState: gridApi.getColumnState(),
        columnGroupState: gridApi.getColumnGroupState(),
        filterState: gridApi.getFilterModel(),
        advancedFilterState: gridApi.getAdvancedFilterModel()
      };
      localStorage.setItem(key, JSON.stringify(gridState));
      // console.debug('Grid state saved to localStorage');
    } catch (error) {
      console.error('Failed to save grid state:', error);
    }
  }, [gridApiRef, key]);

  const restoreGridState = useCallback(() => {
    const gridApi: GridApi | undefined = gridApiRef?.current?.api;
    if (!gridApi) return;

    try {
      const stateJSON = localStorage.getItem(key);
      if (stateJSON) {
        // console.debug('Restoring grid state from localStorage');
        const gridState: GridState = JSON.parse(stateJSON);
        gridApi.applyColumnState({ state: gridState.columnState, applyOrder: true });

        // Explicitly setting column widths is necessary
        // because they are not automatically restored from the saved state.
        gridState.columnState.forEach((state) => {
          if (state.width) {
            // console.debug(`Setting column width for ${state.colId}: ${state.width}`);
            // updating this according to latest ag-grid versions
            gridApi.setColumnWidths([{ key: state.colId, newWidth: state.width }], true);
          }
        });

        gridApi.setColumnGroupState(gridState.columnGroupState);
        gridApi.setFilterModel(gridState.filterState);
        gridApi.setAdvancedFilterModel(gridState.advancedFilterState);
      }
    } catch (error) {
      console.error('Failed to restore grid state:', error);
    }
  }, [gridApiRef, key]);

  const clearGridState = useCallback(() => {
    localStorage.removeItem(key);
  }, [key]);

  return { atLeastOneStateAvailable, saveGridState, restoreGridState, clearGridState };
};
