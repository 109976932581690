import {
  Alert,
  Autosuggest,
  Box,
  Button,
  Checkbox,
  ContentLayout,
  ExpandableSection,
  FormField,
  Header,
  Input,
  Select,
  SpaceBetween,
  StatusIndicator,
  Textarea
} from '@amzn/awsui-components-react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { logger } from 'src/analytics/KatalLogger';
import { selectAllLdapGroups } from 'src/app/AppMetadataSelector';
import { ErrorFallback } from 'src/components/common/ErrorFallback';
import FormFieldPopover from 'src/components/common/FormFieldPopover';
import { eCorpSegmentNames, isMandatoryCorpSegment } from 'src/constants/corp-segment-constants';
import { eEntityStatus, eErrorMessages } from 'src/constants/generic-constants';
import XptMessages from 'src/constants/xpt-messages';
import { useAuth } from 'src/features/auth/AuthContextProvider';
import { selectAllBusinessGroups } from 'src/features/business-group/businessGroupSelectors';
import { submitBusinessGroups } from 'src/features/business-group/businessGroupSlice';
import { selectActivePlanningCycles } from 'src/features/planning-cycle/planningCycleSelector';
import { atLeastOnePlanningCycleIsActive } from 'src/features/planning-cycle/planningCycleUtils';
import {
  BusinessGroupEntity,
  BusinessSegmentDataTypes,
  BusinessSegmentsEntity,
  CorpSegmentsValueEntity,
  DataClassification,
  LDAPGroupInformation,
  SegmentHierarchy
} from 'src/models/AppContextModels';
import { LoadingStatus } from 'src/models/AuthContextModels';
import { useAppDispatch } from 'src/store/useAppDispatch';
import { uploadToS3 } from 'src/utils/aws-s3-services';
import { fetchNewItemMetadata, fetchUpdatedItemMetadata, stringToSingleSelectDropdown } from 'src/utils/generic-utilities';
import { getOnboardSegmentBucketName } from 'src/utils/xpt-s3-bucket-details';
import { v4 as uuidv4 } from 'uuid';
import { LoadingSpinner } from '../../../components/common/LoadingSpinner';
import { DataClassificationNameAndShortDesc, OnboardingFormEntity } from '../../../models/OnboardingModel';
import CorpSegmentHierarchySelectionModal, { SelectedSegmentDetails } from './CorpSegmentHierarchySelection';
import * as OnboardingFormUtils from './OnboardingFormUtils';
import { useOnboardingContext } from './OnboardingHomePage';
import { masterBusinessSegments, masterCorpSegments, masterCorpSegmentStatus } from 'src/store/selectors/corpSegmentSelectors';

const OnboardingForm: React.FC = () => {
  const userAuthDetails = useAuth();
  const dispatch = useAppDispatch();

  const onboardingContext = useOnboardingContext();

  const { id: paramDataClassificationId } = useParams();
  const navigate = useNavigate();

  const [currentDataClassificationNamesAndShortDesc, setCurrentDataClassificationNamesAndShortDesc] = useState<DataClassificationNameAndShortDesc[]>(
    []
  );

  const [isPlanningCycleActiveForDataClassification, setIsPlanningCycleActiveForDataClassification] = useState(false);

  const onboardingFormRef = useRef<FormikProps<OnboardingFormEntity>>(null);
  const [formAction, setFormAction] = useState<'Create' | 'Update'>('Create');

  const [currentOnboardingBusinessGroupEntity, setCurrentOnboardingBusinessGroupEntity] = useState<BusinessGroupEntity>({} as BusinessGroupEntity);
  const [initialFormData, setInitialFormData] = useState<OnboardingFormEntity>(OnboardingFormUtils.INITIAL_DATA);
  const [formInitializationStatus, setFormInitializationStatus] = useState<boolean>(true);

  const [showSegmentModal, setShowSegmentModal] = useState(false);
  const [selectedSegmentDetails, setSelectedSegmentDetails] = useState<SelectedSegmentDetails>({} as SelectedSegmentDetails);

  const ldapGroups = useSelector(selectAllLdapGroups);
  const businessGroups = useSelector(selectAllBusinessGroups);
  const activePlanningCycles = useSelector(selectActivePlanningCycles);

  const masterCorpSegmentLoadingStatus = useSelector(masterCorpSegmentStatus);
  const masterCorpSegmentsData = useSelector(masterCorpSegments);
  const masterBusinessSegmentsData = useSelector(masterBusinessSegments);

  useEffect(() => {
    if (masterCorpSegmentLoadingStatus.status === LoadingStatus.Failed) {
      logger.error(masterCorpSegmentLoadingStatus.messageForError);
      onboardingContext.notificationMessage(XptMessages.ONBOARDING_INITIALIZATION_ERROR, 'error', true);
      setFormInitializationStatus(false);
    } else if (masterCorpSegmentLoadingStatus.status === LoadingStatus.Completed) {
      loadInitialOnboardingForm();
    }
  }, [masterCorpSegmentLoadingStatus]);

  // Main function to load initial onboarding form
  const loadInitialOnboardingForm = async () => {
    setFormInitializationStatus(true);
    if (paramDataClassificationId !== 'new') {
      const dataClassificationId = Number(paramDataClassificationId);

      // Check if the conversion to number is valid
      if (isNaN(dataClassificationId)) {
        logger.error(`${XptMessages.ONBOARDING_INVALID_DATA_CLASSIFICATION_ID}: ${paramDataClassificationId}`);
        onboardingContext.notificationMessage(XptMessages.ONBOARDING_INVALID_DATA_CLASSIFICATION_ID, 'error', true);
        navigate('/admin-console/onboarding');
        return;
      }

      const dataClassificationFound = businessGroups.find(
        (dataClassification) => dataClassification.data_classification.data_classification_id === dataClassificationId
      );

      if (dataClassificationFound) {
        const foundSome = atLeastOnePlanningCycleIsActive(dataClassificationId, activePlanningCycles);
        setIsPlanningCycleActiveForDataClassification(foundSome);
        await loadExistingBusinessGroup(dataClassificationId);
      } else {
        navigate('/admin-console/onboarding');
      }
    } else {
      initializeNewBusinessGroup();
    }
  };

  // Function to load existing business group to Form
  const loadExistingBusinessGroup = async (dataClassificationId: number) => {
    try {
      setFormAction('Update');

      // Ensures a matching BusinessGroupEntity is always retrieved.
      // Utilizes the non-null assertion (!) as we guarantee the existence of a match for the given dataClassificationId.
      const businessGroupResponse: BusinessGroupEntity = businessGroups.find(
        (businessGroup) => businessGroup.data_classification.data_classification_id === dataClassificationId
      )!;
      const dataClassificationNamesExceptSelected = OnboardingFormUtils.getDataClassificationNamesAndShortDescExceptSelected(
        businessGroups,
        dataClassificationId
      );

      const existingBusinessGroupAsFormData = await OnboardingFormUtils.getUpdatedOnboardingFormData(businessGroupResponse);
      setInitialFormData(existingBusinessGroupAsFormData);
      setCurrentDataClassificationNamesAndShortDesc(dataClassificationNamesExceptSelected);
      setCurrentOnboardingBusinessGroupEntity(businessGroupResponse);

      if (!existingBusinessGroupAsFormData.item_metadata.is_active) {
        onboardingContext.notificationMessage(XptMessages.ONBOARDING_ACTIVATE_BUSINESS_GROUPS, 'info', true);
      }
    } catch (error: any) {
      logger.error(`Unable to fetch Business Group Id - ${dataClassificationId} details to initialize form.`, error);
      onboardingContext.notificationMessage('Unable to fetch Business Group details.', 'error', true);
      navigate('/admin-console/onboarding');
    } finally {
      setFormInitializationStatus(false);
    }
  };

  // Function to initialize form for new business group
  const initializeNewBusinessGroup = async () => {
    try {
      setFormAction('Create');
      setCurrentOnboardingBusinessGroupEntity({} as OnboardingFormEntity);
      const listOfAllDataClassificationNames = OnboardingFormUtils.getAllDataClassificationNamesAndShortDesc(businessGroups);
      setCurrentDataClassificationNamesAndShortDesc(listOfAllDataClassificationNames);
      const newOnboardingFormData: OnboardingFormEntity = await OnboardingFormUtils.getNewOnboardingFormData(
        masterCorpSegmentsData,
        fetchNewItemMetadata(userAuthDetails.Alias)
      );
      setInitialFormData(newOnboardingFormData);
    } catch (error: any) {
      logger.error(XptMessages.ONBOARDING_UNABLE_TO_FETCH, error);
      onboardingContext.notificationMessage(XptMessages.ONBOARDING_UNABLE_TO_FETCH, 'error', true);
    } finally {
      setFormInitializationStatus(false);
    }
  };

  const updateOnboardingEntityStatus = (
    values: OnboardingFormEntity | undefined,
    userAuthDetails: any,
    currentEntity: BusinessGroupEntity,
    isActivating: boolean
  ): BusinessGroupEntity | undefined => {
    if (!values) {
      // Handling the undefined case
      return undefined;
    }

    const updatedCorpSegments = values?.corp_segments.map(({ corp_segment_values_s3_data_fetched, ...rest }) => rest);

    return {
      ...values,
      corp_segments: updatedCorpSegments,
      status: eEntityStatus.InProgress,
      item_metadata: {
        ...fetchUpdatedItemMetadata(userAuthDetails.Alias, currentEntity.item_metadata),
        is_active: isActivating ? !values.item_metadata?.is_active : values.item_metadata?.is_active
      }
    };
  };

  const updateBusinessGroupStatus = async () => {
    onboardingFormRef?.current?.setSubmitting(true);
    const inProgressMessageId = uuidv4();

    try {
      onboardingContext.notificationMessage(eErrorMessages.API_REQUEST_IN_PROGRESS, 'in-progress', false, inProgressMessageId);
      const updatedOnboardingEntity = updateOnboardingEntityStatus(
        onboardingFormRef?.current?.values,
        userAuthDetails,
        currentOnboardingBusinessGroupEntity,
        true
      );

      if (!updatedOnboardingEntity) {
        throw new Error('Onboarding entity data is undefined');
      }

      await dispatch(submitBusinessGroups(updatedOnboardingEntity)).unwrap();
      const successMessage = generateSuccessMessage(
        formAction,
        onboardingFormRef?.current?.values?.data_classification?.data_classification_name || ''
      );
      logger.info(successMessage);
      onboardingContext.notificationMessage(successMessage, 'success', true);
      closeAndNavigate();
    } catch (error: any) {
      handleUpdateError(error);
    } finally {
      onboardingContext.clearSpecificFlashMessage(inProgressMessageId); // Clear the in-progress message
      onboardingFormRef?.current?.setSubmitting(false);
    }
  };

  const closeAndNavigate = () => {
    onboardingContext?.reloadBusinessGroups();
    navigate('/admin-console/onboarding');
  };

  const handleUpdateError = (error: any) => {
    const errorMessage = generateErrorMessage(formAction, onboardingFormRef?.current?.values?.data_classification?.data_classification_name || '');
    logger.error(errorMessage, { error });
    onboardingContext.notificationMessage(errorMessage, 'error', true);
  };

  const generateSuccessMessage = (action: string, dataClassificationName: string) =>
    `Successfully ${action.toLowerCase()}d ${dataClassificationName}`;

  const generateErrorMessage = (action: string, dataClassificationName: string) => `Unable to ${action.toLowerCase()} ${dataClassificationName}`;

  // Utility function to generate folder key for S3
  const generateFolderKey = (dataClassification: DataClassification, corpSegmentKey: string): string => {
    const { data_classification_short_description } = dataClassification;
    return `business_group_onboarding_data/${data_classification_short_description}/corp-segments/${corpSegmentKey}.json`;
  };

  // Function to upload corp segment values to S3 and return the updated segments
  const uploadCorpSegmentsToS3 = async (
    corpSegments: CorpSegmentsValueEntity[],
    formValues: OnboardingFormEntity
  ): Promise<CorpSegmentsValueEntity[]> => {
    const uploadPromises = corpSegments.map(async (corpSegment) => {
      if (corpSegment.corp_segment_name === eCorpSegmentNames.COST_CENTER) {
        const bucketName = getOnboardSegmentBucketName();
        const folder_key = generateFolderKey(formValues.data_classification, corpSegment.corp_segment_name_key);
        const uploadedToS3Path = `s3://${bucketName}/${folder_key}`;
        await uploadToS3(bucketName, folder_key, corpSegment.corp_segment_values_s3_data_fetched, true);
        return { ...corpSegment, corp_segment_values_s3_path: uploadedToS3Path };
      } else {
        return {
          ...corpSegment,
          corp_segment_values_s3_path: masterCorpSegmentsData.find((masterCorp) => masterCorp.segment_name === corpSegment.corp_segment_name)
            ?.segment_hierarchy_s3_path!
        };
      }
    });

    return Promise.all(uploadPromises);
  };

  // Function to prepare the onboardingSubmitModel
  const prepareOnboardingSubmitModel = (
    formValues: OnboardingFormEntity,
    corpSegments: CorpSegmentsValueEntity[],
    formAction: string,
    userAuthDetails: any,
    currentOnboardingEntity: BusinessGroupEntity
  ): BusinessGroupEntity => {
    const updatedCorpSegments = corpSegments.map(({ corp_segment_values_s3_data_fetched, ...rest }) => rest);

    return {
      ...formValues,
      corp_segments: updatedCorpSegments,
      status: eEntityStatus.InProgress,
      item_metadata:
        formAction === 'Create'
          ? fetchNewItemMetadata(userAuthDetails.Alias)
          : fetchUpdatedItemMetadata(userAuthDetails.Alias, currentOnboardingEntity?.item_metadata)
    };
  };

  // Function to handle the GraphQL mutation

  // The main handleSubmit function
  const handleSubmit = async (formValues: OnboardingFormEntity, formikHelpers: FormikHelpers<OnboardingFormEntity>) => {
    const inProgressMessageId = uuidv4();
    onboardingContext.notificationMessage('Request in progress', 'in-progress', false, inProgressMessageId);

    try {
      const updatedCorpSegments = await uploadCorpSegmentsToS3(formValues.corp_segments, formValues);
      const onboardingSubmitModel = prepareOnboardingSubmitModel(
        formValues,
        updatedCorpSegments,
        formAction,
        userAuthDetails,
        currentOnboardingBusinessGroupEntity
      );

      await dispatch(submitBusinessGroups(onboardingSubmitModel)).unwrap();
      const successMessage = `Successfully ${formAction?.toLowerCase()}d ${formValues?.data_classification?.data_classification_name}`;
      logger.info(successMessage);
      onboardingContext.notificationMessage(successMessage, 'success', true);
      formikHelpers.setSubmitting(false);
      onboardingContext?.reloadBusinessGroups();
      navigate('/admin-console/onboarding');
    } catch (error: any) {
      formikHelpers.setSubmitting(false);
      const errorMessage = `Unable to ${formAction?.toLowerCase()} ${formValues?.data_classification?.data_classification_name}`;
      logger.error(errorMessage, { error: error });
      onboardingContext.notificationMessage(errorMessage, 'error', true);
    } finally {
      onboardingContext.clearSpecificFlashMessage(inProgressMessageId);
    }
  };

  // Function to handle common logic for onClick actions
  const handleSegmentClick = (corpSegment: CorpSegmentsValueEntity, isDefaultValue: boolean, isReadOnly: boolean) => {
    const segmentHierarchialValues = masterCorpSegmentsData.find((segment) => segment.segment_name === corpSegment.corp_segment_name);

    if (segmentHierarchialValues) {
      const corpSegmentHierarchialData = isDefaultValue
        ? OnboardingFormUtils.reformatSegmentsHierarchy([corpSegment.corp_segment_default_value])
        : OnboardingFormUtils.reformatSegmentsHierarchy(corpSegment.corp_segment_values_s3_data_fetched);

      setSelectedSegmentDetails({
        isDefaultValue,
        isReadOnly,
        modalHeader: corpSegment.corp_segment_name,
        selectionType: isDefaultValue ? 'single' : 'multiple',
        groupingColumnHeaderName: corpSegment.corp_segment_name,
        segmentS3Path: segmentHierarchialValues.segment_hierarchy_s3_path,
        segmentHierarchialData: corpSegmentHierarchialData
      });

      setShowSegmentModal(true);
    } else {
      onboardingContext.notificationMessage(`Unable to find Corp Segment '${corpSegment.corp_segment_name}' information.`, 'error', true);
    }
  };

  // Click of "Default Value" in Corp Segments
  const onClickOfDefaultValueSegment = (corpSegment: CorpSegmentsValueEntity) => {
    handleSegmentClick(corpSegment, true, false); // setting isReadOnly = false, to make it editable even while planning cycle is active.
  };

  // Click of "Values" in Corp Segments
  const onClickOfSegment = (corpSegment: CorpSegmentsValueEntity) => {
    handleSegmentClick(corpSegment, false, corpSegment.corp_segment_name !== eCorpSegmentNames.COST_CENTER); // setting isReadOnly = false, to make it editable even while planning cycle is active.
  };

  // Function to update a specific corp segment field
  const updateCorpSegmentField = (isDefaultValue: boolean, groupingColumn: string, selectedSegments: SegmentHierarchy[]) => {
    const fieldPath = isDefaultValue ? 'corp_segment_default_value' : 'corp_segment_values_s3_data_fetched';
    const fieldValue = isDefaultValue ? selectedSegments[0] : selectedSegments;

    onboardingFormRef?.current?.values?.corp_segments?.forEach((corpSegment, index) => {
      if (corpSegment.corp_segment_name === groupingColumn) {
        onboardingFormRef?.current?.setFieldTouched(`corp_segments[${index}].${fieldPath}`, true, true);
        onboardingFormRef?.current?.setFieldValue(`corp_segments[${index}].${fieldPath}`, fieldValue, true);
      }
    });
  };

  // On confirmation of "Values" or "Default Value" in Corp Segments
  const onConfirmOfSegmentHierarchy = (isDefaultValue: boolean, groupingColumn: string, selectedSegments: SegmentHierarchy[]) => {
    updateCorpSegmentField(isDefaultValue, groupingColumn, selectedSegments);
    setTimeout(() => {
      onboardingFormRef?.current?.validateForm();
    }, 100);
    setShowSegmentModal(false);
  };

  const onCancelSegmentModal = () => {
    setShowSegmentModal(false);
  };

  // Utility function to update business segments in form
  const updateBusinessSegments = (updatedSegments: BusinessSegmentsEntity[]) => {
    onboardingFormRef?.current?.setFieldValue('business_segments', updatedSegments);
    updatedSegments.forEach((_, index) => {
      onboardingFormRef?.current?.setFieldTouched(`business_segments[${index}].business_segment_id`, true, true);
      onboardingFormRef?.current?.setFieldTouched(`business_segments[${index}].business_segment_name`, true, true);
      onboardingFormRef?.current?.setFieldTouched(`business_segments[${index}].business_segment_data_type`, true, true);
    });
    onboardingFormRef?.current?.validateField('business_segments');
  };

  // Removes the selected segment at index
  const removeBusinessSegment = (index: number) => {
    const currentSegments = onboardingFormRef?.current?.values?.business_segments || [];
    const updatedSegments = [...currentSegments];
    updatedSegments.splice(index, 1);
    updateBusinessSegments(updatedSegments);
  };

  // Adds new item at last index
  const addBusinessSegment = () => {
    const currentSegments = onboardingFormRef?.current?.values?.business_segments || [];
    const updatedSegments = [...currentSegments, OnboardingFormUtils.Empty_Business_Segment];
    updateBusinessSegments(updatedSegments);
  };

  // get business segment exists if exists
  const checkIfItIsExistingBusinessSegment = (business_segment_name: string) => {
    return masterBusinessSegmentsData.find((masterBusinessSegment) => masterBusinessSegment.segment_name === business_segment_name);
  };

  // Determines if business segment data type should be disabled
  const disableBusinessSegmentDataType = (business_segment_name: string, index: number): boolean => {
    return checkIfItIsExistingBusinessSegment(business_segment_name) ? true : false;
  };

  const onChangeOfBusinessSegmentBlur = (business_segment_name: string, index: number) => {
    const isExistingBusinessSegment = checkIfItIsExistingBusinessSegment(business_segment_name);
    if (isExistingBusinessSegment) {
      onboardingFormRef?.current?.setFieldValue(`business_segments[${index}].business_segment_id`, isExistingBusinessSegment.segment_id);
      onboardingFormRef?.current?.setFieldValue(
        `business_segments[${index}].business_segment_data_type`,
        isExistingBusinessSegment.segment_data_type
      );

      setTimeout(() => {
        onboardingFormRef?.current?.setFieldTouched(`business_segments[${index}].business_segment_id`, true, true);
        onboardingFormRef?.current?.setFieldTouched(`business_segments[${index}].business_segment_name`, true, true);
        onboardingFormRef?.current?.setFieldTouched(`business_segments[${index}].business_segment_data_type`, true, true);
      }, 100);
    } else {
      onboardingFormRef?.current?.setFieldValue(`business_segments[${index}].business_segment_id`, null);
      onboardingFormRef?.current?.setFieldValue(`business_segments[${index}].business_segment_data_type`, '');
    }
    onboardingFormRef?.current?.validateField('business_segments');
  };

  const addRestrictedGroup = () => {
    const tmpItems = [...(onboardingFormRef?.current?.values?.restricted_groups || [])];
    tmpItems.push(OnboardingFormUtils.Empty_Restricted_Group);
    onboardingFormRef?.current?.setFieldValue('restricted_groups', tmpItems);
    onboardingFormRef?.current?.setFieldTouched('restricted_groups');
    onboardingFormRef?.current?.validateField('restricted_groups');
  };

  const removeRestrictedGroup = (index: number) => {
    const tmpItems = [...(onboardingFormRef?.current?.values?.restricted_groups || [])];
    tmpItems.splice(index, 1);
    onboardingFormRef?.current?.setFieldValue('restricted_groups', tmpItems);
    onboardingFormRef?.current?.validateField('restricted_groups');
  };

  const isBusinessGroupActive = () => !onboardingFormRef?.current?.values?.item_metadata?.is_active;

  return (
    <>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          window.location.reload();
        }}
      >
        <CorpSegmentHierarchySelectionModal
          showModal={showSegmentModal}
          onCancel={onCancelSegmentModal}
          onConfirmOfSegmentHierarchy={(isDefaultValue: boolean, groupingColumn: string, selectedSegments: SegmentHierarchy[]) =>
            onConfirmOfSegmentHierarchy(isDefaultValue, groupingColumn, selectedSegments)
          }
          selectedSegmentDetails={selectedSegmentDetails}
        />
        {formInitializationStatus && <LoadingSpinner />}
        {!formInitializationStatus && (
          <Formik<OnboardingFormEntity>
            innerRef={onboardingFormRef}
            enableReinitialize={true}
            initialValues={initialFormData}
            validationSchema={OnboardingFormUtils.validationSchema(currentDataClassificationNamesAndShortDesc)}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={handleSubmit}
          >
            {({ errors, handleBlur, handleSubmit, touched, dirty, isValid, setFieldTouched, values, setFieldValue, isSubmitting }) => {
              return (
                <>
                  <Form onSubmit={handleSubmit}>
                    <ContentLayout
                      header={
                        <Header
                          actions={
                            <SpaceBetween size="m" direction="horizontal">
                              <Button variant="normal" formAction="none" onClick={() => navigate('/admin-console/onboarding')}>
                                Cancel
                              </Button>
                              {formAction === 'Update' && (
                                <Button
                                  variant={values?.item_metadata?.is_active ? 'normal' : 'primary'}
                                  formAction="none"
                                  onClick={updateBusinessGroupStatus}
                                  disabled={isPlanningCycleActiveForDataClassification}
                                >
                                  {values?.item_metadata?.is_active ? 'Deactivate' : 'Activate'}
                                </Button>
                              )}
                              <Button variant="primary" disabled={isSubmitting || !dirty} formAction={'submit'}>
                                {formAction === 'Create' ? 'Create' : 'Update'}
                              </Button>
                            </SpaceBetween>
                          }
                          variant="h1"
                        >
                          {formAction === 'Create'
                            ? `Create new business group`
                            : `Edit ${initialFormData?.data_classification?.data_classification_name || ''}`}
                        </Header>
                      }
                    >
                      {isPlanningCycleActiveForDataClassification && (
                        <Box margin={{ top: 'l', bottom: 'l' }}>
                          <Alert
                            type="warning"
                            header={`There is currently an active planning cycle for this business group. Modifications are only permitted for modifying Corp Segment Values.`}
                          />
                        </Box>
                      )}
                      <SpaceBetween size="l" direction="vertical">
                        <Box margin={{ bottom: 'l' }}>
                          <ExpandableSection variant="container" headerText="Data Classification" defaultExpanded>
                            <Box margin={{ left: 'm' }}>
                              <SpaceBetween size="m" direction="vertical">
                                <SpaceBetween size="m" direction="horizontal">
                                  <FormField
                                    className="width-40-rem"
                                    label="Name"
                                    errorText={
                                      touched?.data_classification?.data_classification_name && errors?.data_classification?.data_classification_name
                                    }
                                  >
                                    <Input
                                      readOnly={isPlanningCycleActiveForDataClassification}
                                      disabled={isBusinessGroupActive() || isSubmitting}
                                      onChange={({ detail }) => {
                                        setFieldValue('data_classification.data_classification_name', detail.value, true);
                                      }}
                                      onBlur={() => {
                                        setFieldTouched('data_classification.data_classification_name', true, true);
                                      }}
                                      value={values?.data_classification?.data_classification_name}
                                    />
                                  </FormField>

                                  <FormField
                                    className="width-35-rem"
                                    label="Short Description"
                                    constraintText={'Short form for name, 2-3 characters in uppercase'}
                                    errorText={
                                      touched?.data_classification?.data_classification_short_description &&
                                      errors?.data_classification?.data_classification_short_description
                                    }
                                  >
                                    <Input
                                      disabled={isBusinessGroupActive() || isSubmitting}
                                      readOnly={formAction === 'Update' || isPlanningCycleActiveForDataClassification}
                                      onChange={({ detail }) => {
                                        setFieldValue('data_classification.data_classification_short_description', detail.value, true);
                                      }}
                                      onBlur={() => {
                                        setFieldTouched('data_classification.data_classification_short_description', true, true);
                                      }}
                                      value={values?.data_classification?.data_classification_short_description}
                                    />
                                  </FormField>
                                </SpaceBetween>

                                <FormField
                                  label="Description"
                                  errorText={
                                    touched?.data_classification?.data_classification_description &&
                                    errors?.data_classification?.data_classification_description
                                  }
                                >
                                  <Textarea
                                    readOnly={isPlanningCycleActiveForDataClassification}
                                    disabled={isBusinessGroupActive() || isSubmitting}
                                    onChange={({ detail }) => setFieldValue('data_classification.data_classification_description', detail.value)}
                                    onBlur={() => setFieldTouched('data_classification.data_classification_description', true, true)}
                                    value={values?.data_classification?.data_classification_description}
                                  />
                                </FormField>
                              </SpaceBetween>
                            </Box>
                          </ExpandableSection>
                        </Box>

                        <Box margin={{ bottom: 'l' }}>
                          <ExpandableSection variant="container" headerText={'LDAP groups'} defaultExpanded>
                            <Box margin={{ left: 'm' }}>
                              <SpaceBetween size="m" direction="horizontal">
                                <FormField
                                  className="width-40-rem"
                                  label={`Budget Leader Group`}
                                  errorText={touched?.business_leaders_group?.group_name && errors?.business_leaders_group?.group_name}
                                >
                                  <Select
                                    disabled={isBusinessGroupActive() || isSubmitting || isPlanningCycleActiveForDataClassification}
                                    filteringType="auto"
                                    selectedOption={OnboardingFormUtils.userGroupInfoToSelectDropdown(values?.business_leaders_group)}
                                    onChange={({ detail }) =>
                                      setFieldValue(
                                        `business_leaders_group`,
                                        OnboardingFormUtils.selectDropdownToUserGroupInfo(detail.selectedOption, ldapGroups?.business_leaders_group)
                                      )
                                    }
                                    onBlur={() => setFieldTouched('business_leaders_group', true, true)}
                                    options={OnboardingFormUtils.userGroupInfoToSelectDropdownOptions(ldapGroups?.business_leaders_group)}
                                  />
                                </FormField>

                                <FormField
                                  className="width-40-rem"
                                  label={`Budget Owner Group`}
                                  errorText={touched?.business_owners_group?.group_name && errors?.business_owners_group?.group_name}
                                >
                                  <Select
                                    disabled={isBusinessGroupActive() || isSubmitting || isPlanningCycleActiveForDataClassification}
                                    filteringType="auto"
                                    selectedOption={OnboardingFormUtils.userGroupInfoToSelectDropdown(values?.business_owners_group)}
                                    onChange={({ detail }) =>
                                      setFieldValue(
                                        `business_owners_group`,
                                        OnboardingFormUtils.selectDropdownToUserGroupInfo(detail.selectedOption, ldapGroups?.business_owners_group)
                                      )
                                    }
                                    onBlur={() => setFieldTouched('business_owners_group', true, true)}
                                    options={OnboardingFormUtils.userGroupInfoToSelectDropdownOptions(ldapGroups?.business_owners_group)}
                                  />
                                </FormField>
                              </SpaceBetween>
                            </Box>

                            <Box variant="h3" margin={{ left: 'm', top: 'm', bottom: 'm' }}>
                              {'Restricted groups'}
                            </Box>
                            <Box margin={{ left: 'm' }}>
                              <SpaceBetween size="m" direction="vertical">
                                {values?.restricted_groups?.map((restrictedGroup: LDAPGroupInformation, index: number) => (
                                  <Fragment key={index}>
                                    <SpaceBetween size="m" direction="horizontal">
                                      <FormField className="width-40-rem">
                                        <Select
                                          disabled={isBusinessGroupActive() || isSubmitting || isPlanningCycleActiveForDataClassification}
                                          filteringType="auto"
                                          selectedOption={OnboardingFormUtils.userGroupInfoToSelectDropdown(restrictedGroup)}
                                          onChange={({ detail }) =>
                                            setFieldValue(
                                              `restricted_groups[${index}]`,
                                              OnboardingFormUtils.selectDropdownToUserGroupInfo(
                                                detail.selectedOption,
                                                ldapGroups?.business_owners_group
                                              )
                                            )
                                          }
                                          onBlur={() => setFieldTouched(`restricted_groups[${index}]`, true, true)}
                                          options={OnboardingFormUtils.restrictedInfoToSelectDropdownOptions(
                                            values?.restricted_groups,
                                            ldapGroups?.business_owners_group?.filter((boGroup: LDAPGroupInformation) => boGroup.is_restricted)
                                          )}
                                        />
                                      </FormField>
                                      <Button
                                        disabled={isBusinessGroupActive() || isSubmitting || isPlanningCycleActiveForDataClassification}
                                        formAction="none"
                                        variant="normal"
                                        onClick={() => removeRestrictedGroup(index)}
                                      >
                                        remove
                                      </Button>
                                    </SpaceBetween>
                                  </Fragment>
                                ))}
                                <Button
                                  variant="normal"
                                  formAction="none"
                                  disabled={
                                    values?.restricted_groups?.length >=
                                      (ldapGroups?.business_owners_group?.filter((boGroup: LDAPGroupInformation) => boGroup.is_restricted)?.length ||
                                        0) ||
                                    isBusinessGroupActive() ||
                                    isSubmitting ||
                                    isPlanningCycleActiveForDataClassification
                                  }
                                  onClick={() => addRestrictedGroup()}
                                >
                                  {'Add restricted group'}
                                </Button>
                                {typeof errors?.restricted_groups === 'string' && (
                                  <StatusIndicator type="error">{`${errors?.restricted_groups}`}</StatusIndicator>
                                )}
                              </SpaceBetween>
                            </Box>
                          </ExpandableSection>
                        </Box>

                        {/* Corp Segments  */}
                        <Box margin={{ bottom: 'l' }}>
                          <ExpandableSection variant="container" headerText={'Corp Segments'} defaultExpanded>
                            <Box className="onboarding-corp-segment">
                              <Box margin={{ left: 'm' }}>
                                <CorpSegmentsHeader />
                              </Box>
                              <Box margin={{ left: 'm' }}>
                                {values?.corp_segments
                                  ?.sort(OnboardingFormUtils.customCorpSegmentSort)
                                  ?.map((corpSegment: CorpSegmentsValueEntity, index: number) => (
                                    <Fragment key={index}>
                                      <SpaceBetween size="l" direction="horizontal" className="onboarding-corp-segment-row">
                                        <FormField
                                          className="required-column-width"
                                          errorText={
                                            touched?.corp_segments &&
                                            touched?.corp_segments[index] &&
                                            touched?.corp_segments[index]?.corp_segment_required &&
                                            errors?.corp_segments &&
                                            (errors?.corp_segments[index]
                                              ? (errors?.corp_segments[index] as unknown as CorpSegmentsValueEntity)?.corp_segment_required || ''
                                              : null)
                                          }
                                        >
                                          <Checkbox
                                            disabled={
                                              isMandatoryCorpSegment(values?.corp_segments[index]?.corp_segment_name as eCorpSegmentNames) ||
                                              isBusinessGroupActive() ||
                                              isSubmitting ||
                                              isPlanningCycleActiveForDataClassification
                                            }
                                            onChange={({ detail }) => setFieldValue(`corp_segments[${index}].corp_segment_required`, detail.checked)}
                                            checked={values?.corp_segments[index]?.corp_segment_required}
                                          />
                                        </FormField>

                                        {/* Corp Segment  */}
                                        <FormField className="segment-column-width">
                                          <Input value={values?.corp_segments[index]?.corp_segment_name} readOnly />
                                        </FormField>

                                        {/* Corp Segment - Default Value  */}
                                        <FormField
                                          className="default-value-column-width"
                                          errorText={
                                            touched?.corp_segments &&
                                            touched?.corp_segments[index] &&
                                            touched?.corp_segments[index]?.corp_segment_default_value &&
                                            errors?.corp_segments &&
                                            (errors?.corp_segments[index]
                                              ? (errors?.corp_segments[index] as unknown as CorpSegmentsValueEntity)?.corp_segment_default_value
                                                  ?.segment_description || ''
                                              : null)
                                          }
                                        >
                                          <Button
                                            onClick={() => onClickOfDefaultValueSegment(corpSegment)}
                                            formAction="none"
                                            disabled={values?.corp_segments[index]?.corp_segment_required || isBusinessGroupActive() || isSubmitting}
                                          >
                                            {OnboardingFormUtils.getCorpSegmentDefaultButtonValue(
                                              corpSegment,
                                              values?.corp_segments[index]?.corp_segment_required
                                            )}
                                          </Button>
                                        </FormField>

                                        {/* Corp Segment - Value  */}
                                        {eCorpSegmentNames.COST_CENTER === corpSegment.corp_segment_name ? (
                                          <FormField
                                            className="allowed-value-column-width"
                                            // @ts-ignore
                                            errorText={
                                              touched?.corp_segments &&
                                              touched?.corp_segments[index] &&
                                              touched?.corp_segments[index].corp_segment_values_s3_data_fetched &&
                                              errors?.corp_segments &&
                                              (errors?.corp_segments[index]
                                                ? (errors?.corp_segments[index] as unknown as CorpSegmentsValueEntity)
                                                    ?.corp_segment_values_s3_data_fetched || ''
                                                : null)
                                            }
                                          >
                                            <Button
                                              disabled={isBusinessGroupActive() || isSubmitting}
                                              onClick={() => onClickOfSegment(corpSegment)}
                                              formAction="none"
                                            >
                                              {OnboardingFormUtils.getCorpSegmentButtonValue(corpSegment)}
                                            </Button>
                                          </FormField>
                                        ) : (
                                          <>
                                            <Button
                                              disabled={isBusinessGroupActive() || isSubmitting}
                                              onClick={() => onClickOfSegment(corpSegment)}
                                              formAction="none"
                                            >
                                              {OnboardingFormUtils.getCorpSegmentButtonValue(corpSegment)}
                                            </Button>
                                          </>
                                        )}
                                      </SpaceBetween>
                                    </Fragment>
                                  ))}
                              </Box>
                            </Box>
                          </ExpandableSection>
                        </Box>

                        <Box margin={{ bottom: 'l' }}>
                          <ExpandableSection variant="container" headerText={'Business Segments'} defaultExpanded>
                            <Box className="onboarding-business-segment">
                              <Box margin={{ left: 'm' }}>
                                <BusinessSegmentsHeader />
                              </Box>
                              <Box margin={{ left: 'm' }}>
                                <SpaceBetween size="m" direction="vertical">
                                  {values?.business_segments?.map((businessSegment: BusinessSegmentsEntity, index: number) => (
                                    <Fragment key={index}>
                                      <SpaceBetween size="l" direction="horizontal" className="onboarding-business-segment-row">
                                        <FormField className="id-column-width">
                                          <Checkbox
                                            disabled={isBusinessGroupActive() || isSubmitting || isPlanningCycleActiveForDataClassification}
                                            onChange={({ detail }) => setFieldValue(`business_segments[${index}].is_id_column`, detail.checked)}
                                            checked={values?.business_segments[index]?.is_id_column}
                                          />
                                        </FormField>

                                        <FormField
                                          className="segment-column-width"
                                          errorText={
                                            touched?.business_segments &&
                                            touched?.business_segments[index] &&
                                            touched?.business_segments[index].business_segment_name &&
                                            errors?.business_segments &&
                                            (errors?.business_segments[index]
                                              ? (errors?.business_segments[index] as unknown as BusinessSegmentsEntity)?.business_segment_name || ''
                                              : null)
                                          }
                                        >
                                          <Autosuggest
                                            readOnly={isPlanningCycleActiveForDataClassification}
                                            disabled={isBusinessGroupActive() || isSubmitting}
                                            enteredTextLabel={(value) => `Use: "${value}"`}
                                            onChange={({ detail }) =>
                                              setFieldValue(`business_segments[${index}].business_segment_name`, detail.value)
                                            }
                                            onBlur={() =>
                                              onChangeOfBusinessSegmentBlur(values?.business_segments[index]?.business_segment_name, index)
                                            }
                                            value={values?.business_segments[index]?.business_segment_name || ''}
                                            options={OnboardingFormUtils.loadMasterBusinessSegmentsSuggestions(masterBusinessSegmentsData)}
                                            ariaLabel="Autosuggest example with suggestions"
                                            placeholder="Enter value"
                                            empty="No matches found"
                                          />
                                        </FormField>

                                        <FormField
                                          className="data-type-column-width"
                                          errorText={
                                            touched?.business_segments &&
                                            touched?.business_segments[index] &&
                                            touched?.business_segments[index].business_segment_data_type &&
                                            errors?.business_segments &&
                                            (errors?.business_segments[index]
                                              ? (errors?.business_segments[index] as unknown as BusinessSegmentsEntity)?.business_segment_data_type ||
                                                ''
                                              : null)
                                          }
                                        >
                                          <Select
                                            expandToViewport
                                            filteringType="auto"
                                            selectedOption={stringToSingleSelectDropdown(
                                              values?.business_segments[index]?.business_segment_data_type
                                            )}
                                            onChange={({ detail }) =>
                                              setFieldValue(`business_segments[${index}].business_segment_data_type`, detail.selectedOption.label)
                                            }
                                            options={BusinessSegmentDataTypes}
                                            disabled={
                                              disableBusinessSegmentDataType(values?.business_segments[index]?.business_segment_name, index) ||
                                              isBusinessGroupActive() ||
                                              isSubmitting ||
                                              isPlanningCycleActiveForDataClassification
                                            }
                                          />
                                        </FormField>

                                        <FormField className="remove-column-width">
                                          <Button
                                            disabled={isBusinessGroupActive() || isSubmitting || isPlanningCycleActiveForDataClassification}
                                            variant="normal"
                                            formAction="none"
                                            onClick={() => removeBusinessSegment(index)}
                                          >
                                            Remove
                                          </Button>
                                        </FormField>
                                      </SpaceBetween>
                                    </Fragment>
                                  ))}
                                  <SpaceBetween size="m" direction="horizontal">
                                    <Button
                                      disabled={isBusinessGroupActive() || isSubmitting || isPlanningCycleActiveForDataClassification}
                                      variant="normal"
                                      formAction="none"
                                      onClick={() => addBusinessSegment()}
                                    >
                                      {'Add new business segment'}
                                    </Button>
                                    {typeof errors?.business_segments === 'string' && (
                                      <StatusIndicator type="error">{`${errors?.business_segments}`}</StatusIndicator>
                                    )}
                                  </SpaceBetween>
                                </SpaceBetween>
                              </Box>
                            </Box>
                          </ExpandableSection>
                        </Box>

                        {/* <DisplayContentInCode details={onboardingFormRef?.current?.values} /> */}
                      </SpaceBetween>
                    </ContentLayout>
                  </Form>
                </>
              );
            }}
          </Formik>
        )}
      </ErrorBoundary>
    </>
  );
};

export default OnboardingForm;

const BusinessSegmentsHeader = () => {
  return (
    <>
      <SpaceBetween size="m" direction="horizontal">
        <Box variant="awsui-key-label" className="id-column-width" margin={{ top: 'm', bottom: 's' }}>
          ID field
        </Box>
        <Box variant="awsui-key-label" className="segment-column-width" margin={{ top: 'm', bottom: 's' }}>
          Segment Name
        </Box>
        <Box variant="awsui-key-label" className="data-type-column-width" margin={{ top: 'm', bottom: 's' }}>
          Data Type
        </Box>
        <Box variant="awsui-key-label" className="remove-column-width" margin={{ top: 'm', bottom: 's' }}></Box>
      </SpaceBetween>
    </>
  );
};

const CorpSegmentsHeader = () => {
  return (
    <>
      <SpaceBetween size="m" direction="horizontal">
        <Box variant="awsui-key-label" className="required-column-width" margin={{ top: 'm', bottom: 's' }}>
          <Header
            variant="h3"
            info={<FormFieldPopover header="Required" content={'Select if Corp Segment is a mandatory field in Forecast Template.'} />}
          >
            Required
          </Header>
        </Box>

        <Box variant="awsui-key-label" className="segment-column-width" margin={{ top: 'm', bottom: 's' }}>
          <Header variant="h3">Segment</Header>
        </Box>
        <Box variant="awsui-key-label" className="default-value-column-width" margin={{ top: 'm', bottom: 's' }}>
          <Header
            variant="h3"
            info={
              <FormFieldPopover
                header="Required"
                content={"If 'Required' isn't selected, then the default values will be replaced while sending it to downstream apps."}
              />
            }
          >
            Default Value
          </Header>
        </Box>
        <Box variant="awsui-key-label" className="allowed-value-column-width" margin={{ top: 'm', bottom: 's' }}>
          <Header variant="h3" info={<FormFieldPopover header="Values" content={'Select all the applicable values for the Segment'} />}>
            Values
          </Header>
        </Box>
      </SpaceBetween>
    </>
  );
};
