import { eEntityStatus } from 'src/constants/generic-constants';
import { RootState } from 'src/store/store';
import { LDAPGroups, LDAPGroupInformation, BusinessGroupEntity } from 'src/models/AppContextModels';
import { logger } from 'src/analytics/KatalLogger';

/**
 * Selector to get the loading status from the state.
 */
export const appMetadataLoadingStatus = (state: RootState) => state.xptAppMetadataStore.loadingStatus;

/**
 * Selector to get all LDAP groups from the state.
 */
export const selectAllLdapGroups = (state: RootState) => state.xptAppMetadataStore.ldapGroups;

/**
 * Selector to get all entity statuses from the state.
 */
export const selectAllEntityStatus = (state: RootState) => state.xptAppMetadataStore.entityStatus;

/**
 * Selector to get pending entity statuses from the state.
 */
export const selectPendingEntityStatus = (state: RootState) =>
  state.xptAppMetadataStore.entityStatus.find((_) => _.entity_status === eEntityStatus.InProgress);

/**
 * Helper function to get the distinct sorted users from two LDAP groups.
 * @param group1 - The first LDAP group information.
 * @param group2 - The second LDAP group information.
 * @returns A sorted array of distinct users.
 */
const getDistinctSortedUsers = (group1?: LDAPGroupInformation, group2?: LDAPGroupInformation): string[] => {
  const users1 = group1?.user_list || [];
  const users2 = group2?.user_list || [];
  const allUsers = [...users1, ...users2];
  return Array.from(new Set(allUsers)).sort((a, b) => a.localeCompare(b));
};

/**
 * Selector to get all distinct users_list based on business_leaders_group and business_owners_group
 * from the current business group, sorted in ascending order.
 */
export const selectDistinctUsersFromCurrentBusinessGroup = (state: RootState): string[] => {
  const currentBusinessGroup: BusinessGroupEntity | null = state.businessGroupStore.currentBusinessGroup;
  const ldapGroups: LDAPGroups = state.xptAppMetadataStore.ldapGroups;

  if (!currentBusinessGroup) return [];

  const { business_leaders_group, business_owners_group } = currentBusinessGroup;

  const budgetLeaderLdapInfo = ldapGroups.business_leaders_group.find((group) => group.group_id === business_leaders_group.group_id);
  if (!budgetLeaderLdapInfo) {
    logger.error(
      `Unable to find Budget Leader LDAP Group details from listUserGroups. Business Group ${currentBusinessGroup.data_classification.data_classification_short_description}, Budget Leader Group ${business_leaders_group.group_id}`
    );
  }
  const budgetOwnerLdapInfo = ldapGroups.business_owners_group.find((group) => group.group_id === business_owners_group.group_id);
  if (!budgetOwnerLdapInfo) {
    logger.error(
      `Unable to find Budget Owner LDAP Group details from listUserGroups. Business Group ${currentBusinessGroup.data_classification.data_classification_short_description}, Budget Owner Group ${business_owners_group.group_id}`
    );
  }
  return getDistinctSortedUsers(budgetLeaderLdapInfo, budgetOwnerLdapInfo);
};

/**
 * Selector to get all distinct users_list based on development_group and fpna_admin_group
 * from the LDAP groups, sorted in ascending order.
 */
export const selectDistinctUsersFromDevAndFpnaGroups = (state: RootState): string[] => {
  const ldapGroups = state.xptAppMetadataStore.ldapGroups;
  const { development_group, fpna_admin_group } = ldapGroups;
  return getDistinctSortedUsers(development_group, fpna_admin_group);
};

/**
 * Selector to get the distinct combination of users from both current business group and
 * development_group & fpna_admin_group, sorted in ascending order.
 */
export const selectDistinctUsersCombinedFromCurrentBusinessGroup = (state: RootState): string[] => {
  const currentBusinessGroupUsers = selectDistinctUsersFromCurrentBusinessGroup(state);
  const devAndFpnaGroupUsers = selectDistinctUsersFromDevAndFpnaGroups(state);

  const allUsers = [...currentBusinessGroupUsers, ...devAndFpnaGroupUsers];
  return Array.from(new Set(allUsers)).sort((a, b) => a.localeCompare(b));
};
