import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  CollectionPreferencesProps,
  Flashbar,
  FlashbarProps,
  FormField,
  Pagination,
  PropertyFilter,
  PropertyFilterProps,
  Select,
  Table,
  TableProps
} from '@amzn/awsui-components-react';
import React, { useRef, useState } from 'react';
import { paginationAriaLabels, propertyFilterI18nStrings } from 'src/i18n-strings';
import { TableEmptyState, TableNoMatchState, XptTablePreferences, getMatchesCountText } from '../TableCommons';
import { FilterSet, useFilterSets } from './XptTableUseFilterSets';
import { generateFilteringProperties } from './XptTable';
import { extractFieldNamesForDefaultVisibleContent, generateVisibleContentOptions, xptTableSortingComparator } from './XptTableConfig';
import { DynamicColumnDetails } from '../../../models/XptTableModel';

interface XptTableWithSavedFiltersProps {
  xptTableVariant: TableProps.Variant;
  loadingStatus: boolean;
  entityName: string;
  xptTableHeader: React.ReactNode;
  allItems: any[];
  allColumns: DynamicColumnDetails;
  columnDefinitions: TableProps.ColumnDefinition<any>[];
  itemsPerPage: number;
  selectionType?: TableProps.SelectionType;
  selectedItems?: any[];
  setSelectedItems?: (selectedItems: any[]) => {};
  filterSets: FilterSet[];
  defaultFilterSets?: FilterSet;
  stickyColumnsInfo?: any;
  hasCustomPreference?: boolean;
}

const XptTableWithSavedFilters: React.FC<XptTableWithSavedFiltersProps> = ({
  xptTableVariant,
  loadingStatus,
  entityName,
  xptTableHeader,
  allItems,
  allColumns,
  columnDefinitions,
  itemsPerPage,
  selectionType,
  selectedItems,
  setSelectedItems,
  filterSets,
  defaultFilterSets,
  stickyColumnsInfo,
  hasCustomPreference = true
}) => {
  const [tableRowData, setTableRowData] = React.useState<any[]>([]);
  const [tableColumnDefinitions, setTableColumnDefinitions] = React.useState<TableProps.ColumnDefinition<any>[]>([]);

  const [tableDefaultPreferences, setTableDefaultPreferences] = React.useState<CollectionPreferencesProps.Preferences>({});
  const [tableVisibleCOntentOptions, setTableVisibleContentOptions] = React.useState<CollectionPreferencesProps.VisibleContentOptionsGroup[]>([]);
  const [filteringProperties, setFilteringProperties] = React.useState<PropertyFilterProps.FilteringProperty[]>([]);

  const [flashNotifications, setFlashNotifications] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [savedFilterSets, setSavedFilterSets] = useState<FilterSet[]>(filterSets || []);
  const selectRef = useRef(null);

  React.useEffect(() => {
    document.body.style.lineHeight = 'normal';

    if (defaultFilterSets) {
      actions.setPropertyFiltering({ tokens: defaultFilterSets?.query?.tokens || [], operation: defaultFilterSets?.query?.operation });
    }
  }, []);

  // generating Table column definitions from allColumns
  React.useEffect(() => {
    setTableColumnDefinitions(columnDefinitions || []);
    setTableRowData(allItems || []);
  }, [columnDefinitions, allItems]);

  // generating Table default preferences from allColumns
  React.useEffect(() => {
    if (allColumns) {
      const defaultPreferences = {
        pageSize: itemsPerPage,
        visibleContent: extractFieldNamesForDefaultVisibleContent(allColumns),
        wrapLines: false,
        stripedRows: false,
        custom: false
      } as CollectionPreferencesProps.Preferences;
      setTableDefaultPreferences(defaultPreferences);
      setTableVisibleContentOptions(generateVisibleContentOptions(allColumns));
    }
  }, [allColumns, itemsPerPage]);

  const onClearFilter = () => {
    actions.setPropertyFiltering({ tokens: [], operation: propertyFilterProps.query.operation });
  };

  // generating filtering properties from allColumns
  React.useEffect(() => {
    if (allColumns) {
      const properties = generateFilteringProperties(allColumns);
      setFilteringProperties(properties);
    }
  }, [allColumns]);

  const displayNotification = (notification: FlashbarProps.MessageDefinition) => {
    setFlashNotifications([
      {
        ...notification,
        onDismiss: () => {
          setFlashNotifications((currentNotifications) => currentNotifications.filter((item) => item.id !== notification.id));
        }
      },
      ...flashNotifications
    ]);
  };

  const { items, actions, filteredItemsCount, collectionProps, paginationProps, propertyFilterProps } = useCollection(tableRowData, {
    propertyFiltering: {
      filteringProperties,
      empty: <TableEmptyState resourceName={``} />,
      noMatch: <TableNoMatchState onClearFilter={onClearFilter} />
    },
    pagination: {
      pageSize: tableDefaultPreferences.pageSize
    },
    sorting: {
      defaultState: {
        sortingColumn: columnDefinitions?.find((col) => col.id == allColumns.columnInfo.sortingColumn) || columnDefinitions[0],
        isDescending: allColumns.columnInfo.isDescending
      }
    }
  });

  const { buttonDropdownProps, selectProps, actionModal } = useFilterSets({
    filterSets: savedFilterSets,
    query: propertyFilterProps.query,
    filteringProperties: propertyFilterProps.filteringProperties,
    selectRef,
    updateFilters: (query) => {
      actions.setPropertyFiltering(query);
    },
    updateSavedFilterSets: (newFilterSets) => {
      setSavedFilterSets(newFilterSets);
      // Persist the new filters here
    },
    showNotification: (notification) => displayNotification(notification)
  });

  return (
    <>
      <Flashbar stackItems={true} items={flashNotifications} />
      {actionModal}
      <Table
        variant={xptTableVariant}
        header={xptTableHeader}
        loading={loadingStatus}
        loadingText="Loading"
        stickyHeader={true}
        columnDefinitions={tableColumnDefinitions}
        stickyColumns={stickyColumnsInfo}
        items={items}
        selectionType={selectionType}
        selectedItems={selectedItems}
        onSelectionChange={({ detail }) => setSelectedItems && setSelectedItems(detail.selectedItems)}
        onRowClick={({ detail }) => setSelectedItems && setSelectedItems([detail.item])}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationAriaLabels(paginationProps.pagesCount)} />}
        {...collectionProps}
        trackBy={allColumns?.columnInfo?.trackBy}
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            filteringAriaLabel="Find resources"
            filteringPlaceholder="Find resources"
            i18nStrings={propertyFilterI18nStrings(entityName)}
            countText={getMatchesCountText(filteredItemsCount!)}
            expandToViewport={true}
            customControl={<FormField label="Saved filter sets">{<Select {...selectProps} data-testid="saved-filters" ref={selectRef} />}</FormField>}
            // Will enable customFilterActions, once persisting feature is implemented
            // customFilterActions={<ButtonDropdown {...buttonDropdownProps} data-testid="filter-actions" />}
          />
        }
        visibleColumns={tableDefaultPreferences.visibleContent}
        resizableColumns={tableDefaultPreferences.custom}
        wrapLines={tableDefaultPreferences.wrapLines}
        stripedRows={tableDefaultPreferences.stripedRows}
        contentDensity={tableDefaultPreferences.contentDensity}
        preferences={
          <XptTablePreferences
            preferences={tableDefaultPreferences}
            setPreferences={setTableDefaultPreferences}
            visibleContentOptions={tableVisibleCOntentOptions}
            hasCustomPreference={hasCustomPreference}
          />
        }
      />
    </>
  );
};

export default XptTableWithSavedFilters;
