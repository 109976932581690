import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Flashbar, FlashbarProps, Header, Select, SelectProps, SpaceBetween } from '@amzn/awsui-components-react';
import { XPTBreadcrumbs } from 'src/components/common/XptBreadcrumb';
import XptTable from 'src/components/common/xpt-table/XptTable';
import { XptAppLayout } from 'src/components/common/xpt-app-layout/XptAppLayout';
import AdminConsoleSideNavigation from '../admin-console/AdminConsoleSideNavigation';
import PlanningCycleManageFormAdmin from './PlanningCycleManageFormAdmin';
import { PlanningCycleGroupedByScenarioYear } from 'src/models/PlanningCycleModel';
import { fetchPlanningCycles } from './planningCycleSlice';
import { selectPlanningCyclesGroupedByScenarioYear } from './planningCycleSelector';
import { useFlashbar } from 'src/hooks/useFlashbar';
import { logger } from 'src/analytics/KatalLogger';
import { getHeaderCounter } from 'src/utils/generic-utilities';
import { LockStatusModal } from './PlanningCycleLockStatusModal';
import { ADMIN_PLANNING_TABLE_CONFIG, defaultSelection, getAdminPlanningBreadcrumbItems, getAdminPlanningCycleTableDefinitions, planningCycleOptions } from './PlanningCycleConfig';
import { useAppDispatch } from 'src/store/useAppDispatch';
import { useSelector } from 'react-redux';
import { AdminPlanningCycleHelpPanel } from './PlanningCycleHelpPanel';

export type AdminPlanningCycleContextType = {
  notificationMessage: (content: string, flashBarType: FlashbarProps.Type, isDismissible: boolean, messageId?: string) => void;
  clearSpecificFlashMessage: (messageId: string) => void;
  loadingPlanningCycles: boolean;
  setLoadingPlanningCycles: (loadingModules: boolean) => void;
};

export const PlanningCycleContext = createContext<AdminPlanningCycleContextType>({
  notificationMessage: () => {},
  clearSpecificFlashMessage: (messageId: string) => {},
  loadingPlanningCycles: false,
  setLoadingPlanningCycles: (loadingModules: boolean) => {}
});

export const useAdminPlanningContext = () => useContext(PlanningCycleContext);

const PlanningCycleHomePageAdmin: React.FC = () => {
  const appLayout = useRef<any>();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const planningCycleParamId = searchParams.get('planningCycle');

  const dispatch = useAppDispatch();

  const [loadingPlanningCycles, setLoadingPlanningCycles] = useState<boolean>(true);
  const { flashbarItems, displayFlashMessage, clearSpecificFlashMessage } = useFlashbar();
  const [planningCyclesTableRow, setPlanningCyclesTableRow] = useState<PlanningCycleGroupedByScenarioYear[]>([]);
  const [selectedItem, setSelectedItem] = useState<PlanningCycleGroupedByScenarioYear | null>(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedPlanningCycleFilter, setSelectedPlanningCycleFilter] = useState<SelectProps.Option>(defaultSelection);

  const planningCyclesGroupedByScenarioYear = useSelector(selectPlanningCyclesGroupedByScenarioYear);

  // Handles initial and filter-based loading of planning cycles
  useEffect(() => {
    basedOnPlanningCycleFilter(selectedPlanningCycleFilter.value);
  }, [selectedPlanningCycleFilter]);

  useEffect(() => {
    basedOnPlanningCycleFilter(selectedPlanningCycleFilter?.value);
    setLoadingPlanningCycles(false);
  }, [planningCyclesGroupedByScenarioYear]);

  // Reloads planning cycles from the backend
  const reloadPlanningCycles = async () => {
    try {
      setLoadingPlanningCycles(true);
      await dispatch(fetchPlanningCycles()).unwrap();
      basedOnPlanningCycleFilter(selectedPlanningCycleFilter.value);
    } catch (error) {
      logger.error('Unable to load planning cycles', { error });
      displayFlashMessage('Unable to load planning cycles', 'error', true);
    } finally {
      setLoadingPlanningCycles(false);
    }
  };

  // Handler for lock status click
  const handleLockStatusClick = (item: PlanningCycleGroupedByScenarioYear) => {
    setSelectedItem(item);
    setIsModalVisible(true);
  };

  // Close modal handler
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedItem(null);
  };

  const notificationMessage = (content: string, flashBarType: FlashbarProps.Type, isDismissible: boolean, messageId?: string) => {
    displayFlashMessage(content, flashBarType, isDismissible, messageId);
  };

  const basedOnPlanningCycleFilter = (selectedValue: string | undefined) => {
    switch (selectedValue) {
      case 'active':
        setPlanningCyclesTableRow(planningCyclesGroupedByScenarioYear.filter((item) => !item.allPlanningCyclesClosed));
        break;
      case 'historical':
        setPlanningCyclesTableRow(planningCyclesGroupedByScenarioYear.filter((item) => item.allPlanningCyclesClosed));
        break;
      case 'all':
      default:
        setPlanningCyclesTableRow(planningCyclesGroupedByScenarioYear);
        break;
    }
  };

  const PlanningCycleTableHeader = () => {
    return (
      <Header
        actions={
          <SpaceBetween size="xl" direction="horizontal">
            <Box>
              <Select
                options={planningCycleOptions}
                selectedOption={selectedPlanningCycleFilter}
                onChange={({ detail }) => {
                  setSelectedPlanningCycleFilter(detail.selectedOption);
                }}
              />
            </Box>
            <Box>
              <SpaceBetween size="m" direction="horizontal">
                <Button iconName="refresh" onClick={reloadPlanningCycles}></Button>
                <Button iconName="add-plus" iconAlign="left" variant="primary" onClick={createPlanningCycles}>
                  Create planning cycle
                </Button>
              </SpaceBetween>
            </Box>
          </SpaceBetween>
        }
        counter={getHeaderCounter([], planningCyclesTableRow)}
      >
        {'Planning Cycles'}
      </Header>
    );
  };

  const onEditPlanningCycle = (item: PlanningCycleGroupedByScenarioYear) => {
    navigate(`/admin-console/planning-cycle/manage?planningCycle=${item.scenario_year}`);
  };

  const createPlanningCycles = () => {
    navigate('/admin-console/planning-cycle/manage?planningCycle=new');
  };

  // Column definitions with modal logic
  const columnDefinitions = getAdminPlanningCycleTableDefinitions(handleLockStatusClick, onEditPlanningCycle);

  return (
    <>
      {selectedItem && <LockStatusModal item={selectedItem} visible={isModalVisible} onClose={closeModal} />}
      <XptAppLayout
        ref={appLayout}
        headerSelector="#h"
        navigation={<AdminConsoleSideNavigation />}
        tools={<AdminPlanningCycleHelpPanel />}
        breadcrumbs={<XPTBreadcrumbs items={getAdminPlanningBreadcrumbItems(!planningCycleParamId ? 'table' : 'form')} />}
        notifications={<Flashbar items={flashbarItems} stackItems />}
        stickyNotifications={true}
        maxContentWidth={Number.MAX_VALUE}
        contentType={!planningCycleParamId ? 'table' : 'form'}
        content={
          <div className="xpt-app-layout-content">
            <PlanningCycleContext.Provider
              value={{
                loadingPlanningCycles,
                setLoadingPlanningCycles,
                notificationMessage,
                clearSpecificFlashMessage
              }}
            >
              {!planningCycleParamId && (
                <XptTable
                  xptTableVariant={'full-page'}
                  loadingStatus={loadingPlanningCycles}
                  entityName={ADMIN_PLANNING_TABLE_CONFIG.columnInfo.entityName}
                  xptTableHeader={<PlanningCycleTableHeader />}
                  allItems={planningCyclesTableRow}
                  allColumns={ADMIN_PLANNING_TABLE_CONFIG}
                  columnDefinitions={columnDefinitions}
                  selectedItems={[]}
                  setSelectedItems={() => []}
                  itemsPerPage={20}
                />
              )}
              {planningCycleParamId && <PlanningCycleManageFormAdmin />}
            </PlanningCycleContext.Provider>
          </div>
        }
      />
    </>
  );
};

export default PlanningCycleHomePageAdmin;
