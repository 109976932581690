import React from 'react';
import { Modal, Box } from '@amzn/awsui-components-react';
import CodeView from '@amzn/awsui-code-view/code-view';
import CopyToClipboard from '@amzn/awsui-components-react/polaris/copy-to-clipboard';
// @ts-ignore
import typescriptHighlight from '@amzn/awsui-code-view/highlight/typescript';
// @ts-ignore
import pythonHighlight from '@amzn/awsui-code-view/highlight/python';
// @ts-ignore
import jsonHighlight from '@amzn/awsui-code-view/highlight/json';

type ContentType = 'json' | 'typescript' | 'python';

interface CodeViewModalProps {
  visible: boolean;
  onClose: () => void;
  header: string;
  content: any;
  contentType: ContentType;
}

const CodeViewModal: React.FC<CodeViewModalProps> = ({ visible, onClose, header, content, contentType }) => {
  let formattedContent = '';
  let highlight;

  switch (contentType) {
    case 'json':
      formattedContent = content ? JSON.stringify(content, null, 2) : '';
      highlight = jsonHighlight;
      break;
    case 'typescript':
      formattedContent = content || '';
      highlight = typescriptHighlight;
      break;
    case 'python':
      formattedContent = content || '';
      highlight = pythonHighlight;
      break;
    default:
      formattedContent = content || '';
      highlight = jsonHighlight; // Default to JSON if content type is unknown
  }

  return (
    <Modal visible={visible} onDismiss={onClose} closeAriaLabel="Close modal" header={header}>
      <Box>
        <CodeView
          content={formattedContent}
          lineNumbers
          highlight={highlight}
          actions={
            <CopyToClipboard
              copyButtonAriaLabel="Copy code"
              copyErrorText="Code failed to copy"
              copySuccessText="Code copied"
              textToCopy={formattedContent}
            />
          }
        />
      </Box>
    </Modal>
  );
};

export default CodeViewModal;
