import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { logger } from 'src/analytics/KatalLogger';
import { getMasterSegments } from 'src/api/app-sync-services';
import { eCorpSegmentNames, eSegmentCategoryType } from 'src/constants/corp-segment-constants';
import XptMessages from 'src/constants/xpt-messages';
import {
  convertToDropdownValues,
  filterOutMasterCorpSegmentsFromSelectedValues,
  getTransformedCorpSegments
} from 'src/features/business-group/forecast-template-v2/forecast-utils/CorpSegmentsUtils';
import { MasterBusinessSegments, MasterCorpSegments, SegmentHierarchy } from 'src/models/AppContextModels';
import { LoadingStatus } from 'src/models/AuthContextModels';
import { ForecastTemplateMasterCorpSegmentDropdownValues, OptionDropdown } from 'src/models/ForecastModels';
import { getFileFromS3URI } from 'src/utils/aws-s3-services';
import { RootState } from '../store';

interface CorpSegmentState {
  loadingStatus: LoadingStatus;
  masterCorpSegments: MasterCorpSegments[];
  masterCorpSegmentDropdownValues: ForecastTemplateMasterCorpSegmentDropdownValues[];
  masterCostCenterDropdownValuesForAllBusinessGroups: string[];
  masterBusinessSegments: MasterBusinessSegments[];
  masterCorpSegmentsStatus: {
    status: LoadingStatus;
    messageForError: string;
  };
}

export const corpSegmentInitialState: CorpSegmentState = {
  loadingStatus: LoadingStatus.NotInitiated,
  masterCorpSegments: [],
  masterCorpSegmentDropdownValues: [],
  masterCostCenterDropdownValuesForAllBusinessGroups: [],
  masterBusinessSegments: [],
  masterCorpSegmentsStatus: {
    status: LoadingStatus.NotInitiated,
    messageForError: ''
  }
};

const updateLoadingStatus = (state: CorpSegmentState, status: LoadingStatus) => {
  state.loadingStatus = status;
};

const fetchCorpSegmentDataFromS3 = async (segment: MasterCorpSegments) => {
  try {
    const dataFromS3 = await getFileFromS3URI(segment.segment_hierarchy_s3_path);
    return {
      ...segment,
      segment_hierarchy_data_from_s3: dataFromS3 as unknown as SegmentHierarchy[]
    } as MasterCorpSegments;
  } catch (s3Error: any) {
    logger.error('Failed to fetch S3 data for segment:', { ...s3Error, segmentName: segment.segment_name });
    return {
      ...segment,
      segment_hierarchy_data_from_s3: []
    };
  }
};

export const fetchCorpSegments = createAsyncThunk('corpSegment/fetchCorpSegments', async (_, { dispatch, rejectWithValue }) => {
  try {
    const masterSegmentsResponse = await getMasterSegments();

    const corpSegment: MasterCorpSegments[] = masterSegmentsResponse?.filter(
      (masterSegment) => masterSegment.segment_category === eSegmentCategoryType.CORP
    ) as MasterCorpSegments[];

    const corpSegments = await Promise.all(corpSegment.map(fetchCorpSegmentDataFromS3));

    return corpSegments;
  } catch (error: any) {
    logger.error('Error fetching corporate segments:', error);
    return rejectWithValue('Failed to fetch corporate segments');
  }
});

export const fetchBusinessSegments = createAsyncThunk('corpSegment/fetchBusinessSegments', async (_, { getState, dispatch, rejectWithValue }) => {
  try {
    const masterSegmentsResponse = await getMasterSegments();

    const businessSegmentResponse = masterSegmentsResponse?.filter(
      (masterSegment) => masterSegment.segment_category === eSegmentCategoryType.BUSINESS
    ) as MasterBusinessSegments[];

    return businessSegmentResponse;
  } catch (error: any) {
    logger.error('Error fetching business segments:', error);
    return rejectWithValue('Failed to fetch business segments');
  }
});

export const fetchMasterCorpSegmentDropdownValues = createAsyncThunk(
  'forecastTemplate/fetchMasterCorpSegmentDropdownValues',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const businessGroup = state.businessGroupStore.currentBusinessGroup;
    const masterCorpSegmentsData = state.corpSegmentsStore.masterCorpSegments;

    if (!businessGroup || !masterCorpSegmentsData) {
      return [];
    }

    return getTransformedCorpSegments(businessGroup.corp_segments, masterCorpSegmentsData);
  }
);

export const fetchCostCenterDropdownValuesOfAllBusinessGroups = createAsyncThunk(
  'forecastTemplate/fetchCostCenterDropdownValuesOfAllBusinessGroups',
  async (_, { getState }) => {
    const state = getState() as RootState;

    const businessGroups = state.businessGroupStore.businessGroups;
    const masterCorpSegmentsData = state.corpSegmentsStore.masterCorpSegments;
    const masterCostCenterInfo = masterCorpSegmentsData?.find((corpSegment) => corpSegment.segment_name === eCorpSegmentNames.COST_CENTER);

    if (!businessGroups || !masterCorpSegmentsData || !masterCostCenterInfo) {
      return [];
    }

    const costCenterSelectedValuesOfAllBusinessGroups: string[] = [];

    for (const businessGroup of businessGroups) {
      try {
        const costCenterSegment = businessGroup.corp_segments.find((corpSegment) => corpSegment.corp_segment_name === eCorpSegmentNames.COST_CENTER)!;
        const corpSegmentData = (await getFileFromS3URI(costCenterSegment?.corp_segment_values_s3_path)) as unknown as SegmentHierarchy[];
        const filteredCostCenterHierarchy = filterOutMasterCorpSegmentsFromSelectedValues(
          masterCostCenterInfo.segment_hierarchy_data_from_s3,
          corpSegmentData
        );
        const costCenters = convertToDropdownValues(
          filteredCostCenterHierarchy,
          masterCostCenterInfo.segment_name
        ).masterCorpSegmentDropdownValues.map((eachDropdownOption: OptionDropdown) => eachDropdownOption.label);
        costCenterSelectedValuesOfAllBusinessGroups.push(...costCenters);
      } catch (error: any) {
        logger.error('Error fetching cost center data for business group:', {
          ...error,
          businessGroup: businessGroup.data_classification.data_classification_name
        });
      }
    }
    return costCenterSelectedValuesOfAllBusinessGroups?.sort((a, b) => a.localeCompare(b));
  }
);

const corpSegmentSlice = createSlice({
  name: 'corpSegment',
  initialState: corpSegmentInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCorpSegments.pending, (state) => {
        state.loadingStatus = LoadingStatus.Loading;
        state.masterCorpSegmentsStatus.status = LoadingStatus.Loading;
      })
      .addCase(fetchCorpSegments.fulfilled, (state, action) => {
        state.masterCorpSegments = action.payload;
        state.masterCorpSegmentsStatus.status =
          action.payload.length === Object.keys(eCorpSegmentNames).length ? LoadingStatus.Completed : LoadingStatus.Failed;
        state.masterCorpSegmentsStatus.messageForError =
          state.masterCorpSegmentsStatus.status === LoadingStatus.Failed ? XptMessages.MASTER_CORP_SEGMENTS_LOADING_FAILED : '';
        updateLoadingStatus(state, LoadingStatus.Completed);
      })
      .addCase(fetchCorpSegments.rejected, (state) => {
        state.loadingStatus = LoadingStatus.Failed;
        state.masterCorpSegmentsStatus.status = LoadingStatus.Failed;
      })
      .addCase(fetchMasterCorpSegmentDropdownValues.fulfilled, (state, action) => {
        state.masterCorpSegmentDropdownValues = action.payload;
      })
      .addCase(fetchMasterCorpSegmentDropdownValues.rejected, (state) => {
        state.masterCorpSegmentDropdownValues = [];
      })
      .addCase(fetchCostCenterDropdownValuesOfAllBusinessGroups.fulfilled, (state, action) => {
        state.masterCostCenterDropdownValuesForAllBusinessGroups = action.payload;
      })
      .addCase(fetchCostCenterDropdownValuesOfAllBusinessGroups.rejected, (state) => {
        state.masterCostCenterDropdownValuesForAllBusinessGroups = [];
      })
      .addCase(fetchBusinessSegments.pending, (state) => {
        state.loadingStatus = LoadingStatus.Loading;
      })
      .addCase(fetchBusinessSegments.fulfilled, (state, action) => {
        state.masterBusinessSegments = action.payload;
        updateLoadingStatus(state, LoadingStatus.Completed);
      })
      .addCase(fetchBusinessSegments.rejected, (state) => {
        state.loadingStatus = LoadingStatus.Failed;
      });
  }
});

export default corpSegmentSlice.reducer;
