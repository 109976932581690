import { BudgetOwnershipTransfer, BudgetOwnershipTransferWithBusinessGroups } from 'src/models/BudgetOwnerTransferModel';
import { AdminBaseBreadcrumbs } from '../AdminConsole';
import { DynamicColumnDetails } from 'src/models/XptTableModel';
import { StatusIndicator, TableProps } from '@amzn/awsui-components-react';
import { getStatusIndicatorColorOverride, getStatusIndicatorType } from 'src/utils/generic-utilities';
import { eEntityStatus } from 'src/constants/generic-constants';
import { convertsUTCtoUserLocalAndReturnsReadableFormat } from 'src/utils/date-time-utilities';
import { BusinessGroupEntity } from 'src/models/AppContextModels';
import { getBusinessGroupById } from 'src/features/business-group/BusinessGroupUtils';

export const geBOTransferBreadcrumbItems = () => {
  return [
    ...AdminBaseBreadcrumbs,
    {
      text: 'Budget Owner Transfer',
      href: '/admin-console/budget-owner-transfer'
    }
  ];
};

export const BUDGET_OWNER_TRANSFER_CONFIG: DynamicColumnDetails = {
  columnInfo: {
    entityName: 'Budget Owner Transfer',
    trackBy: 'log_id',
    sortingColumn: 'last_updated_at',
    isDescending: true
  },
  data: [
    {
      fieldName: 'performed_by',
      displayName: 'Performed By',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'from_alias',
      displayName: 'From Alias',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'to_alias',
      displayName: 'To Alias',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'number_of_forecast_inputs_updated',
      displayName: 'Effected Records',
      canBeHiddenFromProperties: true,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'number'
      }
    },
    {
      fieldName: 'business_groups',
      displayName: 'Business Groups',
      canBeHiddenFromProperties: true,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'status',
      displayName: 'Status',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'last_updated_at',
      displayName: 'Last Updated At',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    }
  ]
};

export const getBudgetOwnerTransferDefinitions = (): TableProps.ColumnDefinition<BudgetOwnershipTransferWithBusinessGroups>[] => [
  {
    id: 'log_id',
    header: 'Id',
    cell: (item: BudgetOwnershipTransferWithBusinessGroups) => item.log_id,
    sortingField: 'log_id'
  },
  {
    id: 'performed_by',
    header: 'Performed By',
    cell: (item: BudgetOwnershipTransferWithBusinessGroups) => item.performed_by,
    sortingField: 'performed_by',
    isRowHeader: true
  },
  {
    id: 'from_alias',
    header: 'From Alias',
    cell: (item: BudgetOwnershipTransferWithBusinessGroups) => item.from_alias,
    sortingField: 'from_alias',
    isRowHeader: true
  },
  {
    id: 'to_alias',
    header: 'To Alias',
    cell: (item: BudgetOwnershipTransferWithBusinessGroups) => item.to_alias,
    sortingField: 'to_alias',
    isRowHeader: true
  },
  {
    id: 'number_of_forecast_inputs_updated',
    header: 'Effected Records',
    cell: (item: BudgetOwnershipTransferWithBusinessGroups) =>
      item.number_of_forecast_inputs_updated !== null ? item.number_of_forecast_inputs_updated : '-',
    sortingField: 'number_of_forecast_inputs_updated',
    isRowHeader: true
  },
  {
    id: 'business_groups',
    header: 'Business Groups',
    cell: (item: BudgetOwnershipTransferWithBusinessGroups) => item.business_groups?.join(', '),
    sortingField: 'business_groups',
    isRowHeader: true
  },
  {
    id: 'status',
    header: 'Status',
    cell: (item: BudgetOwnershipTransferWithBusinessGroups) => {
      if (!item.status) {
        // Handling the null case, e.g., by displaying a default value or a specific UI element
        return <div>-</div>;
      }
      return (
        <StatusIndicator
          type={getStatusIndicatorType(item.status as eEntityStatus)}
          colorOverride={getStatusIndicatorColorOverride(item.status as eEntityStatus)}
        >
          {item.status}
        </StatusIndicator>
      );
    },
    sortingField: 'status',
    isRowHeader: true
  },
  {
    id: 'last_updated_at',
    header: 'Last Updated At',
    cell: (item: BudgetOwnershipTransferWithBusinessGroups) => convertsUTCtoUserLocalAndReturnsReadableFormat(item.last_updated_at),
    sortingField: 'last_updated_at',
    isRowHeader: true
  }
];

export const BUDGET_OWNER_MESSAGES = {
  UNABLE_TO_LOAD_AUDIT_LOG: 'Unable to load audit log data',
  UNABLE_TO_LOAD_BUDGET_OWNERS: 'Unable to load current budget owners',
  BUDGET_OWNER_TRANSFER_IN_PROGRESS: 'Budget Owner Transfer in progress',
  BUDGET_OWNER_TRANSFER_SUCCESSFUL: (noOfRecordsUpdated: number) =>
    `Budget Owner Transfer successful. ${noOfRecordsUpdated} record${noOfRecordsUpdated > 1 ? 's' : ''} updated.`,
  BUDGET_OWNER_TRANSFER_FAILED: 'Budget Owner Transfer failed',
  NO_DATA_TO_TRANSFER: 'No data found to transfer.'
};

export const parseBudgetOwnerTransfer = (
  budgetOwnershipTransfer: BudgetOwnershipTransfer[],
  allBusinessGroups: BusinessGroupEntity[]
): BudgetOwnershipTransferWithBusinessGroups[] => {
  return budgetOwnershipTransfer.map((budgetOwnerTransferRecord) => {
    const businessGroups = budgetOwnerTransferRecord.data_classification_id_lists
      ? budgetOwnerTransferRecord.data_classification_id_lists.map(
          (data_classification_id) =>
            getBusinessGroupById(data_classification_id, allBusinessGroups)?.data_classification.data_classification_name || ''
        )
      : [];

    return {
      ...budgetOwnerTransferRecord,
      business_groups: businessGroups
    };
  });
};
