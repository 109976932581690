import { Box, Popover, PopoverProps, StatusIndicator } from '@amzn/awsui-components-react';
import React from 'react';

interface FormFieldPopoverProps {
  header: string;
  dismissButton?: boolean;
  content?: React.ReactNode;
  children?: React.ReactNode;
  triggerType?: PopoverProps.TriggerType;
}
const FormFieldPopover: React.FC<FormFieldPopoverProps> = ({ dismissButton, header, content, children, triggerType }: FormFieldPopoverProps) => {
  return (
    <>
      <Popover
        dismissButton={dismissButton || false}
        position="top"
        size="medium"
        triggerType={triggerType ? triggerType : 'custom'}
        header={header}
        content={content}
      >
        {children ? children : <StatusIndicator type="info"></StatusIndicator>}
      </Popover>
    </>
  );
};

export default FormFieldPopover;
