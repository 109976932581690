import { Box, Container, ContentLayout, Flashbar, FlashbarProps, FormField, Select, SelectProps, SpaceBetween } from '@amzn/awsui-components-react';
import { ColDef } from 'ag-grid-community';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logger } from 'src/analytics/KatalLogger';
import { XptAppLayout } from 'src/components/common/xpt-app-layout/XptAppLayout';
import { XPTBreadcrumbs } from 'src/components/common/XptBreadcrumb';
import { useFlashbar } from 'src/hooks/useFlashbar';
import { getLocalStorageItem, setLocalStorageItem } from 'src/hooks/useLocalStorage';
import { PlanningCycle } from 'src/models/PlanningCycleModel';
import { XptForecastReportGridRowData, XptReportExportFileDetails, XptReportRowDataStructured } from 'src/models/XptReportingModels';
import { AppDispatch, RootState } from 'src/store/store';
import { deepClone } from 'src/utils/ag-grid-utils';
import { getFileFromS3URI } from 'src/utils/aws-s3-services';
import { useAuth } from '../auth/AuthContextProvider';
import { businessGroupBaseBreadcrumbs, currentBusinessGroupName } from '../business-group/businessGroupSelectors';
import { getForecastTemplateHeaderInfo } from '../business-group/forecast-template-v2/forecast-utils/ForecastTemplateUtils';
import { selectAllPlanningCyclesForCurrentGroup, selectScenarioSnapshotPerBusinessGroup } from '../planning-cycle/planningCycleSelector';
import BusinessGroupSideNavigation from '../xpt-layout/XptBusinessGroupSideNavigation';
import { generateColumnDefinitions } from './XptReportColumnGenerator';
import { ReportGrid } from './XptReportGrid';
import { ReportTypes, XptReportGridFixedFields } from './XptReportGridConstants';
import {
  flattenXptReportRowDataStructured,
  getPlanningCyclesDropdownOptions,
  getSelectedPlanningCycle,
  getXptReportFileName,
  getXptReportsBreadcrumbItems
} from './XptReportingUtils';

const EMPTY_OPTION: SelectProps.Option | null = null;
export const XptForecastReport: React.FC = () => {
  const appLayout = useRef<any>();
  const { Alias } = useAuth();

  const dispatch = useDispatch<AppDispatch>();
  const currentBusinessGroup = useSelector((state: RootState) => state.businessGroupStore.currentBusinessGroup);
  const businessGroupName = useSelector(currentBusinessGroupName);
  const data_classification_id = currentBusinessGroup?.data_classification.data_classification_id;
  const dataClassificationShortDesc: string | undefined = currentBusinessGroup?.data_classification.data_classification_short_description;
  const gridStateKey = `UniqueGridStateKey-XptForecastReport-${dataClassificationShortDesc}`;

  const businessGroupBaseBreadcrumb = useSelector(businessGroupBaseBreadcrumbs);
  const { flashbarItems, displayFlashMessage, clearSpecificFlashMessage, clearAllMessages } = useFlashbar();

  const scenarios = useSelector((state: RootState) => state.planningCycleStore.scenarios);
  const planningCycles = useSelector(selectAllPlanningCyclesForCurrentGroup);
  const scenarioSnapshots = useSelector(selectScenarioSnapshotPerBusinessGroup);

  const [planningCycleOptions, setPlanningCycleOptions] = useState<SelectProps.OptionGroup[]>([]);
  const [planningCycleOptionsFlattened, setPlanningCycleOptionsFlattened] = useState<SelectProps.Options>([]);

  const [localStorageKey, setLocalStorageKey] = useState<string | null>(null);
  const [selectedPlanningCycle, setSelectedPlanningCycleState] = useState<SelectProps.Option | null>(EMPTY_OPTION);

  const [columnDefinitions, setColumnDefinitions] = useState<ColDef[]>([]);
  const [xptReportData, setXptReportData] = useState<XptForecastReportGridRowData[]>([]);
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);

  const [exportFileDetails, setExportFileDetails] = useState<XptReportExportFileDetails>();

  const notificationMessage = (content: string, flashBarType: FlashbarProps.Type, isDismissible: boolean, messageId?: string) => {
    displayFlashMessage(content, flashBarType, isDismissible, messageId);
  };

  // Once Planning Cycle & Snapshots are available, will create Dropdown options for Planning Cycle
  useEffect(() => {
    const planningCycleDropdownOptions: SelectProps.OptionGroup[] = getPlanningCyclesDropdownOptions(scenarios, planningCycles, scenarioSnapshots);
    setPlanningCycleOptions(planningCycleDropdownOptions);
    const flattened = planningCycleDropdownOptions.flatMap((option) => option.options);
    setPlanningCycleOptionsFlattened(flattened);
  }, [scenarios, planningCycles, scenarioSnapshots]);

  useEffect(() => {
    if (dataClassificationShortDesc) {
      const key = `xpt-Reports-SelectedPlanningCycle-${dataClassificationShortDesc}`;
      setLocalStorageKey(key);
      const localStorageItem = getLocalStorageItem<SelectProps.Option>(key);
      if (localStorageItem && localStorageItem?.value) {
        const validOptions = planningCycleOptionsFlattened.map(pl => pl.label);
       if(validOptions.includes(localStorageItem?.label)) {
        setSelectedPlanningCycleState(localStorageItem);
       } else {
        setSelectedPlanningCycleState(planningCycleOptionsFlattened[0] || null);
       }
      } else if (planningCycleOptionsFlattened.length > 0) {
        setSelectedPlanningCycleState(planningCycleOptionsFlattened[0]);
      }
    }
  }, [dataClassificationShortDesc, planningCycleOptionsFlattened, dispatch]);

  // Persisting Planning Cycle selection
  useEffect(() => {
    if (localStorageKey) {
      setLocalStorageItem(localStorageKey, selectedPlanningCycle);
    }
  }, [selectedPlanningCycle, localStorageKey]);

  useEffect(() => {
    if (dataClassificationShortDesc && selectedPlanningCycle) {
      const { fileName, sheetName } = getXptReportFileName(dataClassificationShortDesc, selectedPlanningCycle?.label || '', ReportTypes.FORECAST);
      setExportFileDetails({ fileName, sheetName });
    }
  }, [dataClassificationShortDesc, selectedPlanningCycle]);

  const handlePlanningCycleChange = (detail: SelectProps.ChangeDetail) => {
    setSelectedPlanningCycleState(detail.selectedOption);
  };

  useEffect(() => {
    initializeReport();
  }, [selectedPlanningCycle]);

  const initializeReport = () => {
    if (!selectedPlanningCycle || !data_classification_id || !dataClassificationShortDesc) return;

    try {
      const selectedPlanningCycleData = getSelectedPlanningCycle(
        selectedPlanningCycle,
        data_classification_id,
        dataClassificationShortDesc,
        scenarioSnapshots,
        planningCycles
      );

      if (selectedPlanningCycleData) {
        const { path, planningCycleSelected } = selectedPlanningCycleData;
        generateReport(path, planningCycleSelected);
      }
    } catch (error: any) {
      logger.error('Error generating forecast report s3 paths:', error);
    }
  };

  const generateReport = async (planningCycleS3Path: string | undefined, planningCycleSelected: PlanningCycle | undefined) => {
    if (planningCycleSelected && currentBusinessGroup && planningCycleS3Path) {
      logger.info(`Generating Forecast report for ${planningCycleSelected?.planning_cycle_year}`);
      setIsGeneratingReport(true);
      const forecastHeaderInfo = getForecastTemplateHeaderInfo(currentBusinessGroup, planningCycleSelected);
      const optionalCorpSegmentsHeader = forecastHeaderInfo.corpSegmentOptionalFields;
      const forecastRowDataResponseFromS3 = (await getFileFromS3URI(planningCycleS3Path)) as unknown as XptReportRowDataStructured[];
      const flattenedData: XptForecastReportGridRowData[] = flattenXptReportRowDataStructured(
        forecastRowDataResponseFromS3,
        optionalCorpSegmentsHeader,
        planningCycleSelected
      );

      const mutableForecastRowData = deepClone(flattenedData);
      setXptReportData(mutableForecastRowData);

      const colDefs = await generateColumnDefinitions(Alias, planningCycleSelected, currentBusinessGroup);
      setColumnDefinitions(colDefs);

      setIsGeneratingReport(false);
    }
  };

  return (
    <>
      <XptAppLayout
        ref={appLayout}
        headerSelector="#h"
        navigation={<BusinessGroupSideNavigation />}
        toolsHide={true}
        breadcrumbs={<XPTBreadcrumbs items={getXptReportsBreadcrumbItems(businessGroupBaseBreadcrumb, businessGroupName)} />}
        notifications={<Flashbar stackItems items={flashbarItems} />}
        stickyNotifications={true}
        maxContentWidth={Number.MAX_VALUE}
        contentType="default"
        content={
          <div className="xpt-app-layout-content">
            <ContentLayout disableOverlap>
              <Container disableHeaderPaddings disableContentPaddings>
                <Box padding={{ top: 's', right: 's', bottom: 'm', left: 's' }}>
                  <SpaceBetween size="m" direction="horizontal">
                    <FormField label="Planning Cycle" className="width-25-rem">
                      <Select
                        options={planningCycleOptions}
                        selectedOption={selectedPlanningCycle}
                        onChange={({ detail }) => handlePlanningCycleChange(detail)}
                      />
                    </FormField>
                  </SpaceBetween>
                </Box>
              </Container>
              <ReportGrid
                columnDefs={columnDefinitions}
                rowData={xptReportData}
                uniqueIdField={XptReportGridFixedFields.XptLineItemSeqId.value}
                isLoading={isGeneratingReport}
                localStorageKey={gridStateKey}
                refreshReport={initializeReport}
                exportFileDetails={exportFileDetails}
                notificationMessage={notificationMessage}
              />
            </ContentLayout>
          </div>
        }
      />
    </>
  );
};
