import React from 'react';
import { Link } from '@amzn/awsui-components-react';
import { PHONE_TOOL_URL } from 'src/constants/generic-constants';
import UserImageIcon from './UserImageIcon';

interface XptUserAliasNameWithImgIconProps {
  userAlias: string;
}

/**
 * XptUserAliasNameWithImgIcon component displays a user alias with an image icon
 * and a link to the user's profile in the phone tool.
 */
export const XptUserAliasNameWithImgIcon: React.FC<XptUserAliasNameWithImgIconProps> = ({ userAlias }) => {
  const userPhoneToolLink = `${PHONE_TOOL_URL}${userAlias}`;

  return (
    <div className="user-alias-img">
      <UserImageIcon alias={userAlias} />
      <Link target="_blank" variant="secondary" href={userPhoneToolLink}>
        {userAlias}
      </Link>
    </div>
  );
};

export default XptUserAliasNameWithImgIcon;
