import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { logger } from 'src/analytics/KatalLogger';

interface DropdownValidation {
  dropdownData: string[];
  column: string;
  startsAtRow: number;
}

export interface GenerateAndDownloadExcelFileParams {
  data: string | Blob | undefined;
  sheetName: string;
  fileNameWithoutExtension: string;
  validations: DropdownValidation[];
}

/**
 * Generates and downloads an Excel file with specified dropdown values for specified columns.
 * @param {GenerateAndDownloadExcelFileParams} params - Parameters for the function.
 */
const exportExcelWithDropdowns = async (params: GenerateAndDownloadExcelFileParams): Promise<void> => {
  const { data, sheetName, fileNameWithoutExtension, validations } = params;

  if (!data) {
    logger.error('No data to export');
    return;
  }

  // Create a new workbook
  const workbook = new ExcelJS.Workbook();

  // Load the existing data into the workbook if it exists
  const existingDataBuffer = data instanceof Blob ? await data.arrayBuffer() : new ArrayBuffer(0);
  let worksheet = workbook.addWorksheet(sheetName);
  if (existingDataBuffer.byteLength > 0) {
    await workbook.xlsx.load(existingDataBuffer);
    const existingWorksheet = workbook.getWorksheet(1);
    if (existingWorksheet) {
      worksheet = existingWorksheet;
    }
  }

  // Apply data validation for dropdowns based on the provided validations array
  validations.forEach(({ dropdownData, column, startsAtRow }, index) => {
    // Define the list as a named range in the workbook
    const listSheet = workbook.addWorksheet(`List${index + 1}`);
    dropdownData.forEach((item, i) => {
      listSheet.getCell(`A${i + 1}`).value = item;
    });

    const rangeName = `DropdownRange${index + 1}`;
    workbook.definedNames.add(rangeName, `List${index + 1}!$A$1:$A$${dropdownData.length}`);

    const lastRow = worksheet.lastRow?.number || 1;
    for (let row = startsAtRow; row <= lastRow; row++) {
      worksheet.getCell(`${column}${row}`).dataValidation = {
        type: 'list',
        allowBlank: false,
        formulae: [`=${rangeName}`]
      };
    }
  });

  // Generate the binary data for the new workbook
  const buffer = await workbook.xlsx.writeBuffer();

  // Convert the buffer to a Blob
  const blob = new Blob([buffer], { type: 'application/octet-stream' });

  // Save the Blob as an Excel file
  saveAs(blob, `${fileNameWithoutExtension}.xlsx`);
};

export default exportExcelWithDropdowns;
