import {
  GetObjectCommand,
  HeadObjectCommand,
  ListObjectsV2Command,
  ListObjectsV2CommandInput,
  PutObjectCommand,
  PutObjectCommandOutput,
  S3Client,
  S3ClientConfig
} from '@aws-sdk/client-s3';
import ENVIRONMENT_VARIABLES from 'src/constants/environment-variables';
import { eErrorMessages } from 'src/constants/generic-constants';
import { logger } from '../analytics/KatalLogger';
import { getAWSServiceCredentials } from './auth-token-services';
import { getMoment, getTimeDifference } from './date-time-utilities';
import { formatBytes } from './generic-utilities';

// Function to create S3 client
const getS3Client = async () => {
  const s3ClientConfig: S3ClientConfig = {
    region: ENVIRONMENT_VARIABLES.env.Region,
    credentials: await getAWSServiceCredentials()
  };
  const client = new S3Client(s3ClientConfig);
  return client;
};

export const getListOfObjectsFromKeys = async (bucket: string, s3Key: string) => {
  const s3Client = await getS3Client();
  const listCommandInput: ListObjectsV2CommandInput = {
    Bucket: bucket,
    StartAfter: s3Key
  };
  const listCommand = new ListObjectsV2Command(listCommandInput);
  const listOfObjects = await s3Client.send(listCommand);
  return listOfObjects;
};

/**
 * Parses an S3 URI to extract the bucket name and key.
 * @param s3URI The S3 URI to parse (e.g., s3://bucket-name/key).
 * @returns An object containing the bucket name and key.
 * @throws Will throw an error if the S3 URI is invalid.
 */
export const parseS3URI = (s3URI: string): { bucket: string; s3Key: string } => {
  const match = s3URI.match(/^s3:\/\/([^/]+)\/(.+)/);
  if (match) {
    const [, bucket, s3Key] = match;
    return {
      bucket,
      s3Key
    };
  } else {
    throw new Error(`Invalid S3 URI: ${s3URI}`);
  }
};

/**
 * Downloads a file from S3 using its URI.
 * @param s3URI The S3 URI of the file to download (e.g., s3://bucket-name/key).
 * @returns The parsed JSON data from the file.
 * @throws Will throw an error if there is an issue downloading the file.
 */
export const getFileFromS3URI = async (s3URI: string): Promise<JSON> => {
  const startTime = getMoment(); // Record the start time

  try {
    const s3Client = await getS3Client();
    const { bucket, s3Key } = parseS3URI(s3URI);

    // Check if the file exists
    const headCommand = new HeadObjectCommand({
      Bucket: bucket,
      Key: s3Key
    });

    try {
      await s3Client.send(headCommand);
    } catch (headError: any) {
      if (headError.name === 'NotFound') {
        throw new Error(eErrorMessages.NO_DATA_FOUND);
      }
      throw headError;
    }

    // Get the file
    const getCommand = new GetObjectCommand({
      Bucket: bucket,
      Key: s3Key,
      ResponseCacheControl: 'no-cache'
    });
    const getResponse = await s3Client.send(getCommand);

    const body = await getResponse.Body?.transformToString('utf-8');

    if (body) {
      const data = JSON.parse(body) as JSON;
      const duration = getTimeDifference(startTime);
      logger.debug(
        `File downloaded from S3. Bucket: ${bucket}, S3 Key: ${s3Key}, File Size in S3: ${formatBytes(
          getResponse.ContentLength || 0
        )}, File Size After Fetch: ${formatBytes(body.length)}, Duration: ${duration}ms.`
      );
      return data;
    } else {
      throw new Error(`Failed to download content file from S3 bucket ${bucket}`);
    }
  } catch (error: any) {
    logger.error('Error while downloading file from S3:', error);
    throw error;
  }
};

/**
 * Uploads data to S3 as either a JSON or text file.
 * @param bucketName The name of the S3 bucket.
 * @param key The key for the stored file in the S3 bucket.
 * @param data The data to upload. Can be any type.
 * @param isJson Whether the data should be uploaded as a JSON file.
 * @param metadata Optional metadata for the S3 object.
 * @returns The response from the S3 PutObject command.
 */
export const uploadToS3 = async (
  bucketName: string,
  key: string,
  data: any,
  isJson: boolean,
  metadata?: Record<string, string>
): Promise<PutObjectCommandOutput> => {
  try {
    const s3Client = await getS3Client();
    const content = JSON.stringify(data);

    const putObjectCommand = new PutObjectCommand({
      Bucket: bucketName,
      Key: key,
      Body: content,
      ContentType: isJson ? 'application/json' : 'text/plain',
      Metadata: metadata
    });

    const s3Response: PutObjectCommandOutput = await s3Client.send(putObjectCommand);

    logger.info(`${isJson ? 'JSON' : 'Text'} file uploaded successfully to s3://${bucketName}/${key}`);
    logger.info(`Response Metadata: Request ID: ${s3Response.$metadata.requestId}, HTTP Status: ${s3Response.$metadata.httpStatusCode}`);

    return s3Response;
  } catch (error: any) {
    logger.error(`Error uploading ${isJson ? 'JSON' : 'text'} to S3`, error);
    throw error;
  }
};
