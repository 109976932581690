import { Badge, BreadcrumbGroupProps, Button, SelectProps, StatusIndicator, TableProps } from '@amzn/awsui-components-react';
import { MasterScenarios, PlanningCycleFlatTable, PlanningCycleGroupedByScenarioYear } from 'src/models/PlanningCycleModel';
import { DynamicColumnDetails } from 'src/models/XptTableModel';
import { AdminBaseBreadcrumbs } from '../admin-console/AdminConsole';

export const defaultSelection: SelectProps.Option = { label: 'Current Planning Cycles', value: 'active' };

export const planningCycleOptions: SelectProps.Options = [
  defaultSelection,
  { label: 'Historical Planning Cycles', value: 'historical' },
  { label: 'All Planning Cycles', value: 'all' }
];

export const getNonAdminPlanningBreadcrumbItems = (
  businessGroupBaseBreadcrumbs: BreadcrumbGroupProps.Item[],
  currentBusinessGroupName?: string
): BreadcrumbGroupProps.Item[] => {
  if (!currentBusinessGroupName) {
    return businessGroupBaseBreadcrumbs;
  }

  return [
    ...businessGroupBaseBreadcrumbs,
    {
      text: 'Planning cycle',
      href: `/${currentBusinessGroupName}/planning-cycle`
    }
  ];
};

export const getAdminPlanningBreadcrumbItems = (placeOfDisplay: 'table' | 'form'): BreadcrumbGroupProps.Item[] => {
  if (placeOfDisplay === 'table') {
    return [
      ...AdminBaseBreadcrumbs,
      {
        text: 'Planning cycle',
        href: '/admin-console/planning-cycle'
      }
    ];
  } else {
    return [
      ...AdminBaseBreadcrumbs,
      {
        text: 'Planning cycle',
        href: '/admin-console/planning-cycle'
      },
      {
        text: 'Planning cycle management',
        href: ''
      }
    ];
  }
};

export const ADMIN_PLANNING_TABLE_CONFIG: DynamicColumnDetails = {
  columnInfo: {
    entityName: 'Planning Cycles',
    trackBy: 'scenario_year',
    sortingColumn: 'scenario_name',
    isDescending: true
  },
  data: [
    {
      fieldName: 'scenario_name',
      displayName: 'Planning Cycle',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'scenario_year',
      displayName: 'Scenario',
      canBeHiddenFromProperties: true,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'data_classification_name_csv',
      displayName: 'Business Groups',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'lock_status_summary',
      displayName: 'Lock Status',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: false,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'actions',
      displayName: '',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: false,
      metadata: {
        type: 'string'
      }
    }
  ]
};

// Add a new type for the callback function
type LockStatusClickHandler = (item: PlanningCycleGroupedByScenarioYear) => void;
type EditPlanningCycleClickHandler = (item: PlanningCycleGroupedByScenarioYear) => void;

export const getAdminPlanningCycleTableDefinitions = (
  onLockStatusClick: LockStatusClickHandler,
  onEditPlanningCycle: EditPlanningCycleClickHandler
) =>
  [
    {
      id: 'scenario_name',
      header: 'Planning Cycle',
      cell: (item: PlanningCycleGroupedByScenarioYear) => item.scenario_name,
      sortingField: 'scenario_name',
      sortingComparator: (a, b) => a.scenario_name.localeCompare(b.scenario_name)
    },
    {
      id: 'scenario_year',
      header: 'Scenario',
      cell: (item: PlanningCycleGroupedByScenarioYear) => item.scenario_year,
      sortingField: 'scenario_year'
    },
    {
      id: 'data_classification_name_csv',
      header: 'Business Groups',
      cell: (item: PlanningCycleGroupedByScenarioYear) => item.data_classification_name_csv,
      sortingField: 'data_classification_name_csv'
    },
    {
      id: 'lock_status_summary',
      header: 'Lock Status',
      cell: (item: PlanningCycleGroupedByScenarioYear) => (
        <Button variant="inline-link" ariaLabel={`Lock Status`} onClick={() => onLockStatusClick(item)}>
          {item.lock_status_summary}
        </Button>
      ),
      sortingField: 'lock_status_summary'
    },
    {
      id: 'actions',
      header: '',
      cell: (item: PlanningCycleGroupedByScenarioYear) => (
        <Button variant="inline-link" ariaLabel={`Edit`} iconAlign="right" iconName="angle-right" onClick={() => onEditPlanningCycle(item)}>
          Edit
        </Button>
      ),
      minWidth: 170
    }
  ] as TableProps.ColumnDefinition<PlanningCycleGroupedByScenarioYear>[];

export const NON_ADMIN_PLANNING_TABLE_CONFIG: DynamicColumnDetails = {
  columnInfo: {
    entityName: 'Planning Cycles',
    trackBy: 'scenario_seq_id',
    sortingColumn: 'scenario_name',
    isDescending: true
  },
  data: [
    {
      fieldName: 'scenario_name',
      displayName: 'Planning Cycle',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'scenario_year',
      displayName: 'Scenario',
      canBeHiddenFromProperties: true,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'planning_cycle_bo_lock_status',
      displayName: 'Status',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: false,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'actuals_date_range',
      displayName: 'Actuals date range',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: false,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'forecast_date_range',
      displayName: 'Forecast date range',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: false,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'planning_cycle_window',
      displayName: 'Planning cycle window',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: false,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'budget_owner_lock_date',
      displayName: 'Budget owner lock date',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: false,
      metadata: {
        type: 'string'
      }
    }
  ]
};

export const getNonAdminPlanningCycleTableDefinitions = (): TableProps.ColumnDefinition<PlanningCycleFlatTable>[] => [
  {
    id: 'scenario_name',
    header: 'Planning Cycle',
    cell: (item: PlanningCycleFlatTable) => item.scenario_name
  },
  {
    id: 'scenario_year',
    header: 'Scenario',
    cell: (item: PlanningCycleFlatTable) => item.scenario_year,
    sortingField: 'scenario_year'
  },
  {
    id: 'planning_cycle_bo_lock_status',
    header: 'Status',
    cell: (item: PlanningCycleFlatTable) => (
      <Badge color={item.planning_cycle_bo_lock_status === 'Locked' ? 'red' : 'green'}>{item.planning_cycle_bo_lock_status}</Badge>
    )
  },
  {
    id: 'actuals_date_range',
    header: 'Actuals date range',
    cell: (item: PlanningCycleFlatTable) => item.actuals_date_range
  },
  {
    id: 'forecast_date_range',
    header: 'Forecast date range',
    cell: (item: PlanningCycleFlatTable) => item.forecast_date_range
  },
  {
    id: 'budget_owner_lock_date',
    header: 'Budget owner lock date',
    cell: (item: PlanningCycleFlatTable) =>
      item.budget_owner_lock_date ? item.budget_owner_lock_date : <StatusIndicator type={'warning'}>{'Lock date missing'}</StatusIndicator>
  },
  {
    id: 'planning_cycle_window',
    header: 'Planning cycle window',
    cell: (item: PlanningCycleFlatTable) => item.planning_cycle_window
  },
];

export const planningCycleLockStatusModalColumnDefinition = (): TableProps.ColumnDefinition<PlanningCycleFlatTable>[] => [
  {
    id: 'data_classification_name',
    header: 'Business Group',
    cell: (item: PlanningCycleFlatTable) => item.data_classification_name
  },
  {
    id: 'actuals_date_range',
    header: 'Actuals date range',
    cell: (item: PlanningCycleFlatTable) => item.actuals_date_range
  },
  {
    id: 'forecast_date_range',
    header: 'Forecast date range',
    cell: (item: PlanningCycleFlatTable) => item.forecast_date_range
  },
  {
    id: 'planning_cycle_lock_status',
    header: 'Status',
    cell: (item: PlanningCycleFlatTable) => (
      <Badge color={item.planning_cycle_lock_status === 'Locked' ? 'red' : 'green'}>{item.planning_cycle_lock_status}</Badge>
    )
  },
  {
    id: 'planning_cycle_window',
    header: 'Planning cycle window',
    cell: (item: PlanningCycleFlatTable) => item.planning_cycle_window
  },
];

export const scenarioToSelectOptions = (scenario: MasterScenarios[]) => {
  return scenario
    .map((scenario) => {
      return {
        label: `${scenario?.scenario_name}`,
        value: `${scenario?.scenario_id}`
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const scenarioToPlanningCycleNameSelectOptions = (scenario: MasterScenarios[], scenarioName: string) => {
  return scenario
    .find((sce) => sce.scenario_name === scenarioName)
    ?.planning_cycle_name_list?.map((planning_cycle) => {
      return {
        label: `${planning_cycle}`,
        value: `${planning_cycle}`
      };
    })
    ?.sort((a, b) => a.label.localeCompare(b.label));
};
