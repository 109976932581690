import { KatSpinner } from '@amzn/katal-react';
import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import AppInitWrapper from './app/AppInitWrapper';

import { App } from './app/App';
import './index.scss';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './app/queryClient';

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOMClient.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <AppInitWrapper>
          <React.Suspense fallback={<KatSpinner size="large" />}>
            <App />
          </React.Suspense>
        </AppInitWrapper>
      </QueryClientProvider>
    </React.StrictMode>
  );
}
