/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../API';
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const listMasterSegments = /* GraphQL */ `query ListMasterSegments {
  listMasterSegments {
    segment_id
    segment_name
    segment_name_key
    segment_category
    description
    segment_data_type
    segment_hierarchy_s3_path
    
  }
}
` as GeneratedQuery<APITypes.ListMasterSegmentsQueryVariables, APITypes.ListMasterSegmentsQuery>;
export const listBusinessGroups = /* GraphQL */ `query ListBusinessGroups {
  listBusinessGroups {
    data_classification {
      data_classification_id
      data_classification_name
      data_classification_short_description
      data_classification_description
      
    }
    business_leaders_group {
      group_id
      group_name
      group_description
      is_restricted
      default_values {
        segment_hierarchy
        segment_description
        
      }
      user_list
      
    }
    business_owners_group {
      group_id
      group_name
      group_description
      is_restricted
      default_values {
        segment_hierarchy
        segment_description
        
      }
      user_list
      
    }
    restricted_groups {
      group_id
      group_name
      group_description
      is_restricted
      default_values {
        segment_hierarchy
        segment_description
        
      }
      user_list
      
    }
    corp_segments {
      corp_segment_id
      corp_segment_name
      corp_segment_name_key
      corp_segment_data_type
      corp_segment_required
      corp_segment_default_value {
        segment_hierarchy
        segment_description
        
      }
      corp_segment_values_s3_path
      
    }
    business_segments {
      business_segment_id
      is_id_column
      business_segment_name
      business_segment_name_key
      business_segment_data_type
      business_segment_values_s3_path
      
    }
    status
    item_metadata {
      is_active
      created_by
      created_at
      updated_by
      updated_at
      
    }
    
  }
}
` as GeneratedQuery<APITypes.ListBusinessGroupsQueryVariables, APITypes.ListBusinessGroupsQuery>;
export const listUserGroups = /* GraphQL */ `query ListUserGroups {
  listUserGroups {
    business_leaders_group {
      group_id
      group_name
      group_description
      is_restricted
      default_values {
        segment_hierarchy
        segment_description
        
      }
      user_list
      
    }
    business_owners_group {
      group_id
      group_name
      group_description
      is_restricted
      default_values {
        segment_hierarchy
        segment_description
        
      }
      user_list
      
    }
    development_group {
      group_id
      group_name
      group_description
      is_restricted
      default_values {
        segment_hierarchy
        segment_description
        
      }
      user_list
      
    }
    fpna_admin_group {
      group_id
      group_name
      group_description
      is_restricted
      default_values {
        segment_hierarchy
        segment_description
        
      }
      user_list
      
    }
    read_only_group {
      group_id
      group_name
      group_description
      is_restricted
      default_values {
        segment_hierarchy
        segment_description
        
      }
      user_list
      
    }
    
  }
}
` as GeneratedQuery<APITypes.ListUserGroupsQueryVariables, APITypes.ListUserGroupsQuery>;
export const listPlanningCycles = /* GraphQL */ `query ListPlanningCycles {
  listPlanningCycles {
    scenario_seq_id
    planning_cycle_year
    baseline_scenario {
      baseline_scenario_seq_id
      baseline_scenario_name
      
    }
    scenario {
      scenario_id
      scenario_name
      planning_cycle_name
      
    }
    is_active
    actuals_start_month_id
    actuals_end_month_id
    forecast_start_month_id
    forecast_end_month_id
    data_classification {
      data_classification_id
      data_classification_name
      data_classification_short_description
      data_classification_description
      
    }
    created_at
    created_by
    updated_at
    updated_by
    planning_cycle_open_date
    planning_cycle_lock_date
    budget_owner_lock_date
    forecast_data_available
    is_prophecy_enabled
    last_synced_to_prophecy
    prophecy_created_at
    prophecy_created_by
    
  }
}
` as GeneratedQuery<APITypes.ListPlanningCyclesQueryVariables, APITypes.ListPlanningCyclesQuery>;
export const listScenarios = /* GraphQL */ `query ListScenarios {
  listScenarios {
    scenario_id
    scenario_name
    planning_cycle_name_list
    
  }
}
` as GeneratedQuery<APITypes.ListScenariosQueryVariables, APITypes.ListScenariosQuery>;
export const listCurrentScenarios = /* GraphQL */ `query ListCurrentScenarios($dataClassificationId: Int!) {
  listCurrentScenarios(dataClassificationId: $dataClassificationId) {
    scenario_seq_id
    scenario_name
    
  }
}
` as GeneratedQuery<APITypes.ListCurrentScenariosQueryVariables, APITypes.ListCurrentScenariosQuery>;
export const listScenarioSnapshots = /* GraphQL */ `query ListScenarioSnapshots($dataClassificationId: Int!) {
  listScenarioSnapshots(dataClassificationId: $dataClassificationId) {
    scenario_name
    scenario_snapshot_id
    scenario_snapshot_name
    scenario_snapshot_note
    scenario_seq_id
    planning_cycle_name
    planning_cycle_year
    scenario_snapshot_time
    created_at
    updated_at
    created_by
    updated_by
    scenario_snapshot_status {
      entity_status
      entity_status_id
      
    }
    
  }
}
` as GeneratedQuery<APITypes.ListScenarioSnapshotsQueryVariables, APITypes.ListScenarioSnapshotsQuery>;
export const listEntityStatus = /* GraphQL */ `query ListEntityStatus {
  listEntityStatus {
    entity_status
    entity_status_id
    
  }
}
` as GeneratedQuery<APITypes.ListEntityStatusQueryVariables, APITypes.ListEntityStatusQuery>;
export const listContactDetails = /* GraphQL */ `query ListContactDetails {
  listContactDetails {
    contact_id
    group_name
    primary_contact {
      contact_alias
      contact_name
      
    }
    other_contacts {
      contact_alias
      contact_name
      
    }
    item_metadata {
      is_active
      created_by
      created_at
      updated_by
      updated_at
      
    }
    
  }
}
` as GeneratedQuery<APITypes.ListContactDetailsQueryVariables, APITypes.ListContactDetailsQuery>;
export const listResourcesDetails = /* GraphQL */ `query ListResourcesDetails {
  listResourcesDetails {
    resource_name
    resource_link
    item_metadata {
      is_active
      created_by
      created_at
      updated_by
      updated_at
      
    }
    
  }
}
` as GeneratedQuery<APITypes.ListResourcesDetailsQueryVariables, APITypes.ListResourcesDetailsQuery>;
export const listUserMetricsDetails = /* GraphQL */ `query ListUserMetricsDetails {
  listUserMetricsDetails {
    metrics_name
    metrics_payload
    created_at
    created_by
    
  }
}
` as GeneratedQuery<APITypes.ListUserMetricsDetailsQueryVariables, APITypes.ListUserMetricsDetailsQuery>;
export const listAnnouncementDetails = /* GraphQL */ `query ListAnnouncementDetails {
  listAnnouncementDetails {
    announcement_id
    data_classification {
      data_classification_id
      data_classification_name
      
    }
    announcement_title
    announcement_message
    announcement_date
    item_metadata {
      is_active
      created_by
      created_at
      updated_by
      updated_at
      
    }
    
  }
}
` as GeneratedQuery<APITypes.ListAnnouncementDetailsQueryVariables, APITypes.ListAnnouncementDetailsQuery>;
export const listActualsFilterData = /* GraphQL */ `query ListActualsFilterData($data_classification_id: Int!) {
  listActualsFilterData(data_classification_id: $data_classification_id) {
    actual_months
    cost_center {
      cost_center_code
      cost_center_description
      
    }
    
  }
}
` as GeneratedQuery<APITypes.ListActualsFilterDataQueryVariables, APITypes.ListActualsFilterDataQuery>;
export const listLineItemIds = /* GraphQL */ `query ListLineItemIds($data_classification_id: Int!) {
  listLineItemIds(data_classification_id: $data_classification_id)
}
` as GeneratedQuery<APITypes.ListLineItemIdsQueryVariables, APITypes.ListLineItemIdsQuery>;
export const listUsefulLifeDetails = /* GraphQL */ `query ListUsefulLifeDetails {
  listUsefulLifeDetails {
    useful_life_id
    cost_center_code
    expense_type
    useful_life
    is_active
    created_at
    created_by
    updated_at
    updated_by
    
  }
}
` as GeneratedQuery<APITypes.ListUsefulLifeDetailsQueryVariables, APITypes.ListUsefulLifeDetailsQuery>;
export const listExpenseTypeDetails = /* GraphQL */ `query ListExpenseTypeDetails {
  listExpenseTypeDetails {
    expense_type_id
    budget_type
    expense_type
    description
    group_info {
      group_name
      group_value
      data_classification_id
      
    }
    is_active
    created_at
    created_by
    updated_at
    updated_by
    
  }
}
` as GeneratedQuery<APITypes.ListExpenseTypeDetailsQueryVariables, APITypes.ListExpenseTypeDetailsQuery>;
export const listAuditLogDetails = /* GraphQL */ `query ListAuditLogDetails {
  listAuditLogDetails {
    audit_log_id
    page_name
    data_classification_id
    status
    record_count
    operation_type
    additional_info
    created_at
    created_by
    
  }
}
` as GeneratedQuery<APITypes.ListAuditLogDetailsQueryVariables, APITypes.ListAuditLogDetailsQuery>;
export const listCommodityDetails = /* GraphQL */ `query ListCommodityDetails {
  listCommodityDetails {
    commodity_id
    commodity
    description
    is_active
    created_at
    created_by
    updated_at
    updated_by
    
  }
}
` as GeneratedQuery<APITypes.ListCommodityDetailsQueryVariables, APITypes.ListCommodityDetailsQuery>;
export const listTransferBudgetOwnershipLog = /* GraphQL */ `query ListTransferBudgetOwnershipLog {
  listTransferBudgetOwnershipLog {
    log_id
    action
    performed_by
    from_alias
    to_alias
    last_updated_at
    status
    number_of_forecast_inputs_updated
    data_classification_id_lists
    
  }
}
` as GeneratedQuery<APITypes.ListTransferBudgetOwnershipLogQueryVariables, APITypes.ListTransferBudgetOwnershipLogQuery>;
export const listBudgetOwners = /* GraphQL */ `query ListBudgetOwners {
  listBudgetOwners {
    budget_owner
    data_classification_id_lists
    
  }
}
` as GeneratedQuery<APITypes.ListBudgetOwnersQueryVariables, APITypes.ListBudgetOwnersQuery>;
export const listBudgetTypeDetails = /* GraphQL */ `query ListBudgetTypeDetails {
  listBudgetTypeDetails {
    account_code
    budget_type
    
  }
}
` as GeneratedQuery<APITypes.ListBudgetTypeDetailsQueryVariables, APITypes.ListBudgetTypeDetailsQuery>;
export const listForecastPlanningCycles = /* GraphQL */ `query ListForecastPlanningCycles {
  listForecastPlanningCycles {
    data_classification_id
    scenario_seq_id
    
  }
}
` as GeneratedQuery<APITypes.ListForecastPlanningCyclesQueryVariables, APITypes.ListForecastPlanningCyclesQuery>;
export const exportForecastVarianceReport = /* GraphQL */ `query ExportForecastVarianceReport($payload: VarianceReportInput) {
  exportForecastVarianceReport(payload: $payload) {
    numberOfRecordsUpdated
    
  }
}
` as GeneratedQuery<APITypes.ExportForecastVarianceReportQueryVariables, APITypes.ExportForecastVarianceReportQuery>;
