import { Alert, Box, Button, ButtonDropdown, Container, FlashbarProps, Header, SpaceBetween } from '@amzn/awsui-components-react';
import { ColDef, ExcelExportParams, GetRowIdParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import { useGridState } from 'src/hooks/useGridState';
import { XptReportExportFileDetails } from 'src/models/XptReportingModels';
import { RootState } from 'src/store/store';
import { processCellForClipboard, StatusBarConfig } from 'src/utils/ag-grid-utils';
import { useAuth } from '../auth/AuthContextProvider';
import {
  DEFAULT_GROUP_DISPLAY_TYPE,
  DEFAULT_GROUP_EXPANSION_LEVEL,
  DEFAULT_PIVOT_MODE,
  DefaultColDef,
  ReportSideBarConfig,
  RowGroupPanelShowOption,
  TOTAL_ROW_POSITION
} from './XptReportGridConstants';

interface ReportGridProps {
  columnDefs: ColDef[];
  rowData: any[];
  uniqueIdField: string;
  isLoading: boolean;
  localStorageKey: string;
  refreshReport: () => void;
  exportFileDetails?: XptReportExportFileDetails;
  notificationMessage: (content: string, flashBarType: FlashbarProps.Type, isDismissible: boolean, messageId?: string) => void;
}

export const ReportGrid: React.FC<ReportGridProps> = ({
  columnDefs,
  rowData,
  uniqueIdField,
  isLoading,
  localStorageKey,
  refreshReport,
  exportFileDetails,
  notificationMessage
}) => {
  const { Alias } = useAuth();
  const themeClassName = useSelector((state: RootState) => state.xptAppMetadataStore.themeClassName);
  const gridRef = useRef<AgGridReact>(null);
  const { saveGridState, restoreGridState, clearGridState } = useGridState(gridRef, localStorageKey);

  const getRowId = (params: GetRowIdParams) => params.data[uniqueIdField]?.toString();

  const restoreGrid = () => {
    setTimeout(() => {
      restoreGridState();
      gridRef.current?.api.refreshCells();
    }, 0);
  };

  const handleGridReady = useCallback(() => {
    restoreGrid();
  }, [localStorageKey]);

  const handleExportToExcel = () => {
    if (exportFileDetails) {
      const exportParams: ExcelExportParams = {
        author: Alias,
        fileName: exportFileDetails.fileName,
        sheetName: exportFileDetails.sheetName
      };
      gridRef.current?.api.exportDataAsExcel(exportParams);
    }
  };

  const handleResetView = () => {
    clearGridState();
    notificationMessage('View reset to default view', 'success', true);
    setTimeout(() => {
      refreshReport();
    }, 0);
  };

  return (
    <Box padding={{ top: 'm' }}>
      <Container
        className="xpt-report-ag-grid-container"
        disableContentPaddings
        header={
          <Header
            variant="h2"
            actions={
              <SpaceBetween size="m" direction="horizontal">
                <Button iconName="refresh" disabled={isLoading} onClick={refreshReport} />
                <Button iconName="download" disabled={isLoading} onClick={handleExportToExcel}>
                  Export to Excel
                </Button>
                <ButtonDropdown
                  items={[
                    {
                      id: 'reset-view',
                      text: 'Reset to default view',
                      disabled: isLoading
                    }
                  ]}
                  onItemClick={(e) => {
                    if (e.detail.id === 'reset-view') {
                      handleResetView();
                    }
                  }}
                  disabled={isLoading}
                  mainAction={{
                    text: 'Save current view',
                    onClick: () => {
                      saveGridState();
                      notificationMessage('View saved successfully', 'success', true);
                    },
                    disabled: isLoading
                  }}
                  variant="normal"
                />
              </SpaceBetween>
            }
          />
        }
      >
        <div className={themeClassName} style={{ height: '100%', width: '100%' }}>
          <ErrorBoundary
            FallbackComponent={() => (
              <Alert
                type="error"
                dismissible={false}
                visible={true}
                header="Error"
                action={<Button onClick={() => window.location.reload()}>Reload</Button>}
              >
                {'Unable to load grid data, please try reloading.'}
              </Alert>
            )}
          >
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <AgGridReact
                ref={gridRef}
                onGridReady={handleGridReady}
                getRowId={getRowId}
                defaultColDef={DefaultColDef}
                columnDefs={columnDefs}
                rowData={rowData}
                sideBar={ReportSideBarConfig}
                enableRangeHandle
                enableRangeSelection
                suppressMultiRangeSelection
                rowGroupPanelShow={RowGroupPanelShowOption}
                enableAdvancedFilter={false}
                alwaysMultiSort
                statusBar={StatusBarConfig}
                rowHeight={30}
                suppressContextMenu={false}
                suppressCsvExport
                enterNavigatesVerticallyAfterEdit
                enterNavigatesVertically
                processCellForClipboard={processCellForClipboard}
                suppressAggFuncInHeader
                groupMaintainOrder
                grandTotalRow={TOTAL_ROW_POSITION}
                suppressRowGroupHidesColumns
                groupDisplayType={DEFAULT_GROUP_DISPLAY_TYPE}
                groupDefaultExpanded={DEFAULT_GROUP_EXPANSION_LEVEL}
                pivotMode={DEFAULT_PIVOT_MODE}
              />
            )}
          </ErrorBoundary>
        </div>
      </Container>
    </Box>
  );
};
