import { BreadcrumbGroupProps } from '@amzn/awsui-components-react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { UsefulLifeMappingGridEntity } from 'src/models/xPTMappingModels';
import { compareObjects, filterObjectFields } from 'src/utils/comparison-utils';
import { AdminBaseBreadcrumbs } from '../AdminConsole';

export const getUsefulLifeBreadcrumbItems = (): BreadcrumbGroupProps.Item[] => {
  return [
    ...AdminBaseBreadcrumbs,
    {
      text: 'Useful Life',
      href: '/admin-console/useful-life'
    }
  ];
};

const integerValueParser = (params: any) => {
  const value = parseInt(params.newValue, 10);
  return isNaN(value) ? null : value;
};

export const usefulLifeDetailsColumnDefs = (costCenterList: string[], expenseTypeList: string[]): ColDef[] => {
  return [
    {
      headerName: 'Row Id',
      field: 'row_id',
      hide: true,
      suppressColumnsToolPanel: true
    },
    {
      headerName: 'Useful Life Id',
      field: 'useful_life_id',
      hide: true,
      suppressColumnsToolPanel: true
    },
    {
      headerName: 'Cost Center Code',
      field: 'cost_center_code',
      sortable: true,
      filter: true,
      checkboxSelection: true,
      editable: true,
      headerClass: 'header-center',
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: costCenterList,
        valueListMaxHeight: 300,
        allowTyping: true,
        searchType: 'matchAny',
        filterList: true
      }
    },
    {
      headerName: 'Expense Type',
      field: 'expense_type',
      sortable: true,
      filter: true,
      editable: true,
      headerClass: 'header-center',
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: expenseTypeList,
        valueListMaxHeight: 300,
        allowTyping: true,
        searchType: 'matchAny',
        filterList: true
      }
    },
    {
      headerName: 'Useful Life',
      field: 'useful_life',
      cellRenderer: 'agAnimateShowChangeCellRenderer',
      cellEditor: 'agNumberCellEditor',
      sortable: true,
      filter: true,
      editable: true,
      headerClass: 'header-center',
      valueParser: integerValueParser
    },
    {
      headerName: 'Active',
      field: 'is_active',
      hide: true,
      sortable: true,
      filter: true,
      suppressColumnsToolPanel: true,
      cellRenderer: (params: ICellRendererParams) => (params.value ? 'Yes' : 'No'),
      headerClass: 'header-center'
    },
    { headerName: 'Created At', field: 'created_at', hide: true, headerClass: 'header-center' },
    { headerName: 'Created By', field: 'created_by', hide: true, headerClass: 'header-center' },
    { headerName: 'Updated At', field: 'updated_at', hide: true, headerClass: 'header-center' },
    { headerName: 'Updated By', field: 'updated_by', hide: true, headerClass: 'header-center' }
  ];
};

/**
 * Filters out the non-relevant fields for comparison from the row data.
 * @param {UsefulLifeMappingGridEntity} row - The row data to filter.
 * @returns {Partial<UsefulLifeMappingGridEntity>} The filtered row data.
 */
export const filterUsefulLifeFields = (row: UsefulLifeMappingGridEntity): Partial<UsefulLifeMappingGridEntity> => {
  return filterObjectFields(row, ['isModified', 'row_id']);
};

/**
 * Compares the original row data with the new row data to determine if they are different.
 * @param {UsefulLifeMappingGridEntity} originalRow - The original row data.
 * @param {UsefulLifeMappingGridEntity} newRow - The new row data.
 * @returns {boolean} True if the rows are different, false otherwise.
 */
export const isUsefulLifeRowModified = (originalRow: UsefulLifeMappingGridEntity, newRow: UsefulLifeMappingGridEntity): boolean => {
  const filteredOriginalRow = filterUsefulLifeFields(originalRow);
  const filteredNewData = filterUsefulLifeFields(newRow);

  return !compareObjects(filteredOriginalRow, filteredNewData);
};
