export interface PAPIUserInfo {
  personId: string;
  lastName: string;
  firstName: string;
  login: string;
  badgeColor: string;
  businessTitle: string;
  preferredEmail?: any;
  primaryEmail: string;
  primaryPhone: string;
  isManager: boolean;
  managerPersonId: string;
  managerLogin: string;
  managerName: string;
  jobProfileId: string;
  jobProfileName: string;
  costCenterId: string;
  extendedCostCenterId: string;
  costCenterName: string;
  extendedCostCenterName: string;
  companyId: string;
  companyUdmId: string;
  companyName: string;
  jobStatus: string;
  employeeStatus: string;
  workspace: string;
  tenureDays: number;
  serviceDate: number;
  legacyEmployeeId: string;
  isPrehire: boolean;
  pastTenureDays: number;
}

export enum PAPIEmployeeJobStatus {
  Active = 'A',
  Inactive = 'I'
}

export interface ValidateUserAliasResponse {
  isValid: boolean;
  validationMessage: string;
  userInfo?: {
    firstName: string;
    lastName: string;
  };
}