import { ColDef } from 'ag-grid-community';

// Default column definition for AgGrid
export const DefaultColDefActuals: ColDef = {
  resizable: true,
  filter: true,
  sortable: true,
  floatingFilter: false,
  filterParams: {
    applyMiniFilterWhileTyping: true
  }
};
