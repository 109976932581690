import { AutosuggestProps, BreadcrumbGroupProps, SelectProps } from '@amzn/awsui-components-react';
import { logger } from 'src/analytics/KatalLogger';
import { CORP_SEGMENTS_SEQUENCE, eCorpSegmentNames, eSegmentCategoryType, isMandatoryCorpSegment } from 'src/constants/corp-segment-constants';
import { eEntityStatus } from 'src/constants/generic-constants';
import {
  BusinessGroupEntity,
  BusinessSegmentsEntity,
  CorpSegmentsEntity,
  CorpSegmentsValueEntity,
  ItemMetadata,
  LDAPGroupInformation,
  MasterBusinessSegments,
  MasterCorpSegments,
  SegmentHierarchy
} from 'src/models/AppContextModels';
import { getFileFromS3URI } from 'src/utils/aws-s3-services';
import { getMoment, getTimeDifference } from 'src/utils/date-time-utilities';
import * as Yup from 'yup';
import { DataClassificationNameAndShortDesc, OnboardingFormEntity } from '../../../models/OnboardingModel';
import { AdminBaseBreadcrumbs } from '../AdminConsole';

export const getOnboardingBreadcrumbItems = (paramDataClassificationId: any): BreadcrumbGroupProps.Item[] => {
  if (paramDataClassificationId)
    return [
      ...AdminBaseBreadcrumbs,
      {
        text: 'Business Groups',
        href: '/admin-console/onboarding'
      },
      {
        text: 'Manage',
        href: '/admin-console/' + paramDataClassificationId
      }
    ];
  else
    return [
      ...AdminBaseBreadcrumbs,
      {
        text: 'Business Groups',
        href: '/admin-console/onboarding'
      }
    ];
};

export const Empty_Business_Segment: BusinessSegmentsEntity = {
  business_segment_id: null,
  is_id_column: false,
  business_segment_name: '',
  business_segment_name_key: null,
  business_segment_data_type: 'text',
  business_segment_values_s3_path: null
};

export const Empty_Restricted_Group: LDAPGroupInformation = {
  group_id: '',
  group_name: '',
  group_description: '',
  is_restricted: false,
  default_values: []
};

export const INITIAL_DATA: OnboardingFormEntity = {
  data_classification: {
    data_classification_id: null,
    data_classification_name: '',
    data_classification_short_description: '',
    data_classification_description: ''
  },
  business_leaders_group: {
    group_id: '',
    group_name: '',
    group_description: '',
    is_restricted: false,
    default_values: []
  },
  business_owners_group: {
    group_id: '',
    group_name: '',
    group_description: '',
    is_restricted: false,
    default_values: []
  },
  restricted_groups: [],
  corp_segments: [],
  business_segments: [],
  status: eEntityStatus.Pending,
  item_metadata: {
    is_active: true,
    created_by: '',
    created_at: '',
    updated_by: '',
    updated_at: ''
  }
};

export const getNewOnboardingFormData = async (
  masterCorpSegments: MasterCorpSegments[],
  itemMetadata: ItemMetadata
): Promise<OnboardingFormEntity> => {
  // Filter and prepare promises for async operations
  const corpSegmentsPromises = masterCorpSegments
    .filter((masterCorpSegment) => masterCorpSegment.segment_category === 'CORP')
    .map(async (masterCorpSegment) => {
      return {
        corp_segment_id: masterCorpSegment.segment_id,
        corp_segment_name: masterCorpSegment.segment_name,
        corp_segment_name_key: masterCorpSegment.segment_name,
        corp_segment_required: isMandatoryCorpSegment(masterCorpSegment.segment_name as eCorpSegmentNames),
        corp_segment_data_type: 'dropdown',
        corp_segment_default_value: {} as SegmentHierarchy,
        corp_segment_values_s3_path: '',
        corp_segment_values_s3_data_fetched:
          masterCorpSegment.segment_name === eCorpSegmentNames.COST_CENTER ? [] : masterCorpSegment.segment_hierarchy_data_from_s3
      } as CorpSegmentsValueEntity;
    });

  // Resolve all promises from the mapping
  const corpSegments: CorpSegmentsValueEntity[] = await Promise.all(corpSegmentsPromises);

  // Construct the final object with resolved corp segments
  const onboardingFormData = {
    ...INITIAL_DATA,
    corp_segments: corpSegments,
    item_metadata: itemMetadata
  };

  return onboardingFormData;
};

export const getUpdatedOnboardingFormData = async (businessGroupEntity: BusinessGroupEntity): Promise<OnboardingFormEntity> => {
  const initiateTimer = getMoment();

  // Use the utility function to update corp segments
  const updatedCorpSegments = await fetchAndUpdateCorpSegments(businessGroupEntity.corp_segments);

  const updatedFormData = {
    ...businessGroupEntity,
    corp_segments: updatedCorpSegments
  } as OnboardingFormEntity;

  const elapsed = getTimeDifference(initiateTimer);
  logger.info(`Fetching Corp Segments from S3 took: ${elapsed} ms`);

  return updatedFormData;
};

/**
 * Fetches and updates corporate segments with values from S3 paths.
 * @param corpSegments Array of CorpSegmentsEntity from a BusinessGroupEntity.
 * @returns Updated array of CorpSegmentsValueEntity with fetched S3 data.
 */
export const fetchAndUpdateCorpSegments = async (corpSegments: CorpSegmentsEntity[]): Promise<CorpSegmentsValueEntity[]> => {
  const updatedCorpSegments = await Promise.all(
    corpSegments.map(async (corpSegment): Promise<CorpSegmentsValueEntity> => {
      // Check if the S3 path is null or empty, and set corp_segment_values_s3_data_fetched to []
      if (!corpSegment.corp_segment_values_s3_path) {
        return {
          ...corpSegment,
          corp_segment_values_s3_data_fetched: []
        } as CorpSegmentsValueEntity;
      }

      const corpSegmentValuesS3Data = await getFileFromS3URI(corpSegment.corp_segment_values_s3_path);
      return {
        ...corpSegment,
        corp_segment_values_s3_data_fetched: corpSegmentValuesS3Data
      } as unknown as CorpSegmentsValueEntity;
    })
  );

  return updatedCorpSegments;
};

export const reformatSegmentsHierarchy = (corp_segment_values: SegmentHierarchy[]): SegmentHierarchy[] => {
  return corp_segment_values.map((segment: SegmentHierarchy) => ({
    segment_hierarchy: segment.segment_hierarchy,
    segment_description: segment.segment_description
  }));
};

export const loadMasterBusinessSegmentsSuggestions = (masterBusinessSegments: MasterBusinessSegments[]): AutosuggestProps.Options => {
  return masterBusinessSegments
    .filter((masterBusinessSegment) => masterBusinessSegment.segment_category === eSegmentCategoryType.BUSINESS)
    .map((masterBusinessSegment) => {
      return { label: masterBusinessSegment.segment_name, value: masterBusinessSegment.segment_name };
    });
};

/*
  To get all Business Group Names and Short Description
*/
export const getAllDataClassificationNamesAndShortDesc = (businessGroups: BusinessGroupEntity[]): DataClassificationNameAndShortDesc[] => {
  let dataClassificationNames: DataClassificationNameAndShortDesc[] = businessGroups?.map((dataClassification) => {
    return {
      name: dataClassification?.data_classification?.data_classification_name || '',
      shortDesc: dataClassification?.data_classification?.data_classification_short_description || ''
    };
  });
  return dataClassificationNames;
};

/*
  To get all Business Group Names and Short Description except selected
*/
export const getDataClassificationNamesAndShortDescExceptSelected = (
  businessGroups: BusinessGroupEntity[],
  ignoreDataClassificationId: number | null
) => {
  let _currentDataClassificationNames: any[] = businessGroups
    ?.filter((dataClassification) => dataClassification.data_classification.data_classification_id !== ignoreDataClassificationId)
    ?.map((dataClassification) => {
      return {
        name: dataClassification?.data_classification?.data_classification_name || '',
        shortDesc: dataClassification?.data_classification?.data_classification_short_description || ''
      };
    });
  return _currentDataClassificationNames;
};

export const validationSchema = (existingDataClassificationNames: any[]) => {
  const notJustEmptySpaces = (value: any) => {
    return !/^\s+$/.test(value); // Test if the value is not just empty spaces
  };

  return Yup.object().shape({
    data_classification: Yup.object().shape({
      data_classification_name: Yup.string()
        .required('required')
        .max(2, 'min 2 characters required')
        .max(50, 'max 50 characters allowed')
        .matches(/^[a-zA-Z0-9_\-.\s]{2,50}$/, 'Allows only letters, numbers, _, or -')
        .test('not-just-empty-spaces', 'Value cannot be just empty spaces', notJustEmptySpaces)
        .test('unique-data-classification-name', 'Data Classification name already exists', async function (value) {
          if (
            existingDataClassificationNames &&
            existingDataClassificationNames?.map((existingDataClassification) => existingDataClassification.name).includes(value || '')
          ) {
            return false; // Validation failed
          }
          return true; // Validation passed
        }),
      data_classification_short_description: Yup.string()
        .required('required')
        .matches(/^[A-Z]{2,3}$/, 'short description must be 2 to 3 alphabetic characters in UPPER case only')
        .test('unique-data-classification-short_description', 'Short Description already exists', async function (value) {
          if (
            existingDataClassificationNames &&
            existingDataClassificationNames?.map((existingDataClassification) => existingDataClassification.shortDesc).includes(value || '')
          ) {
            return false; // Validation failed
          }
          return true; // Validation passed
        }),
      data_classification_description: Yup.string()
        .required('required')
        .max(200, 'max 200 characters allowed')
        .test('not-just-empty-spaces', 'Value cannot be just empty spaces', notJustEmptySpaces)
        .matches(/^[a-zA-Z0-9_\-.\s]{1,200}$/, 'Allows only letters, numbers, _, or -')
    }),
    business_leaders_group: Yup.object().shape({
      group_id: Yup.string().required('required').test('not-just-empty-spaces', 'Value cannot be just empty spaces', notJustEmptySpaces),
      group_name: Yup.string().required('required').test('not-just-empty-spaces', 'Value cannot be just empty spaces', notJustEmptySpaces),
      group_description: Yup.string().required('required').test('not-just-empty-spaces', 'Value cannot be just empty spaces', notJustEmptySpaces)
    }),
    business_owners_group: Yup.object().shape({
      group_id: Yup.string().required('required').test('not-just-empty-spaces', 'Value cannot be just empty spaces', notJustEmptySpaces),
      group_name: Yup.string().required('required').test('not-just-empty-spaces', 'Value cannot be just empty spaces', notJustEmptySpaces),
      group_description: Yup.string().required('required').test('not-just-empty-spaces', 'Value cannot be just empty spaces', notJustEmptySpaces)
    }),
    restricted_groups: Yup.array().of(
      Yup.object().shape({
        group_id: Yup.string().required('required'),
        group_name: Yup.string()
          .required('required')
          .max(200, 'max 200 characters allowed')
          .matches(/^[a-zA-Z0-9_\-.\s]{1,200}$/, 'Allows only letters, numbers, _, or -')
          .test('not-just-empty-spaces', 'Value cannot be just empty spaces', notJustEmptySpaces),
        group_description: Yup.string()
          .required('required')
          .max(200, 'max 200 characters allowed')
          .matches(/^[a-zA-Z0-9_\-.\s]{1,200}$/, 'Allows only letters, numbers, _, or -')
          .test('not-just-empty-spaces', 'Value cannot be just empty spaces', notJustEmptySpaces),
        default_values: Yup.array().of(Yup.object()).min(1, 'At least 1 value is required')
      })
    ),
    // Discussed with the team, Removing this validation for now.
    // corp_segments: Yup.array()
    //   .of(
    //     Yup.object().shape({
    //       corp_segment_id: Yup.number().required('required'),
    //       corp_segment_name: Yup.string()
    //         .required('required')
    //         .max(200, 'max 200 characters allowed')
    //         .matches(/^[a-zA-Z0-9_\-.\s]{1,200}$/, 'Allows only letters, numbers, _, or -')
    //         .test('not-just-empty-spaces', 'Value cannot be just empty spaces', notJustEmptySpaces),
    //       corp_segment_required: Yup.boolean(),
    //       corp_segment_default_value: Yup.object().when('corp_segment_required', {
    //         is: false,
    //         then: Yup.object().shape({
    //           segment_hierarchy: Yup.array().nullable().required('Required'),
    //           segment_description: Yup.string().nullable().required(`Provide a default value when the 'Required' option is not selected.`)
    //         })
    //       }),
    //       corp_segment_values_s3_data_fetched: Yup.array()
    //         .of(Yup.object())
    //         .when('corp_segment_required', {
    //           is: true,
    //           then: Yup.array().of(Yup.object()).min(1, 'At least 1 value is required'),
    //           otherwise: Yup.array().of(Yup.object())
    //         })
    //     })
    //   )
    //   .min(1, 'At least 1 Corp Segment is required'),
    business_segments: Yup.array()
      .of(
        Yup.object().shape({
          business_segment_id: Yup.mixed().nullable(true),
          is_id_column: Yup.boolean(),
          business_segment_name: Yup.string()
            .required('required')
            .max(200, 'max 200 characters allowed')
            .matches(/^[a-zA-Z0-9_\-.\s]{1,200}$/, 'Allows only letters, numbers, _, or -')
            .test('not-just-empty-spaces', 'Value cannot be just empty spaces', notJustEmptySpaces),
          business_segment_data_type: Yup.string().required('required')
        })
      )
      .test('unique-business-segments', 'Business Segments must have unique names', function () {
        const names = this.parent.business_segments.map((segment: BusinessSegmentsEntity) => segment.business_segment_name);
        const uniqueNames = [...new Set(names)];
        return uniqueNames.length === names.length;
      })
  });
};

export const getCorpSegmentDefaultButtonValue = (corpSegment: CorpSegmentsValueEntity, isDisabled: boolean) => {
  if (!isDisabled) {
    if (corpSegment?.corp_segment_default_value?.segment_hierarchy && corpSegment?.corp_segment_default_value?.segment_description) {
      return `${getSegmentNameFromPath(corpSegment?.corp_segment_default_value?.segment_hierarchy)} - ${
        corpSegment?.corp_segment_default_value?.segment_description || ''
      }`;
    } else {
      return 'select a default value';
    }
  } else {
    return 'Not required';
  }
};

export const getCorpSegmentButtonValue = (corpSegment: CorpSegmentsValueEntity) => {
  return `${corpSegment?.corp_segment_values_s3_data_fetched?.length} selected` || '';
};

export const flattenTheSevenSegments = (segmentHierarchy: SegmentHierarchy[]) => {
  const options = segmentHierarchy.map((item) => ({
    label: getSegmentNameFromPath(item.segment_hierarchy),
    value: item.segment_description,
    description: item.segment_description
  }));
  return options;
};

export const getSegmentNameFromPath = (segment_hierarchy: string[] | []) => {
  return segment_hierarchy ? segment_hierarchy[segment_hierarchy?.length - 1] : '';
};

export const userGroupInfoToSelectDropdownOptions = (userGroupInfo?: LDAPGroupInformation[]) => {
  return userGroupInfo?.map((userGroup) => {
    return {
      label: `${userGroup.group_name}`,
      value: `${userGroup.group_id}`
    };
  });
};

export const restrictedInfoToSelectDropdownOptions = (
  alreadySelectedRestrictedGroups: LDAPGroupInformation[],
  allRestrictedGroupsInfo?: LDAPGroupInformation[]
): SelectProps.Options => {
  if (!allRestrictedGroupsInfo) {
    return [];
  }

  const options: SelectProps.Options = allRestrictedGroupsInfo.map((group) => {
    const isSelected = alreadySelectedRestrictedGroups.some((selectedGroup) => selectedGroup.group_id === group.group_id);

    return {
      label: group.group_name,
      value: group.group_id,
      disabled: isSelected // Optionally disable already selected groups
    } as SelectProps.Option;
  });

  return options;
};

export const userGroupInfoToSelectDropdown = (userGroupInfo: LDAPGroupInformation) => {
  return {
    label: `${userGroupInfo?.group_name}`,
    value: `${userGroupInfo?.group_id}`
  };
};

export const selectDropdownToUserGroupInfo = (selectedGroup: SelectProps.Option, listUserGroups?: LDAPGroupInformation[]) => {
  return listUserGroups?.find((group) => group.group_id === selectedGroup.value);
};

export const segmentHierarchyComparator = (a: SegmentHierarchy, b: SegmentHierarchy) => {
  return a?.segment_description === b?.segment_description && a?.segment_hierarchy?.join() === b?.segment_hierarchy?.join();
};

export const flattenTheSegmentHierarchyData = (segmentHierarchyData: SegmentHierarchy[]): SegmentHierarchy[] => {
  return (
    segmentHierarchyData?.map((segment, index) => {
      // shallow copy of the segment_hierarchy array
      let segmentHierarchyCopy = [...segment.segment_hierarchy];
      let poppedElement: string | undefined;

      try {
        // Perform the pop operation on the copied array
        poppedElement = segmentHierarchyCopy.pop();
      } catch (error: any) {
        logger.error('Error occurred while popping the segmentHierarchy element', error);
        return {
          segment_description: segment.segment_description,
          segment_hierarchy: []
        };
      }

      return {
        segment_description: segment.segment_description,
        segment_hierarchy: poppedElement ? [poppedElement] : []
      } as SegmentHierarchy;
    }) || []
  );
};

export const customCorpSegmentSort = (a: { corp_segment_name: string }, b: { corp_segment_name: string }) => {
  const indexA = CORP_SEGMENTS_SEQUENCE.indexOf(a.corp_segment_name as eCorpSegmentNames);
  const indexB = CORP_SEGMENTS_SEQUENCE.indexOf(b.corp_segment_name as eCorpSegmentNames);

  if (indexA !== -1 && indexB !== -1) {
    return indexA - indexB;
  }

  if (indexA !== -1) {
    return -1;
  }

  if (indexB !== -1) {
    return 1;
  }

  return a.corp_segment_name.localeCompare(b.corp_segment_name);
};

export const customBusinessSegmentSort = (a: { business_segment_name: string }, b: { business_segment_name: string }) => {
  return a.business_segment_name.localeCompare(b.business_segment_name);
};

/**
 * Removes the leaf nodes from the tree data.
 * A leaf node is a node that does not have any children.
 *
 * @param {SegmentHierarchy[]} treeData - The tree data to be processed.
 * @returns {SegmentHierarchy[]} - The tree data without leaf nodes.
 */
export const removeLeafNodes = (treeData: SegmentHierarchy[]): SegmentHierarchy[] => {
  const nonLeafNodes: SegmentHierarchy[] = [];

  /**
   * Checks if a node has children.
   *
   * @param {string[]} nodeName - The name of the node.
   * @returns {boolean} - True if the node has children, false otherwise.
   */
  const hasChildren = (nodeName: string[]): boolean => {
    return treeData.some(
      (node) => node.segment_hierarchy.length > nodeName.length && node.segment_hierarchy.slice(0, nodeName.length).join() === nodeName.join()
    );
  };

  // Filter out the non-leaf nodes
  treeData.forEach((node) => {
    if (hasChildren(node.segment_hierarchy)) {
      nonLeafNodes.push(node);
    }
  });

  return nonLeafNodes;
};
