import { Box, ContentLayout, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { DataClassification } from 'src/models/AppContextModels';
import { selectActiveBusinessGroups } from '../business-group/businessGroupSelectors';
import { RootState } from 'src/store/store';
import { LoadingStatus } from 'src/models/AuthContextModels';
import { entryLog } from 'src/analytics/KatalLogger';
import { compareNullableNumbers } from 'src/utils/generic-utilities';

export const useBusinessGroupNameFromPath = () => {
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  const businessGroupName = pathParts[1];
  return businessGroupName;
};

export const XptSecondaryNavBar: React.FC<any> = () => {
  const navigate = useNavigate();
  const isLoading = useSelector((state: RootState) => state.businessGroupStore.businessGroupLoadingStatus);
  const activeBusinessGroups = useSelector(selectActiveBusinessGroups);

  const [dataClassifications, setDataClassifications] = useState<DataClassification[]>();
  const businessGroupName = useBusinessGroupNameFromPath();

  useEffect(() => {
    if (isLoading === LoadingStatus.Completed) {
      setDataClassifications(activeBusinessGroups.map((_) => _.data_classification));
    }
  }, [isLoading]);

  const onClickOfDataClassification = (dataClassification: DataClassification) => {
    entryLog(`${dataClassification.data_classification_id}`, dataClassification.data_classification_short_description);
    navigate('/' + dataClassification.data_classification_name + '/forecast-input');
  };

  return (
    <ContentLayout className="app-secondary-top-navigation">
      <Box>
        <SpaceBetween direction="horizontal" size="s">
          {dataClassifications
            ?.sort((a, b) => compareNullableNumbers(a.data_classification_id, b.data_classification_id, 'asc'))
            ?.map((dataClassification: DataClassification, index: number) => {
              const isHighlighted = businessGroupName === dataClassification.data_classification_name;
              return (
                <SpaceBetween direction="horizontal" size="s" key={index}>
                  <div className={`global-secondary-nav-item ${isHighlighted ? 'highlighted-nav-item' : ''}`}>
                    <Box margin={{ left: 's', bottom: 'm', right: 'xxxs' }}>
                      <span className="display-name" onClick={() => onClickOfDataClassification(dataClassification)}>
                        {dataClassification.data_classification_name}
                      </span>
                    </Box>
                  </div>
                </SpaceBetween>
              );
            })}
        </SpaceBetween>
      </Box>
    </ContentLayout>
  );
};
