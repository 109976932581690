import { useCollection } from '@amzn/awsui-collection-hooks';
import { Pagination, Table, TableProps } from '@amzn/awsui-components-react';
import React from 'react';
import { paginationAriaLabels } from 'src/i18n-strings';

interface XptWidgetTableProps {
  variant: TableProps.Variant;
  loadingStatus: boolean;
  allItems: any[];
  columnDefinitions: TableProps.ColumnDefinition<any>[];
  hasPagination: boolean;
  itemsPerPage?: number;
  className?: string; // Optional className prop
}
const XptWidgetTable: React.FC<XptWidgetTableProps> = (tableProps) => {
  const { items, paginationProps } = useCollection(tableProps.allItems, {
    pagination: tableProps.hasPagination
      ? {
          pageSize: tableProps.itemsPerPage || 5
        }
      : undefined
  });

  return (
    <>
      <Table
        className={tableProps.className}
        loading={tableProps.loadingStatus}
        loadingText="Loading"
        stickyHeader={true}
        variant={tableProps.variant}
        items={items}
        columnDefinitions={tableProps.columnDefinitions}
        pagination={tableProps.hasPagination && <Pagination {...paginationProps} ariaLabels={paginationAriaLabels(paginationProps.pagesCount)} />}
      />
    </>
  );
};

export default XptWidgetTable;
