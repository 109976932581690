import { Icon, Link, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { eTicketLinks } from 'src/constants/generic-constants';

export const XPTFooter: React.FC = () => {
  const onSupportClick = () => {
    window.open(eTicketLinks.GENERAL_ISSUE_TEMPLATE_URL, '_blank');
  };

  return (
    <>
      <div className="footer">
        <SpaceBetween direction="horizontal" size="xs" alignItems="center">
          <Link color="inverted" variant="secondary" fontSize="body-s" onFollow={() => onSupportClick()}>
            <SpaceBetween direction="horizontal" size="xs">
              <span>{'Support'}</span>
              <Icon name="external" size="small" />
            </SpaceBetween>
          </Link>
        </SpaceBetween>
      </div>
    </>
  );
};
