import { AppLayout, Box, Button, ContentLayout, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { logger } from 'src/analytics/KatalLogger';
import peccyImage from 'src/images/peccy-permissions-gate.png';
import { useAuth } from '../auth/AuthContextProvider';
import { retrieveParameterFromSSM } from 'src/utils/ssm-parameter';
import { eTicketLinks } from 'src/constants/generic-constants';

const MESSAGES = {
  HEADER: 'Oops! It looks like Peccy couldn’t find your access permission. 🦙🛑',
  SUBHEADER: 'Submit a request to gain entry!',
  BUTTON_TEXT: 'Submit an Access Request'
};

export const AccessRestricted: React.FC = () => {
  const userAuth = useAuth();

  const getAccessRequestSimLink = async (): Promise<string> => {
    try {
      const params = '/xpt-fe/xpt-sim-access-request';
      const result = await retrieveParameterFromSSM(params);
      return result;
    } catch (error) {
      return eTicketLinks.GENERAL_ISSUE_TEMPLATE_URL;
    }
  };

  const handleRequestAccess = async () => {
    logger.warn(`Submit an Access Request button clicked by ${userAuth.Alias}`);
    const link = await getAccessRequestSimLink();
    window.open(link, '_blank');
  };

  return (
    <AppLayout
      headerSelector="#h2"
      navigationHide={true}
      toolsHide={true}
      contentType="dashboard"
      content={
        <div className="xpt-no-access-layout-content">
          <ContentLayout>
            <Box margin={{ top: 'xl', left: 'l', right: 'l' }} padding={{ left: 'l', right: 'l' }}>
              <SpaceBetween size="l" className="access-restricted-container">
                <Box variant="h2">{MESSAGES.HEADER}</Box>
                <Box variant="h4">{MESSAGES.SUBHEADER}</Box>
                <Button
                  ariaLabel="Request access bug (opens new tab)"
                  iconAlign="right"
                  iconName="external"
                  target="_blank"
                  onClick={handleRequestAccess}
                >
                  {MESSAGES.BUTTON_TEXT}
                </Button>
                <img src={peccyImage} alt="Peccy Permissions Gate" className="access-restricted-image" />
              </SpaceBetween>
            </Box>
          </ContentLayout>
        </div>
      }
    />
  );
};

export default AccessRestricted;
