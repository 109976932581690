import { PaginationProps, TableProps } from '@amzn/awsui-components-react';

export const renderAriaLive: TableProps['renderAriaLive'] = ({ firstIndex, lastIndex, totalItemsCount }) =>
  `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`;

export const paginationAriaLabels: (totalPages?: number) => PaginationProps.Labels = (totalPages) => ({
  nextPageLabel: 'Next page',
  previousPageLabel: 'Previous page',
  pageLabel: (pageNumber) => `Page ${pageNumber} of ${totalPages || 'all pages'}`
});
