import {
  Box,
  Button,
  Container,
  ContentLayout,
  Flashbar,
  FlashbarProps,
  FormField,
  Header,
  Select,
  SelectProps,
  SpaceBetween
} from '@amzn/awsui-components-react';
import { ColDef } from 'ag-grid-community';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { logger } from 'src/analytics/KatalLogger';
import { generateVarianceReport } from 'src/api/app-sync-services';
import { XptAppLayout } from 'src/components/common/xpt-app-layout/XptAppLayout';
import { XPTBreadcrumbs } from 'src/components/common/XptBreadcrumb';
import { useFlashbar } from 'src/hooks/useFlashbar';
import {
  ROLL_UP_PERIOD,
  VarianceReportEntity,
  VarianceReportFlattenedData,
  XptReportExportFileDetails,
  XptVarianceReportInput
} from 'src/models/XptReportingModels';
import { RootState } from 'src/store/store';
import { getFileFromS3URI } from 'src/utils/aws-s3-services';
import { getForecastS3BucketName } from 'src/utils/xpt-s3-bucket-details';
import { useAuth } from '../auth/AuthContextProvider';
import { businessGroupBaseBreadcrumbs, currentBusinessGroupName } from '../business-group/businessGroupSelectors';
import { selectAllPlanningCyclesForCurrentGroup, selectScenarioSnapshotPerBusinessGroup } from '../planning-cycle/planningCycleSelector';
import BusinessGroupSideNavigation from '../xpt-layout/XptBusinessGroupSideNavigation';
import { ReportGrid } from './XptReportGrid';
import { ReportTypes, XptReportGridFixedFields } from './XptReportGridConstants';
import { getPlanningCyclesDropdownOptions, getXptReportFileName } from './XptReportingUtils';
import { generateVarianceReportColumnDefinitions } from './XptVarianceReportColumnGenerator';
import {
  EMPTY_OPTION,
  flattenReportData,
  getVarianceInputForAPI,
  getXptVarianceReportsBreadcrumbItems,
  RollUpPeriodOptions
} from './XptVarianceReportUtils';

export const XptVarianceReport: React.FC = () => {
  const appLayout = useRef<any>();
  const { Alias } = useAuth();

  const currentBusinessGroup = useSelector((state: RootState) => state.businessGroupStore.currentBusinessGroup);
  const businessGroupName = useSelector(currentBusinessGroupName);
  const data_classification_id = currentBusinessGroup?.data_classification.data_classification_id;
  const dataClassificationShortDesc: string | undefined = currentBusinessGroup?.data_classification.data_classification_short_description;
  const gridStateKey = `UniqueGridStateKey-XptVarianceReport-${dataClassificationShortDesc}`;

  const businessGroupBaseBreadcrumb = useSelector(businessGroupBaseBreadcrumbs);
  const { flashbarItems, displayFlashMessage, clearSpecificFlashMessage, clearAllMessages } = useFlashbar();

  const scenarios = useSelector((state: RootState) => state.planningCycleStore.scenarios);
  const planningCycles = useSelector(selectAllPlanningCyclesForCurrentGroup);
  const scenarioSnapshots = useSelector(selectScenarioSnapshotPerBusinessGroup);

  const [planningCycleOptions, setPlanningCycleOptions] = useState<SelectProps.OptionGroup[]>([]);

  // Helper function to generate local storage keys
  const generateLocalStorageKey = (key: string) => `${key}-${dataClassificationShortDesc}`;

  // State variables to hold the selected options
  const [selectedCurrentPlanningCycle, setSelectedCurrentPlanningCycleState] = useState<SelectProps.Option | null>(null);
  const [selectedComparisonPlanningCycle, setSelectedComparisonPlanningCycleState] = useState<SelectProps.Option | null>(null);
  const [selectedRollupPeriod, setSelectedRollupPeriod] = useState<SelectProps.Option | null>(null);

  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const [columnDefinitions, setColumnDefinitions] = useState<ColDef[]>([]);
  const [xptReportData, setXptReportData] = useState<VarianceReportFlattenedData[]>([]);
  const [exportFileDetails, setExportFileDetails] = useState<XptReportExportFileDetails>();

  const notificationMessage = (content: string, flashBarType: FlashbarProps.Type, isDismissible: boolean, messageId?: string) => {
    displayFlashMessage(content, flashBarType, isDismissible, messageId);
  };

  // Initialize the selected options from local storage once dataClassificationShortDesc is available
  useEffect(() => {
    if (dataClassificationShortDesc) {
      const storedCurrentPlanningCycle = getInitialState('selectedCurrentPlanningCycle', EMPTY_OPTION);
      const storedComparisonPlanningCycle = getInitialState('selectedComparisonPlanningCycle', EMPTY_OPTION);
      const storedRollupPeriod = getInitialState(
        'selectedRollupPeriod',
        RollUpPeriodOptions.find((period) => period.label === ROLL_UP_PERIOD.YEARLY) || EMPTY_OPTION
      );

      setSelectedCurrentPlanningCycleState(storedCurrentPlanningCycle);
      setSelectedComparisonPlanningCycleState(storedComparisonPlanningCycle);
      setSelectedRollupPeriod(storedRollupPeriod);
    }
  }, [dataClassificationShortDesc]);

  // Retrieve initial state from local storage or use default
  const getInitialState = (key: string, defaultValue: SelectProps.Option | null) => {
    const storedValue = localStorage.getItem(generateLocalStorageKey(key));
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  };

  // Once Planning Cycle & Snapshots are available, will create Dropdown options for Planning Cycle
  useEffect(() => {
    const planningCycleDropdownOptions: SelectProps.OptionGroup[] = getPlanningCyclesDropdownOptions(scenarios, planningCycles, scenarioSnapshots);
    setPlanningCycleOptions(planningCycleDropdownOptions);
  }, [scenarios, planningCycles, scenarioSnapshots]);

  useEffect(() => {
    if (dataClassificationShortDesc && selectedCurrentPlanningCycle) {
      const { fileName, sheetName } = getXptReportFileName(
        dataClassificationShortDesc,
        selectedCurrentPlanningCycle?.label || '',
        ReportTypes.FORECAST
      );
      setExportFileDetails({ fileName, sheetName });
    }
  }, [dataClassificationShortDesc, selectedCurrentPlanningCycle]);

  useEffect(() => {
    if (
      selectedCurrentPlanningCycle?.value &&
      selectedComparisonPlanningCycle?.value &&
      selectedRollupPeriod?.value &&
      selectedCurrentPlanningCycle?.value !== selectedComparisonPlanningCycle?.value
    ) {
      initializeReport();
    }
  }, [scenarios, planningCycles, scenarioSnapshots, selectedCurrentPlanningCycle, selectedComparisonPlanningCycle, selectedRollupPeriod]);

  const initializeReport = async () => {
    if (
      !selectedCurrentPlanningCycle?.value ||
      !selectedComparisonPlanningCycle?.value ||
      !selectedRollupPeriod?.value ||
      !data_classification_id ||
      !dataClassificationShortDesc || 
      !scenarioSnapshots
    )
      return;

    try {
      setIsGeneratingReport(true);
      const { bucketName, region } = getForecastS3BucketName();
      const rollupPeriod = selectedRollupPeriod?.value;

      const varianceReportInput: XptVarianceReportInput = getVarianceInputForAPI(
        selectedCurrentPlanningCycle,
        selectedComparisonPlanningCycle,
        rollupPeriod,
        data_classification_id,
        dataClassificationShortDesc,
        scenarioSnapshots,
        planningCycles,
        Alias,
        bucketName,
        region
      );
      logger.info(
        `Generating Variance report for Current PL: ${selectedCurrentPlanningCycle?.label} & Comparison PL: ${selectedComparisonPlanningCycle.label} with ${rollupPeriod} granularity.`
      );

      // Calling API to generate variance report in S3
      const generateReport = await generateVarianceReport(varianceReportInput);
      logger.info(`Variance report generation API response `, { response: generateReport });

      // Once API responds, without throwing error, we can fetch the report from S3
      // Getting Report Data from S3
      const s3URI = `s3://${bucketName}/${varianceReportInput.export_s3_key}`;
      const reportData = (await getFileFromS3URI(s3URI)) as unknown as VarianceReportEntity;
      logger.info(`Variance report data fetched from S3 with ${reportData?.variance_report?.length} records.`);

      // Flattening the report data to make it suitable for the Grid
      const flattenedData = flattenReportData(reportData.variance_report, currentBusinessGroup);
      logger.info(`Variance report data flattened with ${flattenedData?.length} records.`);
      setXptReportData(flattenedData);

      // generating column Definition
      // This will be used by the Grid to render the report
      const colDefs = await generateVarianceReportColumnDefinitions(
        currentBusinessGroup,
        reportData.variance_periods,
        rollupPeriod as ROLL_UP_PERIOD
      );
      setColumnDefinitions(colDefs);
    } catch (error: any) {
      logger.error(`Error while generating variance report`, { error: error });
      notificationMessage(error.message, 'error', true);
    } finally {
      setIsGeneratingReport(false);
    }
  };

  // Persist the selection in local storage whenever it changes
  useEffect(() => {
    if (selectedCurrentPlanningCycle && dataClassificationShortDesc) {
      localStorage.setItem(generateLocalStorageKey('selectedCurrentPlanningCycle'), JSON.stringify(selectedCurrentPlanningCycle));
    }
  }, [selectedCurrentPlanningCycle, dataClassificationShortDesc]);

  useEffect(() => {
    if (selectedComparisonPlanningCycle && dataClassificationShortDesc) {
      localStorage.setItem(generateLocalStorageKey('selectedComparisonPlanningCycle'), JSON.stringify(selectedComparisonPlanningCycle));
    }
  }, [selectedComparisonPlanningCycle, dataClassificationShortDesc]);

  useEffect(() => {
    if (selectedRollupPeriod && dataClassificationShortDesc) {
      localStorage.setItem(generateLocalStorageKey('selectedRollupPeriod'), JSON.stringify(selectedRollupPeriod));
    }
  }, [selectedRollupPeriod, dataClassificationShortDesc]);

  const getErrorText = () => {
    if (selectedCurrentPlanningCycle?.value && selectedComparisonPlanningCycle?.value) {
      return selectedCurrentPlanningCycle?.value === selectedComparisonPlanningCycle?.value ? `Should not be same as Planning Cycle` : '';
    } else {
      return '';
    }
  };

  return (
    <>
      <XptAppLayout
        ref={appLayout}
        headerSelector="#h"
        navigation={<BusinessGroupSideNavigation />}
        toolsHide={true}
        breadcrumbs={<XPTBreadcrumbs items={getXptVarianceReportsBreadcrumbItems(businessGroupBaseBreadcrumb, businessGroupName)} />}
        notifications={<Flashbar stackItems items={flashbarItems} />}
        stickyNotifications={true}
        maxContentWidth={Number.MAX_VALUE}
        contentType="default"
        content={
          <div className="xpt-app-layout-content">
            <ContentLayout disableOverlap header={<Header>{`Variance Report`}</Header>}>
              {/* Filter selection  */}
              <Container disableContentPaddings disableHeaderPaddings>
                <Box padding={{ top: 's', right: 's', bottom: 'm', left: 's' }}>
                  <SpaceBetween size="m" direction="horizontal">
                    <FormField label="Planning Cycle" className="width-25-rem">
                      <Select
                        expandToViewport
                        disabled={isGeneratingReport}
                        options={planningCycleOptions}
                        selectedOption={selectedCurrentPlanningCycle}
                        onChange={({ detail }) => setSelectedCurrentPlanningCycleState(detail.selectedOption)}
                      />
                    </FormField>

                    <FormField label="Comparison Planning Cycle" errorText={getErrorText()} className="width-25-rem">
                      <Select
                        expandToViewport
                        disabled={isGeneratingReport}
                        options={planningCycleOptions}
                        selectedOption={selectedComparisonPlanningCycle}
                        onChange={({ detail }) => setSelectedComparisonPlanningCycleState(detail.selectedOption)}
                      />
                    </FormField>

                    <FormField label="Rollup Period" className="width-25-rem">
                      <Select
                        expandToViewport
                        disabled={isGeneratingReport}
                        options={RollUpPeriodOptions}
                        selectedOption={selectedRollupPeriod}
                        onChange={({ detail }) => setSelectedRollupPeriod(detail.selectedOption)}
                      />
                    </FormField>
                  </SpaceBetween>
                </Box>
              </Container>
              {/* Reporting Grid to display grid data  */}
              <ReportGrid
                columnDefs={columnDefinitions}
                rowData={xptReportData}
                uniqueIdField={XptReportGridFixedFields.XptLineItemSeqId.value}
                isLoading={isGeneratingReport}
                localStorageKey={gridStateKey}
                refreshReport={initializeReport}
                exportFileDetails={exportFileDetails}
                notificationMessage={notificationMessage}
              />
            </ContentLayout>
          </div>
        }
      />
    </>
  );
};
