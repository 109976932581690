import { logger } from 'src/analytics/KatalLogger';
import { useAuth } from './AuthContextProvider';
import { UserAuthContext } from 'src/models/AuthContextModels';

interface RoleModifier {
  setBusinessLeader: (isBusinessLeader: boolean) => void;
  setBudgetOwnerRole: (isBudgetOwner: boolean) => void;
}

/**
 * Custom hook to modify the user's role dynamically based on the current Business Group context.
 * This function adjusts the user's role as they navigate through different Business Groups within the application.
 *
 * Note: If the user's role has been deliberately modified using the ModifyAuthContext component (for testing purposes),
 * this hook will consider that modification and not override the role.
 */
export const useRoleModifier = (): RoleModifier => {
  const { updateUserAuthDetails, isUserRoleModified } = useAuth();

  const setRole = (updates: Partial<UserAuthContext>) => {
    if (!isUserRoleModified) {
      logger.debug(`Setting roles to ${JSON.stringify(updates)}`);
      updateUserAuthDetails(updates, false);
    } else {
      logger.debug(`Skipping role update as testing mode is active. The roles will not change while navigating between business groups.`);
    }
  };

  const setBudgetOwnerRole = (isBudgetOwner: boolean) => {
    setRole({ isBudgetOwner });
  };

  const setBusinessLeader = (isBusinessLeader: boolean) => {
    setRole({ isBusinessLeader });
  };

  return {
    setBusinessLeader,
    setBudgetOwnerRole
  };
};
