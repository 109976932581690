import { logger } from 'src/analytics/KatalLogger';
import { eBusinessSegmentNames, eCorpSegmentNames } from 'src/constants/corp-segment-constants';
import { MasterBusinessSegments } from 'src/models/AppContextModels';
import {
  ForecastTemplateColumns,
  ForecastTemplateDataValidationStatus,
  ForecastTemplateMasterCorpSegmentDropdownValues,
  VALIDATION_NOT_INITIATED
} from 'src/models/ForecastModels';
import { ValidationErrorDetail, ValidationStatusEntity } from 'src/models/XptGenericModels';
import { AccountBudgetTypeMapping } from 'src/models/xPTMappingModels';
import { convertMonthFormatToDisplay, getCurrentUTCTimeInISO } from 'src/utils/date-time-utilities';
import { generateUniqueId } from 'src/utils/generic-utilities';
import { ForecastGridFixedFields } from './ForecastGridConstants';
import ForecastValidationMessages from './ValidationMessages';
import { roundToPrecision } from 'src/utils/ag-grid-utils';

export const INITIAL_VALIDATION_STATUS: ForecastTemplateDataValidationStatus = {
  HeadersMatching: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Header validation' },
  ForecastMonthValidation: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Forecast Month validation' },
  MandatoryFieldValidation: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Mandatory fields validation' },
  NonEditableFieldValidations: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Non editable fields validation' },
  UnAuthorizedRows: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Budget owner validation' },
  SegmentsValidation: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Segments validation' },
  DuplicateRecordValidation: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Duplicate validation' },
  RepeatedRecordValidation: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Repeated validation' }
};

export const NO_MODIFIED_ROWS: ForecastTemplateDataValidationStatus = {
  HeadersMatching: {
    colorOverride: 'green',
    validationStatus: 'success',
    validationMessage: ForecastValidationMessages.HEADER_VALIDATION_SUCCESS,
    validationDefaultMessage: '',
    validationErrorDetails: []
  },
  ForecastMonthValidation: {
    colorOverride: 'grey',
    validationStatus: 'stopped',
    validationMessage: ForecastValidationMessages.NO_MODIFIED_ROWS,
    validationDefaultMessage: '',
    validationErrorDetails: []
  },
  MandatoryFieldValidation: {
    colorOverride: 'grey',
    validationStatus: 'stopped',
    validationMessage: ForecastValidationMessages.NO_MODIFIED_ROWS,
    validationDefaultMessage: '',
    validationErrorDetails: []
  },
  NonEditableFieldValidations: {
    colorOverride: 'grey',
    validationStatus: 'stopped',
    validationMessage: ForecastValidationMessages.NO_MODIFIED_ROWS,
    validationDefaultMessage: '',
    validationErrorDetails: []
  },
  SegmentsValidation: {
    colorOverride: 'grey',
    validationStatus: 'stopped',
    validationMessage: ForecastValidationMessages.NO_MODIFIED_ROWS,
    validationDefaultMessage: '',
    validationErrorDetails: []
  },
  UnAuthorizedRows: {
    colorOverride: 'grey',
    validationStatus: 'stopped',
    validationMessage: ForecastValidationMessages.NO_MODIFIED_ROWS,
    validationDefaultMessage: '',
    validationErrorDetails: []
  },
  DuplicateRecordValidation: {
    colorOverride: 'grey',
    validationStatus: 'stopped',
    validationMessage: ForecastValidationMessages.NO_MODIFIED_ROWS,
    validationDefaultMessage: '',
    validationErrorDetails: []
  },
  RepeatedRecordValidation: {
    colorOverride: 'grey',
    validationStatus: 'stopped',
    validationMessage: ForecastValidationMessages.NO_MODIFIED_ROWS,
    validationDefaultMessage: '',
    validationErrorDetails: []
  }
};

export const INITIAL_SUBMIT_STATUS: ValidationStatusEntity = {
  colorOverride: 'grey',
  validationStatus: 'pending',
  validationMessage: 'Not Initiated',
  validationDefaultMessage: '',
  validationErrorDetails: []
};

// Defines the maximum number of fractional digits allowed for forecast month values.
// Values with more than this number of fractional digits will be rounded to this limit.
export const MAX_FRACTIONAL_DIGITS_ALLOWED = 2;

// Applicable to only file upload
export const transformUploadFileDisplayHeaderToModel = (forecastTemplateRelevantRowData: any[], forecastTemplateColumns: ForecastTemplateColumns) => {
  const lineItemSeqIdDisplayField = ForecastGridFixedFields.XptLineItemSeqId.displayName;
  const lineItemSeqIdValue = ForecastGridFixedFields.XptLineItemSeqId.value;

  const lineItemIdDisplayField = ForecastGridFixedFields.XptLineItemId.displayName;
  const lineItemIdValue = ForecastGridFixedFields.XptLineItemId.value;

  const budgetOwnerDisplayField = ForecastGridFixedFields.BudgetOwner.displayName;
  const budgetOwnerValue = ForecastGridFixedFields.BudgetOwner.value;

  const budgetTypeDisplayField = ForecastGridFixedFields.BudgetType.displayName;
  const budgetTypeValue = ForecastGridFixedFields.BudgetType.value;

  const forecastMonthDisplayFields = forecastTemplateColumns.forecastMonthsDisplayFormat;
  const forecastMonthValues = forecastTemplateColumns.forecastMonthColumnsIds;

  const actualMonthDisplayFields = forecastTemplateColumns.actualMonthsDisplayFormat;
  const actualMonthValues = forecastTemplateColumns.actualMonthColumnIds;

  return forecastTemplateRelevantRowData.map((item) => {
    const transformedItem: any = {};

    transformedItem[lineItemSeqIdValue] = item[lineItemSeqIdDisplayField];
    transformedItem[lineItemIdValue] = item[lineItemIdDisplayField];
    transformedItem[budgetOwnerValue] = item[budgetOwnerDisplayField];
    transformedItem[budgetTypeValue] = item[budgetTypeDisplayField];

    forecastMonthDisplayFields.forEach((field, index) => {
      transformedItem[forecastMonthValues[index]] = item[field];
    });

    actualMonthDisplayFields.forEach((field, index) => {
      transformedItem[actualMonthValues[index]] = item[field];
    });

    // Include the rest of the fields that are not explicitly transformed
    Object.keys(item).forEach((key) => {
      if (
        ![
          lineItemSeqIdDisplayField,
          lineItemIdDisplayField,
          budgetOwnerDisplayField,
          ...forecastMonthDisplayFields,
          ...actualMonthDisplayFields
        ].includes(key)
      ) {
        transformedItem[key] = item[key];
      }
    });

    return transformedItem;
  });
};

/**
 * Transforms forecast month values in the provided row data by parsing strings to floats and handling null values.
 * Applicable only to file upload.
 *
 * @param {any[]} forecastTemplateRelevantRowData - The relevant row data from the forecast template.
 * @param {ForecastTemplateColumns} forecastTemplateColumns - The forecast template columns configuration.
 * @returns {Promise<{ transformedData: any[], validationStatus: ValidationStatusEntity }>} - The transformed data and the validation status of the transformation.
 */
export const transformForecastMonths = async (
  forecastTemplateRelevantRowData: any[],
  forecastTemplateColumns: ForecastTemplateColumns
): Promise<{ transformedData: any[]; validationStatus: ValidationStatusEntity }> => {
  const corpSegmentMandatoryFields = forecastTemplateColumns.corpSegmentMandatoryFields;
  const forecastMonthColumnsIds = forecastTemplateColumns.forecastMonthColumnsIds;
  const validationErrorDetails: ValidationErrorDetail[] = [];

  const transformedData = forecastTemplateRelevantRowData.map((row, rowIndex) => {
    const updatedRow = { ...row };

    // The corp segment values are stored as strings in the system, even though they may be numeric in nature.
    // This code ensures that the values are properly converted to strings before processing or storing them.
    corpSegmentMandatoryFields.forEach((column) => {
      updatedRow[column] = row[column] == null ? null : String(row[column]);
    });

    forecastMonthColumnsIds.forEach((column) => {
      const value = updatedRow[column];
      if (value === null || value === undefined || (typeof value === 'string' && value.trim() === '')) {
        updatedRow[column] = null;
      } else {
        const parsedValue = parseFloat(value);
        if (isNaN(parsedValue)) {
          validationErrorDetails.push({
            rowIndex: rowIndex + 1,
            message: `Invalid number at column '${convertMonthFormatToDisplay(column)}' - ${updatedRow[column]} ${getLineItemIdMessagePart(row)}.`
          });
          updatedRow[column] = null;
        } else {
          // Rounding the value to the specified maximum number of fractional digits
          updatedRow[column] = roundToPrecision(parsedValue, MAX_FRACTIONAL_DIGITS_ALLOWED);
        }
      }
    });

    return updatedRow;
  });

  const validationStatus: ValidationStatusEntity =
    validationErrorDetails.length > 0
      ? {
          colorOverride: 'red',
          validationMessage: ForecastValidationMessages.FORECAST_MONTH_DATA_VALIDATION_FAILED,
          validationStatus: 'error',
          validationDefaultMessage: ForecastValidationMessages.FORECAST_MONTH_DATA_VALIDATION_DEFAULT,
          validationErrorDetails
        }
      : {
          colorOverride: 'green',
          validationMessage: ForecastValidationMessages.FORECAST_MONTH_DATA_VALIDATION_SUCCESS,
          validationStatus: 'success',
          validationDefaultMessage: ForecastValidationMessages.FORECAST_MONTH_DATA_VALIDATION_DEFAULT,
          validationErrorDetails: []
        };

  return { transformedData, validationStatus };
};

// Applicable to only file upload
export const getModifiedRowsFromExcelFile = (
  forecastTemplateColumns: ForecastTemplateColumns,
  transformedUploadFileData: any[],
  forecastTemplateCompleteData: any[],
  userAlias: string,
  scenarioSeqId: number | null,
  accountBudgetTypeMapping: AccountBudgetTypeMapping[],
  isBudgetLeaderOrAdmin: boolean
): any[] => {
  const modifiedRows: any[] = [];

  const modifiedCheckFields = forecastTemplateColumns.modifiedCheckFields;
  const forecastMonthColumnsIds = forecastTemplateColumns.forecastMonthColumnsIds;
  const corpSegmentFields = forecastTemplateColumns.corpSegmentMandatoryFields;

  try {
    transformedUploadFileData.forEach((fileRow) => {
      const isNewRow = !fileRow[ForecastGridFixedFields.XptLineItemId.value] || !fileRow[ForecastGridFixedFields.XptLineItemSeqId.value];

      if (isNewRow) {
        modifiedRows.push({
          ...fileRow,
          [ForecastGridFixedFields.RowId.value]: generateUniqueId(),
          [ForecastGridFixedFields.XptLineItemSeqId.value]: null,
          [ForecastGridFixedFields.XptLineItemId.value]: null,
          [ForecastGridFixedFields.ScenarioSeqId.value]: scenarioSeqId,
          [ForecastGridFixedFields.IsNewRow.value]: true,
          [ForecastGridFixedFields.IsTouched.value]: true,
          [ForecastGridFixedFields.IsEdited.value]: true,
          [ForecastGridFixedFields.IsActive.value]: true,
          [ForecastGridFixedFields.UpdatedBy.value]: userAlias,
          [ForecastGridFixedFields.UpdatedAt.value]: getCurrentUTCTimeInISO()
        });
        return;
      }

      const correspondingRow = forecastTemplateCompleteData.find(
        (templateRow) => templateRow[ForecastGridFixedFields.XptLineItemId.value] === fileRow[ForecastGridFixedFields.XptLineItemId.value]
      );

      if (!correspondingRow) {
        modifiedRows.push(fileRow);
        return;
      }

      let isModified = false;
      for (const checkField of modifiedCheckFields) {
        let fileValue = fileRow[checkField] === '' || fileRow[checkField] === undefined ? null : fileRow[checkField];
        let templateValue =
          correspondingRow[checkField] === 0 ? correspondingRow[checkField] : correspondingRow[checkField] ? correspondingRow[checkField] : null;

        // The corp segment values are stored as strings in the system, even though they may be numeric in nature.
        // This code ensures that the values are properly converted to strings before processing or storing them.
        if (corpSegmentFields.includes(checkField)) {
          fileValue = fileValue ? fileValue.toString() : fileValue;
          templateValue = templateValue ? templateValue.toString() : templateValue;
        }

        // Convert values to decimal with 2 fractional digits if the field is part of forecastMonthColumnsIds
        if (forecastMonthColumnsIds.includes(checkField)) {
          fileValue = fileValue !== null ? roundToPrecision(fileValue, MAX_FRACTIONAL_DIGITS_ALLOWED) : fileValue;
          templateValue = templateValue !== null ? roundToPrecision(templateValue, MAX_FRACTIONAL_DIGITS_ALLOWED) : templateValue;
        }

        // Convert XptLineItemSeqId to integers for comparison
        if (checkField === ForecastGridFixedFields.XptLineItemSeqId.value) {
          fileValue = fileValue ? parseInt(fileValue) : null; // parseInt(fileValue) can be null.
          templateValue = templateValue ? parseInt(templateValue) : templateValue;
        }

        // Coerce both values to string for comparison
        if (fileValue !== templateValue) {
          // console.debug('Modified file row ', JSON.stringify(fileRow));
          // console.debug('Modified original row ', JSON.stringify(correspondingRow));
          // console.debug(
          //   `Modified field with line item id as ${
          //     correspondingRow[ForecastGridFixedFields.XptLineItemId.value]
          //   }: ${checkField}, File Value: ${fileValue}, Template Value: ${templateValue}`
          // );
          isModified = true;
          break;
        }
      }

      if (isModified) {
        const modifiedAccountCode = fileRow[eCorpSegmentNames.ACCOUNT];
        const correspondingBudgetType =
          accountBudgetTypeMapping.find((accountBudgetType) => accountBudgetType.account_code === modifiedAccountCode)?.budget_type || null;
        // logger.info(`modifiedAccountCode ${modifiedAccountCode} and corresponding budget type is ${correspondingBudgetType}`);
        if (correspondingBudgetType === null) logger.warn(`Unable to find Budget Type for Account Code ${modifiedAccountCode}`);

        const modifiedRow = {
          ...fileRow,
          [ForecastGridFixedFields.RowId.value]: generateUniqueId(),
          [ForecastGridFixedFields.XptLineItemSeqId.value]:
            fileRow[ForecastGridFixedFields.XptLineItemSeqId.value] !== null ? +fileRow[ForecastGridFixedFields.XptLineItemSeqId.value] : null,
          [ForecastGridFixedFields.ScenarioSeqId.value]: scenarioSeqId,
          [ForecastGridFixedFields.IsNewRow.value]: false,
          [ForecastGridFixedFields.IsTouched.value]: true,
          [ForecastGridFixedFields.IsEdited.value]: true,
          [ForecastGridFixedFields.IsActive.value]: true,
          [ForecastGridFixedFields.BudgetType.value]: correspondingBudgetType,
          [ForecastGridFixedFields.UpdatedBy.value]: userAlias,
          [ForecastGridFixedFields.UpdatedAt.value]: getCurrentUTCTimeInISO()
        };
        modifiedRows.push(modifiedRow);
      }
    });
  } catch (error: any) {
    logger.error('Error while comparing rows:', error);
  }

  return modifiedRows;
};

// Applicable to only file upload
/**
 * Validates that the headers match the expected headers in both presence and order.
 * @param {string[]} headerRow - The list of headers from the uploaded file.
 * @param {string[]} expectedHeaders - The expected list of headers.
 * @returns {Promise<ValidationStatusEntity>} - The validation status of the headers.
 */
export const validateHeaders = async (headerRow: string[], expectedHeaders: string[]): Promise<ValidationStatusEntity> => {
  const validationErrorDetails: ValidationErrorDetail[] = [];
  if (headerRow.length !== expectedHeaders.length) {
    validationErrorDetails.push({
      message: `Header mismatch.`
    });
  } else {
    headerRow.forEach((header, rowIndex) => {
      if (header !== expectedHeaders[rowIndex]) {
        validationErrorDetails.push({
          rowIndex: rowIndex + 1,
          message: `Header mismatch. Expected '${expectedHeaders[rowIndex]}', found '${header}'.`
        });
      }
    });
  }

  if (validationErrorDetails.length > 0) {
    console.debug(`Header validation failed.`, JSON.stringify(validationErrorDetails, null, 2));
    return {
      colorOverride: 'red',
      validationMessage: ForecastValidationMessages.HEADER_VALIDATION_FAILED,
      validationStatus: 'error',
      validationDefaultMessage: ForecastValidationMessages.HEADER_VALIDATION_DEFAULT_MESSAGE,
      validationErrorDetails
    };
  }

  return {
    colorOverride: 'green',
    validationMessage: ForecastValidationMessages.HEADER_VALIDATION_SUCCESS,
    validationStatus: 'success',
    validationDefaultMessage: ForecastValidationMessages.HEADER_VALIDATION_DEFAULT_MESSAGE,
    validationErrorDetails: []
  };
};

/**
 * Validates that all mandatory fields are present in each object of the file data.
 * @param {string[]} mandatoryFields - The array of mandatory field names.
 * @param {any[]} forecastTemplateRelevantRowData - The array of data objects from the file.
 * @returns {Promise<ValidationStatusEntity>} - The validation result containing errors and validated rows.
 */
export const validateMandatoryFields = async (
  forecastTemplateRelevantRowData: any[],
  forecastTemplateColumns: ForecastTemplateColumns
): Promise<ValidationStatusEntity> => {
  const validationErrorDetails: ValidationErrorDetail[] = [];
  const mandatoryFields = forecastTemplateColumns.mandatoryFields;

  // But to display the error message, we don't need to show Budget Owner & Description fields.
  // Basically Auto generated fields no need to show in error message.
  const corpSegments = forecastTemplateColumns.corpSegmentMandatoryFields?.filter((corpSegment) => !corpSegment.endsWith(' Description'));
  const businessSegments = forecastTemplateColumns.businessSegmentMandatoryFields?.filter(
    (businessSegment) => !businessSegment.endsWith(' Description')
  );
  const errorMessageMandatoryFields = corpSegments.concat(businessSegments);
  const defaultValidationMessage = ForecastValidationMessages.MANDATORY_FIELDS_VALIDATION_DEFAULT_MESSAGE(errorMessageMandatoryFields);

  forecastTemplateRelevantRowData.forEach((row, rowIndex) => {
    const missingFields = mandatoryFields.filter(
      (field) => !row.hasOwnProperty(field) || row[field] === null || row[field] === undefined || row[field] === ''
    );

    if (missingFields.length > 0) {
      missingFields.forEach((field) => {
        validationErrorDetails.push({
          message: `Required field ${field} ${getLineItemIdMessagePart(row)}`
        });
      });
    }
  });

  if (validationErrorDetails.length > 0) {
    console.debug(`Mandatory field check validation failed.`, JSON.stringify(validationErrorDetails, null, 2));
    return {
      colorOverride: 'red',
      validationStatus: 'error',
      validationMessage: ForecastValidationMessages.MANDATORY_FIELDS_VALIDATION_FAILED,
      validationDefaultMessage: defaultValidationMessage,
      validationErrorDetails
    };
  }

  return {
    colorOverride: 'green',
    validationStatus: 'success',
    validationMessage: ForecastValidationMessages.MANDATORY_FIELDS_VALIDATION_SUCCESS,
    validationDefaultMessage: defaultValidationMessage,
    validationErrorDetails: []
  };
};

/**
 * Validates that non-editable fields have not been modified.
 * @param {any[]} forecastTemplateRelevantRowData - The array of data objects from the file.
 * @param {ForecastTemplateColumns} forecastTemplateColumns - The forecast template columns configuration.
 * @param {any[]} forecastTemplateCompleteData - The array of complete data objects from the forecast template.
 * @param {string} userAlias - The alias of the current user.
 * @param {boolean} isAdminUser - Flag indicating if the current user is an admin.
 * @returns {Promise<ValidationStatusEntity>} - The validation result containing errors and validated rows.
 */
export const validateNonEditableFields = async (
  forecastTemplateRelevantRowData: any[],
  forecastTemplateColumns: ForecastTemplateColumns,
  forecastTemplateCompleteData: any[],
  isBudgetLeaderOrAdmin: boolean
): Promise<ValidationStatusEntity> => {
  const validationErrorDetails: ValidationErrorDetail[] = [];

  const lineItemIdFieldName = ForecastGridFixedFields.XptLineItemId.value;
  const lineItemSeqIdFieldName = ForecastGridFixedFields.XptLineItemSeqId.value;

  // Admins can edit Budget Owner. So, excluding the BudgetOwner from check for Admins.
  const nonEditableFieldsBasedOnRole = isBudgetLeaderOrAdmin
    ? forecastTemplateColumns.mandatoryFields.filter((field) => field !== ForecastGridFixedFields.BudgetOwner.value)
    : forecastTemplateColumns.mandatoryFields;

  // Create a map for quick lookup
  const completeDataMap = new Map<string, any>();

  forecastTemplateCompleteData.forEach((row) => {
    const key = `${row[lineItemIdFieldName]}_${row[lineItemSeqIdFieldName]}`;
    completeDataMap.set(key, row);
  });

  forecastTemplateRelevantRowData.forEach((row, rowIndex) => {
    const lineItemId = row[lineItemIdFieldName];
    const lineItemSeqId = row[lineItemSeqIdFieldName];

    if ((lineItemId === null && lineItemSeqId === null) || (lineItemId === '' && lineItemSeqId === '')) {
      return; // Valid combination, skip further checks for this row
    }

    if ((lineItemId && !lineItemSeqId) || (!lineItemId && lineItemSeqId)) {
      validationErrorDetails.push({
        message: `Invalid combination of line item ID and sequence ID ${getLineItemIdMessagePart(row)}`
      });
      return; // Invalid combination, no need to check further for this row
    }

    const key = `${lineItemId}_${lineItemSeqId}`;
    const matchingRow = completeDataMap.get(key);

    if (!matchingRow) {
      validationErrorDetails.push({
        message: `Line item ID and sequence ID combination not found in data ${getLineItemIdMessagePart(row)}`
      });
    } else {
      // Check non-editable fields
      nonEditableFieldsBasedOnRole.forEach((field) => {
        if (row[field] !== matchingRow[field]) {
          validationErrorDetails.push({
            message: `${field} '${row[field]}' ${getLineItemIdMessagePart(row)} - Non-editable field`
          });
        }
      });
    }
  });

  if (validationErrorDetails.length > 0) {
    console.debug(`Non-editable field check validation failed.`, JSON.stringify(validationErrorDetails, null, 2));
    return {
      colorOverride: 'red',
      validationStatus: 'error',
      validationMessage: ForecastValidationMessages.NON_EDITABLE_FIELD_VALIDATION_FAILED,
      validationDefaultMessage: ForecastValidationMessages.NON_EDITABLE_FIELD_VALIDATION_DEFAULT,
      validationErrorDetails
    };
  }

  return {
    colorOverride: 'green',
    validationStatus: 'success',
    validationMessage: ForecastValidationMessages.NON_EDITABLE_FIELD_VALIDATION_SUCCESS,
    validationDefaultMessage: ForecastValidationMessages.NON_EDITABLE_FIELD_VALIDATION_DEFAULT,
    validationErrorDetails: []
  };
};

/**
 * Validates that the user has authorization to modify the rows.
 * @param {any[]} modifiedRows - The array of modified rows to validate.
 * @param {string} userAlias - The alias of the current user.
 * @param {boolean} isAdminUser - Whether the current user is an admin.
 * @returns {Promise<ValidationStatusEntity>} - The validation result containing errors and validated rows.
 */
export const validateUnAuthorizedRows = (modifiedRows: any[], userAlias: string, isAdminUser: boolean): Promise<ValidationStatusEntity> => {
  return new Promise((resolve) => {
    const validationErrorDetails: ValidationErrorDetail[] = [];

    modifiedRows.forEach((row, rowIndex) => {
      const lineItemSeqId = row[ForecastGridFixedFields.XptLineItemSeqId.value];
      const isNewRow = lineItemSeqId === null || lineItemSeqId === '' || lineItemSeqId === undefined;
      const budgetOwner = row[ForecastGridFixedFields.BudgetOwner.value];

      if (!isAdminUser && budgetOwner !== userAlias) {
        validationErrorDetails.push({
          rowIndex: rowIndex + 1,
          message: `Budget Owners can only modify their own rows. Expected Budget Owner: ${userAlias}. Found ${budgetOwner}  ${getLineItemIdMessagePart(
            row
          )}.`
        });
      }
      //  else if (!isNewRow && isAdminUser) {
      // Admin users can modify any row
      // console.debug(`Admin user ${userAlias} is modifying row ${index + 1}.`);
      // }
    });

    if (validationErrorDetails.length > 0) {
      console.debug(`UnAuthorized check validation failed.`, JSON.stringify(validationErrorDetails, null, 2));
      return resolve({
        colorOverride: 'red',
        validationStatus: 'error',
        validationMessage: ForecastValidationMessages.UNAUTHORIZED_ROWS_VALIDATION_FAILED,
        validationDefaultMessage: ForecastValidationMessages.UNAUTHORIZED_ROWS_VALIDATION_DEFAULT,
        validationErrorDetails
      });
    }

    resolve({
      colorOverride: 'green',
      validationStatus: 'success',
      validationMessage: ForecastValidationMessages.UNAUTHORIZED_ROWS_VALIDATION_SUCCESS,
      validationDefaultMessage: ForecastValidationMessages.UNAUTHORIZED_ROWS_VALIDATION_DEFAULT,
      validationErrorDetails: []
    });
  });
};

/**
 * Validates the corp segment and business segment fields in the modified rows.
 * @param {any[]} forecastTemplateRelevantRowData - The array of modified rows to validate.
 * @param {ForecastTemplateColumns} forecastTemplateColumns - The column definitions including segment fields.
 * @param {ForecastTemplateMasterCorpSegmentDropdownValues[]} corpSegmentDropdownValues - The array of master corp segment dropdown values.
 * @param {MasterBusinessSegments[]} masterBusinessSegments - The array of master business segments.
 * @returns {Promise<ValidationStatusEntity>} - The validation result containing errors and validated rows.
 */
export const validateSegments = (
  forecastTemplateRelevantRowData: any[],
  forecastTemplateColumns: ForecastTemplateColumns,
  corpSegmentDropdownValues: ForecastTemplateMasterCorpSegmentDropdownValues[],
  masterBusinessSegments: MasterBusinessSegments[],
  expenseTypesForCurrentGroup: string[],
  expenseTypesCompleteList: string[]
): Promise<ValidationStatusEntity> => {
  return new Promise((resolve) => {
    const validationErrorDetails: ValidationErrorDetail[] = [];

    const { corpSegmentMandatoryFields, businessSegmentDropdownFields } = forecastTemplateColumns;
    const corpSegmentFieldsWithoutDescription = corpSegmentMandatoryFields.filter((field) => !field.endsWith(' Description'));

    forecastTemplateRelevantRowData.forEach((row, rowIndex) => {
      // Validate Corp Segment Fields
      corpSegmentFieldsWithoutDescription.forEach((field) => {
        const dropdownValue = corpSegmentDropdownValues.find((dropdown) => dropdown.masterCorpSegmentDisplayName === field);

        if (!dropdownValue) {
          validationErrorDetails.push({
            message: `Dropdown values for Corp Segment field: ${field} are missing.`
          });
          return;
        }

        const isValid = dropdownValue.masterCorpSegmentDropdownValues.some((option) => option.label === row[field]);

        if (!isValid) {
          validationErrorDetails.push({
            message: `Invalid value for ${field} - ${row[field] ? row[field] : ' '} ${getLineItemIdMessagePart(row)}`
          });
        }
      });

      // Validate Business Segment Fields
      businessSegmentDropdownFields.forEach((field) => {
        const masterSegment = masterBusinessSegments.find((segment) => segment.segment_name === field);

        if (!masterSegment) {
          validationErrorDetails.push({
            message: `Dropdown values for Business Segment field: ${field} are missing.`
          });
          return;
        }

        const validExpenseTypeList = row[ForecastGridFixedFields.IsNewRow.value] ? expenseTypesForCurrentGroup : expenseTypesCompleteList;
        const isValid =
          field === eBusinessSegmentNames.EXPENSE_TYPE
            ? validExpenseTypeList.some((expenseType) => expenseType === row[field])
            : masterSegment.business_segment_dropdown_list.some((option) => option === row[field]);

        if (!isValid) {
          validationErrorDetails.push({
            message: `Invalid value for ${field} - ${row[field] ? row[field] : ' '} ${getLineItemIdMessagePart(row)}`
          });
        }
      });
    });

    if (validationErrorDetails.length > 0) {
      console.debug(`Segment Validation failed.`, JSON.stringify(validationErrorDetails, null, 2));
      resolve({
        colorOverride: 'red',
        validationStatus: 'error',
        validationMessage: ForecastValidationMessages.SEGMENTS_VALIDATION_IN_FAILED,
        validationDefaultMessage: ForecastValidationMessages.SEGMENTS_VALIDATION_DEFAULT,
        validationErrorDetails
      });
    } else {
      resolve({
        colorOverride: 'green',
        validationStatus: 'success',
        validationMessage: ForecastValidationMessages.SEGMENTS_VALIDATION_IN_SUCCESS,
        validationDefaultMessage: ForecastValidationMessages.SEGMENTS_VALIDATION_DEFAULT,
        validationErrorDetails: []
      });
    }
  });
};

/**
 * Checks for duplicate records based on the combination of All the Corp Segment Fields & Business Segment ID Fields. (Doesn't consider Budget Owner while checking for duplicate records)
 * @param {any[]} forecastTemplateRelevantRowData - An array of relevant rows from the forecast template to be validated.
 * @param {ForecastTemplateColumns} forecastTemplateColumns -  An object containing column definitions, specifically corpSegmentMandatoryFields and businessSegmentMandatoryFields.
 * @param {any[]} forecastTemplateCompleteData - An array of all rows from the forecast template for checking duplicates against.
 * @returns {Promise<ValidationStatusEntity>} - The validation result containing errors and validated rows.
 */
export const validateDuplicateRecordsWhileUpload = async (
  forecastTemplateRelevantRowData: any[],
  forecastTemplateColumns: ForecastTemplateColumns,
  forecastTemplateCompleteData: any[]
): Promise<ValidationStatusEntity> => {
  const validationErrorDetails: ValidationErrorDetail[] = [];

  const corpSegmentFieldsWithoutDescription = forecastTemplateColumns.corpSegmentMandatoryFields.filter((field) => !field.endsWith(' Description'));
  // Combine all the Corporate Segment Fields and Business Segment ID Fields into a single array.
  const idColumns = corpSegmentFieldsWithoutDescription.concat(forecastTemplateColumns.businessSegmentMandatoryFields);

  // Iterate over each row in the relevant row data.
  forecastTemplateRelevantRowData.forEach((row, rowIndex) => {
    // Check if there is any existing row in the complete data that matches the current row.
    forecastTemplateCompleteData.some((existingRow) => {
      // Check if all the ID columns match between the current row and the existing row.
      const allColumnsMatch = idColumns.every((column) => row[column] === existingRow[column]);

      if (allColumnsMatch) {
        validationErrorDetails.push({
          rowIndex: rowIndex + 1,
          message: `Record already exist ${getLineItemIdMessagePart(row)}`
        });
        return true;
      }
      return false;
    });
  });

  if (validationErrorDetails.length > 0) {
    console.debug(`Checking if records already exist Validation failed.`, JSON.stringify(validationErrorDetails, null, 2));
    return {
      colorOverride: 'red',
      validationStatus: 'error',
      validationMessage: ForecastValidationMessages.EXISTING_RECORDS_FOUND,
      validationDefaultMessage: ForecastValidationMessages.EXISTING_RECORDS_VALIDATION_DEFAULT,
      validationErrorDetails
    };
  }

  return {
    colorOverride: 'green',
    validationStatus: 'success',
    validationMessage: ForecastValidationMessages.NO_EXISTING_RECORDS,
    validationDefaultMessage: '',
    validationErrorDetails: []
  };
};

/**
 * Checks for duplicate records based on the combination of all the Corp Segment Fields & Business Segment ID Fields within the relevant row data.
 * (Doesn't consider Budget Owner while checking for duplicate records)
 * @param {any[]} forecastTemplateRelevantRowData - An array of relevant rows from the forecast template to be validated.
 * @param {ForecastTemplateColumns} forecastTemplateColumns - An object containing column definitions, specifically corpSegmentMandatoryFields and businessSegmentMandatoryFields.
 * @returns {Promise<ValidationStatusEntity>} - The validation result containing errors and validated rows.
 */
export const validateDuplicateRecords = async (
  forecastTemplateRelevantRowData: any[],
  forecastTemplateColumns: ForecastTemplateColumns
): Promise<ValidationStatusEntity> => {
  const validationErrorDetails: ValidationErrorDetail[] = [];

  const corpSegmentFieldsWithoutDescription = forecastTemplateColumns.corpSegmentMandatoryFields.filter((field) => !field.endsWith(' Description'));
  // Combine all the Corporate Segment Fields and Business Segment ID Fields into a single array.
  const idColumns = corpSegmentFieldsWithoutDescription.concat(forecastTemplateColumns.businessSegmentMandatoryFields);

  // Set to keep track of unique combinations
  const uniqueCombinations = new Set<string>();

  // Iterate over each row in the relevant row data.
  forecastTemplateRelevantRowData.forEach((row, rowIndex) => {
    // Create a unique key for the combination of all ID columns
    const combinationKey = idColumns.map((column) => row[column]).join('|');

    // Check if the combination key already exists in the set
    if (uniqueCombinations.has(combinationKey)) {
      validationErrorDetails.push({
        rowIndex: rowIndex + 1,
        message: `Duplicate record found ${getLineItemIdMessagePart(row)}`
      });
    } else {
      uniqueCombinations.add(combinationKey);
    }
  });

  if (validationErrorDetails.length > 0) {
    console.debug(`Duplicate Validation failed.`, JSON.stringify(validationErrorDetails, null, 2));
    return {
      colorOverride: 'red',
      validationStatus: 'error',
      validationMessage: ForecastValidationMessages.DUPLICATE_RECORDS_FOUND,
      validationDefaultMessage: ForecastValidationMessages.DUPLICATE_RECORDS_VALIDATION_DEFAULT,
      validationErrorDetails
    };
  }

  return {
    colorOverride: 'green',
    validationStatus: 'success',
    validationMessage: ForecastValidationMessages.NO_DUPLICATE_RECORDS,
    validationDefaultMessage: ForecastValidationMessages.DUPLICATE_RECORDS_VALIDATION_DEFAULT,
    validationErrorDetails: []
  };
};

export const getLineItemIdMessagePart = (row: any): string => {
  const lineItemId = row[ForecastGridFixedFields.XptLineItemId.value];
  return lineItemId ? `(ID: ${lineItemId})` : '';
};
