import {
  Badge,
  Box,
  Button,
  Container,
  Flashbar,
  Form,
  FormField,
  Header,
  Input,
  Multiselect,
  Select,
  SpaceBetween,
  Spinner,
  StatusIndicator
} from '@amzn/awsui-components-react';
import { Formik, FormikProps } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { logger } from 'src/analytics/KatalLogger';
import { getBudgetOwnerTransferHistory, getCurrentBudgetOwners, updateBudgetOwnerTransfer } from 'src/api/app-sync-services';
import FormFieldPopover from 'src/components/common/FormFieldPopover';
import { XptAppLayout } from 'src/components/common/xpt-app-layout/XptAppLayout';
import XptTable from 'src/components/common/xpt-table/XptTable';
import { XPTBreadcrumbs } from 'src/components/common/XptBreadcrumb';
import { UserValidationMessages } from 'src/constants/generic-constants';
import { useAuth } from 'src/features/auth/AuthContextProvider';
import { selectAllBusinessGroups } from 'src/features/business-group/businessGroupSelectors';
import { getBusinessGroupById } from 'src/features/business-group/BusinessGroupUtils';
import { useFlashbar } from 'src/hooks/useFlashbar';
import { DropdownModel } from 'src/models/AppContextModels';
import {
  BudgetOwners,
  BudgetOwnershipTransfer,
  BudgetOwnershipTransferForm,
  BudgetOwnershipTransferMutation,
  BudgetOwnershipTransferWithBusinessGroups
} from 'src/models/BudgetOwnerTransferModel';
import { ValidateUserAliasResponse } from 'src/models/PeopleApiModel';
import { dateTimeComparator } from 'src/utils/date-time-utilities';
import { generateUniqueId, getHeaderCounter } from 'src/utils/generic-utilities';
import { validateUserAliasWithPAPI } from 'src/utils/papi-services';
import { getForecastS3BucketName } from 'src/utils/xpt-s3-bucket-details';
import * as Yup from 'yup';
import AdminConsoleSideNavigation from '../AdminConsoleSideNavigation';
import {
  BUDGET_OWNER_MESSAGES,
  BUDGET_OWNER_TRANSFER_CONFIG,
  geBOTransferBreadcrumbItems,
  getBudgetOwnerTransferDefinitions,
  parseBudgetOwnerTransfer
} from './BudgetOwnerTransferConfig';

// Define Yup schema for BudgetOwnershipTransferForm
const budgetOwnershipTransferFormSchema = Yup.object().shape({
  fromUserAlias: Yup.string()
    .required('From User Alias is required')
    .test('unique-alias', 'From User Alias and To User Alias cannot be the same', function (value) {
      return value !== this.parent.toUserAlias;
    }),
  businessGroups: Yup.array().min(1, 'At least one business group').required('Business Group is required'),
  toUserAlias: Yup.string()
    .matches(/^[a-zA-Z]+$/, 'To User Alias can only contain alphabets')
    .max(20, 'To User Alias must be at most 20 characters')
    .required('To User Alias is required'),
  isValidToUserAlias: Yup.boolean().required('isValidToUserAlias is required').oneOf([true], 'To User Alias must be valid')
});

const FormInitialValues: BudgetOwnershipTransferForm = {
  fromUserAlias: '',
  businessGroups: [],
  toUserAlias: '',
  isValidToUserAlias: false
};

const BudgetOwnerTransfer: React.FC = () => {
  const { Alias } = useAuth();
  const appLayout = useRef<any>();
  const allBusinessGroups = useSelector(selectAllBusinessGroups);

  const { flashbarItems, displayFlashMessage, clearSpecificFlashMessage } = useFlashbar();
  const boTransferFormRef = useRef<FormikProps<BudgetOwnershipTransferForm>>(null);

  const [budgetOwnersEntity, setBudgetOwnersEntity] = useState<BudgetOwners[]>([]);
  const [budgetOwnersDataLoading, setBudgetOwnersDataLoading] = useState<boolean>(false);

  const [fromUserAliasList, setFromUserAliasList] = useState<DropdownModel[]>([]);

  const [boTransferFormInitialValues, setBoTransferInitialValues] = useState<BudgetOwnershipTransferForm>(FormInitialValues);
  const [validating, setValidating] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');

  const [transferHistory, setTransferHistory] = useState<BudgetOwnershipTransferWithBusinessGroups[]>([]);
  const [transferHistoryLoading, setTransferHistoryLoading] = useState(true);

  // Fetch the current budget owners and update the fromUserAliasList
  const loadCurrentBudgetOwners = useCallback(async () => {
    try {
      setBudgetOwnersDataLoading(true);
      const budgetOwnersAPIResponse: BudgetOwners[] = await getCurrentBudgetOwners();
      setBudgetOwnersEntity(budgetOwnersAPIResponse);
    } catch (error: any) {
      logger.error('Unable to load current budget owners', error);
      displayFlashMessage(BUDGET_OWNER_MESSAGES.UNABLE_TO_LOAD_BUDGET_OWNERS, 'error', true);
    } finally {
      setBudgetOwnersDataLoading(false);
    }
  }, []);

  // Fetch the budget owner transfer history and update the transferHistory state
  const loadBudgetOwnerTransferHistory = useCallback(async () => {
    try {
      setTransferHistoryLoading(true);
      const budgetOwnerTransferHistory: BudgetOwnershipTransfer[] = await getBudgetOwnerTransferHistory();
      const parsedBudgetOwnerTransferHistory = parseBudgetOwnerTransfer(budgetOwnerTransferHistory, allBusinessGroups);
      const transferHistorySorted = parsedBudgetOwnerTransferHistory.sort((a, b) => dateTimeComparator(a.last_updated_at, b.last_updated_at, false));
      setTransferHistory(transferHistorySorted);
    } catch (error: any) {
      setTransferHistory([]);
      logger.error('Unable to load budget owner transfer history', error);
      displayFlashMessage(BUDGET_OWNER_MESSAGES.UNABLE_TO_LOAD_AUDIT_LOG, 'error', true);
    } finally {
      setTransferHistoryLoading(false);
    }
  }, [setTransferHistory]);

  // Load current budget owners and transfer history when the component mounts
  useEffect(() => {
    loadCurrentBudgetOwners();
    loadBudgetOwnerTransferHistory();
  }, [loadCurrentBudgetOwners, loadBudgetOwnerTransferHistory]);

  // Validate the user alias using the PAPI service
  const validateAlias = async (userInput: string, setFieldValue: (field: string, value: any) => void) => {
    if (!userInput) return;

    setValidating(true);
    try {
      const response: ValidateUserAliasResponse = await validateUserAliasWithPAPI(userInput);
      setFieldValue('isValidToUserAlias', response.isValid);
      setValidationMessage(response.validationMessage);
    } catch (error: any) {
      logger.error(`Alias validation failed using PAPI for ${userInput}`, error);
      setFieldValue('isValidToUserAlias', false);
      setValidationMessage(UserValidationMessages.UserAliasIsInvalid);
    } finally {
      setValidating(false);
    }
  };

  // Reset the form state
  const resetState = () => {
    setValidationMessage('');
    setValidating(false);
    boTransferFormRef.current?.resetForm();
    setBoTransferInitialValues(FormInitialValues);
  };

  // Handle the form submission for budget owner transfer
  const handleSubmit = async (values: BudgetOwnershipTransferForm) => {
    if (values.isValidToUserAlias) {
      const inProgressMessageId = generateUniqueId();
      displayFlashMessage(BUDGET_OWNER_MESSAGES.BUDGET_OWNER_TRANSFER_IN_PROGRESS, 'info', false, inProgressMessageId);
      logger.info('Budget Owner Transfer initiated');
      const selectedDataClassificationIds = values.businessGroups.map((businessGroup) => +businessGroup.value);
      const mutationObject: BudgetOwnershipTransferMutation = {
        from_alias: values.fromUserAlias,
        to_alias: values.toUserAlias,
        performed_by: Alias,
        s3_bucket_name: getForecastS3BucketName().bucketName,
        data_classification_id_lists: selectedDataClassificationIds
      };

      try {
        const budgetOwnerTransferResponse = await updateBudgetOwnerTransfer(mutationObject);
        const noOfRecordsUpdated = budgetOwnerTransferResponse?.updateBudgetOwnership?.numberOfRecordsUpdated;
        if (noOfRecordsUpdated === 0) {
          displayFlashMessage(BUDGET_OWNER_MESSAGES.NO_DATA_TO_TRANSFER, 'success', true);
        } else {
          displayFlashMessage(BUDGET_OWNER_MESSAGES.BUDGET_OWNER_TRANSFER_SUCCESSFUL(noOfRecordsUpdated), 'success', true);
        }

        resetState();
        loadCurrentBudgetOwners();
        loadBudgetOwnerTransferHistory();
      } catch (error: any) {
        logger.error('Budget Owner Transfer failed', error);
        displayFlashMessage(BUDGET_OWNER_MESSAGES.BUDGET_OWNER_TRANSFER_FAILED, 'error', true);
      } finally {
        clearSpecificFlashMessage(inProgressMessageId);
      }
    }
  };

  const hasInProgressTransfer = transferHistory.some((item) => item.status === 'in-progress');

  // Render the Budget Owner Transfer History header
  const BOTransferHistoryHeader = () => (
    <Header
      variant="h2"
      actions={
        <SpaceBetween size="m" direction="horizontal">
          <Button iconName="refresh" onClick={loadBudgetOwnerTransferHistory}></Button>
        </SpaceBetween>
      }
      counter={getHeaderCounter([], transferHistory)}
    >
      Transfer History
    </Header>
  );

  useEffect(() => {
    const fromUserAliases = budgetOwnersEntity?.map(
      (budgetOwner) =>
        ({
          label: budgetOwner.budget_owner,
          value: budgetOwner.budget_owner
        } as DropdownModel)
    );
    setFromUserAliasList(fromUserAliases);
  }, [budgetOwnersEntity]);

  // Business Group options based on selection of fromUserAlias in From
  const getBusinessGroupOptions = (fromUserAlias: string) => {
    const budgetOwnerDetails = budgetOwnersEntity.find((budgetOwner) => budgetOwner.budget_owner === fromUserAlias);
    if (!budgetOwnerDetails) return [];

    const businessGroups: DropdownModel[] = budgetOwnerDetails.data_classification_id_lists.map((dataClassificationId) => {
      const businessGroup = getBusinessGroupById(dataClassificationId, allBusinessGroups);
      return {
        label: businessGroup!.data_classification.data_classification_name,
        value: `${businessGroup!.data_classification.data_classification_id}`
      } as DropdownModel;
    });

    return businessGroups;
  };

  return (
    <XptAppLayout
      ref={appLayout}
      headerSelector="#h"
      navigation={<AdminConsoleSideNavigation />}
      breadcrumbs={<XPTBreadcrumbs items={geBOTransferBreadcrumbItems()} />}
      notifications={<Flashbar items={flashbarItems} stackItems />}
      stickyNotifications={true}
      maxContentWidth={Number.MAX_VALUE}
      contentType="default"
      content={
        <div className="xpt-app-layout-content">
          <Container>
            <Formik<BudgetOwnershipTransferForm>
              innerRef={boTransferFormRef}
              enableReinitialize
              initialValues={boTransferFormInitialValues}
              validationSchema={budgetOwnershipTransferFormSchema}
              onSubmit={handleSubmit}
            >
              {({ values, touched, errors, setFieldValue, handleSubmit, setFieldTouched, isSubmitting, dirty, isValid }) => (
                <form onSubmit={handleSubmit}>
                  <Form header={<Header>Budget Owner Transfer</Header>}>
                    <SpaceBetween size="m" direction="horizontal">
                      <FormField label="From Alias" errorText={touched.fromUserAlias && errors.fromUserAlias} className="width-20-rem">
                        <Select
                          placeholder="Select User Alias"
                          options={fromUserAliasList}
                          selectedOption={{ value: values.fromUserAlias, label: values.fromUserAlias }}
                          filteringType="auto"
                          expandToViewport
                          statusType={budgetOwnersDataLoading ? 'loading' : 'finished'}
                          loadingText="Loading current budget owners"
                          onChange={(e) => {
                            setFieldValue('fromUserAlias', e.detail.selectedOption.value, true);
                            setFieldValue('businessGroups', [], true);
                          }}
                          onBlur={() => {
                            setFieldTouched('fromUserAlias', true, true);
                          }}
                        />
                      </FormField>

                      <FormField
                        label="Business Group"
                        errorText={touched.businessGroups && typeof errors.businessGroups === 'string' ? errors.businessGroups : undefined}
                        className="width-30-rem"
                        info={
                          <FormFieldPopover header="Business Group" content={`Choose User Alias to view the business group owned by that user.`} />
                        }
                      >
                        <Multiselect
                          placeholder="Select Business Groups"
                          filteringType="auto"
                          expandToViewport
                          options={values.fromUserAlias ? getBusinessGroupOptions(values.fromUserAlias) : []}
                          selectedOptions={values.businessGroups}
                          statusType={budgetOwnersDataLoading ? 'loading' : 'finished'}
                          loadingText="Loading business groups"
                          onChange={(e) => {
                            setFieldValue('businessGroups', e.detail.selectedOptions, true);
                          }}
                          onBlur={() => {
                            setFieldTouched('businessGroups', true, true);
                          }}
                        />
                      </FormField>

                      <SpaceBetween size="m" direction="vertical">
                        <FormField label="To Alias" errorText={touched.toUserAlias && errors.toUserAlias} className="width-20-rem">
                          <Input
                            autoComplete={false}
                            value={values.toUserAlias}
                            onChange={(e) => {
                              setFieldValue('toUserAlias', e.detail.value);
                              setFieldValue('isValidToUserAlias', false);
                            }}
                            onBlur={() => {
                              setFieldTouched('toUserAlias', true, true);
                              setFieldTouched('isValidToUserAlias', true, true);
                              validateAlias(values.toUserAlias, setFieldValue);
                            }}
                            disabled={validating}
                          />
                        </FormField>
                        {validating ? (
                          <Box margin="xxxs" padding="xxxs" textAlign="left">
                            <SpaceBetween size="m" direction="horizontal">
                              <Spinner size="normal" />
                              <Badge color="grey">Validating</Badge>
                            </SpaceBetween>
                          </Box>
                        ) : (
                          <Badge color={values.isValidToUserAlias ? 'green' : 'red'}>{validationMessage}</Badge>
                        )}
                      </SpaceBetween>

                      <Box padding={{ top: 'l' }}>
                        <Button
                          disabled={isSubmitting || !dirty || !isValid || validating || hasInProgressTransfer}
                          variant="primary"
                          formAction="submit"
                        >
                          {isSubmitting ? <Spinner size="normal" /> : 'Submit'}
                        </Button>
                      </Box>

                      {hasInProgressTransfer && (
                        <Box padding={{ top: 'l' }}>
                          <StatusIndicator type="info">{`Note: Only one transfer can be in progress at a time.`}</StatusIndicator>
                        </Box>
                      )}
                    </SpaceBetween>
                  </Form>
                </form>
              )}
            </Formik>
          </Container>
          <Box margin={{ top: 'm' }}>
            <XptTable
              xptTableVariant="container"
              loadingStatus={transferHistoryLoading}
              entityName="Budget Owner Transfer"
              xptTableHeader={<BOTransferHistoryHeader />}
              allItems={transferHistory}
              allColumns={BUDGET_OWNER_TRANSFER_CONFIG}
              columnDefinitions={getBudgetOwnerTransferDefinitions()}
              itemsPerPage={10}
              selectedItems={[]}
              setSelectedItems={async (items) => {}}
            />
          </Box>
        </div>
      }
    />
  );
};

export default BudgetOwnerTransfer;
