import { SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';

export const POTaggingStatusDisplay: React.FC = () => {
  const isGridDirty = useSelector((state: RootState) => state.poTaggingStore.isGridDirty);

  return (
    <SpaceBetween size="s" direction="horizontal">
      {isGridDirty && <StatusIndicator type="warning">There are unsaved changes</StatusIndicator>}
    </SpaceBetween>
  );
};
