import { GetParameterCommand, GetParameterCommandOutput, SSMClient } from '@aws-sdk/client-ssm';
import { logger } from 'src/analytics/KatalLogger';
import ENVIRONMENT_VARIABLES from 'src/constants/environment-variables';
import { getAWSServiceCredentials } from './auth-token-services';

/**
 * Retrieves a SecureString value from AWS Systems Manager Parameter Store.
 * @param parameterName The name of the parameter in Parameter Store.
 * @returns The string value of the parameter.
 */
export const retrieveParameterFromSSM = async (parameterName: string): Promise<string> => {
  const REGION = ENVIRONMENT_VARIABLES.env.Region;
  const ssmClientConfig = { region: REGION, credentials: await getAWSServiceCredentials() };
  const ssmClient = new SSMClient(ssmClientConfig);
  const command = new GetParameterCommand({ Name: parameterName, WithDecryption: true });
  const response: GetParameterCommandOutput = await ssmClient.send(command);
  if (!response.Parameter?.Value)
    logger.error(`Unable to load Ag-Grid License key from AWS Systems Manager Parameter Store with key ${parameterName}`);
  return response.Parameter?.Value || '';
};
