import { logger } from 'src/analytics/KatalLogger';
import { ValidationErrorDetail, ValidationStatusEntity } from 'src/models/XptGenericModels';
import { UsefulLifeMappingEntity } from 'src/models/xPTMappingModels';

/**
 * Messages used for validation and submission feedback.
 */
export const UsefulLifeValidationMessages = {
  UNIQUE_COMBINATION_DEFAULT_MESSAGE: 'Each cost center code and expense type combination must be unique.',
  UNIQUE_COMBINATION_VALIDATION_FAILED: 'Validation failed: Duplicate combinations detected.',
  UNIQUE_COMBINATION_VALIDATION_SUCCESS: 'Validation succeeded: No duplicate combinations found.',
  MANDATORY_FIELDS_DEFAULT_MESSAGE: 'Cost center code and expense type are required fields.',
  MANDATORY_FIELDS_VALIDATION_FAILED: 'Validation failed: Required fields are missing.',
  MANDATORY_FIELDS_VALIDATION_SUCCESS: 'Validation succeeded: All required fields are present.',
  SUBMISSION_IN_PROGRESS: 'Submitting useful life mapping data.',
  SUBMISSION_SUCCESS: (count: number) => `Submission successful: ${count} record${count !== 1 ? 's' : ''} have been added or updated.`,
  SUBMISSION_FAILED: 'Submission failed: Unable to submit the request.'
};

/**
 * Validates that each combination of cost center code and expense type is unique.
 *
 * @param {UsefulLifeMappingEntity[]} data - The data to validate.
 * @returns {Promise<ValidationStatusEntity>} - The validation status and any errors found.
 */
export const uniqueCombinationValidator = async (data: UsefulLifeMappingEntity[]): Promise<ValidationStatusEntity> => {
  const seen = new Set();
  const validationErrorDetails: ValidationErrorDetail[] = [];

  data.forEach((item, index) => {
    const combination = `${item.cost_center_code}-${item.expense_type}`;
    if (seen.has(combination)) {
      validationErrorDetails.push({
        message: `Duplicate combination of cost center code and expense type found at row ${index + 1}`
      });
    } else {
      seen.add(combination);
    }
  });

  if (validationErrorDetails.length > 0) {
    logger.debug('Unique combination validation failed.' + JSON.stringify(validationErrorDetails, null, 2));
    return {
      colorOverride: 'red',
      validationStatus: 'error',
      validationMessage: UsefulLifeValidationMessages.UNIQUE_COMBINATION_VALIDATION_FAILED,
      validationDefaultMessage: UsefulLifeValidationMessages.UNIQUE_COMBINATION_DEFAULT_MESSAGE,
      validationErrorDetails
    };
  }

  return {
    colorOverride: 'green',
    validationStatus: 'success',
    validationMessage: UsefulLifeValidationMessages.UNIQUE_COMBINATION_VALIDATION_SUCCESS,
    validationDefaultMessage: UsefulLifeValidationMessages.UNIQUE_COMBINATION_DEFAULT_MESSAGE,
    validationErrorDetails: []
  };
};

/**
 * Validates that all mandatory fields (cost center code and expense type) are present.
 *
 * @param {UsefulLifeMappingEntity[]} data - The data to validate.
 * @returns {Promise<ValidationStatusEntity>} - The validation status and any errors found.
 */
export const mandatoryFieldsValidator = async (data: UsefulLifeMappingEntity[]): Promise<ValidationStatusEntity> => {
  const validationErrorDetails: ValidationErrorDetail[] = [];

  data.forEach((item, index) => {
    if (!item.cost_center_code || !item.expense_type) {
      validationErrorDetails.push({
        message: `Mandatory field missing in row ${index + 1}`
      });
    }
  });

  if (validationErrorDetails.length > 0) {
    logger.debug('Mandatory fields validation failed.' + JSON.stringify(validationErrorDetails, null, 2));
    return {
      colorOverride: 'red',
      validationStatus: 'error',
      validationMessage: UsefulLifeValidationMessages.MANDATORY_FIELDS_VALIDATION_FAILED,
      validationDefaultMessage: UsefulLifeValidationMessages.MANDATORY_FIELDS_DEFAULT_MESSAGE,
      validationErrorDetails
    };
  }

  return {
    colorOverride: 'green',
    validationStatus: 'success',
    validationMessage: UsefulLifeValidationMessages.MANDATORY_FIELDS_VALIDATION_SUCCESS,
    validationDefaultMessage: UsefulLifeValidationMessages.MANDATORY_FIELDS_DEFAULT_MESSAGE,
    validationErrorDetails: []
  };
};

/**
 * Runs all validation checks on the useful life mapping data.
 *
 * @param {UsefulLifeMappingEntity[]} data - The data to validate.
 * @returns {Promise<ValidationStatusEntity[]>} - An array of validation statuses and any errors found.
 */
export const validateUsefulLifeData = async (data: UsefulLifeMappingEntity[]): Promise<ValidationStatusEntity[]> => {
  const validations: ValidationStatusEntity[] = [];
  validations.push(await uniqueCombinationValidator(data));
  validations.push(await mandatoryFieldsValidator(data));
  return validations;
};
