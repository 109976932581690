import React, { createContext, useContext, useState, ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface FlashMessage {
  id: string;
  type: 'success' | 'error' | 'warning' | 'info';
  content: string;
}

interface FlashMessageContextType {
  messages: FlashMessage[];
  addMessage: (message: Omit<FlashMessage, 'id'>) => void;
  removeMessage: (id: string) => void;
}

const FlashMessageContext = createContext<FlashMessageContextType | undefined>(undefined);

export const FlashMessageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [messages, setMessages] = useState<FlashMessage[]>([]);

  const addMessage = (message: Omit<FlashMessage, 'id'>) => {
    const id = uuidv4();
    setMessages((prev) => [...prev, { ...message, id }]);
  };

  const removeMessage = (id: string) => {
    setMessages((prev) => prev.filter((message) => message.id !== id));
  };

  return <FlashMessageContext.Provider value={{ messages, addMessage, removeMessage }}>{children}</FlashMessageContext.Provider>;
};

export const useFlashMessage = () => {
  const context = useContext(FlashMessageContext);
  if (context === undefined) {
    throw new Error('useFlashMessage must be used within a FlashMessageProvider');
  }
  return context;
};
